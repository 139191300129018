import { Button, Popup, RoundedCross } from '@gmini/ui-kit'

import React from 'react'

import { ILotSupplier } from '../../../../types'
import { ArrowIcon } from '../../../../icons/ArrowIcon'

import {
  CloseButton,
  Container,
  Footer,
  Header,
  Title,
} from '../lotWinner/LotWinnerModal.styled'

import {
  AgreementInfoText,
  Divider,
  Content,
  AgreementHeaderRow,
  AgreementText,
  AgreementContentWrap,
  ProviderContent,
  CompleteStatus,
  AgreementContent,
} from './AgreementModal.styled'

type AgreementModalProps = {
  open: boolean
  onClose: () => void
  suppliers: ILotSupplier[]
  onAddAgreement: (supplier: ILotSupplier) => void
}

export const AgreementModal = ({
  open,
  onClose,
  suppliers,
  onAddAgreement,
}: AgreementModalProps) => (
  <Popup open={open} onClose={onClose} width='600px'>
    <Container>
      <Header>
        <Title data-test-id='agreementModalHeading'>
          Добавить соглашение(я)
        </Title>
        <CloseButton data-test-id='agreementModalCloseBtn' onClick={onClose}>
          <RoundedCross />
        </CloseButton>
      </Header>
      <Content>
        <AgreementInfoText>
          После определения списка победителей, что бы закончить работу с лотом{' '}
          <br /> необходимо добавить соглашения с поставщиками (победителями)
        </AgreementInfoText>

        <AgreementHeaderRow>
          <AgreementText>Победители лота</AgreementText>
          <AgreementText>Статус соглашения</AgreementText>
        </AgreementHeaderRow>
        <Divider />
      </Content>
      <AgreementContentWrap>
        {suppliers.map((supplier, idx) => (
          <React.Fragment key={supplier.rowId}>
            <AgreementContent data-test-id={`agreementModalSupplier_${idx}`}>
              <ProviderContent>{supplier.name}</ProviderContent>
              {supplier.hasAgreement ? (
                <CompleteStatus>Добавлено</CompleteStatus>
              ) : (
                <Button
                  data-test-id={`agreementModalSupplier_${idx}_AddBtn`}
                  onClick={() => onAddAgreement(supplier)}
                  color='tertiary'
                  rightIcon={<ArrowIcon />}
                >
                  Добавить
                </Button>
              )}
            </AgreementContent>
            <Divider />
          </React.Fragment>
        ))}
      </AgreementContentWrap>

      <Footer>
        <Button data-test-id='agreementModalCancelBtn' onClick={onClose}>
          Закрыть
        </Button>
      </Footer>
    </Container>
  </Popup>
)
