import { ThunkDispatch, AnyAction } from '@reduxjs/toolkit'

import {
  IRow,
  ILotAssigmentAction,
  TStoreInitialState,
  TAgreementUpdateRequest,
  TSearchRequest,
  TAssignmentSearchRequestModel,
  TAssignmentSectionData,
  TAgreementSectionData,
  TAvailableColumn,
} from '../../../types'

import {
  addLotToAssignment,
  changeStatusAssignment,
  deleteLotAssignment,
  editLotAssignment,
  editLotAgreement,
  getFilterFields,
  getSearchAssignmentFilterItems,
  getSearchOtherPageFilterItems,
  modifyAgreement,
  removeAgreements,
  setCategorySupplier,
  getAssignmentSectionData,
  getAgreementSectionData,
  setAvailableColumns,
  resetSearchOtherPage,
  getAssignmentDetail,
  setExportSectionFlag,
} from '../../../actions'

import * as I from './IAppHeader'

const mapStateToProps = (state: TStoreInitialState): I.StateProps => ({
  assignment: state.viewDetails.assignment,
  agreement: state.viewDetails.agreement,
  filterFields: state.filter,
  categoryIdSuppliers: state.suppliers.categoryId,
  availableColumns: state.filter.availableColumns,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
): I.DispatchProps => ({
  addLotToAssignment: (addLotData: ILotAssigmentAction) =>
    dispatch(addLotToAssignment(addLotData)),
  editLotAssignment: (data: IRow, callback: () => void) =>
    dispatch(editLotAssignment({ data, callback })),
  editLotAgreement: (data: IRow, callback: () => void) =>
    dispatch(editLotAgreement({ data, callback })),
  deleteLotAssignment: (id: number, callback: () => void) =>
    dispatch(deleteLotAssignment({ id, callback })),
  changeStatusAssignment: (
    tenderId: number,
    status: string,
    callback: () => void,
  ) => dispatch(changeStatusAssignment({ tenderId, status, callback })),
  getFilterFields: (isPassed = false) => dispatch(getFilterFields(isPassed)),
  editAgreement: (agreement: TAgreementUpdateRequest, callback: () => void) =>
    dispatch(modifyAgreement({ agreement, callback })),
  removeAgreement: (agreementId: string, callback: () => void) =>
    dispatch(removeAgreements({ ids: [agreementId], callback })),
  getSearchOtherPageFilterItems: (
    params: TSearchRequest,
    callback: () => void,
  ): void => {
    // TODO разобраться с хранилищем, происходят ошибки мутации данных
    dispatch(resetSearchOtherPage())
    dispatch(getSearchOtherPageFilterItems({ params, callback }))
  },
  getSearchAssignmentFilterItems: (
    params: TAssignmentSearchRequestModel,
    callback: () => void,
  ) => dispatch(getSearchAssignmentFilterItems({ params, callback })),
  setCategorySupplier: (categoryId: string) =>
    dispatch(setCategorySupplier(categoryId)),
  getAssignmentSectionData: (
    assignmentId: string,
    callback: (data: TAssignmentSectionData) => void,
  ) => dispatch(getAssignmentSectionData({ assignmentId, callback })),
  //Уточнить по составу интерфейса
  getAgreementSectionData: (
    agreementId: string,
    callback: (data: TAgreementSectionData) => void,
  ) => dispatch(getAgreementSectionData({ agreementId, callback })),
  setAvailableColumns: (columns: Array<TAvailableColumn>) =>
    dispatch(setAvailableColumns(columns)),
  resetSearchOtherPage: () => dispatch(resetSearchOtherPage()),
  getAssignmentDetail: (id: string, callback?: () => void) =>
    dispatch(getAssignmentDetail({ assignmentId: id, callback })),
  setExportSectionFlag: (
    tenderId: number,
    sectionId: number,
    value: boolean,
    callback: VoidFunction,
  ) => dispatch(setExportSectionFlag({ tenderId, sectionId, value, callback })),
})

const mappers = {
  mapStateToProps,
  mapDispatchToProps,
}

export default mappers
