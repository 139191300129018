import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const EyeIcon = ({ color = '#353B60', ...props }: SvgComponentType) => (
  <svg
    width='20'
    height='13'
    viewBox='0 0 20 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.9987 2.00004C13.157 2.00004 15.9737 3.77504 17.3487 6.58337C15.9737 9.39171 13.157 11.1667 9.9987 11.1667C6.84036 11.1667 4.0237 9.39171 2.6487 6.58337C4.0237 3.77504 6.84036 2.00004 9.9987 2.00004ZM9.9987 0.333374C5.83203 0.333374 2.2737 2.92504 0.832031 6.58337C2.2737 10.2417 5.83203 12.8334 9.9987 12.8334C14.1654 12.8334 17.7237 10.2417 19.1654 6.58337C17.7237 2.92504 14.1654 0.333374 9.9987 0.333374ZM9.9987 4.50004C11.1487 4.50004 12.082 5.43337 12.082 6.58337C12.082 7.73337 11.1487 8.66671 9.9987 8.66671C8.8487 8.66671 7.91536 7.73337 7.91536 6.58337C7.91536 5.43337 8.8487 4.50004 9.9987 4.50004ZM9.9987 2.83337C7.93203 2.83337 6.2487 4.51671 6.2487 6.58337C6.2487 8.65004 7.93203 10.3334 9.9987 10.3334C12.0654 10.3334 13.7487 8.65004 13.7487 6.58337C13.7487 4.51671 12.0654 2.83337 9.9987 2.83337Z'
      fill={color}
    />
  </svg>
)
