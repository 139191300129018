import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const CollapsedNameIcon = ({
  color = 'rgba(53, 59, 96, 0.5)',
  ...props
}: SvgComponentType) => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='22' height='4' rx='1' fill={color} />
    <rect y='6' width='22' height='4' rx='1' fill={color} />
    <rect y='12' width='22' height='4' rx='1' fill={color} />
    <rect y='18' width='22' height='4' rx='1' fill={color} />
  </svg>
)
