import { createSlice } from '@reduxjs/toolkit'

import * as _ from 'lodash'

import { EEditableModalType, ETreeListItemType } from '../../../constants'
import { IMoveItem, ITreeListItem } from '../../../types'

import { findTreeNode, uuid } from '../../../utils'

import {
  mergeNodeAction,
  removeNodeAction,
  dragAndDropAction,
  getColumns,
} from './actions'
import { CatalogState } from './catalog.types'

const initialCatalogModalState = {
  open: false,
  data: {
    mode: EEditableModalType.INSERT,
    type: ETreeListItemType.FOLDER,
    title: '',
    submitBtnText: '',
  },
}

const initialState: CatalogState = {
  selectedCheckbox: [],
  settingsTreeData: [],
  modifyDict: {
    add: [],
    remove: [],
    edit: [],
    move: [],
    concat: [],
  },
  catalogModal: initialCatalogModalState,
  selectedMenuItemAction: null,
  mergeType: null,
  confirmDialogOpen: false,
  hasUnsavedChanges: false,
  columns: [],
  visibleGroupColumns: [],
  currentGroupId: null,
  filter: {
    searchDate: null,
  },
}

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setSelectedCheckbox(state, { payload }): CatalogState {
      return { ...state, selectedCheckbox: payload }
    },
    setSettingsTreeData(state, { payload }): CatalogState {
      return { ...state, settingsTreeData: payload }
    },
    setModifyDict(state, { payload }): CatalogState {
      return { ...state, modifyDict: payload }
    },
    setCatalogModal(state, { payload }): CatalogState {
      return { ...state, catalogModal: payload }
    },
    saveCatalogModal(state, { payload: { name, nodeType } }): CatalogState {
      const { modifyDict, settingsTreeData, catalogModal } = state
      const modifyDictState = _.cloneDeep(modifyDict)
      const newSettingsData = _.cloneDeep(settingsTreeData)

      if (
        catalogModal.data.mode === EEditableModalType.INSERT ||
        catalogModal.data.mode === EEditableModalType.GROUP
      ) {
        const {
          data: { item: node },
        } = catalogModal
        const item = {
          id: uuid(),
          name,
          type: nodeType,
          parentId: node?.id,
          count: 0,
          content: [],
        }

        modifyDictState.add.push(item)

        if (!item.parentId) {
          newSettingsData?.push(item)
        } else {
          const nodeItem = findTreeNode(newSettingsData, item.parentId)

          if (nodeItem) {
            nodeItem.content.push(item)
          }
        }
      } else {
        const node = _.get(catalogModal, 'data.item', {}) as ITreeListItem
        const item =
          modifyDictState.add.find((md: ITreeListItem) => md.id === node.id) ||
          modifyDictState.edit.find((md: ITreeListItem) => md.id === node.id)
        if (item) {
          item.name = name
        } else {
          modifyDictState.edit.push({
            ...node,
            name,
            content: [],
          })
        }

        const nodeItem = findTreeNode(newSettingsData, node.id)
        if (nodeItem) {
          nodeItem.name = name
        }
      }

      return {
        ...state,
        modifyDict: modifyDictState,
        settingsTreeData: newSettingsData,
        catalogModal: initialCatalogModalState,
        hasUnsavedChanges: true,
      }
    },
    setSelectedMenuItemAction(state, { payload }): CatalogState {
      return { ...state, selectedMenuItemAction: payload }
    },
    removeNode: removeNodeAction,
    removeSelectedCheckbox(state): CatalogState {
      const modifyDictState = _.cloneDeep(state.modifyDict)
      let newSettingsData = _.cloneDeep(state.settingsTreeData)

      state.selectedCheckbox.forEach((id: string) => {
        _.remove(
          modifyDictState.concat,
          (md: IMoveItem) => md.sourceId === id || md.destinationId === id,
        )
        _.remove(
          modifyDictState.move,
          (md: IMoveItem) => md.sourceId === id || md.destinationId === id,
        )

        if (modifyDictState.add.some((md: ITreeListItem) => md.id === id)) {
          modifyDictState.add = modifyDictState.add.filter(
            (md: ITreeListItem) => md.id !== id,
          )
        } else {
          if (modifyDictState.edit.some((md: ITreeListItem) => md.id === id)) {
            modifyDictState.edit = modifyDictState.edit.filter(
              (md: ITreeListItem) => md.id !== id,
            )
          }
          modifyDictState.remove.push(id)
        }

        const node = findTreeNode(newSettingsData, id)

        if (node) {
          if (!node.parentId) {
            newSettingsData = newSettingsData.filter(
              (item: ITreeListItem) => item.id !== id,
            )
          } else {
            const parentNode = findTreeNode(newSettingsData, node.parentId)
            if (parentNode) {
              parentNode.content = parentNode.content.filter(
                item => item.id !== id,
              )
            }
          }
        }
      })

      return {
        ...state,
        modifyDict: modifyDictState,
        settingsTreeData: newSettingsData,
        selectedCheckbox: [],
        hasUnsavedChanges: true,
      }
    },
    setMergeType(state, { payload: { mergeType } }): CatalogState {
      return { ...state, mergeType }
    },
    mergeNode: mergeNodeAction,
    setConfirmDialogOpen(state, { payload }): CatalogState {
      return { ...state, confirmDialogOpen: payload }
    },
    dragAndDrop: dragAndDropAction,
    setCopyId(state, { payload }): CatalogState {
      return { ...state, copyId: payload }
    },
    setCurrentGroupId(state, action): CatalogState {
      return { ...state, currentGroupId: action.payload }
    },
    reset(): CatalogState {
      return initialState
    },
    resetColumns(state): CatalogState {
      state.columns = []
      return state
    },
    setFilterSearchDate(state, { payload }): CatalogState {
      return { ...state, filter: payload }
    },
  },
  extraReducers: {
    [`${getColumns.fulfilled}`]: (state, { payload }): void => {
      state.columns = payload
    },
  },
})

export const {
  setSelectedCheckbox,
  setSettingsTreeData,
  setModifyDict,
  setCatalogModal,
  removeNode,
  removeSelectedCheckbox,
  setSelectedMenuItemAction,
  mergeNode,
  setMergeType,
  saveCatalogModal,
  setConfirmDialogOpen,
  dragAndDrop,
  setCopyId,
  reset,
  resetColumns,
  setCurrentGroupId,
  setFilterSearchDate,
} = catalogSlice.actions

export { copyNodeAction, getColumns } from './actions'

export default catalogSlice.reducer
