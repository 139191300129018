import React from 'react'
import * as _ from 'lodash'
import { TableBody } from '@material-ui/core'

import { IColumn } from '../../../../../types'

import * as I from './IImprovedTableHeadDisplayColumnsBody'
import { ImprovedTableHeadDisplayColumnsRow } from './ImprovedTableHeadDisplayColumnsRow/ImprovedTableHeadDisplayColumnsRow'

export const ImprovedTableHeadDisplayColumnsBody: React.FC<I.OwnProps> = ({
  columns,
  visibleColumns,
  ifValue,
  showSections,
  showEditPropertyButtons,
  onColumnVisibilityChanged,
  onEditPropertyBtnClick,
  removePropertyHandler,
}): React.ReactElement => {
  const checkIfVisible = (key: number): boolean =>
    _.findIndex(visibleColumns, c => c.key === key) !== -1

  return (
    <TableBody>
      {columns.map((column: IColumn, index: number) => {
        const isVisible = checkIfVisible(column.key)

        if (column.base === ifValue) {
          return (
            <ImprovedTableHeadDisplayColumnsRow
              index={index}
              column={column}
              isVisible={isVisible}
              showSections={showSections}
              onColumnVisibilityChanged={onColumnVisibilityChanged}
              key={column.key}
              showEditPropertyButtons={showEditPropertyButtons}
              onEditPropertyBtnClick={onEditPropertyBtnClick}
              removePropertyHandler={removePropertyHandler}
            />
          )
        }
        return null
      })}
    </TableBody>
  )
}
