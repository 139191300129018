import { alpha, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    borderRadius: '5px',
    backgroundColor: '#F9F9FB',
    '&:hover': {
      backgroundColor: alpha('#F9F9FB', 0.6),
    },
    margin: '0 12px 0 0',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '32px',
      width: 'auto',
    },
  },
  searchIcon: {
    zIndex: 1,
    width: '20px',
    backgroundColor: '#F9F9FB',
    marginLeft: '10px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  closeIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      fontSize: '10px',
    },
  },
  inputInput: {
    width: '177px',
    maxHeight: '40px',
    '& .MuiAutocomplete-root': {
      transition: theme.transitions.create('width'),
      height: '40px',
      color: 'inherit',
    },
    '&.MuiAutocomplete-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused': {
      width: '300px',
      cursor: 'text',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },
    // '& .MuiInputBase-input': {
    //   textIndent: '20px',
    // },
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
    '&.MuiAutocomplete-fullWidth': {
      maxWidth: '397px',
    },
  },
}))

export default useStyles
