import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../../store'

const moduleName = 'catalog'

export const getCatalog = createSelector(
  (state: RootState) => state[moduleName],
  catalog => catalog,
)

export const getSelectedCheckbox = createSelector(
  getCatalog,
  catalog => catalog.selectedCheckbox,
)

export const getSettingsTreeData = createSelector(
  getCatalog,
  catalog => catalog.settingsTreeData,
)
export const getSelectedMenuItemAction = createSelector(
  getCatalog,
  catalog => catalog.selectedMenuItemAction,
)

export const getConfirmDialogOpen = createSelector(
  getCatalog,
  catalog => catalog.confirmDialogOpen,
)

export const getCatalogColumns = createSelector(
  getCatalog,
  catalog => catalog.columns,
)

export const getCurrentGroupId = createSelector(
  getCatalog,
  catalog => catalog.currentGroupId,
)
