import { createSlice } from '@reduxjs/toolkit'

type MaintenanceState = {
  enabled: boolean
}

const initialState: MaintenanceState = {
  enabled: false,
}

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    enableMaintenance: state => {
      state.enabled = true
    },
    disableMaintenance: state => {
      state.enabled = false
    },
  },
})

export const {
  enableMaintenance,
  disableMaintenance,
} = maintenanceSlice.actions

export const maintenance = maintenanceSlice.reducer
