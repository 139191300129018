import styled from 'styled-components'

export const Field = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 77px;

  & > div {
    width: 50%;
    & .MuiAutocomplete-inputRoot {
      padding: 2.5px 65px 2.5px 8px;
    }
    p {
      position: absolute;
      bottom: -23px;
    }
  }
`
export const FieldContainer = styled.div`
  width: 100%;
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`
export const ButtonContainer = styled.div`
  height: 40px;
  align-self: flex-end;
  display: flex;
  align-items: center;
`
