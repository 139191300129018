import React, { useState } from 'react'
import * as Sentry from '@sentry/react'
import { toastr } from 'react-redux-toastr'

import { AssignmentService } from '../../../services/assignments-service'

import { TRowData } from '../../../types'
import { EAPIResponseStatus } from '../../../constants'
import MessageWrapper from '../../MessageWrapper/MessageWrapper'

export const useAssignmentList = (
  setOptionsListLoading: (isLoading: boolean) => void,
): [typeof state, typeof updateState, typeof clearState] => {
  const [state, setState] = useState<TRowData>([])

  const has = <T extends Record<string, unknown>, K extends string>(
    key: K,
    x: T,
  ): x is T => key in x

  const updateState = async (debounced: string): Promise<void> => {
    setOptionsListLoading(true)

    const [, result] = await AssignmentService.searchTenders(debounced)

    if (result.data.status === EAPIResponseStatus.SUCCESS) {
      const assignmentsValues = result.data.data
      if (
        typeof assignmentsValues !== 'object' ||
        !Array.isArray(assignmentsValues) ||
        assignmentsValues.some(item =>
          Object.keys(item).some(k => !has(k, { key: '', value: '' })),
        )
      ) {
        throw new Error(`Получен неверный аргумент: ${assignmentsValues}`)
      }
      setOptionsListLoading(false)
      setState(assignmentsValues)
    } else {
      const err =
        result.data.status === EAPIResponseStatus.FAIL
          ? result.data.data
          : result.data.message

      const msg = <div>{err}</div>

      toastr.error('', '', {
        component: <MessageWrapper>{msg}</MessageWrapper>,
        progressBar: false,
        showCloseButton: false,
      })
      toastr.error(
        '',
        'При получении списка тендерных заданий произошла ошибка!',
      )
      Sentry.captureException(err)
      setOptionsListLoading(false)
      setState([])
    }
  }

  const clearState = (): void => {
    setState([])
  }

  return [state, updateState, clearState]
}
