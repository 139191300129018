import { toastr } from 'react-redux-toastr'

import { createAsyncThunk } from '@reduxjs/toolkit'

import { SuppliersService } from '../../services/supplier-service'
import {
  IColumn,
  IKeyValuePair,
  IRow,
  TSearchRequest,
  TRowData,
  TTableData,
  TSuppliersFilters,
} from '../../types'
import { EActionTypes, EAPIResponseStatus } from '../../constants'

import { modalService } from '../../components/Modal/ModalService'

import { downloadFile } from '../../services/base-api-service/utils'

import { actionCreator, IActionCreator } from '../BaseAction'

import { setAvailableColumns } from '../Filter'

export const getSuppliersNew = createAsyncThunk<
  TTableData,
  {
    setLoading?: (data: boolean) => void
    params: Partial<TSearchRequest>
    suppliersFilters: TSuppliersFilters
    callback?: () => void
  }
>(
  'suppliers/getSuppliersNew',
  async (
    { callback, params, suppliersFilters, setLoading },
    { rejectWithValue, dispatch },
  ) => {
    setLoading?.(true)
    const [err, result] = await SuppliersService.getSuppliers(
      params,
      suppliersFilters,
    )

    if (err) {
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    dispatch(
      setSuppliersFilters({
        agreedSuppliers: suppliersFilters.agreedSuppliers,
        notAgreedSuppliers: suppliersFilters.notAgreedSuppliers,
        expiredSuppliers: suppliersFilters.expiredSuppliers,
      }),
    )
    if (result.data.data?.positionPage) {
      dispatch(setSuppliers(result.data.data.positionPage))
      dispatch(
        setAvailableColumns(
          result.data.data.positionPage.columns.map(column => ({
            name: column.title,
            type: column.type,
          })),
        ),
      )
      setLoading?.(false)
    }
    const data: TTableData = result.data.data?.positionPage || {
      columns: [],
      data: [],
      pagination: { page: 0, rowsPerPage: 20, total: 0 },
    }
    return data
  },
)

export const createSupplier = createAsyncThunk<
  unknown,
  { supplier: TRowData; callback: () => void }
>(
  'suppliers/createSupplier',
  async ({ supplier, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.addSupplier(supplier)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(addSupplier(result.data.data))
      callback()
    }
  },
)

export const modifySupplier = createAsyncThunk<
  unknown,
  { supplier: IRow; callback: () => void }
>(
  'suppliers/modifySupplier',
  async ({ supplier, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.editSupplier(supplier)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.status === EAPIResponseStatus.SUCCESS && result.data.data) {
      dispatch(editSupplier(result.data.data))
      toastr.success('', 'Поставщик успешно отредактирован')
      callback()
    }
  },
)

export const removeSupplier = createAsyncThunk<
  unknown,
  { ids: Array<string>; callback: () => void }
>(
  'suppliers/removeSupplier',
  async ({ ids, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await SuppliersService.deleteSuppliers(ids)

    if (err) {
      throw rejectWithValue(err)
    }
    callback()
  },
)

export const checkSupplierExistence = createAsyncThunk<
  unknown,
  {
    inn: string
    kpp: string
    excludeId: string
    callback: (isExisted: boolean) => void
  }
>(
  'suppliers/checkSupplierExistence',
  async ({ inn, kpp, excludeId, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.checkSupplierExistence(
      inn,
      kpp,
      excludeId,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
      return
    }
    callback(false)
  },
)

export const addSupplierProperty = createAsyncThunk<
  unknown,
  { model: IColumn; categoryId: string; callback?: () => void }
>(
  'suppliers/addSupplierProperty',
  async ({ model, categoryId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await SuppliersService.addProperty(model, categoryId)

    if (err) {
      throw rejectWithValue(err)
    }

    callback?.()
  },
)

export const editSupplierProperty = createAsyncThunk<
  unknown,
  { model: IColumn; callback?: () => void }
>(
  'suppliers/editSupplierProperty',
  async ({ model, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await SuppliersService.editProperty(model)

    if (err) {
      throw rejectWithValue(err)
    }

    callback?.()
  },
)

export const removeSupplierProperty = createAsyncThunk<
  unknown,
  { key: string; callback: () => void }
>(
  'suppliers/removeSupplierProperty',
  async ({ key, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await SuppliersService.removeProperty(key)

    if (err) {
      throw rejectWithValue(err)
    }

    callback?.()
  },
)

export const importSuppliers = createAsyncThunk<
  unknown,
  { file: File; callback: () => void }
>(
  'suppliers/importSuppliers',
  async ({ file, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.importSuppliers(file)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const { failedCount, failed } = result.data.data

      if (failedCount) {
        const text = failed.map(
          ({ inn, kpp, problems }) =>
            `ИНН ${inn}, КПП ${kpp}: ${problems.join('; ')}`,
        )

        modalService.openTextModal('Импорт завершен с ошибками', text)
      } else {
        toastr.success('', 'Импорт успешно завершен!')
      }
    }
  },
)

export function setCategorySupplier(
  categoryId: string,
): IActionCreator<string> {
  return actionCreator<string>(EActionTypes.SET_CATEGORY_SUPPLIER, categoryId)
}

export function setSuppliers(
  suppliers: TTableData,
): IActionCreator<TTableData> {
  return actionCreator<TTableData>(EActionTypes.SET_SUPPLIERS, suppliers)
}

export function setSuppliersFilters(
  suppliersFilters: TSuppliersFilters,
): IActionCreator<TSuppliersFilters> {
  return actionCreator<TSuppliersFilters>(
    EActionTypes.SET_SUPPLIERS_FILTERS,
    suppliersFilters,
  )
}

export function addSupplier(supplier: IRow): IActionCreator<IRow> {
  return actionCreator<IRow>(EActionTypes.ADD_SUPPLIER, supplier)
}

export function editSupplier(supplier: IRow): IActionCreator<IRow> {
  return actionCreator<IRow>(EActionTypes.EDIT_SUPPLIER, supplier)
}

export function resetSuppliers(): IActionCreator<null> {
  return actionCreator<null>(EActionTypes.RESET_SUPPLIERS, null)
}

export const setSelectedSuppliers = (
  selected: any[],
): IActionCreator<string[]> =>
  actionCreator<string[]>(EActionTypes.SET_SELECTED_SUPPLIERS, selected)

export const addSectionSupplier = createAsyncThunk<
  unknown,
  { name: string; callback: (model: IKeyValuePair) => void }
>(
  'suppliers/addSectionSupplier',
  async ({ name, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.addSection(name)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
    }
  },
)

export const editSectionSupplier = createAsyncThunk<
  unknown,
  { value: IKeyValuePair; callback: (model: IKeyValuePair) => void }
>(
  'suppliers/editSectionSupplier',
  async ({ value, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SuppliersService.editSection(
      value.key,
      value.value,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
    }
  },
)

export const removeSectionSupplier = createAsyncThunk<
  unknown,
  { key: string; callback: () => void }
>(
  'suppliers/removeSectionSupplier',
  async ({ key, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await SuppliersService.removeSection(key)

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const exportTemplate = createAsyncThunk<unknown>(
  'suppliers/exportTemplate',
  async (_, { rejectWithValue, dispatch }) => {
    const [err, blob] = await SuppliersService.exportTemplate()

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)
