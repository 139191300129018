import React from 'react'
import {
  FormControl,
  FormHelperText,
  Input,
  FormLabel,
  InputBaseProps,
} from '@material-ui/core'

import { useStyles } from './Style'

type Props = {
  error?: string | null | boolean
  label?: string | null
  maxLength?: number
} & Omit<InputBaseProps, 'error'>

export const TextField: React.FC<Props> = ({
  error,
  label,
  maxLength,
  inputProps,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.root} error={!!error}>
      <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>
      <Input
        fullWidth
        disableUnderline
        {...restProps}
        classes={{ input: classes.input }}
        inputProps={{
          maxLength,
          ...inputProps,
        }}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}
