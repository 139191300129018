import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Divider, List, SvgIcon } from '@material-ui/core'

import { TTreeList, TTreeModifyDict } from '../../../../types'
import { ButtonComponent } from '../../../Button/Button'
import {
  EButtonIcon,
  EButtonType,
  EEditableModalType,
  ETreeListItemType,
} from '../../../../constants'

import { useAppDispatch } from '../../../../store'
import { getCatalog } from '../../store/catalog-selectors'
import {
  setSettingsTreeData,
  setCatalogModal,
  removeSelectedCheckbox,
  saveCatalogModal,
  setConfirmDialogOpen,
} from '../../store/catalog-slice'
import usePositionCatalogStyles from '../../styles/Style'

import { SettingListItems } from './components/setting-list-items'
import { CatalogModal } from './components/catalog-modal'

type CatalogSettingProps = {
  saveAllChanges: (modifyDictParam: TTreeModifyDict) => void
  handleOpenSettings: () => void
  settingsTreeData: TTreeList
  disableNodeMovingConcat?: boolean
  disableRootNodeAdding?: boolean
  maxLevel?: number
  withCopyingElements?: boolean
  getCategories?: () => void
  handleDiscardChanges: () => void
  withGroup?: boolean
}

export const CatalogSetting: React.FC<CatalogSettingProps> = ({
  saveAllChanges,
  handleOpenSettings,
  settingsTreeData,
  disableRootNodeAdding,
  disableNodeMovingConcat,
  maxLevel,
  withCopyingElements,
  getCategories,
  handleDiscardChanges,
  withGroup,
}) => {
  useEffect(() => {
    dispatch(setSettingsTreeData(settingsTreeData))
    // eslint-disable-next-line
  }, [])
  const {
    selectedCheckbox,
    catalogModal,
    hasUnsavedChanges,
    modifyDict,
  } = useSelector(getCatalog)
  const dispatch = useAppDispatch()
  const classes = usePositionCatalogStyles()

  const handleCloseCatalogModal = (): void => {
    dispatch(setCatalogModal({}))
  }

  const handleRemoveSelectedCheckbox = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    event.stopPropagation()
    dispatch(removeSelectedCheckbox())
  }

  const handleSubmitCatalogModal = ({
    name,
    nodeType,
  }: {
    name: string
    nodeType: ETreeListItemType
  }): void => {
    dispatch(saveCatalogModal({ name, nodeType }))
  }

  return (
    <>
      <div role='presentation'>
        <List className={classes.drawerTitle}>
          <h5 data-test-id='catalogSettingsHeading'>Каталог</h5>
          <SvgIcon
            data-test-id='catalogSettingsCloseBtn'
            onClick={(): void => {
              if (hasUnsavedChanges) {
                dispatch(setConfirmDialogOpen(true))
                return
              }
              handleOpenSettings()
            }}
            viewBox={'0 0 14 13'}
            style={{ fontSize: '10px' }}
          >
            <path
              d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
              fill='#535F77'
            />
          </SvgIcon>
        </List>
        <Divider />
        <SettingListItems
          maxLevel={maxLevel}
          disableNodeMovingConcat={disableNodeMovingConcat}
          withCopyingElements={withCopyingElements}
          getCategories={getCategories}
          handleDiscardChanges={handleDiscardChanges}
          withGroup={withGroup}
        />
        <List className={classes.drawerFooter}>
          {selectedCheckbox.length > 0 && (
            <div className={classes.secondPartFooter}>
              <ButtonComponent
                data-test-id='catalogSettingsDeleteBtn'
                text={'Удалить'}
                type={EButtonType.WARNING}
                typeIcon={EButtonIcon.DELETE}
                onClick={handleRemoveSelectedCheckbox}
              />
            </div>
          )}
          {selectedCheckbox.length === 0 && (
            <div className={classes.secondPartFooter}>
              {withGroup && (
                <ButtonComponent
                  data-test-id='catalogSettingsAddGroupBtn'
                  text={'Группа'}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.ADD}
                  onClick={() =>
                    dispatch(
                      setCatalogModal({
                        open: true,
                        data: {
                          mode: EEditableModalType.INSERT,
                          type: ETreeListItemType.GROUP,
                          title: 'Добавить группу',
                          submitBtnText: 'Добавить',
                        },
                      }),
                    )
                  }
                />
              )}

              {!disableRootNodeAdding && (
                <ButtonComponent
                  data-test-id='catalogSettingsAddSectionBtn'
                  text={'Раздел'}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.ADD}
                  onClick={() =>
                    dispatch(
                      setCatalogModal({
                        open: true,
                        data: {
                          mode: EEditableModalType.INSERT,
                          type: ETreeListItemType.FOLDER,
                          title: 'Добавить раздел',
                          submitBtnText: 'Добавить',
                        },
                      }),
                    )
                  }
                />
              )}
              <ButtonComponent
                data-test-id='catalogSettingsSaveBtn'
                text={'Сохранить'}
                type={EButtonType.PRIMARY}
                typeIcon={EButtonIcon.SAVE}
                onClick={(): void => saveAllChanges(modifyDict)}
              />
            </div>
          )}
        </List>
      </div>
      {catalogModal.open && (
        <CatalogModal
          open={catalogModal.open}
          mode={catalogModal.data.mode}
          type={catalogModal.data.type}
          node={catalogModal.data.item}
          title={catalogModal.data.title}
          submitBtnText={catalogModal.data.submitBtnText}
          handleClose={handleCloseCatalogModal}
          handleSubmitCatalogModal={handleSubmitCatalogModal}
        />
      )}
    </>
  )
}
