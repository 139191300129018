/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Theme, createStyles } from '@material-ui/core'

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '25px',
      '& .MuiTypography-h6': {
        fontFamily: 'Proxima Nova Regular',
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '18px',
      color: theme.palette.grey[500],
    },
  })
