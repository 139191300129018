import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    borderTop: '1px solid #E2E4E9',
    borderBottom: '1px solid #E2E4E9',
    borderRadius: 0,
    '& .MuiIconButton-label': {
      color: '#4C5ECF',
    },
    '&.MuiAccordion-rounded:last-child': {
      borderRadius: 0,
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      content: 'none',
    },
    '& .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(-90deg)',
    },
    '& .Mui-expanded': {
      transform: 'rotate(0)',
    },
  },
  summary: {
    flexDirection: 'row-reverse',
    background: '#F9F9FB',
    padding: 0,
    '& .MuiIconButton-edgeEnd': {
      margin: 0,
    },
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  summaryContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '12px',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  title: {
    color: '#4C5ECF',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  actions: {
    color: '#4C5ECF',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  details: {
    padding: 0,
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
}))
