import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  autocompletePaper: {
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
  },
  editBtn: {
    height: '62px !important',
    width: '62px !important',
    display: 'inline-flex !important',
  },
}))
