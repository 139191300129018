import styled from 'styled-components'

export const Popup = styled.div`
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 7px 0;
  background: #fff;
  z-index: 10000;
  top: 93%;
  right: 33%;
`

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 40px 28px;
`

export const Divider = styled.div`
  background: #e5e7f1;
  height: 1px;
  margin: 0 24px;
`
export const ActionList = styled.div`
  margin-top: 14px;
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
  padding: 0 24px;
  color: #000335;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;
  &:hover {
    background: #f4f4f8;
    cursor: pointer;
  }
`

export const Name = styled.div`
  margin-top: 24px;
  white-space: nowrap;
  color: #000335;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
`

export const Role = styled.div`
  text-transform: uppercase;
  color: #fff;
  margin-top: 6px;
  background: #4c5ecf;
  border-radius: 25px;
  padding: 5px 14px;
  line-height: 14px;
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.15px;
  text-align: center;
`
