import styled from 'styled-components'

type FilterButtonProps = {
  active: boolean
}

export const FilterButtonWrap = styled.div`
  display: flex;
  border: ${({ active }: FilterButtonProps) =>
    active ? '1px solid #4c5ecf' : '1px solid #F9F9FB'};
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  position: relative;
`
export const FilterContent = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ active }: FilterButtonProps) =>
    active ? '#4c5ecf' : '#F9F9FB'};
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ active }: FilterButtonProps) =>
      active ? '#384dd4' : '#F4F4F8'};
  }
  padding: 16px 12px 16px 15px;
  gap: 11px;
`
export const FilterText = styled.div`
  color: ${({ active }: FilterButtonProps) => (active ? '#fff' : '#353B60')};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
`
export const FilterEndContent = styled.div`
  display: flex;
  align-items: baseline;
  background: #fff;
  border-radius: 0 4px 4px 0;
  &:hover {
    path:first-child {
      fill: #384dd4;
    }
  }
`
export const CancelIconWrap = styled.div`
  padding: 8px;
`
