import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  input: {
    color: '#1B2B4B',
    padding: '15px',
    fontSize: '14px',
    fontFamily: 'Proxima Nova Regular',
    lineHeight: '17px',
    backgroundColor: '#F9F9FB',
    transition: 'background-color 200ms',
    borderRadius: '4px',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '.Mui-error > &': {
      borderColor: '#f44336',
    },
  },
  label: {
    color: '#1B2B4B',
    height: '17px',
    fontSize: '14px',
    alignSelf: 'flex-start',
    fontFamily: 'Proxima Nova Light',
    lineHeight: '17px',
    '&.Mui-focused': {
      color: '#1B2B4B',
    },
    '&.Mui-error': {
      color: '#f44336',
    },
  },
}))
