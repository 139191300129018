import { apiClient, ApiResponse } from '../base-api-service/base-api-service'

import { IAPIResult, User } from '../../types'

const headers: Record<string, string> = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export class AuthService {
  static getCurrentUser(): Promise<ApiResponse<IAPIResult<User>>> {
    return apiClient.get({
      baseUrl: '/api/auth/v1',
      path: '/auth/current-user',
      params: {
        'retrieve-permissions': true,
        'project-urn': 'DEFAULT',
      },
      headers,
    })
  }
}
