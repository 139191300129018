import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { List } from '@material-ui/core'

import { ITreeListItem } from '../../../../../types'
import { ETreeListItemType } from '../../../../../constants'

import { useAppDispatch } from '../../../../../store'
import useListItemStyles from '../../../styles/useListItemStyles'
import {
  setSelectedCheckbox,
  setSelectedMenuItemAction,
  dragAndDrop,
} from '../../../store/catalog-slice'
import {
  getSelectedMenuItemAction,
  getSettingsTreeData,
} from '../../../store/catalog-selectors'

import { MenuMoveAction } from './menu-move-action'
import { SettingListItem } from './setting-list-item'
import { MenuItemAction } from './menu-item-action'

export type SettingListItemsProps = {
  disableNodeMovingConcat?: boolean
  maxLevel?: number
  withCopyingElements?: boolean
  getCategories?: () => void
  handleDiscardChanges: () => void
  withGroup?: boolean
}

export const SettingListItems: React.FC<SettingListItemsProps> = ({
  disableNodeMovingConcat,
  maxLevel,
  withCopyingElements,
  getCategories,
  handleDiscardChanges,
  withGroup,
}) => {
  const classes = useListItemStyles()
  const dispatch = useAppDispatch()
  const selectedMenuItemAction = useSelector(getSelectedMenuItemAction)
  const items = useSelector(getSettingsTreeData)
  /**
   * при нажатие многоточих выбирается HTML element для открытия меню
   */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [
    selectedAnchorMenuItem,
    setSelectedAnchorMenuItem,
  ] = useState<null | HTMLElement>(null)
  /**
   * Выбирается элемент, во время перестаскивания.
   */
  const [draggedItem, setDraggedItem] = useState<ITreeListItem>()
  const [open, setOpen] = useState<Record<string, boolean>>({})
  const [checked, setChecked] = useState<string[]>([])
  const [depthLevel, setDepthLevel] = useState<number>(0)

  const handleItemClick = (id: string, type: ETreeListItemType) => (): void => {
    if (type === ETreeListItemType.LIST) {
      return
    }
    const isOpen = get(open, id, false)
    setOpen({ ...open, [id]: !isOpen })
  }

  const handleCheckbox = (id: string) => (): void => {
    const newState = checked.includes(id)
      ? checked.filter(el => el !== id)
      : [...checked, id]
    setChecked(newState)
    dispatch(setSelectedCheckbox(newState))
  }

  const handleMenuItemAction = (
    node: ITreeListItem,
    depthLevelParam: number,
  ) => (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    dispatch(setSelectedMenuItemAction(node))
    setDepthLevel(depthLevelParam)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleDragAndDrop = (params: {
    sourceId: string
    destinationId?: string
    beforeId?: string
  }): void => {
    dispatch(dragAndDrop(params))
  }

  return (
    <>
      <List
        component='nav'
        className={classes.settingsTree}
        style={{ background: 'white' }}
      >
        {items.map((item: ITreeListItem, idx) => (
          <SettingListItem
            key={item.id}
            node={item}
            level={0}
            open={open}
            checked={checked}
            handleMenuItemAction={handleMenuItemAction}
            handleItemClick={handleItemClick}
            handleCheckbox={handleCheckbox}
            draggedItem={draggedItem}
            setDraggedItem={setDraggedItem}
            handleDragAndDrop={handleDragAndDrop}
            dataTestIdPostfix={`${idx}`}
          />
        ))}
      </List>
      {Boolean(anchorEl) && selectedMenuItemAction && (
        <MenuItemAction
          setSelectedAnchorMenuItem={setSelectedAnchorMenuItem}
          anchorEl={anchorEl}
          handleClose={handleClose}
          selectedMenuItemAction={selectedMenuItemAction}
          depthLevel={depthLevel}
          maxLevel={maxLevel}
          disableNodeMovingConcat={disableNodeMovingConcat}
          withCopyingElements={withCopyingElements}
          getCategories={getCategories}
          handleDiscardChanges={handleDiscardChanges}
          withGroup={withGroup}
          listItems={items}
        />
      )}
      {Boolean(selectedAnchorMenuItem) && selectedMenuItemAction && (
        <MenuMoveAction
          handleCloseMenuItemAction={handleClose}
          selectedAnchorMenuItem={selectedAnchorMenuItem}
          setSelectedAnchorMenuItem={setSelectedAnchorMenuItem}
          selectedMenuItemAction={selectedMenuItemAction}
          items={items}
        />
      )}
    </>
  )
}
