/* eslint-disable import/no-anonymous-default-export */
import * as _ from 'lodash'

import { TTableData, IRow, TAgreementState } from '../../types'
import { EActionTypes } from '../../constants'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetAgreementsAction = IBaseActionCreator<
  EActionTypes.SET_AGREEMENTS,
  TTableData
>

export type IAddAgreementAction = IBaseActionCreator<
  EActionTypes.ADD_AGREEMENT,
  IRow
>

export type IEditAgreementAction = IBaseActionCreator<
  EActionTypes.EDIT_AGREEMENT,
  IRow
>

export type ISetAgreementCategoryAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORY_AGREEMENT,
  string
>

export type TAgreementsAction =
  | ISetAgreementsAction
  | IAddAgreementAction
  | IEditAgreementAction
  | ISetAgreementCategoryAction

const initialState: TAgreementState = {
  categoryId: '',
  data: {
    columns: [],
    data: [],
    pagination: {
      page: 1,
      rowsPerPage: 10,
      total: 1,
    },
  },
}

export default (
  state = initialState,
  action: TAgreementsAction,
): TAgreementState => {
  switch (action.type) {
    case EActionTypes.SET_AGREEMENTS: {
      const newState = _.chain(state)
        .cloneDeep()
        .extend({ data: action.payload })
        .value()
      return newState
    }
    case EActionTypes.ADD_AGREEMENT:
      return _.chain(state)
        .cloneDeep()
        .extend({
          data: {
            columns: [...state.data.columns],
            data: [action.payload, ...state.data.data],
            pagination: {
              ...state.data.pagination,
              total: state.data.pagination.total + 1,
            },
          },
        })
        .value()
    case EActionTypes.EDIT_AGREEMENT: {
      const row = state.data.data.find(
        item => item.rowId === action.payload.rowId,
      )

      if (row) {
        _.extend(row, { ...action.payload })
      }

      return _.cloneDeep(state)
    }
    case EActionTypes.SET_CATEGORY_AGREEMENT: {
      return _.chain(state)
        .cloneDeep()
        .extend({ categoryId: action.payload })
        .value()
    }
    default:
      return state
  }
}
