import { createAsyncThunk } from '@reduxjs/toolkit'

import { TSearchModel, TSearchRequest } from '../../../types'

import { EActionTypes } from '../../../constants'
import { treeify, LocalStorageHelper } from '../../../utils'
import { SearchService } from '../../../services/search-service/search-service'

import { actionCreator, IActionCreator } from '../../BaseAction'
import { setCategory } from '../../Positions'
import { setCategoryAgreement } from '../../Agreements'
import { setCategorySupplier } from '../../Suppliers'

export const getSearchOtherPageFilterItems = createAsyncThunk<
  unknown,
  { params: TSearchRequest; callback?: () => void }
>(
  'suppliers/getSearchOtherPageFilterItems',
  async ({ params, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SearchService.searchOtherSearhFilterItems(
      params,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      if (!result.data.data.categoriesData.length) {
        return
      }
      result.data.data.categoriesData = treeify([
        ...result.data.data.categoriesData,
      ])
      dispatch(
        searchOtherPage({
          responseParams: result.data.data,
          requestParams: params,
          isSearchRequest: true,
        }),
      )

      if (window.location.pathname.includes('/positions')) {
        dispatch(setCategory(result.data.data.categoryId))
        LocalStorageHelper.set<string>(
          `/positions_categoryId`,
          result.data.data.categoryId,
        )
      }
      if (window.location.pathname.includes('/agreements')) {
        dispatch(setCategoryAgreement(result.data.data.categoryId))
        LocalStorageHelper.set<string>(
          `/agreements_categoryId`,
          result.data.data.categoryId,
        )
      }
      if (window.location.pathname.includes('/suppliers')) {
        dispatch(setCategorySupplier(result.data.data.categoryId))
        LocalStorageHelper.set<string>(
          `/suppliers_categoryId`,
          result.data.data.categoryId,
        )
      }

      callback?.()
    }
  },
)

export function searchOtherPage(
  otherPageSearch: TSearchModel,
): IActionCreator<TSearchModel> {
  return actionCreator<TSearchModel>(
    EActionTypes.SEARCH_OTHER_PAGE,
    otherPageSearch,
  )
}

export const resetSearchOtherPage = (): IActionCreator<string> =>
  actionCreator<string>(EActionTypes.RESET_SEARCH_OTHER_PAGE, '')
