import React from 'react'

import {
  SupplierKey,
  SupplierRowWrapper,
  SupplierValue,
} from './SupplierRow.styled'

type SupplierRowProps = {
  property?: string
  value: string
}

export const SupplierRow = ({ value, property }: SupplierRowProps) => (
  <SupplierRowWrapper>
    {property && <SupplierKey>{`${property}:`}</SupplierKey>}
    <SupplierValue indent={!!property}>{value}</SupplierValue>
  </SupplierRowWrapper>
)
