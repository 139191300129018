import * as ExcelJs from 'exceljs'
import * as Excel from 'exceljs/dist/es5/exceljs.browser'

import moment from 'moment'
import { isNil } from 'lodash'

import { toastr } from 'react-redux-toastr'

import { fetchSuppliers } from '../components/AddAssignmentSupplierModal/AddSupplierAssigment'

import { ITreeListItem, TSearchDataOtherPage, TTreeList } from '../types'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  EColumnType,
  ETreeListItemType,
} from '../constants'

export const findParentNodeWithTheGroupType = (
  tree: TTreeList,
  id: number,
): ITreeListItem | null => {
  const node = findTreeNode(tree, String(id))
  if (node === null) {
    return null
  }

  if (node.type === ETreeListItemType.GROUP) {
    return node
  }

  if (!node.parentId) {
    return null
  }

  return findParentNodeWithTheGroupType(tree, Number(node.parentId))
}

export const getParentNode = (
  tree: TTreeList,
  id: string,
): ITreeListItem | null => {
  const node = findTreeNode(tree, id)
  if (node === null) {
    return null
  }
  if (!node.parentId) {
    return null
  }

  return findTreeNode(tree, node.parentId)
}

export const findTreeNode = (
  treeData: TTreeList,
  key: string,
): ITreeListItem | null => {
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < treeData.length; i++) {
    result = findTreeElement(treeData[i], key)
    if (result) {
      return result
    }
  }

  return result
}

export const getHasChildrenNodeWithTheGroupType = (
  treeItem: ITreeListItem,
): boolean => {
  if (treeItem.type === ETreeListItemType.GROUP) {
    return true
  }

  if (!treeItem.content) {
    return false
  }

  let result = false
  for (let i = 0; i < treeItem.content.length; i++) {
    result = getHasChildrenNodeWithTheGroupType(treeItem.content[i])
  }
  return result
}

const findTreeElement = (
  node: ITreeListItem,
  key: string,
): ITreeListItem | null => {
  if (node.id.toString() === key.toString()) {
    return node
  }
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < node.content.length; i++) {
    result = findTreeElement(node.content[i], key)
  }
  return result
}

export const catalogMapper = (catalog: TTreeList) =>
  catalog?.reduce<TTreeList>(
    (acc, { parentId = null, name, id, type, content }) => {
      let children: TTreeList = []
      if (
        (type === ETreeListItemType.FOLDER ||
          type === ETreeListItemType.GROUP) &&
        content?.length
      ) {
        children = catalogMapper(content)
      }
      const obj = {
        name,
        parentId,
        id,
        type,
      }
      return acc.concat(obj as any, children)
    },
    [],
  )

export const searchOptionsMapper = (options: TSearchDataOtherPage[]) =>
  options?.reduce((acc, { categoryPath, itemNames }) => {
    if (itemNames?.length) {
      return acc.concat(
        itemNames.map(item => ({ value: item, group: categoryPath })),
      )
    }
    return acc
  }, [] as any)

export function formatPrice(price: number | string): string {
  return parseFloat(price as string).toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  })
}

export const getNodeTypeById = (
  catalog: TTreeList,
  nodeId: string,
): ETreeListItemType | undefined =>
  catalog.find(catalogItem => Number(catalogItem.id) === Number(nodeId))?.type

const INN_FIELD = 'ИНН'
const KPP_FIELD = 'КПП'

type TargetCellType = number | string

export const validatePrice = ({
  file,
  onSuccess,
  onFailure,
}: {
  file: File
  onSuccess: () => void
  onFailure: () => void
}) => {
  const reader = new FileReader()
  const workbook: ExcelJs.Workbook = new Excel.Workbook()
  let inn: TargetCellType | undefined = ''
  let kpp: TargetCellType | undefined = ''

  const isTargetCellType = (val: ExcelJs.CellValue): val is TargetCellType =>
    typeof val === 'string' || typeof val === 'number'

  const getNextCellValue = (
    cellArr: ExcelJs.CellValue[],
    nextCellIndex: number,
  ) => cellArr.slice(nextCellIndex, cellArr.length).find(isTargetCellType)

  reader.readAsArrayBuffer(file)
  reader.onload = async () => {
    const buffer = reader.result as any
    const book = await workbook.xlsx.load(buffer)
    await book.eachSheet(sheet => {
      sheet.eachRow(row => {
        row.eachCell({ includeEmpty: true }, (cell, i) => {
          if (!Array.isArray(row.values)) {
            return false
          }

          if (cell.value === INN_FIELD) {
            inn = getNextCellValue(row.values, i + 1)
          } else if (cell.value === KPP_FIELD) {
            kpp = getNextCellValue(row.values, i + 1)
          }
        })
      })
    })

    if (!inn && !kpp) {
      toastr.error(
        '',
        `В таблице введены некорректные инн и кпп, убедитесь в правильности заполнении формы`,
      )
      onFailure()
    }

    const suppliersData = await fetchSuppliers(`${inn}`)
    const supplier = suppliersData?.find(
      data => data.inn === inn && data.kpp === kpp,
    )

    if (!supplier) {
      toastr.error(
        '',
        ` ИНН:${inn} и КПП:${kpp} в загружаемом файле отсутствуют в базе`,
      )
      onFailure()
    }
    onSuccess()
  }
}

export const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const formatValue = (
  type: EColumnType,
  value: string | undefined,
  defaultValue = '',
  unit?: string,
  location?: string | null,
  title?: string,
): string => {
  switch (type) {
    case EColumnType.STRING: {
      if (title === 'Заключение СБ') {
        return mapConclusionToRus(value || '')
      }
      return `${value ? `${value}${unit ? ` ${unit}` : ''}` : defaultValue}`
    }
    case EColumnType.BOOLEAN: {
      return `${value ? `${value === 'true' ? 'Да' : 'Нет'}` : defaultValue}`
    }
    case EColumnType.LIST: {
      if (title?.toLocaleLowerCase() === 'цена') {
        const newValue = value?.split('|').map((el: any) =>
          isNaN(el)
            ? el
            : Intl.NumberFormat('ru-RU', {
                minimumFractionDigits: 2,
              }).format(Number(el)),
        )

        return (newValue && newValue.join(', ')) || defaultValue
      }
      return value?.split('|').join(', ') || defaultValue
    }
    case EColumnType.DATE: {
      if (!value || value.trim().length < 10) {
        return defaultValue
      }
      return `${
        value ? moment(value).format(DEFAULT_DISPLAY_DATE_FORMAT) : defaultValue
      }`
    }
    case EColumnType.DOUBLE:
    case EColumnType.CURRENCY: {
      const parseNumberValue = Number(value)
      // eslint-disable-next-line no-undefined
      const formatNumber = Intl.NumberFormat(undefined).format(
        Number(parseNumberValue),
      )

      return `${
        !isNaN(Number(value)) && value !== ''
          ? `${formatNumber}${
              type === EColumnType.CURRENCY ? ' ₽' : `${unit ? ` ${unit}` : ''}`
            }`
          : value === ''
          ? '-'
          : defaultValue
      }`
    }
    case EColumnType.PHONE: {
      return `${value ? `${value}` : defaultValue}`
    }
    default: {
      return value || ' '
    }
  }
}

export class LocalStorageHelper {
  public static set<T>(name: string, value: T): void {
    localStorage.setItem(name, JSON.stringify(value) || '')
  }

  public static get<T>(name: string): T | null {
    const item = localStorage.getItem(name)
    return item ? JSON.parse(item) : null
  }

  public static remove(name: string): void {
    localStorage.removeItem(name)
  }

  public static clear(): void {
    localStorage.clear()
  }
}

export const treeify = (list: TTreeList): TTreeList => {
  const treeList: TTreeList = []
  const lookup: { [key: string]: ITreeListItem } = {}

  list.forEach((node: ITreeListItem) => {
    lookup[node.id] = node
    node.content = []
  })

  list.forEach((node: ITreeListItem) => {
    if (isNil(node.parentId)) {
      treeList.push(node)
      return
    }

    lookup[node.parentId].content.push(node)
  })

  return treeList
}

export const flatten = (catalog: TTreeList): TTreeList =>
  catalog.reduce(
    (accum: TTreeList, item: ITreeListItem) =>
      accum.concat([item], flatten(item.content)),
    [],
  )

export const mapConclusionToRus = (conclusion: string): string => {
  switch (conclusion) {
    case 'NotFound':
      return 'Не найден'

    case 'Agreed':
      return 'Согласован'

    case 'NotAgreed':
      return 'Не согласован'

    case 'Expired':
      return 'Истекший'

    default:
      return conclusion
  }
}

export const isConclusionStatus = (value: string): boolean =>
  ['NotFound', 'Agreed', 'NotAgreed', 'Expired'].includes(value) ? true : false

export const createDateRangeByDays = (
  currentDate: Date,
  days: number,
  sign?: 'plus' | 'minus',
): [string, string] => {
  const date = new Date(currentDate)
  let resultDate: any = null

  if (sign === 'plus') {
    resultDate = date.getDate() + days
  } else {
    resultDate = date.getDate() - days
  }

  date.setDate(resultDate)

  if (sign === 'plus') {
    return [currentDate.toISOString(), date.toISOString()]
  }

  return [date.toISOString(), currentDate.toISOString()]
}

export const createDateRangeByMonths = (
  currentDate: Date,
  days: number,
  sign?: 'plus' | 'minus',
): [string, string] => {
  const date = new Date(currentDate)
  let resultDate: any = null

  if (sign === 'plus') {
    resultDate = date.getMonth() + days
  } else {
    resultDate = date.getMonth() - days
  }

  date.setMonth(resultDate)

  return [date.toISOString(), currentDate.toISOString()]
}
