import { createAsyncThunk } from '@reduxjs/toolkit'

import { TTreeList } from '../../types'
import { EActionTypes } from '../../constants'

import { CategoryService } from '../../services/category-service'

import { actionCreator, IActionCreator } from '../BaseAction'

export const getSpecialisations = createAsyncThunk<unknown>(
  'specialisations/getSpecialisations',
  async (_, { rejectWithValue, dispatch }) => {
    const [err, res] = await CategoryService.getCategoriesSupplier()

    if (err) {
      throw rejectWithValue(err)
    }

    if (res.data.data) {
      dispatch(setSpecialisations(res.data.data || []))
    }
  },
)

export function setSpecialisations(list: TTreeList): IActionCreator<TTreeList> {
  return actionCreator<TTreeList>(EActionTypes.SET_SPECIALISATIONS, list)
}
