import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const EventsLogIcon = ({
  color = '#353B60',
  ...props
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect x='11' y='7' width='6' height='2' rx='1' fill={color} />
    <rect x='7' y='7' width='2' height='2' rx='1' fill={color} />
    <rect x='11' y='11' width='6' height='2' rx='1' fill={color} />
    <rect x='7' y='11' width='2' height='2' rx='1' fill={color} />
    <rect x='11' y='15' width='3' height='2' rx='1' fill={color} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 19.5C15 17.025 17.025 15 19.5 15C21.975 15 24 17.025 24 19.5C24 21.975 21.975 24 19.5 24C17.025 24 15 21.975 15 19.5ZM19.5 17C19.2239 17 19 17.2239 19 17.5V19.8C19 19.8326 19.0031 19.8645 19.0091 19.8953C18.9974 20.079 19.088 20.2621 19.2582 20.3604L21.116 21.433C21.3552 21.5711 21.661 21.4891 21.799 21.25C21.9371 21.0109 21.8552 20.7051 21.616 20.567L20 19.634V17.5C20 17.2239 19.7761 17 19.5 17Z'
      fill={color}
    />
    <rect x='7' y='15' width='2' height='2' rx='1' fill={color} />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.65053 20.3495C4.08422 20.7832 4.60897 21 5.22478 21H13.1739C13.0602 20.5185 13 20.0163 13 19.5C13 19.3318 13.0064 19.165 13.0189 19H5.5C5.22386 19 5 18.7761 5 18.5V5.5C5 5.22386 5.22386 5 5.5 5H18.5C18.7761 5 19 5.22386 19 5.5V13.0189C19.165 13.0064 19.3318 13 19.5 13C20.0163 13 20.5185 13.0602 21 13.1739V5.22478C21 4.60897 20.7832 4.08422 20.3495 3.65053C19.9158 3.21684 19.391 3 18.7752 3H5.22478C4.60897 3 4.08422 3.21684 3.65053 3.65053C3.21684 4.08422 3 4.60897 3 5.22478V18.7752C3 19.391 3.21684 19.9158 3.65053 20.3495Z'
      fill={color}
    />
  </svg>
)
