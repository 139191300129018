import { CompleteIcon, LongText, Tooltip } from '@gmini/ui-kit'

import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'

import { Winner } from './LotWinner.types'
import {
  List,
  WinnerPositionText,
  PositionInfoText,
  Option,
  IconWrap,
  SelectContainer,
  TextFieldStyled,
} from './LotWinnerSelect.styled'

interface LotWinnerSelectProps {
  options: Omit<Winner, 'position'>[]
  position: string
  onSelect: ({ winner, position }: { winner: Winner; position: string }) => void
  selectedValue?: Winner[]
  getOptionLabel: (winner: Winner, position: string) => string
  getPositionInfo: (winnerId: string, position: string) => string
  isSelected: (winnerId: string, position: string) => boolean
  disabled?: boolean
  error?: React.ReactNode
}

export const LotWinnerSelect = ({
  options,
  position,
  onSelect,
  selectedValue,
  getOptionLabel,
  getPositionInfo,
  disabled,
  isSelected,
  error,
}: LotWinnerSelectProps) => {
  const optionLabel = selectedValue?.[0]
    ? getOptionLabel(selectedValue?.[0], position)
    : ''
  return (
    <SelectContainer>
      <WinnerPositionText
        selected={!!selectedValue}
      >{`${position}-е место`}</WinnerPositionText>
      <Autocomplete
        options={options}
        renderInput={params => (
          <Tooltip title={optionLabel.length > 60 ? optionLabel : ''}>
            <TextFieldStyled
              {...params}
              name='project'
              variant='outlined'
              placeholder='Выберите победителя'
              fullWidth={true}
              data-test-id={`lotWinnerSelect_${position}`}
            />
          </Tooltip>
        )}
        fullWidth={true}
        noOptionsText='Нет доступных совпадений'
        getOptionLabel={option => getOptionLabel(option, position)}
        ListboxProps={{ style: { padding: 0 } }}
        renderOption={option => (
          <List
            data-test-id={`lotWinnerSelect_${position}_option_${option.rowId}`}
            onClick={() => {
              onSelect({ winner: option, position })
            }}
          >
            <Option>
              <LongText partSize={30}>{`${option.name} ` || ''}</LongText>
            </Option>

            <IconWrap>
              <PositionInfoText>
                {getPositionInfo(option.rowId, position)}
              </PositionInfoText>
              {isSelected(option.rowId, position) && <CompleteIcon />}
            </IconWrap>
          </List>
        )}
        disabled={disabled}
        value={selectedValue?.[0] || null}
      />
      {error}
    </SelectContainer>
  )
}
