import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const ArrowDownIcon = ({
  color = '#4c5ecf',
  ...props
}: SvgComponentType) => (
  <svg
    width='10'
    height='5'
    viewBox='0 0 10 5'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M0 0L5 5L10 0H0Z' fill={color} />
  </svg>
)
