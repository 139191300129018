import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Divider, Typography } from '@material-ui/core'

import { Authorities } from '../../services/auth-service/auth-constants'

import { ETreeListItemType, EButtonType, EButtonIcon } from '../../constants'
import {
  IKeyValuePair,
  IAssignmentLot,
  TAgreementCreateRequest,
  ELotStatus,
} from '../../types'

import { usePermissions } from '../../hooks/usePermissions'
import { ButtonComponent } from '../../components/Button/Button'
import { CatalogSearch } from '../../components/CatalogSearch/CatalogSearch'
import { AddKeyValuePairModal } from '../../components/AddKeyValuePairModal/AddKeyValuePairModal'
import { TabPanel } from '../../components/TabPanel/TabPanel'

import useStyles from './style'
import { AssignmentLotSearch } from './assignment-lot-search/assignment-lot-search'
import { PropsFromRedux, connector } from './assignment-view-search-container'

const AssignmentViewSearch: React.FC<PropsFromRedux> = ({
  assignment,
  searchParams,
  categoriesData,
  tenderId,
  addProperty,
  editProperty,
  removeProperty,
  exportTemplate,
  exportLot,
  removeLotPositions,
  removeLotSupplier,
  removeLot,
  combineLot,
  changeLotAssignment,
  changeLotName,
  movePositions,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  addAgreement,
  getSearchOtherPageLotCategory,
  getSearchAssignmentFilterItems,
  setTender,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const history = useHistory()
  const { tab } = useParams<{ tab: string }>()

  const [shouldGetData, setShouldGetData] = useState<boolean>(true)
  const [openRenameLotModal, setOpenRenameLotModal] = useState<boolean>(false)

  const [selectedLot, setSelectedLot] = useState<IAssignmentLot>()

  useEffect(() => {
    if (shouldGetData && tenderId) {
      getSearchAssignmentFilterItems({
        params: searchParams,
        callback: () => setShouldGetData(false),
      })
    }
    // eslint-disable-next-line
  }, [shouldGetData, tenderId])

  const classes = useStyles()

  const onSelectNodeHandler = React.useCallback(
    (nodeId: string): void => {
      setTender(nodeId)
      setShouldGetData(true)
    },
    [setTender],
  )

  const addSupplierHandler = React.useCallback(
    (
      lotId: string,
      supplierId: string,
      price: File,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      addAssignmentLotSupplier({ lotId, supplierId, file: price, callback: cb })
    },
    [addAssignmentLotSupplier],
  )

  const updatePriceHandler = React.useCallback(
    (
      lotId: string,
      supplierId: string,
      price: File,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        callback()
        setShouldGetData(true)
      }

      updateAssignmentLotSupplier({
        lotId,
        supplierId,
        file: price,
        callback: cb,
      })
    },
    [updateAssignmentLotSupplier],
  )

  const addToAgreementHandler = React.useCallback(
    (agreement: TAgreementCreateRequest, callback: () => void): void => {
      agreement.dateEnd = `${agreement.dateEnd}T00:00`
      addAgreement({ agreement, callback })
    },
    [addAgreement],
  )

  const removeLotPositionsHandler = React.useCallback(
    (
      lotId: string,
      positionsIds: Array<string>,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      removeLotPositions({ lotId, positionIds: positionsIds, callback: cb })
    },
    [removeLotPositions],
  )

  const removeLotHandler = React.useCallback(
    (lotId: string): void => {
      removeLot({ lotId, callback: () => setShouldGetData(true) })
    },
    [removeLot],
  )

  const moveLotPositionsHandler = React.useCallback(
    (
      fromLotId: string,
      toLotId: string,
      selectedRows: Array<string>,
      removeFromCurrentLot: boolean,
      callback: () => void,
    ): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      movePositions({
        fromLotId,
        toLotId,
        positionsIds: selectedRows,
        removeFromCurrentLot,
        callback: cb,
      })
    },
    [movePositions],
  )

  const changeLotAssignmentHandler = React.useCallback(
    (lotId: string, assignmentId: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }
      changeLotAssignment({ lotId, assignmentId, callback: cb })
    },
    [changeLotAssignment],
  )

  const removeLotSupplierHandler = React.useCallback(
    (lotId: string, providerId: string, callback: () => void): void => {
      removeLotSupplier({ lotId, providerId, callback })
    },
    [removeLotSupplier],
  )

  const addPropertyHandler = React.useCallback(
    (lotId: string, name: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      addProperty({ lotId, name, callback: cb })
    },
    [addProperty],
  )

  const editPropertyHandler = React.useCallback(
    (key: string, name: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      editProperty({ key, name, callback: cb })
    },
    [editProperty],
  )

  const removePropertyHandler = React.useCallback(
    (key: string, callback: () => void): void => {
      const cb = (): void => {
        setShouldGetData(true)
        callback()
      }

      removeProperty({ key, callback: cb })
    },
    [removeProperty],
  )

  const exportTemplateHandler = React.useCallback(
    ({
      lotId,
      categories,
    }: {
      lotId: string
      categories: {
        categoryId: string
        propositionCustomFieldIds: string[]
        columnIds: string[]
      }[]
    }): void => {
      exportTemplate({ lotId, categories })
    },
    [exportTemplate],
  )

  const changePageHandler = React.useCallback(
    (
      page: number,
      rowsPerPage: number,
      assignmentId: number,
      categoryId: number,
      lotId: number,
    ): void => {
      const params = {
        ...searchParams,
        tenderId: assignmentId,
        pageData: {
          page,
          perPage: rowsPerPage,
          categoryId,
          lotId,
          tenderId: assignmentId,
        },
      }
      getSearchOtherPageLotCategory(params)
    },
    [getSearchOtherPageLotCategory, searchParams],
  )

  if (
    (!assignment && !categoriesData?.length) ||
    !checkPermissions(Authorities.ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS)
  ) {
    return (
      <div className={classes.root}>
        <p style={{ marginLeft: '18px', fontSize: '22px' }}>
          Ничего не найдено
        </p>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <div className='flex-item'>
        <CatalogSearch
          onSelectNode={onSelectNodeHandler}
          categoriesData={categoriesData}
          type={ETreeListItemType.FOLDER}
        />
      </div>
      {assignment && (
        <div className='flex-item tab-panel'>
          <TabPanel index={0}>
            {openRenameLotModal && (
              <AddKeyValuePairModal
                dataTestIdPrefix='assignmentViewSearchChangeLotModal'
                open={openRenameLotModal}
                title={'Изменить лот'}
                label={'Название'}
                model={{
                  key: selectedLot?.id || '',
                  value: selectedLot?.name || '',
                }}
                saveBtnText={'Сохранить'}
                cancelBtnText={'Отменить'}
                onSave={(model: IKeyValuePair): void => {
                  setOpenRenameLotModal(false)
                  changeLotName({
                    lotId: model.key,
                    name: model.value,
                    description: '',
                    status: ELotStatus[selectedLot?.status || 'Новый'],
                    callback: (): number => 0,
                  })
                }}
                onClose={(): void => setOpenRenameLotModal(false)}
              />
            )}
            {assignment.name && assignment.id && (
              <div className={classes.tenderInfo}>
                <Typography
                  data-test-id='assignmentViewSearchHeading'
                  className={classes.tenderName}
                  variant='subtitle1'
                  component='div'
                >
                  {assignment.name} №{assignment.id}
                  <ButtonComponent
                    data-test-id='assignmentViewSearchOpenAssignmentBtn'
                    text={''}
                    type={EButtonType.DEFAULT}
                    typeIcon={EButtonIcon.ARROW_BACK}
                    noMargin
                    hidden={false}
                    onClick={(): void =>
                      history.push(`/assignments/${tab}/${assignment.id}`)
                    }
                  />
                </Typography>
                <Divider />
              </div>
            )}
            {assignment &&
              assignment.lots
                .filter(lot => lot.positions?.length)
                .map((lot, index) => (
                  <AssignmentLotSearch
                    index={index}
                    key={lot.id}
                    lot={lot}
                    assignment={assignment}
                    searchParams={searchParams}
                    exportTemplateHandler={exportTemplateHandler}
                    exportLotHandler={exportLot}
                    addPropertyHandler={addPropertyHandler}
                    editPropertyHandler={editPropertyHandler}
                    removePropertyHandler={removePropertyHandler}
                    addToAgreementHandler={addToAgreementHandler}
                    removeLotSupplierHandler={removeLotSupplierHandler}
                    removeLotPositionsHandler={removeLotPositionsHandler}
                    removeLotHandler={removeLotHandler}
                    moveLotPositionsHandler={moveLotPositionsHandler}
                    changeLotAssignmentHandler={changeLotAssignmentHandler}
                    changePageHandler={changePageHandler}
                    addSupplierHandler={addSupplierHandler}
                    updatePriceHandler={updatePriceHandler}
                    openRenameLotModal={(
                      open: boolean,
                      pLot: IAssignmentLot,
                    ): void => {
                      setSelectedLot(pLot)
                      setOpenRenameLotModal(open)
                    }}
                    getSearchAssignmentFilterItems={(params, callback) =>
                      getSearchAssignmentFilterItems({ params, callback })
                    }
                  />
                ))}
          </TabPanel>
        </div>
      )}
    </div>
  )
}

export default connector(AssignmentViewSearch)
