import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: '#616284',
    flex: '0 0 60px',
  },
  tabs: {
    '& .MuiTab-root': {
      maxWidth: 'none',
      minWidth: 'none',
      padding: '25px 0',
      margin: '0 10px',
    },
    '& .MuiTab-textColorPrimary': {
      color: '#535F77',
      '&.Mui-selected': {
        color: '#4C5ECF',
      },
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Proxima Nova Light',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      textTransform: 'none',
    },
    '& .PrivateTabIndicator-colorPrimary-13': {
      backgroundColor: '#4C5ECF',
      height: '3px',
    },
  },
  toolbar: theme.mixins.toolbar,
  searchFilterContainer: {
    display: 'flex',
  },
  drawerList: {
    background: 'rgba(44, 52, 98, 0.22)',
    '& .MuiList-root:first-child': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiPaper-root': {
      width: '334px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerContentDivider: {
    margin: '0 0 0 24px',
    backgroundColor: '#E2E4E9',
  },
  drawerListHeader: {
    margin: '30px 10px 20px 24px',
    padding: '0',
    '& h5': {
      fontSize: '24px',
      color: '#000',
      fontWeight: 'normal',
      margin: '0',
      cursor: 'default',
    },
    '& .MuiList-padding': {
      minWidth: '334px',
    },
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
    margin: '0 15px 0 0',
    height: '59px',
    width: '318px',
    background: '#ffffff',
    right: '0',
    bottom: '0',
    '& button': {
      margin: '0 0 0 10px',
    },
  },
  drawerContent: {
    '& .MuiButton-root ': {
      minWidth: '286px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 0 64px 24px',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    margin: '0',
    '& .makeStyles-primary-27': {
      borderRadius: '5px 0 0 5px',
    },
    '& div:last-child': {
      borderRadius: '0 5px 5px 0',
      borderWidth: '1px 1px 1px 0',
      borderStyle: 'solid',
      borderColor: '#4C5ECF',
      minHeight: '43px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginRight: '0',
      },
    },
  },
  searchBackBlock: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    '& h5': {
      fontSize: '24px',
      fontWeight: '500',
      color: '#1B2B4B',
      margin: '26px',
    },
  },
}))
