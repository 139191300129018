/* eslint-disable no-undefined */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useLocation } from 'react-router-dom'
import { useParams } from 'react-router'
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'

import { IColumn, IImprovedTableBody, IRow } from '../../../types'
import { formatValue } from '../../../utils'
import { EColumnType } from '../../../constants'

import useImprovedTableRowStyles from './Style'

export const ImprovedTableBody: React.FC<IImprovedTableBody> = ({
  data,
  selectedRows,
  nameCatalog,
  onRowSelectingChanged,
  requiredColumns,
  visibleColumns,
  isRowChecked,
  showSections,
  dataTestIdPrefix,
}): React.ReactElement => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = useParams<{ id: any }>()
  const classes = useImprovedTableRowStyles()

  return (
    <TableBody>
      {data.map((row: IRow, rowIdx) => {
        const checkIfRowSelected = (name: string): boolean =>
          selectedRows.indexOf(name) !== -1

        const isRowSelected = checkIfRowSelected(row.rowId)
        const filterColumnsRowList = visibleColumns
          .filter(column => (!column.section || showSections) && !column.hidden)
          .map(column => {
            const cellData = row.data.find(d => d.key === column.key)
            let viewValue = ''
            if (
              cellData &&
              column.type === EColumnType.LIST &&
              nameCatalog &&
              nameCatalog[column.key]
            ) {
              const arr: Array<string> = []
              cellData.value.split('|').forEach((id: string) => {
                arr.push(nameCatalog[column.key][id])
              })

              viewValue = arr.join('|')
            }

            return (
              {
                ...cellData,
                title: column.title,
                value: viewValue || cellData?.value,
              } || {
                key: column.key,
                value: '',
                title: column.title,
              }
            )
          })

        const hasError = row.data
          .filter(d =>
            requiredColumns.some(
              rc => !rc.section && Number(rc.key) === Number(d.key),
            ),
          )
          .some(d => !d.value || !d.value.length)

        return (
          <TableRow
            data-test-id={`${dataTestIdPrefix}TableRow_${rowIdx}`}
            role='checkbox'
            aria-checked={isRowSelected}
            tabIndex={-1}
            key={row.rowId}
            selected={isRowSelected}
            className={`${classes.tableRow}${hasError ? ' error' : ''}`}
            classes={{ selected: classes.rowSelected }}
          >
            {isRowChecked && (
              <TableCell padding='checkbox' className={classes.stickyCheckbox}>
                <Checkbox
                  data-test-id={`${dataTestIdPrefix}TableRow_${rowIdx}_Checkbox`}
                  color='primary'
                  onClick={(event: React.MouseEvent<HTMLButtonElement>): void =>
                    onRowSelectingChanged(event, row.rowId)
                  }
                  checked={isRowSelected}
                  disableRipple
                  className={classes.checkedRow}
                  classes={{ checked: classes.checkboxChecked }}
                />
              </TableCell>
            )}
            {filterColumnsRowList.map((d: any, index) => (
              <TableCell
                key={d.key}
                className={index === 0 ? classes.stickyCell : undefined}
                title={formatValue(
                  visibleColumns.find(vc => vc.key === d.key)?.type ||
                    EColumnType.STRING,
                  d?.value?.split('|')[0],
                  '',
                  visibleColumns.find(vc => vc.key === d.key)?.unit,
                  location.pathname,
                  d.title,
                )}
              >
                <Tooltip
                  arrow
                  disableFocusListener
                  disableTouchListener
                  classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow,
                  }}
                  title={
                    d.value && d.value.split('|').length > 1 ? (
                      <React.Fragment>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {visibleColumns
                                .filter(
                                  (column: IColumn) =>
                                    column.title === 'Цена' ||
                                    column.title === 'Поставщик' ||
                                    column.title === 'Соглашение',
                                )
                                .map(data => (
                                  <TableCell key={data.key} align='left'>
                                    {data?.title}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              {visibleColumns
                                .map(vc =>
                                  filterColumnsRowList.find(
                                    (d: any) =>
                                      d.key === vc.key &&
                                      (vc.title === 'Цена' ||
                                        vc.title === 'Поставщик' ||
                                        vc.title === 'Соглашение'),
                                  ),
                                )
                                .filter(data => data !== undefined)
                                .map((data: any, index) => (
                                  <TableCell
                                    key={index}
                                    style={{
                                      margin: '0',
                                      padding: '10px 10px 0 15px',
                                      maxWidth: '150px',
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    <TableRow>
                                      {data?.value
                                        .split('|')
                                        .splice(1)
                                        .map((cell: any) => (
                                          <TableRow key={cell}>
                                            <p
                                              style={{
                                                margin: '0 0 10px 0',
                                                padding: '0',
                                              }}
                                            >
                                              {formatValue(
                                                visibleColumns.find(
                                                  vc => vc.key === d.key,
                                                )?.type || EColumnType.STRING,
                                                cell,
                                                '',
                                                visibleColumns.find(
                                                  vc => vc.key === d.key,
                                                )?.unit,
                                                null,
                                                data.title,
                                              )}
                                            </p>
                                          </TableRow>
                                        ))}
                                    </TableRow>
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </React.Fragment>
                    ) : (
                      ''
                    )
                  }
                  placement='bottom'
                >
                  <div className='flex-container'>
                    {(location.pathname.includes('positions') ||
                      (location.pathname.includes('/assignments/') &&
                        id > 0)) &&
                      index === 0 &&
                      row.photo &&
                      row.photo.length > 0 && (
                        <Avatar src={row.photo.split('|')[0]}></Avatar>
                      )}
                    <span
                      style={
                        d.value && d.value.split('|').length > 1
                          ? { fontWeight: 'bold' }
                          : { fontWeight: 'normal' }
                      }
                    >
                      {d.value
                        ? formatValue(
                            visibleColumns.find(vc => vc.key === d.key)?.type ||
                              EColumnType.STRING,
                            d.value,
                            '',
                            visibleColumns.find(vc => vc.key === d.key)?.unit,
                            location.pathname,
                            d.title,
                          )
                        : '-'}
                    </span>
                  </div>
                </Tooltip>
              </TableCell>
            ))}
            <TableCell></TableCell>
            <TableCell className={classes.settingsEmptyBodyCell}></TableCell>
          </TableRow>
        )
      })}
    </TableBody>
  )
}
