import { makeStyles, Theme } from '@material-ui/core/styles'

const usePositionCatalogStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: '22px 0 0 0',
    minWidth: 270,
    background: '#f9f9fb',
    minHeight: '180px',
    borderRadius: '5px',
    '& .MuiListSubheader-gutters': {
      paddingRight: '0',
    },
  },
  listTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    color: ' #616284',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '44px',
    width: 'inherit',
    maxWidth: 'inherit',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      padding: '0',
      margin: '0 15px',
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      color: 'white',
      fontSize: '15px',
    },
    '& .MuiDivider-root': {
      height: '44px',
    },
  },
  dividedHeight: {
    height: '1.5px',
    margin: '0 0 20px 0',
  },
  catalog: {
    padding: '0 15px',
    marginTop: '-10px',
    maxHeight: 'calc(100vh - 185px)',
    overflowY: 'auto',
    '& .MuiTypography-root': {
      wordBreak: 'break-word',
      width: '240px',
      padding: '0 5px 0 0',
    },
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& .Mui-selected': {
      borderRadius: '5px !important',
      backgroundColor: '#f2f0f8 !important',
    },
  },
  listItem: {
    display: 'flex',
    height: 'auto',
    minHeight: '45px',
    '&:hover, &:active, &:focus': {
      backgroundColor: '#f2f0f8',
      borderRadius: '5px',
      height: 'auto',
      color: '#4c5ecf',
    },
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      color: '#05082E',
      maxWidth: '270px',
    },
  },
  listItemInCollapse: {
    paddingLeft: theme.spacing(4.5),
  },
}))

export default usePositionCatalogStyles
