import React, { HTMLAttributes } from 'react'
import cn from 'classnames'

import useStyles from './Style'

type SidebarButtonProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean
}

export const SidebarButton = ({
  active,
  onClick,
  children,
  ...other
}: SidebarButtonProps) => {
  const classes = useStyles()

  return (
    <div
      className={cn(classes.button, { active })}
      onClick={onClick}
      {...other}
    >
      {children}
    </div>
  )
}
