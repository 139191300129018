import { AppBar, Tab, Tabs, Toolbar } from '@material-ui/core'
import { useQuery } from '@gmini/utils'

import { useHistory } from 'react-router-dom'
import ReactDOM from 'react-dom'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Authorities } from '../../../../services/auth-service/auth-constants'

import { AssignmentTab, EPageType } from '../../../../types'
import { EButtonIcon, EButtonType } from '../../../../constants'
import { ButtonComponent } from '../../../Button/Button'

import * as I from '../IAppHeader'
import { AppHeaderSearchAndFilter } from '../AppHeaderSearchAndFilter/AppHeaderSearchAndFilter'
import { useStyles } from '../Style'
import { usePermissions } from '../../../../hooks/usePermissions'
import { getSpecialisations } from '../../../../actions/Specialisations'

import { useAppDispatch } from '../../../../store'

import { AppHeaderAssignment } from './AppHeaderAssignmentPL'

type AppHeaderAssignmentContainerProps = I.StateProps &
  I.DispatchProps &
  I.OwnProps & {
    showDrawerHandler: (isShow: boolean) => void
    flagBackPageHandler: () => void
    refreshCategoryId: () => void
    a11yProps: (index: number) => Record<string, unknown>
    flagBackPage: boolean
    openDrawer: boolean
    setFlagBackPage: React.Dispatch<React.SetStateAction<boolean>>
    assignmentOpen: boolean
    search: boolean
  }

export const AppHeaderAssignmentContainer = ({
  ...props
}: AppHeaderAssignmentContainerProps) => {
  const {
    showDrawerHandler,
    flagBackPageHandler,
    refreshCategoryId,
    a11yProps,
    flagBackPage,
    openDrawer,
    activeTabIndex,
    assignment,
    filterFields,
    shouldResetFilter,
    availableColumns,
    changeActiveTab,
    changeStatusAssignment,
    addLotToAssignment,
    deleteLotAssignment,
    editLotAssignment,
    getFilterFields,
    getSearchAssignmentFilterItems,
    getAssignmentSectionData,
    getAssignmentDetail,
    setExportSectionFlag,
    setFlagBackPage,
    assignmentOpen,
    search,
  } = props

  const query = useQuery()
  const categoryId = query.get('categoryId') || ''
  const headerRef = useRef<HTMLDivElement>(null)
  const [isSticky, setIsSticky] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getSpecialisations())
  }, [dispatch])

  useEffect(() => {
    if (!assignmentOpen) {
      return
    }
    const handleScroll = () => {
      const top = headerRef.current?.getBoundingClientRect().top
      if (!top) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
    document.body.addEventListener('wheel', handleScroll)
    return () => {
      document.body.removeEventListener('wheel', handleScroll)
    }
  }, [headerRef, assignmentOpen])

  const classes = useStyles()

  const checkPermissions = usePermissions()
  const history = useHistory()

  const getHeaderContent = useMemo(() => {
    if (search) {
      return (
        <>
          <div className={classes.searchBackBlock}>
            <ButtonComponent
              data-test-id='assignmentsSearchBackBtn'
              text={''}
              type={EButtonType.DEFAULT}
              typeIcon={EButtonIcon.ARROW_BACK}
              noMargin
              hidden={false}
              onClick={(): void => {
                history.push({
                  pathname: `/assignments/${activeTabIndex.assignments}`,
                  search: query.toString(),
                })
                setFlagBackPage(true)
              }}
            />
            <h5 data-test-id='assignmentsSearchHeading'>
              Поиск по тендерным заданиям
            </h5>
          </div>
          {checkPermissions(
            Authorities.ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS,
          ) && (
            <AppHeaderSearchAndFilter
              openDrawer={openDrawer}
              shouldResetFilter={shouldResetFilter}
              filterFields={filterFields}
              flagBackPage={flagBackPage}
              flagBackPageHandler={flagBackPageHandler}
              getFilterFields={getFilterFields}
              showDrawerHandler={showDrawerHandler}
              getSearchAssignmentFilterItems={getSearchAssignmentFilterItems}
              refreshCategoryId={refreshCategoryId}
              categoryId={categoryId}
              availableColumns={availableColumns}
              currentPage={EPageType.TENDER}
              isArchived={AssignmentTab.Archived === activeTabIndex.assignments}
            />
          )}
        </>
      )
    }
    if (assignmentOpen) {
      return (
        <>
          <AppHeaderAssignment
            isSticky={isSticky}
            assignment={assignment}
            changeStatusAssignment={changeStatusAssignment}
            deleteLotAssignment={deleteLotAssignment}
            editLotAssignment={editLotAssignment}
            addLotToAssignment={addLotToAssignment}
            getAssignmentSectionData={getAssignmentSectionData}
            getAssignmentDetail={getAssignmentDetail}
            onSetExportSectionFlag={setExportSectionFlag}
          />
          {checkPermissions(
            Authorities.ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS,
          ) && (
            <AppHeaderSearchAndFilter
              isHidden={isSticky}
              openDrawer={openDrawer}
              shouldResetFilter={shouldResetFilter}
              filterFields={filterFields}
              flagBackPage={flagBackPage}
              flagBackPageHandler={flagBackPageHandler}
              getFilterFields={getFilterFields}
              showDrawerHandler={showDrawerHandler}
              getSearchAssignmentFilterItems={getSearchAssignmentFilterItems}
              refreshCategoryId={refreshCategoryId}
              categoryId={categoryId}
              availableColumns={availableColumns}
              currentPage={EPageType.TENDER}
              isArchived={AssignmentTab.Archived === activeTabIndex.assignments}
            />
          )}
        </>
      )
    }
    return (
      <>
        <Tabs
          className={classes.tabs}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          value={activeTabIndex.assignments}
          onChange={(_, value: number) =>
            changeActiveTab(value, refreshCategoryId)
          }
          aria-label='view-tabs'
        >
          {checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_ACTIVE) && (
            <Tab
              data-test-id='activeAssignmentsTab'
              label='Активные'
              {...a11yProps(0)}
            />
          )}
          {checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_COMPLETED) && (
            <Tab
              data-test-id='completedAssignmentsTab'
              wrapped
              label='Завершенные'
              {...a11yProps(1)}
            />
          )}
          <Tab
            data-test-id='archivedAssignmentsTab'
            wrapped
            label='Архив'
            {...a11yProps(2)}
          />
        </Tabs>
        {checkPermissions(Authorities.ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS) && (
          <AppHeaderSearchAndFilter
            openDrawer={openDrawer}
            shouldResetFilter={shouldResetFilter}
            filterFields={filterFields}
            flagBackPage={flagBackPage}
            flagBackPageHandler={flagBackPageHandler}
            getFilterFields={getFilterFields}
            showDrawerHandler={showDrawerHandler}
            getSearchAssignmentFilterItems={getSearchAssignmentFilterItems}
            refreshCategoryId={refreshCategoryId}
            categoryId={categoryId}
            availableColumns={availableColumns}
            currentPage={EPageType.TENDER}
            isArchived={AssignmentTab.Archived === activeTabIndex.assignments}
          />
        )}
      </>
    )
  }, [
    a11yProps,
    activeTabIndex.assignments,
    addLotToAssignment,
    assignment,
    assignmentOpen,
    availableColumns,
    categoryId,
    changeActiveTab,
    changeStatusAssignment,
    classes.searchBackBlock,
    classes.tabs,
    deleteLotAssignment,
    editLotAssignment,
    filterFields,
    flagBackPage,
    flagBackPageHandler,
    getAssignmentDetail,
    getAssignmentSectionData,
    getFilterFields,
    getSearchAssignmentFilterItems,
    query,
    history,
    isSticky,
    search,
    openDrawer,
    refreshCategoryId,
    setExportSectionFlag,
    setFlagBackPage,
    shouldResetFilter,
    showDrawerHandler,
    checkPermissions,
  ])

  const header = (
    <AppBar ref={headerRef} position='static' className={classes.appBar}>
      <Toolbar
        style={{
          borderBottom: isSticky
            ? '1px solid #E0E0E0'
            : '1px solid transparent',
        }}
      >
        {getHeaderContent}
      </Toolbar>
    </AppBar>
  )
  return assignmentOpen
    ? ReactDOM.createPortal(
        header,
        document.getElementById('assignment-sticky-header') || document.body,
      )
    : header
}
