import { IconButton } from '@gmini/ui-kit'
import { makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 0 65px',
    backgroundColor: '#4C5ECF',
    paddingLeft: '24px',
  },
  column: {
    display: 'flex',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '19px',
  },
  logoName: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '22px',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginLeft: '8px',
    color: '#fff',
    marginRight: '8px',
  },
  divider: {
    background: 'rgba(255, 255, 255, 0.2)',
    width: '1px',
    margin: '14px 0 15px',
  },
  userAccountWrapper: {
    position: 'relative',
  },
}))

export default useStyles

export const StyledIconButton = styled(IconButton)`
  && {
    path,
    rect {
      fill: rgba(255, 255, 255, 0.5);
      transition: all 0.2s ease-out;
    }
    :hover {
      background: rgba(255, 255, 255, 0.1);
      path,
      rect {
        fill: rgba(255, 255, 255, 1);
      }
    }
  }
`
type EventsLogButtonWrapperProps = {
  active: boolean
}
export const EventsLogButtonWrapper = styled.div`
  && {
    margin: 0px 10px;
    align-self: center;
    border-radius: 4px;
    padding: 6px;
    ${({ active }: EventsLogButtonWrapperProps) =>
      active
        ? css`
            background: rgba(67, 83, 180, 1);
            path,
            rect {
              fill: rgba(255, 255, 255, 1);
            }
            ${StyledIconButton} {
              pointer-events: none;
            }
          `
        : ''}
  }
`
