import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontFamily: 'Proxima Nova Regular',
    margin: '0 -15px',
    height: '100%',
    '& .flex-item': {
      margin: '0 15px',
      '&.tab-panel': {
        flex: '1 1 auto',
        width: '100px',
      },
    },
  },
  tenderName: {
    fontSize: '24px',
    lineHeight: '29px',
    margin: '10px 0',
    color: '#1B2B4B',
    maxWidth: '100vw',
    wordBreak: 'break-word',
  },
  tenderInfo: {
    '& .info-container': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '10px -10px',
      width: '100%',
      '& .info-item': {
        margin: '10px 10px',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        '& .title': {
          fontSize: '12px',
          lineHeight: '15px',
          color: '#616284',
        },
        '& .content': {
          fontSize: '16px',
          lineHeight: '19px',
          color: '#1B2B4B',
        },
      },
    },
  },
  addlotBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
}))

export default useStyles
