import { connect } from 'react-redux'

import { TStoreInitialState } from '../../types'

import * as I from './ILoader'
import container from './LoaderContainer'
import { LoaderPL } from './LoaderPL'

export default connect<
  I.StateProps,
  Record<string, unknown>,
  Record<string, unknown>,
  TStoreInitialState
>(container.mapStateToProps)(LoaderPL)
