import { IconButton } from '@gmini/ui-kit'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabs: {
    flexGrow: 1,
    flexShrink: 1,
    '& .tab.Mui-selected': {
      pointerEvents: 'none',
    },
  },
  search: {
    width: '350px',
    flexShrink: 0,
  },
  content: {
    display: 'flex',
    fontFamily: 'Proxima Nova Regular',
    margin: '0 -15px',
    height: 'calc(100% - 82px)',
    '& .flex-item': {
      margin: '0 15px',
      '&.tab-panel': {
        flex: '1 1 auto',
        width: '100px',
        marginTop: '25px',
      },
    },
  },
  actions: {
    display: 'flex',
    margin: '0 0 20px 0',
    alignItems: 'center',
    '& button': {
      marginRight: '5px',
    },
  },
  floatedActions: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

export default useStyles

export const SettingsButton = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: 0;
  }
`
