import React from 'react'
import { Link } from 'react-router-dom'
import { GT_COLUMN_TYPES_RELATION } from '@ifellow/ui-library'

import { GridCellParams } from '@material-ui/x-grid'

import {
  ColumnSettings,
  IAssignmentColumn,
  IAssignmentXGridColumn,
  IColumn,
  IXGridColumn,
  TEditModalInitialState,
  TRow,
  TShortAgreement,
} from '../types'
import { EColumnType } from '../constants'
import { AssignmentAgreementsPopup } from '../components/assignment-agreements-popup/assignment-agreements-popup'

import { EXPAND_COLUMN_ID } from '../pages/position/postion-table'

import { renderHeaderUtil } from './renderHeaderUtil'
import { renderCell } from './renders'

export const xGridTypes = {
  ...GT_COLUMN_TYPES_RELATION,
  [EColumnType.TREE_LIST]: GT_COLUMN_TYPES_RELATION.list,
}

export const getModalValue = (
  state: TEditModalInitialState,
  fieldName: string,
): unknown => {
  const fieldId = state.settings.find(item => item.title === fieldName)?.key

  if (fieldId) {
    return state.row.data.find(item => item.key === fieldId)?.value
  }
}

export const columnsMapper = ({
  columns,
  tableType,
  renderExpandedRows,
  cellRenderer,
}: {
  columns: (IColumn & ColumnSettings)[]
  tableType?: string
  cellRenderer?: (
    params: GridCellParams,
    type: EColumnType,
    unit?: string,
  ) => any
  renderExpandedRows?: ({
    key,
    title,
    type,
  }: {
    key: string
    title: string
    type: EColumnType
  }) => IXGridColumn
}): Array<IXGridColumn> => {
  const normalizeColumns = columns.map(column => ({
    ...column,
    title:
      column.title.lastIndexOf('[') === -1
        ? column.title
        : column.title.substring(0, column.title.lastIndexOf('[')),
  }))
  return normalizeColumns
    .filter(column => !column.hidden)
    .map(({ key, type, title, unit, width, hide }) => {
      if (
        tableType === 'agreements' &&
        String(title) === '№ тендерного задания'
      ) {
        return {
          field: String(key),
          id: String(key),
          headerName: title,
          type: xGridTypes[type],
          editable: false,
          renderCell: (params: GridCellParams) => (
            <div>
              <Link to={`/assignments/0/${params.value}`}>{params.value}</Link>
            </div>
          ),
          hide,
          width,
          minWidth: 150,
          renderHeader: () => renderHeaderUtil(title),
        }
      } else if (key === EXPAND_COLUMN_ID && renderExpandedRows) {
        return renderExpandedRows({ title, key, type })
      }

      return {
        field: String(key),
        id: String(key),
        headerName: title,
        type: xGridTypes[type],
        editable: false,
        renderCell: (params: GridCellParams) =>
          cellRenderer
            ? cellRenderer(params, type, unit)
            : renderCell(params, type, unit),
        hide,
        width,
        minWidth: 150,
        renderHeader: () => renderHeaderUtil(title),
      }
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rowsMapper = (rows: TRow) =>
  rows.map(({ rowId, data, ...rest }) => ({
    ...rest,
    id: rowId,
    rowId,
    ...data.reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    ),
  }))

export const assignmentColumnsMapper = (
  columns: Array<IAssignmentColumn>,
): Array<IAssignmentXGridColumn> =>
  columns
    .filter(column => !column.hidden && !column.section)
    .map(
      ({
        key,
        type,
        title,
        unit,
        supplierId,
        supplierName,
        hasAgreement,
        isSupplierPrice,
        position,
        agreement,
      }) => ({
        field: String(key),
        headerName: title,
        type: xGridTypes[type],
        editable: false,
        renderCell: (params: GridCellParams) => renderCell(params, type, unit),
        minWidth: 150,
        supplierId,
        supplierName,
        hasAgreement,
        isSupplierPrice,
        position,
        agreement,
      }),
    )

export const popupAssignmentColumnsMapper = (
  columns: (IColumn & ColumnSettings)[],
  agreements: Array<TShortAgreement>,
): Array<IXGridColumn> => {
  const normalizeColumns = columns.map(column => ({
    ...column,
    title:
      column.title.lastIndexOf('[') === -1
        ? column.title
        : column.title.substring(0, column.title.lastIndexOf('[')),
  }))
  return normalizeColumns
    .filter(column => !column.hidden)
    .map(({ key, type, title, unit, hide, width }) => {
      if (String(title) === 'Количество соглашений') {
        return {
          field: String(key),
          headerName: title,
          type: xGridTypes[type],
          editable: false,
          renderCell: (params: GridCellParams) => {
            const filteredAgreements = agreements.filter(
              item => item.tenderTaskNumber === params.row.id,
            )

            return (
              <AssignmentAgreementsPopup
                key={key}
                agreements={filteredAgreements}
                params={params}
                type={type}
                unit={unit}
              />
            )
          },
          hide,
          width,
          minWidth: 150,
          renderHeader: () => renderHeaderUtil(title),
        }
      }

      return {
        field: String(key),
        headerName: title,
        type: xGridTypes[type],
        editable: false,
        renderCell: (params: GridCellParams) => renderCell(params, type, unit),
        hide,
        width,
        minWidth: 150,
        renderHeader: () => renderHeaderUtil(title),
      }
    })
}
