import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const ExpandMoreIcon = ({
  color = '#00003D',
  ...other
}: SvgComponentType) => (
  <svg
    width='7'
    height='12'
    viewBox='0 0 7 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M0.310919 1.83893L4.43545 6.00537L0.310919 10.1718C0.111865 10.3724 -5.92371e-08 10.6448 -4.68211e-08 10.9289C-3.4405e-08 11.2129 0.111865 11.4853 0.310919 11.6859C0.725499 12.1047 1.3952 12.1047 1.80978 11.6859L6.68907 6.75705C7.10365 6.33825 7.10365 5.66174 6.68907 5.24295L1.80978 0.314094C1.3952 -0.104697 0.725498 -0.104697 0.310919 0.314095C-0.0930304 0.732886 -0.103661 1.42013 0.310919 1.83893Z'
      fill={color}
    />
  </svg>
)
