/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'

import {
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'

import { circleCloseIcon, EColumnType, EFilterOperator } from '../../constants'
import {
  EPageType,
  IKeyValuePair,
  TFilterFieldsModel,
  TFilterFieldType,
} from '../../types'

import useStyles from './Style'
import modalSchemaHandler from './modalSchemaHandler'
import modalSchemaHandleChange from './modalSchemaChangeHandler'
import * as I from './IAppHeaderFilterDrawerContent'

type TOption = {
  key: EFilterOperator
  value: string
}

const selectConditionConstant: Array<TOption> = [
  { key: EFilterOperator.CONTAIN, value: 'Содержит' },
  { key: EFilterOperator.EQ, value: 'Равно' },
  { key: EFilterOperator.NE, value: 'Не равно' },
  { key: EFilterOperator.GT, value: 'Больше чем' },
  { key: EFilterOperator.LT, value: 'Меньше чем' },
  { key: EFilterOperator.INTERVAL, value: 'Интервал' },
]

const columnTypeConditions: Record<EColumnType, Array<string>> = {
  [EColumnType.STRING]: [
    EFilterOperator.CONTAIN,
    EFilterOperator.EQ,
    EFilterOperator.NE,
  ],
  [EColumnType.PHONE]: [
    EFilterOperator.CONTAIN,
    EFilterOperator.EQ,
    EFilterOperator.NE,
  ],
  [EColumnType.BOOLEAN]: [EFilterOperator.EQ, EFilterOperator.NE],
  [EColumnType.DATE]: [
    EFilterOperator.EQ,
    EFilterOperator.NE,
    EFilterOperator.GT,
    EFilterOperator.LT,
    EFilterOperator.INTERVAL,
  ],
  [EColumnType.DOUBLE]: [
    EFilterOperator.EQ,
    EFilterOperator.NE,
    EFilterOperator.GT,
    EFilterOperator.LT,
    EFilterOperator.INTERVAL,
  ],
  [EColumnType.CURRENCY]: [
    EFilterOperator.EQ,
    EFilterOperator.NE,
    EFilterOperator.GT,
    EFilterOperator.LT,
    EFilterOperator.INTERVAL,
  ],
  [EColumnType.LIST]: [
    EFilterOperator.CONTAIN,
    EFilterOperator.EQ,
    EFilterOperator.NE,
  ],
  [EColumnType.TREE_LIST]: [
    EFilterOperator.CONTAIN,
    EFilterOperator.EQ,
    EFilterOperator.NE,
  ],
}

const getConditionOptions = (columnType: EColumnType): Array<TOption> => {
  const columnConditions = columnTypeConditions[columnType]
  if (!columnType) {
    return []
  }

  return selectConditionConstant.filter(value =>
    columnConditions.includes(value.key),
  )
}

const getColumnType = (
  filterFields: TFilterFieldsModel,
  currentPage: EPageType,
  columnName: string,
): EColumnType => {
  if (filterFields.availableColumns.length) {
    return (filterFields.availableColumns.find(
      column => column.name === columnName,
    )?.type || '') as EColumnType
  }
  return (filterFields[currentPage].find(column => column.name === columnName)
    ?.types[0] || '') as EColumnType
}

const optionTitle: Record<EPageType, string> = {
  [EPageType.ITEMS]: 'Позиции',
  [EPageType.PROVIDER]: 'Поставщики',
  [EPageType.AGREEMENT]: 'Соглашения',
  [EPageType.TENDER]: 'Тендерные задания',
}

export const AppHeaderFilterDrawerContent: React.FC<I.OwnProps> = ({
  filterFields,
  filterItem,
  errorCheckFlag,
  errorsField,
  editFilterItem,
  removeFilterItem,
  handleErrorCheckFlag,
  handleShouldData,
  handleErrorsFields,
  availableColumns,
  currentPage,
}): React.ReactElement => {
  const classes = useStyles()
  const [optionsListOpen, setOptionsListOpen] = React.useState(false)
  const [optionsListLoading] = useState<boolean>(false)
  const [condition, setCondition] = useState<string>(
    filterItem.content.singleOperators[0].filterCompareType ===
      EFilterOperator.GTE ||
      filterItem.content.singleOperators[0].filterCompareType ===
        EFilterOperator.LTE
      ? EFilterOperator.INTERVAL
      : filterItem.content.singleOperators[0].filterCompareType ||
          EFilterOperator.CONTAIN,
  )
  const [columnType, setColumnType] = useState<EColumnType>('' as EColumnType)
  const [flag, setFlag] = useState(false)

  const options = useMemo(() => {
    let list

    if (availableColumns.length) {
      list = availableColumns.map(item => ({
        key: optionTitle[currentPage],
        value: item.name,
      }))
    } else {
      list = filterFields[currentPage].map((option: TFilterFieldType) => ({
        key: optionTitle[currentPage],
        value: option.name,
      }))
    }

    return list
  }, [filterFields, currentPage, availableColumns])

  useEffect(() => {
    const newColumnType = getColumnType(
      filterFields,
      currentPage,
      filterItem.content.key,
    )
    setColumnType(newColumnType)

    const prevCondition =
      filterItem.content.singleOperators[0].filterCompareType
    setCondition(
      prevCondition === EFilterOperator.GTE ||
        prevCondition === EFilterOperator.LTE
        ? EFilterOperator.INTERVAL
        : prevCondition || EFilterOperator.CONTAIN,
    )
  }, [
    filterItem.content.key,
    filterItem.content.singleOperators,
    currentPage,
    filterFields,
  ])

  const [stateTextField, setStateTextField] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({
    textField: filterItem.content.singleOperators[0].value || '',
    textFieldSecond:
      filterItem.content.singleOperators[0].filterCompareType !==
      EFilterOperator.GTE
        ? ''
        : filterItem.content.singleOperators[1].value,
    textAutocomplete: {
      value: filterItem.content.key,
      key: filterItem.keyValue,
    },
  })

  useEffect(() => {
    if (
      errorsField.filter(
        data =>
          (Object.keys(data.value).length === 2 &&
            data.value.textField.length === 0 &&
            data.value.textAutocomplete.length === 0) ||
          (Object.keys(data.value).length === 3 &&
            data.value.textField.length === 0 &&
            data.value.textAutocomplete.length === 0 &&
            data.value.textFieldSecond.length === 0),
      ).length === errorsField.length &&
      flag
    ) {
      handleShouldData(false)
      setFlag(false)
    }
    // eslint-disable-next-line
  }, [errorsField, flag])

  useEffect(() => {
    if (errorCheckFlag.find(data => data.value && data.key === filterItem.id)) {
      const errors = modalSchemaHandler(stateTextField, condition)
      handleErrorsFields(filterItem.id, errors)
      handleErrorCheckFlag(false, filterItem.id)

      if (
        Object.keys(errors)
          .map(data => Object.keys(errors[data]))
          .map(data => data.length === 0)
          .includes(true)
      ) {
        setFlag(true)
      }
    }
    // eslint-disable-next-line
  }, [errorCheckFlag, condition])

  //#region обработчик для выпадающего списка названиями кастомных колонок
  const handleSelectedValueChanged = (
    event: React.ChangeEvent<{}>,
    newValue: IKeyValuePair | null,
  ): void => {
    if (!newValue) {
      return
    }

    modalSchemaHandleChange(
      'textAutocomplete',
      newValue.value,
      errorsField.find(data => data.key === filterItem.id)?.value || {
        textAutocomplete: '',
        textField: '',
      },
      condition,
    )

    setStateTextField({
      textAutocomplete: { value: newValue.value, key: newValue.key },
      textField: '',
      textFieldSecond: '',
    })

    const newColumnType = getColumnType(
      filterFields,
      currentPage,
      newValue.value,
    )
    const availableNewConditions = columnTypeConditions[newColumnType]

    let newCondition = condition
    if (!availableNewConditions.includes(condition)) {
      ;[newCondition] = availableNewConditions
    }

    if (newCondition === EFilterOperator.INTERVAL) {
      editFilterItem(filterItem.id, newValue.value, newValue.key, [
        {
          filterCompareType: EFilterOperator.GTE,
          value: stateTextField.textField,
        },
        {
          filterCompareType: EFilterOperator.LTE,
          value: stateTextField.textFieldSecond,
        },
      ])
    } else if (
      newCondition === EFilterOperator.GT ||
      newCondition === EFilterOperator.LT
    ) {
      editFilterItem(filterItem.id, newValue.value, newValue.key, [
        {
          filterCompareType: newCondition,
          value: stateTextField.textField,
        },
      ])
    } else {
      editFilterItem(filterItem.id, newValue.value, newValue.key, [
        {
          filterCompareType: newCondition,
          value: stateTextField.textField,
        },
      ])
    }
  }
  //#endregion

  //#region обработчики для выпадающего списка с уловиями для фильтра
  const handleSelectedConditionChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setCondition(event.target.value as string)
    if ((event.target.value as string) === EFilterOperator.INTERVAL) {
      editFilterItem(
        filterItem.id,
        filterItem.content.key,
        filterItem.keyValue,
        [
          {
            filterCompareType: EFilterOperator.GTE,
            value: stateTextField.textField,
          },
          {
            filterCompareType: EFilterOperator.LTE,
            value: stateTextField.textFieldSecond,
          },
        ],
      )
    } else if (
      (event.target.value as string) === EFilterOperator.GT ||
      (event.target.value as string) === EFilterOperator.LT
    ) {
      editFilterItem(
        filterItem.id,
        filterItem.content.key,
        filterItem.keyValue,
        [
          {
            filterCompareType: event.target.value as string,
            value: stateTextField.textField,
          },
        ],
      )
    } else {
      editFilterItem(
        filterItem.id,
        filterItem.content.key,
        filterItem.keyValue,
        [
          {
            filterCompareType: event.target.value as string,
            value: stateTextField.textField,
          },
        ],
      )
    }
  }
  //#endregion

  //#region обработчик для полей ввода (TextField)
  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.value.length >= 0) {
      modalSchemaHandleChange(
        event.target.name,
        event.target.value,
        errorsField.find(data => data.key === filterItem.id)?.value || {
          textAutocomplete: '',
          textField: '',
        },
        condition,
      )
      setStateTextField({
        ...stateTextField,
        [event.target.name]: event.target.value,
      })
      if (condition === EFilterOperator.INTERVAL) {
        editFilterItem(
          filterItem.id,
          filterItem.content.key,
          filterItem.keyValue,
          [
            {
              filterCompareType: EFilterOperator.GTE,
              value:
                event.target.name === 'textField'
                  ? event.target.value
                  : stateTextField.textField,
            },
            {
              filterCompareType: EFilterOperator.LTE,
              value:
                event.target.name === 'textFieldSecond'
                  ? event.target.value
                  : stateTextField.textFieldSecond,
            },
          ],
        )
      } else if (
        condition === EFilterOperator.GT ||
        condition === EFilterOperator.LT
      ) {
        editFilterItem(
          filterItem.id,
          filterItem.content.key,
          filterItem.keyValue,
          [
            {
              filterCompareType:
                filterItem.content.singleOperators[0].filterCompareType,
              value:
                event.target.name === 'textField' ? event.target.value : '',
            },
          ],
        )
      } else {
        editFilterItem(
          filterItem.id,
          filterItem.content.key,
          filterItem.keyValue,
          [
            {
              filterCompareType:
                filterItem.content.singleOperators[0].filterCompareType,
              value:
                event.target.name === 'textField' ? event.target.value : '',
            },
          ],
        )
      }
    }
  }

  const handleFieldKeyDown = useCallback(
    (event): boolean => {
      if (
        ![EColumnType.CURRENCY, EColumnType.DOUBLE, EColumnType.PHONE].includes(
          columnType,
        )
      ) {
        return true
      }

      // Разрешаем backspace, tab, Delete, стрелки, обычные цифры и цифры на дополнительной клавиатуре
      const key = event.charCode || event.keyCode || 0
      if (
        !(
          key === 13 ||
          key === 8 ||
          key === 9 ||
          key === 46 ||
          event.key === '.' ||
          event.key === ',' ||
          (key >= 37 && key <= 40) ||
          (key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105) ||
          // CTRL-V
          (key === 86 && (event.ctrlKey || event.metaKey)) ||
          // CTRL-C
          (key === 67 && (event.ctrlKey || event.metaKey)) ||
          // CTRL-A
          (key === 65 && (event.ctrlKey || event.metaKey))
        )
      ) {
        event.preventDefault()
        return false
      }

      return true
    },
    [columnType],
  )
  //#endregion

  const handleFieldPaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      if (
        [EColumnType.CURRENCY, EColumnType.DOUBLE, EColumnType.PHONE].includes(
          columnType,
        )
      ) {
        const value = event.clipboardData.getData('text/plain')
        if (!/^[-]?\d+(?:\.\d+)?$/u.test(value)) {
          event.preventDefault()
        }
      }
    },
    [columnType],
  )

  return (
    <div className={classes.drawerCondition}>
      <div className={classes.drawerConditionHeader}>
        <h5>Условие {Number(filterItem.id) + 1}</h5>
        <p
          onClick={(): void => removeFilterItem(filterItem.id)}
          data-test-id={`filterDrawerRemoveConditionBtn_${Number(
            filterItem.id,
          )}`}
        >
          {circleCloseIcon}
        </p>
      </div>
      <Divider />
      <div className={classes.drawerConditionContent}>
        <Autocomplete
          className={classes.drawerAutocomplete}
          open={optionsListOpen}
          onOpen={(): void => {
            setOptionsListOpen(true)
          }}
          onClose={(): void => {
            setOptionsListOpen(false)
          }}
          value={
            !stateTextField.textAutocomplete.key
              ? null
              : stateTextField.textAutocomplete
          }
          options={options}
          onChange={handleSelectedValueChanged}
          getOptionLabel={(option: IKeyValuePair): string => option.value}
          getOptionSelected={(
            option: IKeyValuePair,
            value: IKeyValuePair,
          ): boolean => option.key === value.key}
          groupBy={(option: IKeyValuePair): string => option.key}
          loading={optionsListLoading}
          noOptionsText={'Ничего не найдено'}
          loadingText={'Загрузка...'}
          clearOnEscape
          renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
            <TextField
              {...params}
              variant='outlined'
              error={Boolean(
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textAutocomplete,
              )}
              helperText={
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textAutocomplete
              }
              placeholder='Выберите условие...'
              InputProps={{ ...params.InputProps }}
              data-test-id={`filterDrawerConditionInput1_${Number(
                filterItem.id,
              )}`}
            />
          )}
          renderOption={(option: IKeyValuePair): JSX.Element => {
            const indexOfDivider = option.value.indexOf('[')
            return (
              <div
                data-test-id={`filterDrawerConditionInput1_${Number(
                  filterItem.id,
                )}_option_${option.key}`}
                className={classes.optionConditionInput}
              >
                <div>{option.value.substring(0, indexOfDivider)}</div>
                <div className={classes.optionConditionType}>
                  {option.value.substring(indexOfDivider)}
                </div>
              </div>
            )
          }}
        />
        <FormControl
          variant='outlined'
          className={classes.drawerSelectCondition}
        >
          <Select
            data-test-id={`filterDrawerConditionInput2_${Number(
              filterItem.id,
            )}`}
            value={condition}
            onChange={handleSelectedConditionChange}
          >
            {getConditionOptions(columnType).map(data => (
              <MenuItem
                key={data.key}
                value={data.key}
                data-test-id={`filterDrawerConditionInput2_${Number(
                  filterItem.id,
                )}_option_${data.key}`}
              >
                {data.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {condition !== EFilterOperator.INTERVAL ? (
          <TextField
            placeholder=''
            variant='outlined'
            error={Boolean(
              errorsField.find(data => data.key === filterItem.id)?.value
                .textField,
            )}
            helperText={
              errorsField.find(data => data.key === filterItem.id)?.value
                .textField
            }
            className={classes.drawerTextField}
            value={stateTextField.textField}
            onChange={handleChangeTextField}
            onPasteCapture={handleFieldPaste}
            onKeyDown={handleFieldKeyDown}
            inputProps={{
              name: 'textField',
              'data-test-id': `filterDrawerConditionInput3_${Number(
                filterItem.id,
              )}`,
            }}
          />
        ) : (
          <div className={classes.drawerTextFieldIntervalContainer}>
            <TextField
              placeholder=''
              variant='outlined'
              error={Boolean(
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textField,
              )}
              helperText={
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textField
              }
              className={classes.drawerTextFieldInterval}
              value={stateTextField.textField}
              onKeyDown={handleFieldKeyDown}
              onPasteCapture={handleFieldPaste}
              onChange={handleChangeTextField}
              inputProps={{
                name: 'textField',
                'data-test-id': `filterDrawerConditionInput3_${Number(
                  filterItem.id,
                )}`,
              }}
            />
            <p>—</p>
            <TextField
              placeholder=''
              variant='outlined'
              error={Boolean(
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textFieldSecond,
              )}
              helperText={
                errorsField.find(data => data.key === filterItem.id)?.value
                  .textFieldSecond
              }
              className={classes.drawerTextFieldInterval}
              onKeyDown={handleFieldKeyDown}
              onPasteCapture={handleFieldPaste}
              value={stateTextField.textFieldSecond}
              onChange={handleChangeTextField}
              inputProps={{
                name: 'textFieldSecond',
                'data-test-id': `filterDrawerConditionInput4_${Number(
                  filterItem.id,
                )}`,
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
