import styled, { css } from 'styled-components'
import { ArrowDown as ArrowDownInit } from '@gmini/ui-kit'

export const TextItemWrapper = styled.div`
  position: relative;
  overflow-x: clip;
`

const ItemCss = css`
  display: -webkit-box;
  max-width: 250px;
  font-size: 14px;
  margin-right: 50px;
  white-space: pre-wrap;
  flex-wrap: wrap;
  overflow: hidden;
  max-width: unset;
  margin-right: 0;
  -webkit-box-orient: vertical;

  & a {
    text-decoration: underline;
    color: rgba(76, 94, 207, 1);
  }
`

export const HiddenCalculationDiv = styled.div`
  ${ItemCss}
  position: absolute;
  visibility: hidden;
`

type TextItemProps = {
  expanded: boolean
}

export const TextItem = styled.div`
  ${ItemCss}

  -webkit-line-clamp: ${({ expanded }: TextItemProps) =>
    expanded ? 'unset' : 5};
`

export const ShowAllButton = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #4c5ecf;
  padding-top: 8px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
`
type ArrowDownProps = {
  expanded: boolean
}
export const ArrowDown = styled(ArrowDownInit)`
  transition: all ease-in 0.3s;
  ${({ expanded }: ArrowDownProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
`
