// imports the React Javascript Library
import React, { useMemo, useState, useEffect, ReactNode } from 'react'
import { toastr } from 'react-redux-toastr'
import * as _ from 'lodash'
import { Grid, Avatar, Badge, SvgIcon } from '@material-ui/core'

import * as Sentry from '@sentry/react'

import { IFileUploadState, IFile } from '../../types'

import { ButtonComponent } from '../Button/Button'
import { uuid } from '../../utils'

import {
  EFileUploadMainState,
  EFileUploadListType,
  EButtonType,
  EButtonIcon,
} from '../../constants'

import * as I from './IFileUpload'
import { styles } from './Style'

interface IWrongFile {
  file: File
  message: string
}

const notAvaibleFileTypes: Array<string> = [
  'sh',
  'apk',
  'bat',
  'bin',
  'cgi',
  'cmd',
  'exe',
  'jar',
  'exe',
  'msi',
  'msu',
  'ps1',
  'scr',
  'thm',
  'vb',
  'vbe',
  'vbs',
  'wsf',
]
let avaibleFileTypes: Array<string> = []
let maxFileSize: number
let wrongFiles: Array<IWrongFile> = []

export const FileUpload: React.FC<I.OwnProps> = ({
  initialState,
  maxNumberOfFiles = 10,
  fileTypes,
  showAddBtn = false,
  addBtnText = 'Добавить фото',
  customButton,
  addBtnIcon = EButtonIcon.PHOTO,
  addBtnType = EButtonType.DEFAULT,
  showDeleteBtn = false,
  fileSize = 5,
  allowMultiplySelect = true,
  listType = EFileUploadListType.IMAGE,
  initCallback,
  onFileLoaded,
  loading,
  dataTestIdPrefix,
}): React.ReactElement => {
  let fileInputRef: HTMLInputElement | null = null
  let formRef: HTMLFormElement | null = null

  const [state, setState] = useState<IFileUploadState>({
    mainState: EFileUploadMainState.INITIAL,
    files: { ...initialState.current },
  })
  useMemo(() => {
    initialState.current = { ...state.files }
  }, [state, initialState])

  const classes = styles()

  useEffect(() => {
    if (fileTypes) {
      avaibleFileTypes = fileTypes.split(',')
    }

    if (fileSize) {
      maxFileSize = fileSize * 1049000
    }

    if (initCallback) {
      initCallback()
    }
  })

  const checkIfFileExist = React.useCallback(
    (file: File): boolean =>
      state.files.add.length > 0 &&
      state.files.add.some(rf => rf.file.name === file.name),
    [state.files.add],
  )

  const checkFileSize = React.useCallback(
    (file: File): boolean => !maxFileSize || file.size < maxFileSize,
    [],
  )

  const checkIfFileTypeAvaible = React.useCallback(
    (file: File): boolean =>
      (!avaibleFileTypes ||
        avaibleFileTypes.length === 0 ||
        avaibleFileTypes.some((aft: string) =>
          file.name.toLowerCase().endsWith(`.${aft.toLowerCase().trim()}`),
        )) &&
      notAvaibleFileTypes.every(
        (naft: string) =>
          !file.name.toLowerCase().endsWith(`.${naft.toLowerCase().trim()}`),
      ),
    [],
  )

  const checkResultion = React.useCallback(
    (file: File): Promise<boolean> =>
      new Promise((resolve, reject) => {
        if (file.type.split('/')[0] === 'image') {
          const img = new Image()

          // the following handler will fire after the successful loading of the image
          img.onload = (): void => {
            const { naturalWidth: width, naturalHeight: height } = img
            resolve(width >= 512 && height >= 512)
          }

          // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
          img.onerror = (): void => {
            reject('There was some problem with the image.')
          }

          img.src = URL.createObjectURL(file)
        } else {
          resolve(true)
        }
      }),
    [],
  )

  const restrictionCheck = React.useCallback(
    async (file: File): Promise<void> => {
      if (!checkFileSize(file)) {
        wrongFiles.push({
          file,
          message: `Размер файла превышает установленный лимит: ${fileSize} МБ`,
        })
      } else if (!(await checkResultion(file))) {
        wrongFiles.push({
          file,
          message: `Минимально допустимое разрешение изображений 512*512px`,
        })
      } else if (checkIfFileTypeAvaible(file)) {
        if (Number(state.files.current.length + 1) <= maxNumberOfFiles) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          const id = uuid()

          reader.onloadend = (): void => {
            setState(sta => ({
              ...sta,
              files: {
                current: sta.files.current.concat([
                  {
                    id,
                    file: reader.result as string,
                  },
                ]),
                add: sta.files.add.concat([
                  {
                    id,
                    file,
                  },
                ]),
                remove: sta.files.remove,
              },
            }))
          }
        } else {
          wrongFiles.push({
            file,
            message: `Превышен допустимый лимит количества файлов (${maxNumberOfFiles} шт):`,
          })
        }
      } else {
        const message =
          !avaibleFileTypes || avaibleFileTypes.length === 0
            ? `Нельзя загружать исполняемые типы файлов: ${notAvaibleFileTypes.join(
                ', ',
              )}`
            : `Доступные типы файлов для загрузки: ${avaibleFileTypes.join(
                ', ',
              )}`

        wrongFiles.push({ file, message })
      }
    },
    [
      checkFileSize,
      checkIfFileTypeAvaible,
      checkResultion,
      fileSize,
      maxNumberOfFiles,
      state.files,
    ],
  )

  React.useEffect(() => {
    if (state) {
      onFileLoaded?.(state, setState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, setState])

  const handleUploadClick = React.useCallback(
    async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {
      try {
        wrongFiles = []

        const { files } = event.target as HTMLInputElement
        if (!files || !files.length) {
          return
        }

        for (let i = 0; i < files.length; i++) {
          const file = files[i]
          if (checkIfFileExist(file)) {
            wrongFiles.push({
              file,
              message: `Файл с таким именем уже загружен`,
            })
          } else {
            // eslint-disable-next-line no-await-in-loop
            await restrictionCheck(file)
          }
        }

        if (wrongFiles.length > 0) {
          const msg: Array<JSX.Element> = []

          const dict = _.chain(wrongFiles)
            .groupBy((wf: IWrongFile) => wf.message)
            .map((value, key) => ({
              message: key,
              files: value.map((wf: IWrongFile) => wf.file.name),
            }))
            .value()

          dict.forEach(d => {
            const renderFiles = (name: string, i: number): JSX.Element => (
              <li key={i} style={{ wordBreak: 'break-all' }}>
                <b>{name}</b>
              </li>
            )
            msg.push(
              <div key={uuid()}>
                {d.message}:
                <br />
                <ul>{d.files.map(renderFiles)}</ul>
              </div>,
            )
          })

          const MessageWrapper: React.FC<{ children?: ReactNode }> = ({
            children,
          }): React.ReactElement => <div>{children}</div>

          toastr.error('', '', {
            component: <MessageWrapper key={uuid()}>{msg}</MessageWrapper>,
            progressBar: false,
            showCloseButton: false,
          })
          toastr.error('', '', {
            component: (
              <MessageWrapper key={uuid()}>
                <b>Не все файлы были загружены!</b>
              </MessageWrapper>
            ),
            progressBar: false,
            showCloseButton: false,
          })
        }
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
      }
    },
    [checkIfFileExist, restrictionCheck],
  )

  const removeFile = React.useCallback(
    (file: IFile<string> | undefined): void => {
      if (!file) {
        return
      }

      if (state.files.add.some(f => f.id === file.id)) {
        setState({
          ...state,
          files: {
            current: state.files.current.filter(f => f.id !== file.id),
            add: state.files.add.filter(f => f.id !== file.id),
            remove: state.files.remove,
          },
        })
      } else {
        setState({
          ...state,
          files: {
            current: state.files.current.filter(f => f.id !== file.id),
            add: state.files.add,
            remove: state.files.remove.concat([file.id]),
          },
        })
      }
    },
    [state],
  )

  const renderInitialState = (): JSX.Element => (
    <React.Fragment>
      <Grid container justify='center' alignItems='center'>
        <form
          ref={(form: HTMLFormElement | null): void => {
            formRef = form
          }}
        >
          <input
            ref={(input: HTMLInputElement | null): void => {
              fileInputRef = input
            }}
            className={classes.input}
            id='contained-button-file'
            multiple={allowMultiplySelect}
            type='file'
            onChange={handleUploadClick}
            disabled={!!loading}
            data-test-id={`${dataTestIdPrefix}FileUploadInput`}
          />
        </form>
        {customButton ? (
          customButton({
            onClick: () => {
              if (fileInputRef && formRef) {
                formRef.reset()
                fileInputRef.click()
              }
            },
            'data-test-id': `${dataTestIdPrefix}FileUploadBtn`,
          })
        ) : (
          <ButtonComponent
            data-test-id={`${dataTestIdPrefix}FileUploadBtn`}
            disabled={!!loading}
            text={addBtnText}
            type={addBtnType}
            typeIcon={addBtnIcon}
            onClick={(): void => {
              if (fileInputRef && formRef) {
                formRef.reset()
                fileInputRef.click()
              }
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  )

  const renderGalleryState = (): JSX.Element => {
    const listItems = state.files.current.map((file, index) => (
      <div
        key={file.id}
        style={{
          padding: '5px 5px 5px 5px',
          cursor: 'pointer',
        }}
      >
        {showDeleteBtn ? (
          <Badge
            badgeContent={
              <svg
                data-test-id={`${dataTestIdPrefix}FileUploadDeleteBtn_${index}`}
                onClick={(): void => removeFile(file)}
                viewBox='64 64 896 896'
                width='12px'
                height='12px'
                data-icon='close'
                aria-hidden='true'
              >
                <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
              </svg>
            }
            color='primary'
            overlap='rectangle'
          >
            <Avatar src={file.file} />
          </Badge>
        ) : (
          <Avatar src={file.file} />
        )}
      </div>
    ))

    return (
      <React.Fragment>
        <Grid container spacing={1} direction='row'>
          {listItems}
        </Grid>
      </React.Fragment>
    )
  }

  const renderFileListState = (): JSX.Element => {
    const listItems = state.files.add.map((file, index) => (
      <div
        key={file.id}
        style={{
          padding: '5px 5px 5px 5px',
          cursor: 'pointer',
          display: 'flex',
        }}
      >
        {
          <div className={classes.listItemContainer}>
            <span className={classes.listItem}>{file.file.name}</span>
            {showDeleteBtn && (
              <SvgIcon
                data-test-id={`${dataTestIdPrefix}FileUploadDeleteBtn_${index}`}
                onClick={(): void =>
                  removeFile(state.files.current.find(f => f.id === file.id))
                }
                style={{ width: '10px', height: '10px' }}
                className={classes.listItem}
                viewBox='64 64 896 896'
                data-icon='close'
                aria-hidden='true'
              >
                <path d='M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z'></path>
              </SvgIcon>
            )}
          </div>
        }
      </div>
    ))

    return (
      <React.Fragment>
        <Grid container spacing={1} direction='column'>
          {listItems}
        </Grid>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container spacing={1} direction='row' alignItems='center'>
          <Grid item>{showAddBtn && renderInitialState()}</Grid>
          <Grid item>
            {listType === EFileUploadListType.IMAGE
              ? renderGalleryState()
              : renderFileListState()}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  )
}
