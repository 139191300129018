import { connect, ConnectedProps } from 'react-redux'

import {
  getAssignmentDetail,
  addAssignmentLotProperty,
  editAssignmentLotProperty,
  removeAssignmentLotProperty,
  exportAssignmentLot,
  removeAssignmentLotPositions,
  removeAssignmentLotSupplier,
  removeAssignmentLot,
  combineAssignmentLot,
  changeLotAssignment,
  addLotToAssignment,
  changeLotName,
  movePositions,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  createAgreement,
  getLotCategory,
  createPosition,
  addToAssignment,
  changeStatusAssignment,
  exportAssignmentTemplate,
  getPositionsOld,
} from '../../../actions'

import { RootState } from '../../../store'

import * as I from './assignment-view-types'

export const mapDispatch = {
  getAssignmentDetail,
  addProperty: addAssignmentLotProperty,
  editProperty: editAssignmentLotProperty,
  removeProperty: removeAssignmentLotProperty,
  exportLot: exportAssignmentLot,
  removeLotPositions: removeAssignmentLotPositions,
  removeLotSupplier: removeAssignmentLotSupplier,
  removeLot: removeAssignmentLot,
  combineLot: combineAssignmentLot,
  changeLotAssignment,
  addLotToAssignment,
  changeLotName,
  movePositions,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  addAgreement: createAgreement,
  getLotCategory,
  getPositions: getPositionsOld,
  addPosition: createPosition,
  addToAssignment,
  changeStatusAssignment,
  exportTemplate: exportAssignmentTemplate,
}

export const mapStateToProps = (state: RootState): I.StateProps => ({
  positions: {
    ...state.positions.data,
    columns: state.positions.data.columns.map((column: any) => ({
      ...column,
      title:
        column.title.lastIndexOf('[') === -1
          ? column.title
          : column.title.substring(0, column.title.lastIndexOf('[')),
    })),
  },
  assignment: {
    ...state.viewDetails.assignment,
    data: {
      ...state.viewDetails.assignment.data,
      columns: state.viewDetails.assignment.data.columns.filter(
        (item: any) => !['Проект', 'Объект'].includes(item.title),
      ),
    },
  },
})

export const connector = connect(mapStateToProps, mapDispatch)
export type PropsFromRedux = ConnectedProps<typeof connector>
