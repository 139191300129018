import React, { useCallback, useState } from 'react'

import { TableColumn } from '@gmini/ui-kit'

import { useAppSelector } from 'src/store'

import { Pagination, getLogEvents } from 'src/actions/EventsLog'

import { LogEvent } from '../../../../types'

import { useAppDispatch } from '../../../../store'

import { EventsListPagination } from '../EventsListPagination/EventsListPagination'

import {
  AuthorCell,
  StyledTable,
  TableContent,
  TableWrapper,
} from './EventsList.styled'
import { EventLogTextCell } from './EventLogTextCell/EventLogTextCell'

type EventsListRow = LogEvent

const initColumns: TableColumn<EventsListRow>[] = [
  {
    field: 'type',
    name: 'Вид события',
    visible: true,
    renderCell: ({ row }) => <>{row.type}</>,
  },
  {
    field: 'text',
    name: 'Событие',
    visible: true,
    cellStyle: { minWidth: '630px', wordBreak: 'break-all' },
    style: { width: 'auto' },
    renderCell: ({ row, rowIdx }) => (
      <EventLogTextCell text={row.text} idx={rowIdx} />
    ),
  },
  {
    field: 'author',
    name: 'Автор',
    visible: true,
    renderCell: ({ row }) => <AuthorCell>{row.author}</AuthorCell>,
  },
  {
    field: 'date',
    name: 'Дата',
    visible: true,
    renderCell: ({ row }) => (
      <div>{new Date(row.date).toLocaleDateString()}</div>
    ),
  },
]

export const EventsList = () => {
  const [columns, setColumns] = useState(initColumns)
  const { pending, total, logEvents } = useAppSelector(state => state.eventsLog)
  const dispatch = useAppDispatch()

  const fetchList = useCallback(
    (props: Pagination) => dispatch(getLogEvents(props)),
    [dispatch],
  )

  return (
    <TableWrapper>
      <TableContent>
        <StyledTable
          columns={columns}
          rows={logEvents}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRowCtxMenu={() => {}}
          pending={pending}
          activeRowKey={''}
          getRowKey={row => row.id}
          onChangeColumns={setColumns}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={() => {}}
          data-test-id='eventsTable'
          trProps={rowKey => ({ 'data-test-id': `eventsTableRow_${rowKey}` })}
          tdProps={(_, colIdx) => ({ hidden: colIdx === columns.length })}
          thProps={colIdx => ({ hidden: colIdx === columns.length })}
        />
      </TableContent>
      <EventsListPagination
        fetchList={fetchList}
        total={total}
        perPageOptions={[10, 50, 100]}
      />
    </TableWrapper>
  )
}
