import { makeStyles, Theme } from '@material-ui/core/styles'

export const useImprovedTableHeadDisplayColumnsStyles = makeStyles(
  (theme: Theme) => ({
    table: {
      width: '270px',
      '& .MuiTableCell-root': {
        padding: '0',
      },
      '& .MuiIconButton-label:hover, .MuiIconButton-label:focus, .MuiIconButton-label:active': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
    },
    columnIconBtn: {
      '& button:hover, & button:focus, & button:active': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      '& .MuiButton-root': {
        minWidth: '16px',
        float: 'right',
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& p': {
        fontSize: '12px',
        color: '#535F77',
        margin: '16px 0 10px 13px',
        fontWeight: 'bold',
        padding: '0',
      },
    },
    actionButton: {
      flex: '1 1 20px',
      padding: '14px',
      maxWidth: '20px',
      maxHeight: '20px',
      marginRight: '3px',
      '& .MuiSvgIcon-root': {
        color: 'rgba(0,0,0,0)',
        fontSize: '18px',
      },
    },
    popover: {
      '& .MuiPaper-root': {
        '&::-webkit-scrollbar': {
          width: '8px !important',
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
        },
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      },
    },
  }),
)

export default useImprovedTableHeadDisplayColumnsStyles
