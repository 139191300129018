import React from 'react'

export const CheckmarkIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 12C2 6.47 6.47 2 12 2C17.53 2 22 6.47 22 12C22 17.53 17.53 22 12 22C6.47 22 2 17.53 2 12ZM16.3625 7.87493L11 13.2374L8.63746 10.8749C8.40392 10.6409 8.08686 10.5093 7.75621 10.5093C7.42557 10.5093 7.1085 10.6409 6.87496 10.8749C6.38746 11.3624 6.38746 12.1499 6.87496 12.6374L10.2929 16.0553C10.6834 16.4458 11.3165 16.4458 11.7071 16.0553L18.125 9.63743C18.6125 9.14993 18.6125 8.36243 18.125 7.87493C17.6375 7.39993 16.8375 7.39993 16.3625 7.87493Z'
      fill='#0BBB7B'
    />
  </svg>
)
