import React from 'react'

import { User } from '../../../../types'

import { UserAvatar } from '../UserAvatar/UserAvatar'

import {
  ActionList,
  Divider,
  ListItem,
  Name,
  Popup,
  PopupHeader,
  Role,
} from './UserAccountPopup.styled'

type UserAccountPopupListItem = {
  text: string
  icon: JSX.Element
  handler: () => void
}

type UserAccountPopupProps = {
  userData: User
  items: UserAccountPopupListItem[]
}

export const UserAccountPopup = ({
  userData,
  items,
}: UserAccountPopupProps) => (
  <Popup>
    <PopupHeader>
      <UserAvatar bigGrey fullname={userData.name} />
      <Name>{userData.name}</Name>
      {userData.roles
        ? Object.values(userData.roles).map(r => <Role key={r}>{r}</Role>)
        : null}
    </PopupHeader>
    <Divider />
    <ActionList>
      {items.map(({ text, icon, handler }, idx) => (
        <ListItem
          data-test-id={`userAccountListItem_${idx}`}
          key={text}
          onClick={handler}
        >
          {icon}
          <span>{text}</span>
        </ListItem>
      ))}
    </ActionList>
  </Popup>
)
