import { withStyles, Theme, DialogContent } from '@material-ui/core'

export const ModalContent = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '25px',
    fontFamily: 'Proxima Nova Regular',
    '&::-webkit-scrollbar': {
      width: '8px !important',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& .MuiFilledInput-root, .MuiFilledInput-root:focus': {
      backgroundColor: '#F9F9FB',
    },
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .fullWidth': {
      width: '100%',
      '& .MuiInputBase-root': {
        fontSize: '20px',
        '& .MuiTypography-root': {
          fontSize: '20px',
        },
      },
      '& .MuiFilledInput-root': {
        '& .MuiInputBase-input': {
          fontSize: '20px',
        },
      },
    },
    '& .MuiFilledInput-root:before, & .MuiFilledInput-root:after': {
      content: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '14px',
      '& .MuiTypography-root': {
        fontSize: '14px',
      },
    },
    '& .MuiFilledInput-root': {
      '& .MuiInputBase-input': {
        padding: '15px',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#1B2B4B',
        fontFamily: 'Proxima Nova Regular',
      },
    },
    '& .MuiFormControlLabel-root': {
      margin: '10px 0',
    },
    '& .MuiFormControlLabel-label': {
      alignSelf: 'flex-start',
      fontSize: '14px',
      fontFamily: 'Proxima Nova Light',
      marginBottom: '15px',
      color: '#1B2B4B',
      height: '17px',
      lineHeight: '17px',
    },
    '& .MuiFormControl-root:not(.MuiFormControl-fullWidth)': {
      margin: '10px 0',
    },
    '& .MuiDivider-root': {
      margin: '15px 0',
    },
    '& h3': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#4C5ECF',
      margin: '20px 0',
    },
    '& .propContainer': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 -10px',
    },
    '& .prop': {
      maxWidth: '279px',
      minWidth: '279px',
      margin: '10px 10px',
      justifyContent: 'flex-end',
      '& .MuiFormControl-root': {
        maxWidth: '279px',
        minWidth: '279px',
      },
    },
    '& .prop.listProp': {
      margin: '10px 10px',
      '& .MuiFormControlLabel-root': {
        margin: '0 0 10px 0',
      },
    },
  },
}))(DialogContent)
