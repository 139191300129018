import React from 'react'

import { Link } from '@material-ui/core'

import { Attachments } from '../../types'

import { AddDocumentData } from './DocumentsField'

export const validate = ({ type, file }: AddDocumentData) => ({
  type: !type && 'Введите вид файла',
  file: !file && 'Необходимо добавить хотя бы один файл',
})

export const documentsMapper = (documents: Attachments[]) =>
  documents.map(document => ({
    ...document,
    fileName: (
      <Link href={document.url} download>
        {document.fileName}
      </Link>
    ),
  }))
