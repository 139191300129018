import styled from 'styled-components'

type SupplierValueProps = {
  indent?: boolean
}

export const SupplierRowWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SupplierKey = styled.div`
  font-weight: 700;
`

export const SupplierValue = styled.div`
  font-size: 14px;
  padding-left: ${({ indent }: SupplierValueProps) => indent && '5px'};
`
