import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { Dialog, FormControlLabel, TextField } from '@material-ui/core'

import { ModalTitle } from '../../../../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../../../../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../../../../BaseModal/ModalActions/ModalActions'
import { ITreeListItem } from '../../../../../types'
import {
  EButtonType,
  EEditableModalType,
  ETreeListItemType,
} from '../../../../../constants'
import { ButtonComponent } from '../../../../Button/Button'

import { useStyles } from '../../../styles/refactorStyles'

const validationSchema = yup.object().shape({
  name: yup.string().required('Поле не заполнено'),
})

export type CatalogModalProps = {
  open: boolean
  title: string
  submitBtnText: string
  handleClose: () => void
  handleSubmitCatalogModal: ({
    name,
    nodeType,
  }: {
    name: string
    nodeType: ETreeListItemType
  }) => void
  mode: EEditableModalType
  type: ETreeListItemType
  node?: ITreeListItem
}

export const CatalogModal: React.FC<CatalogModalProps> = ({
  open,
  title,
  submitBtnText,
  handleClose,
  handleSubmitCatalogModal,
  mode,
  node,
  type,
}) => {
  const name = mode === EEditableModalType.EDIT && node?.name ? node.name : ''
  const typeListNode = type

  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      name,
    },
    validationSchema,
    onSubmit: values =>
      handleSubmitCatalogModal({ name: values.name, nodeType: typeListNode }),
  })

  return (
    <div>
      {open && (
        <Dialog
          onClose={handleClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
        >
          <form onSubmit={formik.handleSubmit}>
            <ModalTitle
              dataTestIdPrefix='catalogSettingsModal'
              id='customized-dialog-title'
              onClose={handleClose}
            >
              {title}
            </ModalTitle>
            <ModalContent dividers>
              <FormControlLabel
                className='fullWidth'
                control={
                  <TextField
                    name='name'
                    fullWidth
                    variant='filled'
                    placeholder='Введите название'
                    inputProps={{
                      maxLength: 70,
                      'data-test-id': 'catalogSettingsModalInput',
                    }}
                    error={
                      Boolean(formik.touched.name) &&
                      Boolean(formik.errors.name)
                    }
                    helperText={formik.errors.name}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                }
                label='Название (длина названия не должна превышать 70 символов)'
                labelPlacement='top'
              />
            </ModalContent>
            <ModalActions>
              <div className={classes.buttonContainer}>
                <ButtonComponent
                  data-test-id='catalogSettingsModalCancelBtn'
                  text='Отменить'
                  type={EButtonType.DEFAULT}
                  onClick={handleClose}
                />
                <ButtonComponent
                  data-test-id='catalogSettingsModalSubmitBtn'
                  text={submitBtnText}
                  type={EButtonType.PRIMARY}
                  htmlType='submit'
                />
              </div>
            </ModalActions>
          </form>
        </Dialog>
      )}
    </div>
  )
}
