import styled from 'styled-components'
import { Select } from '@material-ui/core'

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  min-height: 48px;
  margin: 0 24px;
`

export const Description = styled.p`
  font-size: 12px;
  display: block;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin: 0;
`

export const StyledSelect = styled(Select)`
  margin-left: 8px;
  margin-right: 32px;
  & div {
    font-size: 12px;
    text-align: right;
    padding-left: 8px;
    padding-right: 24px;
    text-align-last: right;
  }
`

export const ButtonContainer = styled.div`
  margin-left: 20px;
  & button {
    color: inherit;
  }
`
