import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'

export const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
`
export const PositionFilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const RadioButton = withStyles({
  colorSecondary: {
    '&$checked': {
      color: '#353B60',
    },
  },
  checked: {},
})(Radio)
