import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: '0',
      border: '0',
    },
    '& .MuiPaper-elevation24': {
      boxShadow: '-20px 0px 40px rgba(72, 89, 122, 0.12)',
    },
    '& .MuiPaper-root': {
      width: '645px',
    },
    '& .MuiDivider-root': {
      margin: '0 0 0 34px',
      border: '0.5px solid #E2E4E9',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 10px 10px 34px',
    padding: '0',
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
    '& .MuiTypography-root': {
      fontSize: '22px',
    },
  },
  dialogContent: {
    margin: '25px 0 35px 0',
    padding: '0 0 0 34px',
    '& .MuiTypography-root': {
      color: '#1B2B4B',
      fontSize: '18px',
    },
  },
  dialogActions: {
    boxShadow: '-20px 0px 40px rgba(72, 89, 122, 0.12)',
    margin: '8px 0 0 0',
    paddingRight: '20px',
  },
}))
