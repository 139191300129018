import { makeStyles, Theme } from '@material-ui/core/styles'

export const useImprovedTableHeadDisplayColumnsStyles = makeStyles(
  (theme: Theme) => ({
    checkedRow: {
      margin: '0 0 0 1px',
      '&:hover, &:focus, &:active': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      '&$checkboxChecked, &$checkboxChecked:hover': {
        backgroundColor: 'rgba(0,0,0,0)',
      },
      '& .MuiTableCell-root': {
        border: '0',
      },
    },
    empty: {
      width: '15px',
      height: '42px',
    },
    checkboxChecked: {},
    actionsContainer: {
      display: 'flex',
      float: 'right',
      justifyContent: 'center',
      marginRight: '6px',
    },
    actionButton: {
      flex: '1 1 20px',
      padding: '14px',
      width: '20px',
      height: '20px',
      margin: '0 -3px',
      '& .MuiSvgIcon-root': {
        fontSize: '18px',
      },
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }),
)

export default useImprovedTableHeadDisplayColumnsStyles
