/* eslint-disable import/no-anonymous-default-export */
import { EActionTypes } from '../../constants'

import { TViewState } from '../../types'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type IViewStateSetIsLoadingAction = IBaseActionCreator<
  EActionTypes.SET_DATA_LOADING,
  boolean
>

export type ViewStateAction = IViewStateSetIsLoadingAction

const initialState: TViewState = {
  dataIsLoading: false,
}

export default (state = initialState, action: ViewStateAction): TViewState => {
  switch (action.type) {
    case EActionTypes.SET_DATA_LOADING: {
      return { ...state, dataIsLoading: action.payload }
    }
    default: {
      return state
    }
  }
}
