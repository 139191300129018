import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const FileIcon = ({ color = '#353B60', ...props }: SvgComponentType) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 2.09414C13.2242 2.17341 13.431 2.30125 13.6044 2.472L16.5206 5.336C16.7139 5.52129 16.853 5.75048 16.9301 6H13.5C13.2239 6 13 5.77614 13 5.5V2.09414ZM12 2H5.625C4.73125 2 4 2.72 4 3.6V16.4C4 17.28 4.72312 18 5.61687 18H15.375C16.2688 18 17 17.28 17 16.4V7H13.5C12.6716 7 12 6.32843 12 5.5V2ZM8.0625 14.8H12.9375C13.3844 14.8 13.75 14.44 13.75 14C13.75 13.56 13.3844 13.2 12.9375 13.2H8.0625C7.61562 13.2 7.25 13.56 7.25 14C7.25 14.44 7.61562 14.8 8.0625 14.8ZM8.0625 11.6H12.9375C13.3844 11.6 13.75 11.24 13.75 10.8C13.75 10.36 13.3844 10 12.9375 10H8.0625C7.61562 10 7.25 10.36 7.25 10.8C7.25 11.24 7.61562 11.6 8.0625 11.6Z'
      fill={color}
    />
  </svg>
)
