import React from 'react'

import { HighlightOff } from '@gmini/ui-kit'

import { FilterIcon } from '../../../../icons/FilterIcon'

import {
  CancelIconWrap,
  FilterButtonWrap,
  FilterContent,
  FilterEndContent,
  FilterText,
} from './FilterButton.styled'

type ActiveFilterButtonProps = {
  active: boolean
  text: string
  onFilterClick: () => void
  onCancelClick?: () => void
}

export const FilterButton = ({
  text,
  onCancelClick,
  onFilterClick,
  active,
}: ActiveFilterButtonProps) => (
  <FilterButtonWrap active={active}>
    <FilterContent
      data-test-id='openFilterDrawerBtn'
      active={active}
      onClick={onFilterClick}
    >
      <FilterIcon color={active ? '#fff' : '#353B60'} />
      <FilterText active={active}>{text}</FilterText>
    </FilterContent>
    {active && (
      <FilterEndContent>
        <CancelIconWrap
          data-test-id='cancelFilterDrawerBtn'
          onClick={() => onCancelClick?.()}
        >
          <HighlightOff color='#4c5ecf' />
        </CancelIconWrap>
      </FilterEndContent>
    )}
  </FilterButtonWrap>
)
