import React from 'react'

import * as I from './ISidebarIconWrapper'

export const SidebarIconWrapper: React.FC<I.OwnProps> = ({
  path,
}): React.ReactElement => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    {path}
  </svg>
)
