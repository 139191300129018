import { HelpRounded, IconButton } from '@gmini/ui-kit'

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 24px 34px 24px;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`

export const CloseButton = styled(IconButton)`
  & path {
    fill: #353b60;
  }
`

export const Content = styled.div`
  overflow-y: auto;
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const MessageText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #000335;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`
export const MessagePositionText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin: 0 5px;
`

export const HelpIcon = styled(HelpRounded)`
  & path {
    transition: 0.2s ease-out fill;
  }

  &:hover path {
    fill: rgba(53, 59, 96, 0.5);
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 35px 24px 16px 24px;
  width: 100%;
`

export const WinnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 0;
  gap: 22px;
`
export const ErrorText = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #bf3737;
`
export const PositionError = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
  gap: 5px;
`
