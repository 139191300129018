import React from 'react'
import { DndProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'
// TODO: Make spinner more beautiful =)
import { AuthProvider } from '@gmini/auth'
import { Provider } from 'react-redux'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import ReduxToastr from 'react-redux-toastr'
import { AuthClientTokens } from '@react-keycloak/core'

import * as Sentry from '@sentry/react'

import { apiClient } from '../../services/base-api-service/base-api-service'
import Modal from '../../components/Modal/Modal'

import { App } from '../../components/App/App'

import Loader from '../../components/Loader/Loader'
import { SpinnedBackdrop } from '../../components/SpinnedBackdrop'
import store from '../../store'

import { authClient } from '../../keycloak'

import { CustomHTML5toTouch } from './dnd'

const tokenLogger = (tokens: AuthClientTokens): void => {
  apiClient.setToken(tokens.token || '')
}

// TODO mover auth component to common library
export const Auth = (): JSX.Element => (
  <AuthProvider authClient={authClient} onTokens={tokenLogger}>
    {({ initialized, authClient }) => {
      if (!initialized) {
        return <SpinnedBackdrop />
      }

      if (initialized && authClient.authenticated) {
        authClient.updateToken(5)

        apiClient.setToken(authClient.token)
      }

      return (
        <Provider store={store}>
          <DndProvider
            backend={MultiBackend as any}
            options={CustomHTML5toTouch}
          >
            <>
              <Loader />
              <Router>
                <Sentry.ErrorBoundary fallback={'An error has occur'}>
                  <Switch>
                    <Route
                      path={[
                        //здесь такая пачка рутов из-за использования useParams
                        '/positions/:tab/search',
                        '/positions/:tab',
                        '/suppliers',
                        '/agreements/:tab/search',
                        '/agreements/:tab/:agreementId',
                        '/agreements/:tab',
                        '/assignments/:tab/search',
                        '/assignments/:tab/:assignmentId',
                        '/assignments/:tab',
                        '/eventslog',
                      ]}
                      render={() => <App />}
                    />
                    <Redirect to='/positions/0' />
                  </Switch>
                </Sentry.ErrorBoundary>
              </Router>
              <ReduxToastr
                timeOut={5000}
                newestOnTop={true}
                preventDuplicates={false}
                position='top-center'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                progressBar={false}
                confirmOptions={{
                  cancelText: 'Отменить',
                  okText: 'Подтвердить',
                }}
                closeOnToastrClick
              />
              <Modal />
            </>
          </DndProvider>
        </Provider>
      )
    }}
  </AuthProvider>
)
