import {
  Cancel,
  CheckMark,
  PlusCircle,
  TextArea,
  VerticalThreeDots,
} from '@gmini/ui-kit'

import styled, { css } from 'styled-components'

import { RevitLinkIconButton } from '../../../pages/position/position-table.styled'

export const RevitLinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 47px;
`

export const Label = styled.div`
  flex-shrink: 0;
  margin-right: 6px;
  line-height: 30px;
  color: rgba(162, 163, 183, 1);
`
export const LinkContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
`
export const LinkText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  line-height: 30px;
  color: rgba(0, 3, 53, 1);
`
export const PlusCircleIconButton = styled(PlusCircle)`
  border-radius: 50%;
  padding: 4px;
  width: 32px;
  height: 32px;
  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.1);
  }
`

export const VerticalThreeDotsIconButton = styled(VerticalThreeDots)`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  padding: 9px;
  margin-left: 8px;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.1);
  }
`

export const RevitLinkActionsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 4px);
  background: #fff;
  z-index: 1;
  padding: 7px 0;
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const ActionListItem = styled.div`
  padding: 6px 16px;
  display: flex;
  gap: 12px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 3, 53, 1);
  & svg {
    max-height: 24px;
  }
  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.1);
  }
`
export const RevitLinkBtnWrap = styled.div`
  display: flex;
  border-radius: 50%;
  &:hover {
    background: rgba(53, 59, 96, 0.1);
  }
`
export const RevitLinkIconButtonStyled = styled(RevitLinkIconButton)`
  padding: 4px;
  &:hover {
    cursor: pointer;
    path {
      fill: rgba(53, 59, 96, 0.5);
    }
  }
`
export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-top: -5px;
`
export const TextAreaStyled = styled(TextArea)`
  box-shadow: 0px 5px 10px 0px rgba(53, 60, 96, 0.1);
  & textarea {
    border: 1px solid rgba(76, 94, 207, 1);
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background: (53, 59, 96, 0.4);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`
export const InputButtonsContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`
const ButtonStyles = css`
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 2px;
  border-radius: 4px;
  background: #fff;
  border: 2px solid white;
  &:hover {
    background: #f5f5f9;
    cursor: pointer;
  }
  &:active {
    background: #edeef3;
  }
`

export const CheckmarkButton = styled(CheckMark)`
  ${ButtonStyles}
  &:active {
    & path {
      fill: rgba(34, 53, 170, 1);
    }
  }
`
export const CancelButton = styled(Cancel)`
  ${ButtonStyles}
  &:active {
    & path {
      fill: rgba(191, 55, 55, 1);
    }
  }
`
export const EmptyLinkContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    color: rgba(0, 3, 53, 0.5);
    line-height: 30px;
  }
`
