import { IconButton, RoundedCross } from '@gmini/ui-kit'
import { Dialog } from '@material-ui/core'

import { XGridTable } from '@ifellow/ui-library'
import React from 'react'

import { TColumn, TRow } from '../../types'

import { Title } from './CloseLotModal.styled'
import {
  TableContainer,
  TitleContainer,
  Wrapper,
} from './NoAgreementPositionsModal.styled'

type NoAgreementPositionsModalProps = {
  positions: TRow
  columns: TColumn
  onClose: () => void
  open: boolean
}

const formatTitle = (title: string) =>
  title.lastIndexOf('[') === -1
    ? title
    : title.substring(0, title.lastIndexOf('['))

export const NoAgreementPositionsModal = ({
  positions,
  onClose,
  open,
  columns,
}: NoAgreementPositionsModalProps) => {
  const columnsNamesMap: { [x: string]: string } = columns.reduce(
    (acc, c) => ({
      ...acc,
      [c.key]: formatTitle(c.title),
    }),
    {},
  )

  const formattedPositions = positions.map(p => ({
    ...p.data.reduce(
      (acc, fieldData) => ({
        ...acc,
        [columnsNamesMap[fieldData.key as string]]: fieldData.value,
      }),
      {},
    ),
    id: p.rowId,
    photo: p.photo,
    price: p.price,
  }))

  return (
    <Dialog onClose={onClose} open={open} hideBackdrop maxWidth='md'>
      <Wrapper>
        <TitleContainer>
          <Title data-test-id='noAgreementPositionsModalHeading'>
            Позиции не попавшие в соглашение(я)
          </Title>
          <IconButton
            data-test-id='noAgreementPositionsModalCloseBtn'
            onClick={onClose}
          >
            <RoundedCross />
          </IconButton>
        </TitleContainer>
        <TableContainer>
          <XGridTable
            checkboxSelection={false}
            columns={columns.map(c => ({
              ...c,
              field: formatTitle(c.title),
            }))}
            rows={formattedPositions}
            pageSize={100}
            pageStart={1}
            mode='server'
            dataTestIdPrefix='noAgreementPositionsModalTable'
          />
        </TableContainer>
      </Wrapper>
    </Dialog>
  )
}
