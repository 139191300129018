import { makeStyles, Theme } from '@material-ui/core/styles'

const useButtonStyles = makeStyles((theme: Theme) => ({
  btn: {
    minHeight: '44px',
    fontSize: '14px',
    padding: '0 15px',
    opacity: '0.85',
    '&.MuiButton-root': {
      height: '44px',
      minWidth: '40px',
      fontWeight: '600',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'Proxima Nova Regular',
      '&:hover': {
        opacity: '1',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      marginRight: '10px',
      color: 'rgba(0, 0, 0, 0)',
    },
    '&.no-margin': {
      '&  .MuiSvgIcon-root': {
        margin: 0,
      },
    },
    '& .MuiButton-label': {
      textTransform: 'none',
    },
  },
  warning: {
    '&.MuiButton-root': {
      color: '#FFF',
      backgroundColor: '#C93A3A',
    },
  },
  primary: {
    '&.MuiButton-root': {
      color: '#FFF',
      backgroundColor: '#1B55C5;',
    },
    '&.MuiButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: '#D3D3D3',
      '& .MuiSvgIcon-root': {
        opacity: '0.3',
      },
    },
  },
  default: {
    '&.MuiButton-root': {
      color: '#616284',
      backgroundColor: '#F9F9FB',
      opacity: '1',
      '&:hover': {
        backgroundColor: '#eaeaf1',
      },
    },
    '&.MuiButton-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26)',
      '& .MuiSvgIcon-root': {
        opacity: '0.3',
      },
    },
  },
}))

export default useButtonStyles
