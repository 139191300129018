import React from 'react'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './Style'

type Props = {
  visible: boolean
  title: string
  actions: React.ReactElement
  onClose?: () => void
  dataTestIdPrefix?: string
}

export const DumbModal: React.FC<Props> = ({
  visible,
  onClose,
  title,
  actions,
  children,
  dataTestIdPrefix,
}) => {
  const classes = useStyles()

  return (
    <Dialog onClose={onClose} open={visible}>
      <DialogTitle
        data-test-id={`${dataTestIdPrefix}Heading`}
        className={classes.title}
      >
        {title}
        <IconButton
          data-test-id={`${dataTestIdPrefix}CloseBtn`}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}
