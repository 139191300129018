import { createTransition } from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

const MouseTransition = createTransition(
  'keydown',
  e => e.type && e.key.indexOf('z') !== -1,
)

export const CustomHTML5toTouch: any = {
  backends: [
    {
      backend: HTML5Backend,
    },
    {
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: MouseTransition,
    },
  ],
}
