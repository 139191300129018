import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export type TCheckboxField = {
  name: string
  checked: boolean
  handleChange: (event: React.ChangeEvent<{}>) => void
  label?: string
  dataTestId?: string
}

export const CheckboxField: React.FC<TCheckboxField> = ({
  name,
  checked,
  label,
  handleChange,
  dataTestId,
}) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox
        data-test-id={dataTestId}
        name={name}
        checked={checked}
        onChange={handleChange}
        color='primary'
      />
    }
  />
)
