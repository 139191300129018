import React, { useEffect, useState } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import { PenOutlined, Tooltip, Trash } from '@gmini/ui-kit'

import { Authorities } from '../../../services/auth-service/auth-constants'

import { usePermissions } from '../../../hooks/usePermissions'

import {
  ActionListItem,
  CancelButton,
  CheckmarkButton,
  EmptyLinkContainer,
  InputButtonsContainer,
  Label,
  LinkContainer,
  LinkText,
  PlusCircleIconButton,
  RevitLinkActionsWrapper,
  RevitLinkBtnWrap,
  RevitLinkIconButtonStyled,
  RevitLinkWrapper,
  TextAreaStyled,
  TextAreaWrapper,
  VerticalThreeDotsIconButton,
} from './RevitLinkBlock.styled'
type RevitLinkBlockProps = {
  revitLink: string
  onSubmit: (val: string) => void
  dataTestIdPrefix?: string
}

export const RevitLinkBlock = ({
  revitLink,
  onSubmit,
  dataTestIdPrefix,
}: RevitLinkBlockProps) => {
  const checkPermissions = usePermissions()
  const [isActionsOpen, setIsActionsOpen] = useState(false)
  const [isInputOpen, setIsInputOpen] = useState(false)
  const [value, setValue] = useState(revitLink)

  useEffect(() => {
    if (isInputOpen) {
      setValue(revitLink)
    }
  }, [revitLink, isInputOpen])

  const resetInput = () => {
    setValue('')
    setIsInputOpen(false)
  }

  const handleSubmit = (val: string) => {
    onSubmit(val)
    resetInput()
  }

  const handleDelete = () => {
    onSubmit('')
    setIsActionsOpen(false)
  }

  return (
    <RevitLinkWrapper>
      <Label>Revit-семейство:</Label>
      {isInputOpen ? (
        <TextAreaWrapper>
          <TextAreaStyled
            data-test-id={`${dataTestIdPrefix}RevitLinkInput`}
            placeholder='Ссылка не задана'
            autoFocus
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
            onKeyDown={e => {
              e.stopPropagation()
              if (e.key === 'Escape') {
                resetInput()
              }
              if (e.key === 'Enter') {
                handleSubmit(value)
              }
            }}
          />
          <InputButtonsContainer>
            <CancelButton
              data-test-id={`${dataTestIdPrefix}RevitLinkCancelBtn`}
              onClick={resetInput}
              width={32}
              height={32}
            />
            <CheckmarkButton
              data-test-id={`${dataTestIdPrefix}RevitLinkSubmitBtn`}
              onClick={() => handleSubmit(value)}
              width={32}
              height={32}
            />
          </InputButtonsContainer>
        </TextAreaWrapper>
      ) : revitLink ? (
        <LinkContainer>
          <LinkText>{revitLink}</LinkText>
          <Tooltip title='Скачать Revit-семейство'>
            <RevitLinkBtnWrap>
              <RevitLinkIconButtonStyled
                data-test-id={`${dataTestIdPrefix}RevitLinkDownloadBtn`}
                width={32}
                height={32}
                onClick={() => window.open(revitLink, '_blank')}
              />
            </RevitLinkBtnWrap>
          </Tooltip>
          {checkPermissions(Authorities.ITEMS_EDIT_ITEM) ? (
            <VerticalThreeDotsIconButton
              data-test-id={`${dataTestIdPrefix}RevitLinkMenuBtn`}
              onClick={() => setIsActionsOpen(true)}
            />
          ) : null}
        </LinkContainer>
      ) : (
        <EmptyLinkContainer>
          <span>Ссылка не добавлена</span>
          {checkPermissions(Authorities.ITEMS_EDIT_ITEM) ? (
            <Tooltip title='Добавить ссылку на Revit-семейство'>
              <PlusCircleIconButton
                data-test-id={`${dataTestIdPrefix}RevitLinkAddBtn`}
                onClick={() => setIsInputOpen(true)}
              />
            </Tooltip>
          ) : null}
        </EmptyLinkContainer>
      )}
      {isActionsOpen ? (
        <ClickAwayListener onClickAway={() => setIsActionsOpen(false)}>
          <RevitLinkActionsWrapper>
            <ActionListItem
              data-test-id={`${dataTestIdPrefix}RevitLinkEditBtn`}
              onClick={() => {
                setIsActionsOpen(false)
                setIsInputOpen(true)
              }}
            >
              <PenOutlined />
              <span>Редактировать</span>
            </ActionListItem>
            <ActionListItem
              data-test-id={`${dataTestIdPrefix}RevitLinkDeleteBtn`}
              onClick={handleDelete}
            >
              <Trash color='#353b60' />
              <span>Удалить</span>
            </ActionListItem>
          </RevitLinkActionsWrapper>
        </ClickAwayListener>
      ) : null}
    </RevitLinkWrapper>
  )
}
