import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '645px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -5px',
    '& button': {
      margin: '0 5px',
    },
  },
  hidden: {
    opacity: 0,
    transition: '.5s',
  },
}))
