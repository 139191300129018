import { AxiosResponse } from 'axios'

import { ApiService as ApiService1 } from './apiService'

const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export type ApiResponse<T> = [Error, AxiosResponse<T>]

const API_ENDPOINT = '/api/grand-tender/v1'
export const CPM_API_ENDPOINT = '/api/construction-projects-management/v1'
export const HM_API_ENDPOINT = '/api/hub-management/v1'

export const apiClient = new ApiService1({
  baseUrl: API_ENDPOINT,
  defaultHeaders,
})
