import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const RevitLinkIcon = ({
  color = 'rgba(53, 59, 96, 0.5)',
  width = '24',
  height = '24',
  ...props
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.9999 21.06V16C19.9999 15.4477 19.5522 15 18.9999 15C18.4476 15 17.9999 15.4477 17.9999 16V21.06L16.895 20.2716C16.445 19.9505 15.8196 20.0559 15.4991 20.5062C15.1792 20.9556 15.2834 21.5796 15.7322 21.9002L18.4199 23.82C18.5899 23.94 18.7999 24 18.9999 24C19.1999 24 19.4099 23.94 19.5799 23.81L22.2686 21.8895C22.7169 21.5693 22.8212 20.9465 22.5018 20.4976C22.1809 20.0467 21.5545 19.9427 21.105 20.2657L19.9999 21.06Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 2H14.5858C14.851 2 15.1054 2.10536 15.2929 2.29289L19.7071 6.70711C19.8946 6.89464 20 7.149 20 7.41421V13C20 13.5523 19.5523 14 19 14C18.4477 14 18 13.5523 18 13V8H15.5C14.6716 8 14 7.32862 14 6.50019C14 5.98639 14 5.43256 14 5V4H6V20H13C13.5523 20 14 20.4477 14 21C14 21.5523 13.5523 22 13 22H5.99C4.89 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2Z'
      fill={color}
    />
    <path
      d='M14.3222 9.0859L14.3219 9.08568C14.0427 8.86634 13.7087 8.70156 13.3206 8.59066C12.9325 8.47584 12.4999 8.41875 12.0234 8.41875H9.56406C9.31553 8.41875 9.11406 8.62022 9.11406 8.86875V16.6C9.11406 16.8485 9.31553 17.05 9.56406 17.05H10.4105C10.6591 17.05 10.8605 16.8485 10.8605 16.6V13.8625H12.0278L13.4254 16.6374C13.5529 16.8904 13.812 17.05 14.0953 17.05H14.9381C15.1994 17.05 15.3686 16.7739 15.2499 16.541L13.662 13.4261C13.8806 13.3239 14.0789 13.2042 14.2569 13.0671C14.4524 12.9195 14.62 12.7458 14.7597 12.5463L14.7598 12.5461C14.8959 12.3499 14.9997 12.1282 15.0714 11.8813C15.1472 11.6299 15.1848 11.3496 15.1848 11.041C15.1848 10.6134 15.1095 10.2349 14.9576 9.90694C14.8099 9.57964 14.598 9.30574 14.3222 9.0859ZM13.1352 12.0207L13.1352 12.0207L13.1344 12.0215C13.0195 12.1513 12.8685 12.2525 12.6798 12.3243C12.4951 12.392 12.2786 12.4266 12.0293 12.4266H10.8605V9.85469H12.0234C12.2461 9.85469 12.4421 9.88157 12.6121 9.93446C12.7826 9.9875 12.9249 10.0627 13.0401 10.1594L13.0404 10.1596C13.17 10.267 13.2667 10.4042 13.3302 10.5724L13.3302 10.5724L13.3307 10.5736C13.3981 10.7383 13.4324 10.9309 13.4324 11.1523C13.4324 11.335 13.4058 11.4991 13.3533 11.6452L13.3533 11.6452L13.353 11.6463C13.3043 11.7924 13.2316 11.9169 13.1352 12.0207Z'
      fill={color}
      stroke={color}
      strokeWidth='0.1'
    />
    <defs>
      <clipPath id='clip0_1401_195400'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
