import {
  ColumnSettings,
  IColumn,
  SuppliersPageColumnSettings,
  TColumn,
} from '../types'

const widthMap: { [x: string]: number } = {
  '#': 40,
  Ссылка: 62,
}

const getPositionsDefaultSortOrder = (tabIndex: number) => {
  switch (tabIndex) {
    case 1:
      return [
        '#',
        'ссылка',
        'название',
        'цена',
        'поставщик',
        'соглашение',
        'дата соглашения',
        'проекты/объекты',
        'автор',
        'дата создания',
      ]
    default:
      return ['#', 'ссылка', 'название', 'автор', 'дата создания']
  }
}

const positionsDefaultSort = (columns: TColumn, order: string[]) =>
  columns.sort((a, b) => {
    const aIdx = order.findIndex(s => s === a.title.toLowerCase())
    const bIdx = order.findIndex(s => s === b.title.toLowerCase())

    if (aIdx < 0) {
      return 1
    }
    if (bIdx < 0) {
      return -1
    }

    return aIdx - bIdx
  })

const parseColumns = (columns: TColumn, columnSettings: ColumnSettings[]) => {
  const parsedDataMap: {
    [x: number]: ColumnSettings
  } = columnSettings.reduce(
    (acc, col) => ({
      ...acc,
      [col.key]: col,
    }),
    {},
  )

  return columns
    .slice()
    .sort((a, b) => {
      const aIdx = columnSettings.findIndex(s => s.key === a.key)
      const bIdx = columnSettings.findIndex(s => s.key === b.key)

      // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
      if (aIdx < 0 || bIdx < 0) {
        return 0
      }

      return aIdx - bIdx
    })
    .map(col => ({
      ...col,
      ...(parsedDataMap[col.key as number] || { width: 150 }),
    }))
}

export function getUserCategoryColumnSettingsFromStorage(
  storageKey: string,
  columns: TColumn,
  positionsDefaultSortTab?: number,
): (IColumn & ColumnSettings)[] {
  const data = localStorage.getItem(storageKey)
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]
      return parseColumns(columns, parsedData)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const columnsPrepared =
    typeof positionsDefaultSortTab === 'number'
      ? positionsDefaultSort(
          columns,
          getPositionsDefaultSortOrder(positionsDefaultSortTab),
        )
      : columns

  return columnsPrepared.map(col => ({
    ...col,
    width: widthMap[col.title] || 150,
    hide: false,
    sortOrder: null,
  }))
}

export function getUserCategoryColumnSettingsFromStorageSuppliersPage(
  storageKey: string,
  columns: TColumn,
): {
  columns: (IColumn & ColumnSettings)[]
  agreedSuppliers: boolean
  notAgreedSuppliers: boolean
  expiredSuppliers: boolean
} {
  const data = localStorage.getItem(storageKey)
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as SuppliersPageColumnSettings

      return {
        columns: parseColumns(columns, parsedData.columns),
        agreedSuppliers: parsedData.agreedSuppliers,
        notAgreedSuppliers: parsedData.notAgreedSuppliers,
        expiredSuppliers: parsedData.expiredSuppliers,
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  return {
    columns: columns.map(col => ({
      ...col,
      width: 150,
      hide: false,
      sortOrder: null,
    })),
    agreedSuppliers: true,
    notAgreedSuppliers: true,
    expiredSuppliers: true,
  }
}

export function setColumnsToStorage(
  storageKey: string,
  next: (IColumn & ColumnSettings)[],
) {
  localStorage.setItem(
    storageKey,
    JSON.stringify(
      next.map(({ key, title, hide, width, sortOrder }) => ({
        key,
        title,
        hide,
        width,
        sortOrder,
      })),
    ),
  )
}

export function setColumnsToStorageSuppliersPage(
  storageKey: string,
  next: {
    columns: (IColumn & ColumnSettings)[]
    agreedSuppliers: boolean
    notAgreedSuppliers: boolean
    expiredSuppliers: boolean
  },
) {
  localStorage.setItem(
    storageKey,
    JSON.stringify({
      columns: next.columns.map(({ key, title, hide, width, sortOrder }) => ({
        key,
        title,
        hide,
        width,
        sortOrder,
      })),
      agreedSuppliers: next.agreedSuppliers,
      notAgreedSuppliers: next.notAgreedSuppliers,
      expiredSuppliers: next.expiredSuppliers,
    }),
  )
}
