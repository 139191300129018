import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

export const StyledIconButton = styled(IconButton)`
  font-size: unset;
  width: 41px;
`

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
`
