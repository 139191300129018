import { Table } from '@gmini/ui-kit'
import styled from 'styled-components'

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 700px;
`

export const StyledTable = styled(Table)`
  && {
    & td,
    th {
      padding-left: 20px;
      padding-right: 10px;
    }

    & thead > :last-child td {
      border-bottom: 1px solid rgba(229, 231, 241, 1);
    }
  }
` as typeof Table

export const TableContent = styled.div`
  height: 100%;
  border: 1px solid rgba(229, 231, 241, 1);
  border-radius: 4px;
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 10, 87, 0.07);
    border-radius: 10px;
    border: 1px solid rgba(244, 244, 248, 1);
  }
  &::-webkit-scrollbar-track {
    background: rgba(244, 244, 248, 1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`

export const AuthorCell = styled.div`
  width: max-content;
`
