import { useSectionsList } from './useSectionsList'
import { useDropDownSearch } from './../../../../../../../hooks/useDropDownSearch'
import { useBooleanState } from './../../../../../../../hooks/useBooleanState'
import { useModalValidation } from './../../../../../../../hooks/useModalValidation'

export const useSectionsListHooks = (): [
  typeof useSectionsList,
  typeof useDropDownSearch,
  typeof useBooleanState,
  typeof useModalValidation,
] => [useSectionsList, useDropDownSearch, useBooleanState, useModalValidation]
