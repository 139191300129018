import moment from 'moment'

import { formatPrice } from '../utils'

import { DEFAULT_DISPLAY_DATE_FORMAT, EColumnType } from '../constants'

const renderBooleanType = (value: string) =>
  value ? (value === 'true' ? 'Да' : 'Нет') : ''

export const renderCell = (params: any, type: EColumnType, unit?: string) => {
  switch (type) {
    case EColumnType.BOOLEAN:
      return renderBooleanType(params.value)
    case EColumnType.DATE:
      const date = moment(params.value)
      return date.isValid()
        ? date.format(DEFAULT_DISPLAY_DATE_FORMAT)
        : params.value
        ? params.value
        : ''
    case EColumnType.CURRENCY:
      return [null, undefined, ''].includes(params.value)
        ? '-'
        : formatPrice(params.value)
    default:
      let { value } = params
      if (value && unit) {
        value = `${value} ${unit}`
      }
      return value
  }
}
