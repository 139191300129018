import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import _, { Dictionary } from 'lodash'

import {
  Button,
  Popover,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  IconButton,
} from '@material-ui/core'

import { IColumn } from '../../../../types'

import {
  addDarkIcon,
  settingIcon,
  EColumnType,
  EEditableModalType,
} from '../../../../constants'

import { LocalStorageHelper } from '../../../../utils'

import * as I from './IImprovedTableHeadDisplayColumns'
import useImprovedTableHeadDisplayColumnsStyles from './Style'
import { ImprovedTableHeadDisplayColumnsBody } from './ImprovedTableHeadDisplayColumnsBody/ImprovedTableHeadDisplayColumnsBody'
import { EditablePropertyModal } from './EditablePropertyModal/EditablePropertyModal'

const emptyFunc = (): number => 0

export const ImprovedTableHeadDisplayColumns: React.FC<I.OwnProps> = ({
  columns,
  visibleColumns,
  showEditPropertyButtons,
  isRowChecked,
  showSections,
  onColumnVisibilityChanged,
  addPropertyHandler = emptyFunc,
  addExistingPropertyHandler,
  editPropertyHandler = emptyFunc,
  removePropertyHandler = emptyFunc,
  onAddSection,
  onEditSection,
  onRemoveSection,
  getSectionsDataSource,
  offMenu,
}) => {
  const assignmentId = useParams<{ assignmentId?: string }>()

  const emptyEditablePropertyModalModel = {
    key: '',
    title: '',
    type: EColumnType.STRING,
    required: false,
    base: false,
    hidden: false,
    editable: true,
    measureUnitId: null,
    measureUnitSymbol: null,
    unit: '',
  }

  const editablePropertyModalTitle = {
    insert: 'Добавить свойство',
    edit: 'Редактировать свойство',
  }

  const classes = useImprovedTableHeadDisplayColumnsStyles()
  const location = useLocation()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const [openEditablePropertyModal, setOpenEditablePropertyModal] = useState<
    boolean
  >(false)
  const [editablePropertyModalModel, setEditablePropertyModalModel] = useState<
    IColumn
  >(emptyEditablePropertyModalModel)
  const [editablePropertyModalType, setEditablePropertyModalType] = useState<
    EEditableModalType
  >(EEditableModalType.INSERT)

  const handleClickPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = (): void => {
    setAnchorEl(null)
  }

  const addPropertyBtnClick = (event: React.MouseEvent<HTMLElement>): void => {
    setEditablePropertyModalType(EEditableModalType.INSERT)
    setEditablePropertyModalModel(emptyEditablePropertyModalModel)
    setOpenEditablePropertyModal(true)
  }

  const editPropertyBtnClick = (column: IColumn) => (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setEditablePropertyModalType(EEditableModalType.EDIT)
    setEditablePropertyModalModel(column)
    setOpenEditablePropertyModal(true)
  }

  const savePropertyHandler = (model: IColumn, sectionId?: string): void => {
    setOpenEditablePropertyModal(false)

    if (editablePropertyModalType === EEditableModalType.INSERT) {
      let page = location.pathname.replace('/', '')
      if (page.includes('positions')) {
        const categoryId = LocalStorageHelper.get<string>(
          `${location.pathname}_categoryId`,
        )
        page = `positions_${categoryId}`
      }
      if (page.includes('agreements') && isRowChecked) {
        const categoryId = LocalStorageHelper.get<string>(
          `/positions_agreements_categoryId`,
        )
        page = `positions_agreements_${categoryId}`
      }
      if (page.includes('positions') && page.includes('search')) {
        const categoryId = LocalStorageHelper.get<string>(
          `/positions_categoryId`,
        )
        page = `positions_${categoryId}`
      }
      if (page.includes('suppliers')) {
        const categoryId = LocalStorageHelper.get<string>(
          `${location.pathname}_categoryId`,
        )
        page = `suppliers_${categoryId}`
      }
      if (page.includes('suppliers') && page.includes('search')) {
        const categoryId = LocalStorageHelper.get<string>(
          `/suppliers_categoryId`,
        )
        page = `suppliers_${categoryId}`
      }
      if (page.includes('agreements')) {
        const categoryId = LocalStorageHelper.get<string>(
          `${location.pathname}_categoryId`,
        )
        page = `agreements_${categoryId}`
      }
      if (page.includes('agreements') && page.includes('search')) {
        const categoryId = LocalStorageHelper.get<string>(
          `/agreements_categoryId`,
        )
        page = `agreements_${categoryId}`
      }
      if (page.includes('assignments')) {
        const categoryId = LocalStorageHelper.get<string>(
          `${location.pathname}_categoryId`,
        )
        page = `assignments_${categoryId}`
      }
      if (assignmentId) {
        const categoryId = LocalStorageHelper.get<string>(
          `/positions_categoryId`,
        )
        page = `positions_${categoryId}`
      }
      if (page.includes('assignments') && page.includes('search')) {
        const categoryId = LocalStorageHelper.get<string>(
          `/assignments_categoryId`,
        )
        page = `assignments_${categoryId}`
      }

      LocalStorageHelper.remove(`${page}`)
      addPropertyHandler(model, sectionId)
    } else {
      editPropertyHandler(model, sectionId)
    }
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line no-undefined
  const id = open ? 'simple-popover' : undefined

  const sections: Dictionary<IColumn[]> = _.groupBy(columns, c =>
    !c.section ? 0 : c.section.key,
  )

  const renderTableContainer = (cols: IColumn[]): JSX.Element | null =>
    cols && cols.length ? (
      <TableContainer
        key={cols[0].section?.key || '-1'}
        className={classes.table}
      >
        <div className={classes.titleContainer}>
          <p
            data-test-id='columnSettingsPopupHeading'
            className={!cols[0].section ? '' : 'section'}
          >
            {!cols[0].section ? 'Отображаемые колонки' : cols[0].section.value}
          </p>
          {showEditPropertyButtons &&
            !location.pathname.includes('/assignments/') &&
            !cols[0].section && (
              <IconButton
                data-test-id='columnSettingsPopupAddPropertyBtn'
                className={classes.actionButton}
                onClick={addPropertyBtnClick}
              >
                {addDarkIcon}
              </IconButton>
            )}
        </div>
        <Table>
          <ImprovedTableHeadDisplayColumnsBody
            columns={cols}
            visibleColumns={visibleColumns}
            showSections={showSections}
            onColumnVisibilityChanged={onColumnVisibilityChanged}
            showEditPropertyButtons={false}
            ifValue={true}
          />
          <ImprovedTableHeadDisplayColumnsBody
            columns={cols}
            visibleColumns={visibleColumns}
            showSections={showSections}
            onColumnVisibilityChanged={onColumnVisibilityChanged}
            ifValue={false}
            showEditPropertyButtons={showEditPropertyButtons}
            onEditPropertyBtnClick={editPropertyBtnClick}
            removePropertyHandler={removePropertyHandler}
          />
        </Table>
      </TableContainer>
    ) : null

  return offMenu ? (
    <>
      {openEditablePropertyModal && (
        <EditablePropertyModal
          useSections={
            location.pathname.includes('/assignments') ||
            location.pathname.includes('/suppliers') ||
            location.pathname.includes('/agreements')
          }
          addExistingPropertyHandler={addExistingPropertyHandler}
          open={openEditablePropertyModal}
          title={editablePropertyModalTitle}
          type={editablePropertyModalType}
          model={editablePropertyModalModel}
          onAddSection={onAddSection}
          onEditSection={onEditSection}
          onRemoveSection={onRemoveSection}
          getSectionsDataSource={getSectionsDataSource}
          onSave={savePropertyHandler}
          onClose={(): void => setOpenEditablePropertyModal(false)}
        />
      )}
      {_.keys(sections).map(s => renderTableContainer(sections[s]))}
    </>
  ) : (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align='right' className={classes.columnIconBtn}>
            <Button
              data-test-id='tableHeadColumnSettingsBtn'
              aria-describedby={id}
              onClick={handleClickPopover}
              disableRipple
            >
              {settingIcon}
            </Button>
            <Popover
              className={classes.popover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
            >
              {openEditablePropertyModal && (
                <EditablePropertyModal
                  useSections={
                    location.pathname.includes('/assignments') ||
                    location.pathname.includes('/suppliers') ||
                    location.pathname.includes('/agreements')
                  }
                  open={openEditablePropertyModal}
                  title={editablePropertyModalTitle}
                  type={editablePropertyModalType}
                  model={editablePropertyModalModel}
                  onAddSection={onAddSection}
                  onEditSection={onEditSection}
                  onRemoveSection={onRemoveSection}
                  getSectionsDataSource={getSectionsDataSource}
                  onSave={savePropertyHandler}
                  onClose={(): void => setOpenEditablePropertyModal(false)}
                />
              )}
              {_.keys(sections).map(s => renderTableContainer(sections[s]))}
            </Popover>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
