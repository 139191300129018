import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'calc(100vw - 200px)',
      height: 'calc(100vh - 200px)',
      '& .MuiToolbar-root': {
        padding: '0',
        margin: '0 0 20px 0',
      },
    },
  },
  loaderBackground: {
    width: 'calc(100% - 100px)',
    height: 'calc(100% - 200px)',
    position: 'absolute',
    background: '#F0F8FF',
    opacity: '0.3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px',
    '& .MuiCircularProgress-root': {
      margin: '0 200px 0 0',
    },
  },
}))
