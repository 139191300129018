import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 24px 18px;
  height: 350px;
`

export const TableContainer = styled.div`
  height: 80%;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 46px;
  && path {
    fill: #353b60;
  }
`
