import React, { useMemo } from 'react'
import { Typography } from '@material-ui/core'
import cn from 'classnames'

import useStyles from './styles'

export type TitleComponentProps = {
  categoryName?: string
  path?: string
  className?: string
}

export const TitleComponent: React.FC<TitleComponentProps> = ({
  categoryName,
  path = '',
  className,
}) => {
  const classes = useStyles()
  const { pathLastPiece, categoryPath } = useMemo(
    () => ({
      pathLastPiece: categoryName,
      categoryPath: path?.replaceAll('/', ' / '),
    }),
    [path, categoryName],
  )

  return (
    <div
      className={cn(classes.title, className)}
      style={{ margin: '0 5px 10px 5px', width: '100%' }}
    >
      <Typography
        className={classes.header}
        variant='subtitle1'
        component='div'
        data-test-id='categoryHeading'
      >
        {pathLastPiece}
      </Typography>
      <Typography
        className={classes.content}
        variant='subtitle2'
        component='div'
      >
        {categoryPath}
      </Typography>
    </div>
  )
}
