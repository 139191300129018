import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as _ from 'lodash'
import {
  Drawer,
  Divider,
  TextField,
  List,
  SvgIcon,
  IconButton,
  TableContainer,
  Table,
  Checkbox,
} from '@material-ui/core'

import { toastr } from 'react-redux-toastr'

import { Button } from '@gmini/ui-kit'

import {
  EButtonIcon,
  EButtonType,
  EEditableModalType,
  EColumnType,
  EAPIResponseStatus,
} from '../../../../constants'

import { LocalStorageHelper } from '../../../../utils'

import {
  TColumn,
  IColumn,
  IKeyValuePair,
  TSupplierModel,
  TAgreementNext,
  TAgreementCreateRequest,
  TRowData,
  SupplierInfo,
  ProviderPosition,
  ELotStatus,
  ELotStatusDesc,
  EAssignmentStatus,
  ProjectObjects,
} from '../../../../types'
import { SuppliersService } from '../../../../services/supplier-service'
import { AgreementService } from '../../../../services/agreements-service'
import {
  AssignmentService,
  getMailerUrl,
} from '../../../../services/assignments-service'
import { Authorities } from '../../../../services/auth-service/auth-constants'

import { EyeIcon } from '../../../../icons/EyeIcon'
import { ArrowDownIcon } from '../../../../icons/ArrowDownIcon'

import { AssignmentLotTableToolbar } from '../../../../components/AssignmentLotTableToolbar/AssignmentLotTableToolbar'
import { DialogModal } from '../../../../components/DialogModal/DialogModal'
import { MovePositionsModal } from '../../../../components/MovePositionsModal/MovePositionsModal'
import { AddAgreementModal } from '../../../../components/AddAgreementModal/AddAgreementModal'
import { ButtonComponent } from '../../../../components/Button/Button'
import { AddPositionsContextModal } from '../../../../components/AddPositionsContextModal/AddPositionsContextModal'
import { AssignmentTableDisplayColumnsBody } from '../../../../components/AssignmentTableDisplayColumns/AssignmentTableDisplayColumnsBody/AssignmentTableDisplayColumnsBody'
import { AGREEMENT_TABLE_COLUMNS_WITHOUT_PRICE } from '../../../../components/ImprovedTable/constants'
import { createDumbTableRows } from '../../../../components/ImprovedTable/utils'
import { DumbTable } from '../../../../components/DumbTable/DumbTable'
import { Accordion as AccordionAgreementsDumbTable } from '../../../../components/Accordion/Accordion'
import { SupplierHistoryField } from '../../../../components/SupplierHistoryField'
import { AddSupplierAssignment } from '../../../../components/AddAssignmentSupplierModal/AddSupplierAssigment'

import { TenderConditions } from '../../../../modules/tender-conditions/tender-conditions-controller'
import { TenderNote } from '../../../../modules/tender-conditions/tender-note-dialog'

import { setDataLoading } from '../../../../actions/RequestWrapper'

import {
  addCategoriesToLot,
  changeLotName,
  exportPriceHistoryXls,
  getAllProjects,
} from '../../../../actions'
import { useAppDispatch } from '../../../../store'
import { usePermissions } from '../../../../hooks/usePermissions'

import LotWinnerModal from '../lotWinner/LotWinnerModal'
import { AgreementModal } from '../agreement/AgreementModal'

import * as I from './assignment-lot-types'
import Accordion from './assignment-lot-accordion/assignment-lot-accordion'
import useStyles, { PopoverStyled } from './style'
import { AssignmentLotDescription } from './assignment-lot-description/assignment-lot-description'

interface DialogModalSettings {
  type: 'positions' | 'supplier' | 'lot'
  title: string
  content: string
  leftBtnText: string
  rightBtnText: string
}

const AssignmentLot: React.FC<I.OwnProps> = ({
  index: lotIndex,
  lot,
  assignment,
  author,
  positions,

  getAssignmentDetail,

  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,

  addToAgreementHandler,
  removeLotPositionsHandler,
  removeLotHandler,
  moveLotPositionsHandler,
  changeLotAssignmentHandler,
  removeLotSupplierHandler,

  addSupplierHandler,
  updatePriceHandler,

  openRenameLotModal,
  exportTemplateHandler,
  exportLotHandler,

  addPosition,
  getPositions,
  addToAssignment,
  callbackAssignmentView,
  toggleCreateDialogModal,
}): React.ReactElement => {
  const { id: assignmentId, lots, status: assignmentStatus } = assignment
  const checkPermissions = usePermissions()
  const classes = useStyles()
  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()
  const history = useHistory()
  const [selectedRows, setSelectedRows] = useState<Array<string>>([])
  const [openDialogModal, setOpenDialogModal] = useState<boolean>(false)
  const [openMovePositionsModal, setOpenMovePositionModal] = useState<boolean>(
    false,
  )
  const [dialogModalSettings, setDialogModalSettings] = useState<
    DialogModalSettings
  >({
    type: 'positions',
    title: '',
    content: '',
    leftBtnText: '',
    rightBtnText: '',
  })
  const [openDrawer, setOpenDrawer] = useState(false)
  const [dataListDrawer, setDataListDrawer] = useState<TSupplierModel>()
  const [openAgreementModal, setOpenAgreementModal] = useState<boolean>(false)
  const [selectedSupplier, setSelectedSupplier] = useState<
    SupplierInfo | undefined
  >()

  const [agreementModalModel, setAgreementModalModel] = useState<
    TAgreementNext
  >({
    number: 0,
    supplier: { key: '', value: '' },
    lotId: 0,
    columns: [],
  })

  const [flagAgreementList, setFlagAgreementList] = useState<boolean>(false)
  const [keyCatalogAgreement, setKeyCatalogAgreement] = useState<any>(null)

  const [selectedVisibleSuppliers, setSelectedVisibleSuppliers] = useState<
    TRowData
  >([])

  const [
    showSupplierWithAgreementOnly,
    setShowSupplierWithAgreementOnly,
  ] = useState<boolean>(true)

  const [openAddSupplierModal, setOpenAddSupplierModal] = useState<boolean>(
    false,
  )
  const [
    addAssignmentSupplierModalType,
    setAddAssignmentSupplierModalType,
  ] = useState<EEditableModalType>(EEditableModalType.INSERT)

  const [
    openAddPositionsContextModal,
    setOpenAddPositionsContextModal,
  ] = useState<boolean>(false)

  const [lotWinnerModalOpen, setLotWinnerModalOpen] = useState(false)
  const [addAgreementModal, setAddAgreementModal] = useState(false)

  const suppliers = _.uniqBy(lot.suppliers.data, 'rowId')

  const statusDict: { [key: string]: string } = useMemo(
    () => ({
      NEW: 'Новое',
      SEND_TO_PROVIDERS: 'Отправлено поставщикам',
      CHOSE_PROVIDER: 'Выбор поставщика',
      CLOSED: 'Закрыто',
    }),
    [],
  )

  const updateSelectedRowsState = React.useCallback(
    (newState: Array<string>): void => setSelectedRows(newState),
    [],
  )

  const flagAgreementListHandled = React.useCallback(
    (flag: boolean, key?: any): void => {
      setFlagAgreementList(flag)
      if (key) {
        setKeyCatalogAgreement(key)
      }
    },
    [],
  )

  const [allProjectsAndObjects, setAllProjectsAndObjects] = useState<
    ProjectObjects
  >([])

  const getCategories = React.useCallback(
    () =>
      lot?.positions?.map(item => {
        const categoryId = item?.category?.key
        const visibleCols =
          LocalStorageHelper.get<TColumn>(
            `assignments_${assignmentId}_category_${categoryId}_positions_${lot.id}`,
          ) || []
        const categorySupplierColumns =
          LocalStorageHelper.get<TColumn>(
            `assignments_${assignmentId}_category_${item?.category?.key}_lots_${lot.id}`,
          ) || []
        return {
          categoryId,
          propositionCustomFieldIds: categorySupplierColumns.map(
            column => column.key,
          ),
          columnIds: visibleCols.map(col => col.key),
        }
      }),
    [lot, assignmentId],
  )

  const exportPriceHistoryHandler = useCallback(
    (agreementId: string | number) => {
      const suppliers =
        LocalStorageHelper.get<TColumn>(
          `assignments_${assignmentId}_lots_${lot.id}_suppliers`,
        ) || []
      appDispatch(
        exportPriceHistoryXls({
          lotId: lot.id,
          agreementId: String(agreementId),
          providersIds: suppliers?.map(col => col.key),
          categories: getCategories(),
        }),
      )
    },
    [lot, getCategories, assignmentId, appDispatch],
  )

  const handleRemoveChangesDialogModal = React.useCallback((): void => {
    switch (dialogModalSettings?.type) {
      case 'positions': {
        removeLotPositionsHandler(lot.id, selectedRows, () =>
          updateSelectedRowsState([]),
        )
        break
      }
      case 'supplier': {
        removeLotSupplierHandler(
          lot.id,
          selectedSupplier?.providerId || '',
          (): void => {
            const page = `assignments_${assignmentId}_lots_${lot.id}_suppliers`
            const supplierId = selectedSupplier?.providerId || ''
            if (!supplierId) {
              return
            }

            let suppliers = LocalStorageHelper.get<TRowData>(`${page}`)
            if (suppliers) {
              suppliers = suppliers.filter(item => item.key !== supplierId)
              LocalStorageHelper.set<TRowData>(page, suppliers)
            }

            const visibleSuppliers = selectedVisibleSuppliers.filter(
              item => item.key !== supplierId,
            )
            setSelectedVisibleSuppliers(visibleSuppliers)

            // eslint-disable-next-line no-undefined
            setSelectedSupplier(undefined)
          },
        )

        break
      }
      case 'lot': {
        removeLotHandler(lot.id)
        break
      }
    }
    //props.onRemoveBtnClick(selectedRows, () => props.updateSelectedRowsState([]));
    setOpenDialogModal(false)
  }, [
    assignmentId,
    dialogModalSettings,
    lot.id,
    removeLotHandler,
    removeLotPositionsHandler,
    removeLotSupplierHandler,
    selectedRows,
    selectedSupplier,
    selectedVisibleSuppliers,
    updateSelectedRowsState,
  ])

  const handleCloseDialogModal = React.useCallback((): void => {
    setOpenDialogModal(false)
  }, [])

  const handleSelectedVisibleSuppliers = React.useCallback(
    (data: TRowData): void => {
      const columns = _.uniqBy(data, 'key')

      setSelectedVisibleSuppliers(_.cloneDeep(columns))

      const page = `assignments_${assignmentId}_lots_${lot.id}_suppliers`

      LocalStorageHelper.set<TRowData>(page, columns)

      if (showSupplierWithAgreementOnly) {
        setShowSupplierWithAgreementOnly(false)
      }
    },
    [assignmentId, lot.id, showSupplierWithAgreementOnly],
  )

  const handleClickSuppliersVisibilityCheckbox = React.useCallback(
    (column: IColumn): void => {
      const selectedIndex = _.findIndex(
        selectedVisibleSuppliers,
        c => c.key === column.key,
      )
      let newSelected: TRowData = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedVisibleSuppliers, {
          key: column.key,
          value: column.title,
        })
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedVisibleSuppliers.slice(1))
      } else if (selectedIndex === selectedVisibleSuppliers.length - 1) {
        newSelected = newSelected.concat(selectedVisibleSuppliers.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedVisibleSuppliers.slice(0, selectedIndex),
          selectedVisibleSuppliers.slice(selectedIndex + 1),
        )
      }

      handleSelectedVisibleSuppliers(newSelected)
    },
    [handleSelectedVisibleSuppliers, selectedVisibleSuppliers],
  )

  const toggleDrawer = React.useCallback(
    async (open: boolean, supplier?: IKeyValuePair): Promise<void> => {
      if (supplier) {
        dispatch(setDataLoading(true))
        const [err, detail] = await SuppliersService.getSupplier(supplier.key)

        dispatch(setDataLoading(false))
        if (!detail.data.data) {
          return
        }
        if (
          !selectedSupplier ||
          selectedSupplier.providerId !== detail.data.data.id
        ) {
          setSelectedSupplier({
            providerId: detail.data.data.id,
            inn: detail.data.data.inn,
            kpp: detail.data.data.kpp,
            name: detail.data.data.name,
          })
        }
        setDataListDrawer(detail.data.data)
        setOpenDrawer(open)
        dispatch(setDataLoading(false))

        if (err) {
          //TODO handle error
          toastr.error('Ошибка', '')
        }
      } else {
        // eslint-disable-next-line no-undefined
        setDataListDrawer(undefined)
        setOpenDrawer(open)
      }
    },
    [dispatch, selectedSupplier],
  )
  const refreshProjectList = useCallback(() => {
    appDispatch(getAllProjects())
      .unwrap()
      .then(setAllProjectsAndObjects)
  }, [appDispatch])

  const drawerEditBtnClickHandler = React.useCallback((): void => {
    if (selectedSupplier) {
      toggleDrawer(
        false,
        selectedSupplier
          ? {
              key: selectedSupplier.providerId,
              value: selectedSupplier.name,
            }
          : undefined,
      )
      //props.onEditBtnClick(selectedSupplier.key);
    }
    setAddAssignmentSupplierModalType(EEditableModalType.EDIT)
    setOpenAddSupplierModal(true)
  }, [selectedSupplier, toggleDrawer])

  const drawerAddAgreementBtnClickHandler = React.useCallback(async (): Promise<
    void
  > => {
    if (flagAgreementList) {
      //TODO move to action
      const [err, res] = await AgreementService.getAgreementColumns(
        String(keyCatalogAgreement),
      )

      if (err) {
        console.error(err)
        toastr.error('', 'При получении данных произошла ошибка!')
      }

      if (res.data.data && res.data.data.columns !== null) {
        res.data.data.supplier = selectedSupplier
          ? {
              key: selectedSupplier.providerId,
              value: selectedSupplier.name,
            }
          : { key: '', value: '' }
        res.data.data.lotId = Number(lot.id)
        setAgreementModalModel({
          ...res.data.data,
          columns: res.data.data.columns.filter(
            item => !['Проект', 'Объект'].includes(item.title),
          ),
        })
      } else {
        if (res.data.data) {
          res.data.data.supplier = selectedSupplier
            ? {
                key: selectedSupplier.providerId,
                value: selectedSupplier.name,
              }
            : { key: '', value: '' }
          res.data.data.lotId = Number(lot.id)
          res.data.data.columns = []
          setAgreementModalModel(res.data.data)
        }
      }

      toggleDrawer(
        false,
        selectedSupplier
          ? {
              key: selectedSupplier.providerId,
              value: selectedSupplier.name,
            }
          : undefined,
      )
    } else {
      setOpenAgreementModal(!openAgreementModal)
    }
  }, [
    flagAgreementList,
    keyCatalogAgreement,
    lot.id,
    openAgreementModal,
    selectedSupplier,
    toggleDrawer,
  ])

  useEffect(() => {
    if (flagAgreementList) {
      drawerAddAgreementBtnClickHandler()
      flagAgreementListHandled(false)
    }
  }, [
    drawerAddAgreementBtnClickHandler,
    flagAgreementList,
    flagAgreementListHandled,
  ])

  const addSelectedCategoriesToLot = React.useCallback(
    (ids: string[]) => {
      dispatch(
        addCategoriesToLot({
          categoryIds: ids,
          lotId: Number(lot.id),
          callback: () => {
            history.push(history.location.pathname)
          },
        }),
      )
    },
    [dispatch, history, lot.id],
  )

  const [currentWinners, setCurrentWinners] = useState<
    (ProviderPosition & { name: string })[]
  >([])

  const visibleColumns = selectedVisibleSuppliers.map(svs => ({
    key: svs.key,
    title: svs.value,
    type: EColumnType.STRING,
    required: false,
    base: false,
    hidden: false,
    editable: false,
    measureUnitId: null,
    measureUnitSymbol: null,
    unit: '',
  }))

  const [
    visibleSuppliersAnchorEl,
    setVisibleSuppliersAnchorEl,
  ] = useState<HTMLButtonElement | null>(null)

  const handleCloseVisibleSuppliersMenu = (): void => {
    setVisibleSuppliersAnchorEl(null)
  }

  const visibleSuppliersMenuOpen = Boolean(visibleSuppliersAnchorEl)
  const id = visibleSuppliersMenuOpen ? 'simple-popover' : undefined

  const getCheckedColumnsCount = (): number =>
    visibleColumns.filter(vc =>
      supplierColumns.some(sc => Number(sc.key) === Number(vc.key)),
    ).length

  const allColumnsChecked = (): boolean =>
    _.uniqBy(supplierColumns, 'key').every(sc =>
      visibleColumns.some(vc => Number(sc.key) === Number(vc.key)),
    )
  const assignmentClosed = assignmentStatus === 'Закрыто'

  const drawerGetBaseSection = React.useCallback(
    (): JSX.Element | null =>
      dataListDrawer ? (
        <>
          <Divider />
          <div className={classes.cardRowContainer}>
            <div>
              <h4 className={classes.cardRowTitle}>Общие сведения</h4>
            </div>
            <div className={classes.cardRowBody}>
              <TextField
                label='Адрес'
                value={dataListDrawer.address || '-'}
                maxRows={5}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Представитель'
                value={dataListDrawer.representative || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Телефон'
                value={dataListDrawer.phone || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
              <TextField
                label='Сайт'
                value={dataListDrawer.site || '-'}
                maxRows={3}
                multiline={true}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </div>
            <SupplierHistoryField data={dataListDrawer.tenderTasks} />
          </div>
        </>
      ) : null,
    [
      classes.cardRowBody,
      classes.cardRowContainer,
      classes.cardRowTitle,
      dataListDrawer,
    ],
  )

  const renderDrawerAssignmentContent = React.useCallback(
    (title: string, dataOtherList: Array<IKeyValuePair>): JSX.Element => (
      <>
        <Divider />
        <div className={classes.cardRowContainer}>
          <div>
            <h4 className={classes.cardRowTitle}>{title}</h4>
          </div>
          {dataOtherList.map(value => {
            const parts = value.value.split('|')
            return (
              <div key={value.key} className={classes.cardRowSpaceBetween}>
                <TextField
                  label=''
                  value={value.key}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
                {parts.length === 1 ? (
                  <TextField
                    label=''
                    value={statusDict[value.value]}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    className={classes.cardRowSpaceBetweenText}
                  />
                ) : (
                  <div className={classes.cardMultiline}>
                    <span className='flex-item'>{parts[0]}</span>
                    <div className='flex-item column'>
                      {parts.slice(1).map(p => (
                        <span key={p}>{p}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </>
    ),
    [
      classes.cardMultiline,
      classes.cardRowContainer,
      classes.cardRowSpaceBetween,
      classes.cardRowSpaceBetweenText,
      classes.cardRowTitle,
      statusDict,
    ],
  )

  const dumbTableRows = useMemo(
    () => createDumbTableRows(dataListDrawer?.agreements || []),
    [dataListDrawer?.agreements],
  )

  const renderDrawerContent = React.useCallback(
    (): JSX.Element => (
      <div role='presentation'>
        <List className={classes.drawerListHeader}>
          <h5 data-test-id='assignmentLotSupplierDrawerHeading'>
            {dataListDrawer && dataListDrawer.name}
          </h5>
          <IconButton
            data-test-id='assignmentLotSupplierDrawerCloseBtn'
            onClick={async () => {
              await toggleDrawer(false)
            }}
            className={classes.drawerListHeaderCloseBtn}
          >
            <SvgIcon viewBox={'0 0 14 13'} style={{ fontSize: '16px' }}>
              <path
                d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
                fill='#535F77'
              />
            </SvgIcon>
          </IconButton>
        </List>
        <List className={classes.drawerContent}>
          <Divider />
          {drawerGetBaseSection()}
          {dataListDrawer && dataListDrawer.comment && (
            <>
              <Divider />
              <div className={classes.cardRowContainer}>
                <div>
                  <h4 className={classes.cardRowTitle}>Комментарий</h4>
                </div>
                <div className={classes.cardRowBody}>
                  <TextField
                    label=''
                    value={dataListDrawer.comment}
                    multiline
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {dataListDrawer &&
            dataListDrawer.assignments &&
            dataListDrawer.assignments.length > 0 &&
            renderDrawerAssignmentContent(
              'Тендерные задания',
              dataListDrawer.assignments,
            )}
          {dumbTableRows.length > 0 && (
            <>
              <Divider />
              <div className={classes.cardRowContainer}>
                <AccordionAgreementsDumbTable
                  dataTestIdPrefix='assignmentLotSupplierDrawerAgreements'
                  title='Соглашения'
                >
                  <DumbTable
                    dataTestIdPrefix='assignmentLotSupplierDrawerAgreements'
                    columns={AGREEMENT_TABLE_COLUMNS_WITHOUT_PRICE}
                    rows={dumbTableRows}
                  />
                </AccordionAgreementsDumbTable>
              </div>
            </>
          )}
        </List>
        <List className={classes.drawerFooter}>
          {lot.status !== ELotStatusDesc.CLOSED &&
            checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_REMOVE_SUPPLIER_FROM_LOT,
            ) && (
              <div>
                <ButtonComponent
                  data-test-id='assignmentLotSupplierDrawerDeleteBtn'
                  text={''}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DELETE}
                  noMargin={true}
                  onClick={(): void => {
                    setOpenDialogModal(true)
                    setDialogModalSettings({
                      type: 'supplier',
                      title: 'Удаление поставщика',
                      content:
                        'Вы действительно хотите удалить данного поставщика ?',
                      leftBtnText: 'Отменить',
                      rightBtnText: 'Удалить',
                    })
                    toggleDrawer(
                      false,
                      selectedSupplier
                        ? {
                            key: selectedSupplier.providerId,
                            value: selectedSupplier.name,
                          }
                        : undefined,
                    )
                  }}
                />
              </div>
            )}
          {!lot.suppliers.data.find(
            item => item.rowId === selectedSupplier?.providerId,
          )?.hasAgreement &&
          (checkPermissions(
            Authorities.ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT,
          ) ||
            checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_CREATE_AGREEMENT,
            )) ? (
            <div className={classes.secondPartFooter}>
              {!assignmentClosed &&
                checkPermissions(
                  Authorities.ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT,
                ) && (
                  <ButtonComponent
                    data-test-id='assignmentLotSupplierDrawerUpdatePriceBtn'
                    text={'Обновить прайс'}
                    type={EButtonType.DEFAULT}
                    typeIcon={EButtonIcon.EDIT}
                    onClick={drawerEditBtnClickHandler}
                  />
                )}
            </div>
          ) : null}
        </List>
      </div>
    ),
    [
      classes.drawerListHeader,
      classes.drawerListHeaderCloseBtn,
      classes.drawerContent,
      classes.cardRowContainer,
      classes.cardRowTitle,
      classes.cardRowBody,
      classes.drawerFooter,
      classes.secondPartFooter,
      dumbTableRows,
      dataListDrawer,
      drawerGetBaseSection,
      renderDrawerAssignmentContent,
      lot.status,
      lot.suppliers.data,
      checkPermissions,
      assignmentClosed,
      drawerEditBtnClickHandler,
      toggleDrawer,
      selectedSupplier,
    ],
  )

  const handleCloseLot = useCallback(() => {
    const specialisationIds = lot.specialization.map(item => Number(item.id))
    dispatch(
      changeLotName({
        lotId: lot.id,
        name: lot.name,
        description: lot.description,
        status: ELotStatus['Закрыт'],
        callback: (): void => history.push(history.location.pathname),
        specializationIds: specialisationIds,
      }),
    )
  }, [dispatch, history, lot.description, lot.id, lot.name, lot.specialization])

  const disableActionButtons = !lot?.positions?.length
  const supplierColumns = suppliers.map(sd => ({
    key: sd.rowId,
    title: sd.name,
    type: EColumnType.STRING,
    required: false,
    base: false,
    hidden: false,
    editable: false,
    measureUnitId: null,
    measureUnitSymbol: null,
    unit: '',
  }))

  return (
    <div>
      {openDialogModal && (
        <DialogModal
          open={openDialogModal}
          handleDiscardChanges={handleCloseDialogModal}
          handleChanges={handleRemoveChangesDialogModal}
          modalTitle={dialogModalSettings?.title}
          modalContent={dialogModalSettings?.content}
          modalButtonRightText={dialogModalSettings?.rightBtnText}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText={dialogModalSettings?.leftBtnText}
          modalButtonLeftType={EButtonType.DEFAULT}
          dataTestIdPrefix='assignmentLotDeleteModal'
        />
      )}
      {openAddPositionsContextModal && (
        <AddPositionsContextModal
          positions={positions}
          author={author}
          openModal={openAddPositionsContextModal}
          onClose={(): void => {
            setOpenAddPositionsContextModal(false)
          }}
          addPosition={addPosition}
          getPositions={getPositions}
          addToAssignment={addToAssignment}
          onGetCheckedCategories={addSelectedCategoriesToLot}
          lotId={lot.id}
          callbackAssignmentView={(): void => {
            callbackAssignmentView()
            setOpenAddPositionsContextModal(false)
          }}
          authRoleChecker={checkPermissions}
        />
      )}
      {openAddSupplierModal && (
        <AddSupplierAssignment
          open={openAddSupplierModal}
          type={addAssignmentSupplierModalType}
          supplier={selectedSupplier}
          onSave={(supplierId: string, price: File): void => {
            if (addAssignmentSupplierModalType === EEditableModalType.INSERT) {
              addSupplierHandler({
                lotId: lot.id,
                supplierId,
                price,
                callback: () => {
                  const page = `assignments_${assignmentId}_lots_${lot.id}_suppliers`
                  const columns = LocalStorageHelper.get<TRowData>(page)

                  if (
                    columns &&
                    !columns.some(c => Number(c.key) === Number(supplierId))
                  ) {
                    columns.push({
                      key: supplierId,
                      value:
                        lot.suppliers.data.find(
                          sd => Number(sd.rowId) === Number(supplierId),
                        )?.name || '',
                    })
                    LocalStorageHelper.set<TRowData>(page, columns)
                    setSelectedVisibleSuppliers(columns)
                  }

                  setOpenAddSupplierModal(false)
                  getAssignmentDetail(assignmentId)
                },
              })
            } else {
              updatePriceHandler({
                lotId: lot.id,
                supplierId,
                price,
                callback: () => setOpenAddSupplierModal(false),
              })
            }
          }}
          onClose={(): void => setOpenAddSupplierModal(false)}
        />
      )}
      {openMovePositionsModal && (
        <MovePositionsModal
          open={openMovePositionsModal}
          lots={lots
            .filter(
              l =>
                Number(l.id) !== Number(lot.id) &&
                (l.status === ELotStatusDesc.NEW ||
                  l.status === ELotStatusDesc.SENT),
            )
            .map(l => ({ key: l.id, value: `Лот ${l.position}: ${l.name}` }))}
          onSave={(lotId: string, removeFromCurrentLot: boolean): void => {
            setOpenMovePositionModal(false)
            moveLotPositionsHandler(
              lot.id,
              lotId,
              selectedRows,
              removeFromCurrentLot,
              () => {
                updateSelectedRowsState([])
                callbackAssignmentView()
              },
            )
          }}
          onClose={(): void => setOpenMovePositionModal(false)}
        />
      )}
      {openAgreementModal && (
        <AddAgreementModal
          open={openAgreementModal}
          model={agreementModalModel}
          onClose={(): void => {
            setOpenAgreementModal(false)
            setAgreementModalModel({
              number: 0,
              supplier: { key: '', value: '' },
              lotId: 0,
              columns: [],
            })
          }}
          refreshProjectList={refreshProjectList}
          onSave={(agreement: TAgreementCreateRequest): void => {
            addToAgreementHandler(agreement, () => {
              setShowSupplierWithAgreementOnly(true)
              getAssignmentDetail(assignmentId)
            })
            setOpenAgreementModal(false)
          }}
          flagAgreementListHandled={flagAgreementListHandled}
          hasAgreements={lot.suppliers.data.some(item => item.hasAgreement)}
          allProjectsAndObjects={allProjectsAndObjects}
        />
      )}
      {openDrawer && (
        <Drawer
          className={classes.drawerList}
          anchor={'right'}
          open={openDrawer}
          onClose={async () => {
            await toggleDrawer(false)
          }}
        >
          {renderDrawerContent()}
        </Drawer>
      )}
      <AssignmentLotTableToolbar
        lotIndex={lotIndex}
        assignmentId={assignmentId}
        lot={lot}
        onResetSelectedRows={() => setSelectedRows([])}
        selectedRowsCount={selectedRows.length}
        supplierColumns={supplierColumns}
        assignmentClosed={assignmentStatus.toLowerCase() === 'закрыто'}
        onAddBtnClick={(): void => {
          setAddAssignmentSupplierModalType(EEditableModalType.INSERT)
          setOpenAddSupplierModal(true)
        }}
        onAddBtnPositionsClick={(): void => {
          setOpenAddPositionsContextModal(true)
        }}
        onRemovePositionsBtnClick={(): void => {
          setDialogModalSettings({
            type: 'positions',
            title: 'Удаление позиций',
            content: `Вы действительно хотите удалить позиции (${selectedRows.length})?`,
            leftBtnText: 'Отменить',
            rightBtnText: 'Удалить',
          })
          setOpenDialogModal(true)
        }}
        onRemoveLotBtnClick={(): void => {
          setDialogModalSettings({
            type: 'lot',
            title: 'Удаление лота',
            content: 'Вы действительно хотите удалить лот?',
            leftBtnText: 'Отменить',
            rightBtnText: 'Удалить',
          })
          setOpenDialogModal(true)
        }}
        onCloseLot={handleCloseLot}
        onExportBtnClick={(): void => {
          const suppliers =
            LocalStorageHelper.get<TColumn>(
              `assignments_${assignmentId}_lots_${lot.id}_suppliers`,
            ) || []
          exportLotHandler({
            lotId: lot.id,
            providersIds: suppliers?.map(col => col.key),
            categories: getCategories(),
          })
        }}
        onExportTemplateBtnClick={(): void => {
          exportTemplateHandler({
            lotId: lot.id,
            categories: getCategories(),
          })
        }}
        onSendBtnClick={(): void => {
          window.open(getMailerUrl(lot.id), '_blank')
        }}
        onChangeLotAssignmentClick={(assignment: string): void => {
          changeLotAssignmentHandler(lot.id, assignment, (): number => 0)
        }}
        onEditLotClick={(): void => openRenameLotModal(true, lot)}
        onMovePositionsBtnClick={() => setOpenMovePositionModal(true)}
        toggleCreateDialogModal={toggleCreateDialogModal}
        disableActionButtons={disableActionButtons}
        openAddAgreementModal={() => setAddAgreementModal(true)}
        openLotWinnerModal={async () => {
          const request = {
            lotId: lot.id,
            itemId: selectedRows.length ? selectedRows : [],
          }
          const [, res] = await AssignmentService.getLotPositionWinners(request)

          if (!res.data.data || !res.data.data.lotId) {
            return
          }
          const winners = res.data.data.providerPosition.filter(a => a.position)
          if (winners) {
            const winnersData = winners
              .filter(w => !!w.position)
              .map(w => ({
                ...w,
                name: suppliers.find(s => s.rowId === w.providerId)?.name || '',
              }))

            setCurrentWinners(winnersData)
            setLotWinnerModalOpen(true)
          }
        }}
      />
      <AssignmentLotDescription
        description={lot.description}
        agreements={lot.agreements}
        specialization={lot.specialization}
        lotStatus={lot.status}
      />
      {lot.positions.map((position, lotPositionIndex) => {
        const getParamsSelectAllRequest = async (
          callback: (ids: Array<string>) => void,
        ): Promise<void> => {
          const [err, result] = await AssignmentService.getLotSelectAll(
            lot.id,
            assignmentId,
            position.category.key,
          )
          if (err) {
            toastr.error('', 'При отправке запроса произошла ошибка!', {
              progressBar: false,
              showCloseButton: false,
            })
          }

          if (
            result.data.status === EAPIResponseStatus.SUCCESS &&
            result.data.data
          ) {
            callback(result.data.data)
          }
        }

        return (
          <div key={position.category.key}>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingBottom: '5px',
                }}
              >
                {selectedRows.length > 0 ? (
                  <div>
                    Выбрано <b>{selectedRows.length}</b>
                  </div>
                ) : (
                  <div></div>
                )}
                <Button
                  data-test-id={`assignmentLotSuppliersVisibilityBtn_${lotIndex}_${lotPositionIndex}`}
                  color='tertiary'
                  disabled={!supplierColumns.length}
                  leftIcon={<EyeIcon />}
                  rightIcon={<ArrowDownIcon />}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement>,
                  ): void => {
                    event.stopPropagation()
                    event.preventDefault()
                    if (event.currentTarget) {
                      setVisibleSuppliersAnchorEl(event.currentTarget)
                    }
                  }}
                >
                  {`Поставщики ${getCheckedColumnsCount()}/${
                    _.uniqBy(supplierColumns, 'key').length
                  }`}
                </Button>
              </div>
              <Accordion
                lotAndPositionIndex={`${lotIndex}_${lotPositionIndex}`}
                assignmentId={assignmentId}
                assignmentName={assignment.name}
                assignmentClosed={assignmentClosed}
                category={position.category}
                lotId={lot.id}
                agreements={lot.agreements}
                positionColumns={position.columns.map(column => ({
                  ...column,
                  title:
                    column.title.lastIndexOf('[') === -1
                      ? column.title
                      : column.title.substring(
                          0,
                          column.title.lastIndexOf('['),
                        ),
                }))}
                positionData={position.data}
                suppliers={lot.suppliers}
                showSupplierWithAgreementOnly={showSupplierWithAgreementOnly}
                visibleSuppliers={selectedVisibleSuppliers}
                pagination={position.pagination}
                selectedRows={selectedRows}
                updateSelectedRowsState={updateSelectedRowsState}
                handleSelectedVisibleSuppliers={handleSelectedVisibleSuppliers}
                addPropertyHandler={addPropertyHandler}
                editPropertyHandler={editPropertyHandler}
                removePropertyHandler={removePropertyHandler}
                exportPriceHistoryHandler={exportPriceHistoryHandler}
                toggleDrawer={toggleDrawer}
                getParamsSelectAllRequest={getParamsSelectAllRequest}
                updatePrice={(price: File, supplierId: string) =>
                  addSupplierHandler({
                    lotId: lot.id,
                    supplierId,
                    price,
                    isNewVersion: true,
                  })
                }
              />
            </div>
            <LotWinnerModal
              suppliers={suppliers}
              currentWinners={currentWinners}
              positionSelected={selectedRows}
              open={lotWinnerModalOpen}
              onClose={() => setLotWinnerModalOpen(false)}
              positionTotal={position.pagination.total}
              onSubmit={async winners => {
                const providerPosition: ProviderPosition[] = []
                Object.keys(winners).forEach(position => {
                  const arr = winners[position]

                  arr.forEach(({ rowId, items }) => {
                    const data = {
                      itemId: selectedRows.length ? items : [],
                      position: +position as number,
                      providerId: rowId,
                    }
                    providerPosition.push(data)
                  })
                })

                const [, res] = await AssignmentService.addLotPositionWinners({
                  lotId: lot.id,
                  items: selectedRows.length ? selectedRows : [],
                  providerPosition,
                })
                if (res) {
                  setSelectedRows([])
                  getAssignmentDetail(assignmentId)
                }
              }}
            />
          </div>
        )
      })}
      {
        <PopoverStyled
          className={classes.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          id={id}
          open={visibleSuppliersMenuOpen}
          anchorEl={visibleSuppliersAnchorEl}
          onClose={handleCloseVisibleSuppliersMenu}
        >
          <TableContainer className={classes.table}>
            <div className={classes.titleContainer}>
              <Checkbox
                data-test-id='assignmentLotSuppliersVisibilityMenuAllCheckbox'
                className={classes.checkedRow}
                classes={{ checked: classes.checkboxChecked }}
                color='primary'
                onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
                  event.stopPropagation()
                  event.preventDefault()
                  if (allColumnsChecked()) {
                    handleSelectedVisibleSuppliers([])
                  } else {
                    handleSelectedVisibleSuppliers(
                      _.uniqBy(supplierColumns, 'key').map(sc => ({
                        key: sc.key,
                        value: sc.title,
                      })),
                    )
                  }
                }}
                checked={allColumnsChecked()}
                disabled={false}
              />
              <p>{allColumnsChecked() ? 'Скрыть все' : 'Выбрать все'}</p>
            </div>
            <Table>
              <AssignmentTableDisplayColumnsBody
                columns={_.uniqBy(supplierColumns, 'key')}
                visibleColumns={visibleColumns}
                onColumnVisibilityChanged={
                  handleClickSuppliersVisibilityCheckbox
                }
                ifValue={false}
                showActionButtons={false}
                onEditPropertyBtnClick={() => (): number => 0}
                removePropertyHandler={(): number => 0}
                dataTestIdPrefix='assignmentLotSuppliersVisibilityMenu'
              />
            </Table>
          </TableContainer>
        </PopoverStyled>
      }

      <AgreementModal
        onClose={() => setAddAgreementModal(false)}
        suppliers={_.uniqBy(
          lot.suppliers.data.filter(s => !!s.position),
          'rowId',
        )}
        open={addAgreementModal}
        onAddAgreement={async supplier => {
          const [err, detail] = await SuppliersService.getSupplier(
            supplier.rowId,
          )

          if (detail.data.data) {
            setSelectedSupplier({
              ...detail.data.data,
              providerId: detail.data.data.id,
            })
            drawerAddAgreementBtnClickHandler()
            setAddAgreementModal(false)
          }
          if (err) {
            //TODO handle error
            toastr.error('Ошибка', '')
          }
          //
        }}
      />
      <TenderConditions
        disabledDragDrop={EAssignmentStatus.CLOSED === assignmentStatus}
        assignmentId={assignmentId}
        lotId={parseInt(lot.id, 10)}
        getAssignmentDetail={() => getAssignmentDetail(assignmentId)}
        tenderConditions={lot.tenderConditions}
        lotIndex={lotIndex}
      />
      <TenderNote
        lotId={parseInt(lot.id, 10)}
        note={lot.note}
        disabled={lot.status === ELotStatusDesc.CLOSED}
        getAssignmentDetail={() => getAssignmentDetail(assignmentId)}
        lotIndex={lotIndex}
      />
    </div>
  )
}

export default AssignmentLot
