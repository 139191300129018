import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  drawerCondition: {
    background: '#F9F9FB',
    borderRadius: '5px',
    minWidth: '286px',
    minHeight: '222px',
    margin: '15px 24px',
    '& .MuiOutlinedInput-notchedOutline': {
      outline: 'none',
      border: 'none',
    },
  },
  drawerConditionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h5': {
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '15px 15px 12px 15px',
      margin: '0',
      color: '#616284',
    },
    '& p': {
      margin: '0',
      padding: '0',
      display: 'flex',
      '& .MuiSvgIcon-root': {
        color: 'rgba(0, 0, 0, 0)',
        fontSize: '16px',
        margin: '0 15px 0 -35px',
        cursor: 'pointer',
      },
    },
    '& .MuiDivider-root': {
      backgroundColor: '#E2E4E9',
      margin: '0 15px 0 115px',
    },
  },
  drawerConditionContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px',
    padding: '0',
    fontSize: '14px',
  },
  drawerAutocomplete: {
    minWidth: '266px',
    margin: '10px 0',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiAutocomplete-root': {
      transition: theme.transitions.create('width'),
      color: 'inherit',
    },
    '& .MuiInputBase-root': {
      display: 'flex',
      alignContent: 'center',
      fontSize: '14px',
      maxHeight: '44px',
      background: '#ffffff',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    },
    '& .MuiFormHelperText-root': {
      margin: '0 15px',
      background: '#F9F9FB',
    },
    '&.Mui-focused': {
      minWidth: '266px',
      cursor: 'text',
    },
    '& .MuiSvgIcon-root': {
      color: '#4C5ECF',
    },
    '& .MuiAutocomplete-listbox': {
      '& .MuiListSubheader-root': {
        fontSize: '14px',
      },
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
  },
  drawerTextField: {
    margin: '10px 0',
    '& .MuiInputBase-root': {
      minWidth: '266px',
      maxHeight: '44px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',
      outline: 'none',
      border: 'none',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root': {
      margin: '5px 15px',
    },
  },
  drawerTextFieldIntervalContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& p': {
      margin: '10px 4px',
    },
  },
  drawerTextFieldInterval: {
    margin: '10px 0',
    display: 'flex',
    '& .MuiInputBase-root': {
      maxWidth: '123px',
      maxHeight: '44px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
      borderRadius: '5px',
      outline: 'none',
      border: 'none',
      fontSize: '14px',
    },
    '& .MuiFormHelperText-root': {
      maxWidth: '115px',
      margin: '5px 0',
    },
  },
  drawerSelectCondition: {
    display: 'flex',
    alignContent: 'center',
    '& .MuiInputBase-root': {
      maxWidth: '120px',
      maxHeight: '30px',
      fontSize: '12px',
      background: '#F2F0F8',
      color: '#1B55C5',
      fontWeight: '600',
      margin: '0 0 10px 0',
    },
    '& .MuiSvgIcon-root': {
      color: '#4C5ECF',
    },
    '& .Mui-error': {
      margin: '20px 0',
      '& p': {
        margin: '10px 0 0 0',
      },
    },
  },
  optionConditionInput: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0',
    padding: '0',
    width: '620px',
    wordBreak: 'break-all',
  },
  optionConditionType: {
    marginLeft: '20px',
    fontStyle: 'italic',
  },
}))

export default useStyles
