import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const SkeletonArrow = ({
  color = '#00003D',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g opacity='0.1'>
      <path
        d='M10.2094 9.22595L12.987 12.0036L10.2094 14.7812C10.0753 14.915 10 15.0965 10 15.2859C10 15.4753 10.0753 15.6569 10.2094 15.7906C10.4886 16.0698 10.9396 16.0698 11.2188 15.7906L14.5047 12.5047C14.7839 12.2255 14.7839 11.7745 14.5047 11.4953L11.2188 8.2094C10.9396 7.9302 10.4886 7.9302 10.2094 8.2094C9.93735 8.48859 9.93019 8.94676 10.2094 9.22595Z'
        fill={color}
      />
    </g>
  </svg>
)
