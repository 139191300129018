/* eslint-disable require-unicode-regexp */
/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import {
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  Switch,
  TextField,
} from '@material-ui/core'

import * as _ from 'lodash'
import moment from 'moment'
import InputMask from 'react-input-mask'

import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete'
import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/internal/operators/debounceTime'

import { PositionService } from '../../services/position-service'
import {
  IColumn,
  IFileResult,
  IKeyValuePair,
  IRow,
  ITreeListItem,
  TPromptPositions,
} from '../../types'

import { ButtonComponent } from '../Button/Button'
import { FileUpload } from '../FileUpload/FileUpload'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import {
  EButtonType,
  EColumnType,
  EEditableModalType,
  ETreeListItemType,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../../constants'
import { ListEditor } from '../ListEditor/ListEditor'
import { validate } from '../../utils/validate'
import { flatten, mapConclusionToRus } from '../../utils'

import * as I from './IEditableModal'

import { useStyles } from './Style'

const getNormalizeListValue = (
  row: IRow,
  settings: IColumn[],
  specialisations?: ITreeListItem[],
) => {
  if (!settings.length) {
    return { rowId: '', data: [] } as IRow
  }

  const columnsWithValue = settings.reduce((acc: IColumn[], el) => {
    const columnRow = row.data.find(item => el.key === item.key)
    return [...acc, { ...el, ...columnRow }]
  }, [])

  const flatCatalog = flatten(specialisations || [])
    .filter(item => item.type === ETreeListItemType.LIST)
    .reduce((acc: Record<string, string>, item) => {
      acc[item.id] = item.name
      return acc
    }, {})

  const [listColumn] = columnsWithValue.filter(
    el => el.type === EColumnType.LIST,
  )
  const listColumnValue = _.get(listColumn, 'value', '')
  const splitListColumnValue = listColumnValue
    .split(';')
    .map((el: string) => el.trim())

  const arr = Object.entries(flatCatalog)
  const currentValues = arr.filter(([_, value]) =>
    splitListColumnValue.includes(value),
  )

  const newValues = currentValues.flatMap(([key]) => key)

  return {
    ...row,
    data: row.data.map(el => {
      if (el.key !== listColumn?.key) {
        return el
      }

      return {
        ...el,
        value: newValues.join('|'),
      }
    }),
  }
}

const getTextFieldLength = (name: string): number | null => {
  if (name === 'ИНН') {
    return 12
  } else if (name === 'КПП') {
    return 9
  }

  return null
}

type TCurrencyField = {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { name?: string; value: string } }) => void
  name?: string
}

const CurrencyField: React.FC<TCurrencyField> = props => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={' '}
      isNumericString
      decimalSeparator={','}
    />
  )
}

export const EditableModal: React.FC<I.OwnProps> = ({
  open,
  isMultiline,
  maxRows = 4,
  initialState,
  type,
  title,
  path,
  author,
  dataIsLoading,
  specialisations,
  onClose,
  onSave,
}): React.ReactElement => {
  const classes = useStyles()
  const files = useRef<IFileResult | null>(
    initialState.current.files ? { ...initialState.current.files } : null,
  )

  initialState.current.row = getNormalizeListValue(
    initialState.current.row,
    initialState.current.settings,
    specialisations,
  )
  const [state, setState] = useState<IRow>({ ...initialState.current.row })
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    ..._.reduce(
      initialState.current.row.data,
      (acc, { key }) => ({ ...acc, [key]: '' }),
      {},
    ),
  })

  const [inputValue, setInputValue] = useState<string>('')
  const [searchValue, setSearchValue] = useState<Array<IKeyValuePair>>([])
  const [, setValueAutocomplete] = useState<string>('')

  const [optionsListOpen, setOptionsListOpen] = React.useState(false)
  const [onSearch$] = useState(() => new Subject<TPromptPositions>())
  const [optionsListLoading, setOptionsListLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!_.isEqual(state, initialState.current.row)) {
      setState({ ...initialState.current.row })
      setErrors({
        ..._.reduce(
          initialState.current.row.data,
          (acc, { key }) => ({ ...acc, [key]: '' }),
          {},
        ),
      })

      if (initialState.current.files) {
        files.current = { ...initialState.current.files }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.current])

  useEffect(() => {
    const subscription = onSearch$.pipe(debounceTime(400)).subscribe(
      async (debounced: TPromptPositions): Promise<void> => {
        setOptionsListLoading(true)
        if (debounced && debounced.fieldValue.length > 2) {
          const [err, result] = await PositionService.promptNamePositions(
            debounced,
          )

          if (err) {
            //TODO handle error
            setOptionsListLoading(false)
          }

          if (result.data.data && result.data.data.length > 0) {
            const arr: Array<IKeyValuePair> = []
            result.data.data.map((d, index) =>
              arr.push({ key: index, value: d }),
            )
            setSearchValue(arr)
          }
        } else {
          setOptionsListLoading(false)
          setSearchValue([])
        }
      },
    )
    return (): void => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [onSearch$])

  useEffect(() => {
    const newState = _.cloneDeep(state)
    const findKeyAuthor = initialState.current.settings.find(
      i => i.title === 'Автор',
    )
    const authorItem = newState.data.find(p => p.key === findKeyAuthor?.key)

    const findKeyDate = initialState.current.settings.find(
      i => i.title === 'Дата создания',
    )
    const date = newState.data.find(p => p.key === findKeyDate?.key)

    if (authorItem && date) {
      authorItem.value = authorItem.value.length
        ? authorItem.value
        : author || ''
      date.value = date.value.length
        ? date.value
        : moment().format('YYYY-MM-DDT00:00')
      setState(newState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNumberFieldKeyDown = useCallback((event): boolean => {
    // Разрешаем backspace, tab, Delete, стрелки, обычные цифры и цифры на дополнительной клавиатуре
    const key = event.charCode || event.keyCode || 0
    if (
      !(
        key === 13 ||
        key === 8 ||
        key === 9 ||
        key === 46 ||
        (key >= 37 && key <= 40) ||
        (key >= 48 && key <= 57) ||
        (key >= 96 && key <= 105) ||
        // CTRL-V
        (key === 86 && (event.ctrlKey || event.metaKey)) ||
        // CTRL-C
        (key === 67 && (event.ctrlKey || event.metaKey)) ||
        // CTRL-A
        (key === 65 && (event.ctrlKey || event.metaKey))
      )
    ) {
      event.preventDefault()
      return false
    }

    return true
  }, [])

  const handleCurrencyFieldKeyDown = useCallback((event): boolean => {
    if (
      ![
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        ',',
        '.',
        'Backspace',
      ].some(r => event.key === r)
    ) {
      event.preventDefault()
      return false
    }
    return true
  }, [])

  const handleBooleanPropChanged = (prop: IColumn, key: string): void => {
    const newState = _.cloneDeep(state)
    const item = newState.data.find(i => i.key === key)
    validate(
      prop,
      (item?.value || '') === 'true' ? 'false' : 'true',
      errors,
      setErrors,
    )

    if (item) {
      item.value = (item.value !== 'true').toString()
      setState(newState)
    }
  }

  const handleListPropChanged = (
    newList: Array<string>,
    prop: IColumn,
  ): void => {
    validate(prop, newList.filter(Boolean).join('|'), errors, setErrors)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = newList.filter(Boolean).join('|')
      setState(newState)
    }
  }

  const handleStringPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value
      setState(newState)
    }
  }

  const handlePhonePropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value =
        event.target.value === '+7 (***) ***-**-**' ? '' : event.target.value
      setState(newState)
    }
  }

  const handleStringAutocompletePropChanged = (
    event: string,
    prop: IColumn,
  ): void => {
    validate(prop, event, errors, setErrors)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event
      setState(newState)
    }
  }

  const handleIntegerPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      setState(newState)
    }
  }

  const handleDatePropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)
    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      item.value = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
      setState(newState)
    }
  }

  const validateForm = (): { [key: string]: string } => {
    const error: { [key: string]: string } = {}

    state.data
      .filter(d =>
        initialState.current.settings
          .filter(
            s =>
              ['№', 'статус', 'создано', 'дата'].indexOf(
                s.title.toLowerCase(),
              ) === -1 ||
              path.indexOf('assignments') === -1 ||
              path.indexOf('/assignment/') !== -1,
          )
          .some(s => s.key === d.key),
      )
      .forEach(d => {
        const prop = initialState.current.settings.find(p => p.key === d.key)
        if (prop) {
          const msg = validate(prop, d.value, errors, setErrors)
          if (msg.length) {
            error[prop.key] = msg
          }
        }
      })

    return error
  }

  const saveBtnHandler = (): void => {
    const error = validateForm()

    if (_.keys(error).length) {
      setErrors({ ...errors, ...error })
      return
    }

    const obj = { ...initialState.current, row: { ...state } }

    if (files.current) {
      obj.files = { ...files.current }
    }

    initialState.current = obj

    onSave()
  }

  const debouncedSaveBtnHandler = _.debounce(saveBtnHandler, 500)

  const onDialogClose = (): void => {
    setErrors({
      ..._.reduce(
        initialState.current.row.data,
        (acc, { key }) => ({ ...acc, [key]: '' }),
        {},
      ),
    })
    onClose()
  }

  const getCustomSectionName = (): string =>
    path.indexOf('positions') !== -1
      ? 'Характеристики'
      : path.indexOf('suppliers') !== -1
      ? 'Реквизиты'
      : ''

  const nameRow =
    initialState.current.settings.find(
      prop =>
        prop.base &&
        ['название', 'наименование'].indexOf(prop.title.toLowerCase()) !== -1,
    ) || null
  const excludeName = path.indexOf('/assignment/') === -1

  const renderStringValue = (renderTitle: string, value: string) => {
    switch (renderTitle) {
      case 'Автор':
        return type === EEditableModalType.INSERT ? author : value

      case 'Заключение СБ':
        return mapConclusionToRus(value)

      default:
        return value
    }
  }

  const renderProp = (prop: IColumn) => {
    switch (prop.type) {
      case EColumnType.BOOLEAN:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <FormControl error={errors[prop.key]?.length > 0}>
                <Switch
                  checked={
                    state.data.find(r => r.key === prop.key)?.value ===
                      'true' || false
                  }
                  onChange={(): void =>
                    handleBooleanPropChanged(prop, prop.key)
                  }
                  name={String(prop.key)}
                  required={prop.required}
                  data-test-id={`editableModalPropInput_${prop.key}`}
                />
                <FormHelperText>{errors[prop.key]}</FormHelperText>
              </FormControl>
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.STRING:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='text'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                value={renderStringValue(
                  prop.title,
                  state.data.find(p => p.key === prop.key)?.value || '',
                )}
                onKeyDown={
                  ['ИНН', 'КПП'].includes(prop.title)
                    ? handleNumberFieldKeyDown
                    : undefined
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleStringPropChanged(e, prop)
                }
                InputProps={{
                  endAdornment: prop.measureUnitSymbol ? (
                    <InputAdornment position='end'>
                      {prop.measureUnitSymbol}
                    </InputAdornment>
                  ) : null,
                  inputMode: 'text',
                }}
                inputProps={{
                  maxLength: getTextFieldLength(prop.title),
                  'data-test-id': `editableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
            disabled={
              prop.title === 'Автор' ||
              prop.title === 'Ссылка' ||
              !prop.editable
            }
          />
        )
      case EColumnType.LIST:
        return (
          <FormControl
            error={errors[prop.key]?.length > 0}
            className='prop listProp'
          >
            <FormControlLabel
              key={prop.key}
              control={<></>}
              label={prop.title}
              labelPlacement='top'
            />
            <ListEditor
              dataTestIdPrefix={`editableModalPropInput_${prop.key}`}
              ids={(state.data.find(p => p.key === prop.key)?.value || '')
                .split('|')
                .filter(Boolean)}
              catalog={specialisations ?? []}
              onDelete={(id): void => {
                const oldList = (
                  state.data.find(p => p.key === prop.key)?.value || ''
                ).split('|')
                const index = oldList.findIndex(item => item === id)
                if (index >= 0) {
                  oldList.splice(index, 1)
                }
                handleListPropChanged(oldList, prop)
              }}
              onAdd={(value: string): void => {
                const oldList = (
                  state.data.find(p => p.key === prop.key)?.value || ''
                ).split('|')
                oldList.push(value)
                handleListPropChanged(oldList, prop)
              }}
            />
            <FormHelperText>{errors[prop.key]}</FormHelperText>
          </FormControl>
        )
      case EColumnType.PHONE:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <InputMask
                mask='+7 (999) 999-99-99'
                maskChar='*'
                alwaysShowMask={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handlePhonePropChanged(e, prop)
                }
                value={
                  state.data.find(p => p.key === prop.key)
                    ? state.data.find(p => p.key === prop.key)?.value
                    : ''
                }
              >
                {(): JSX.Element => (
                  <TextField
                    type='phone'
                    required={prop.required}
                    error={errors[prop.key]?.length > 0}
                    helperText={errors[prop.key]}
                    variant='filled'
                    inputProps={{
                      'data-test-id': `editableModalPropInput_${prop.key}`,
                    }}
                  />
                )}
              </InputMask>
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DOUBLE:
      case EColumnType.CURRENCY:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='text'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                value={state.data.find(p => p.key === prop.key)?.value || ''}
                onKeyDown={handleCurrencyFieldKeyDown}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  handleIntegerPropChanged(e, prop)
                }}
                InputProps={{
                  inputComponent: CurrencyField as any,
                }}
                inputProps={{
                  'data-test-id': `editableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
            disabled={!prop.editable}
          />
        )
      case EColumnType.DATE:
        return (
          <FormControlLabel
            key={prop.key}
            className='prop'
            control={
              <TextField
                type='date'
                id='date'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleDatePropChanged(e, prop)
                }
                style={{
                  width: 279,
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                value={
                  prop.title === 'Дата создания' &&
                  type === EEditableModalType.INSERT
                    ? moment().format(REVERSE_DISPLAY_DATE_FORMAT)
                    : state.data.find(p => p.key === prop.key)?.value
                    ? moment(
                        state.data.find(p => p.key === prop.key)?.value,
                      ).format(REVERSE_DISPLAY_DATE_FORMAT)
                    : undefined
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  'data-test-id': `editableModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
            disabled={prop.title === 'Дата создания' || !prop.editable}
          />
        )
      default:
        return <></>
    }
  }

  const renderBaseProperties = initialState.current.settings
    .filter(prop => prop.title !== 'Название')
    .filter(prop => prop.base && !prop.section)
    .filter(
      prop =>
        prop.base && (!nameRow || !excludeName || prop.key !== nameRow.key),
    )
    .map(prop => (
      <React.Fragment key={prop.key}>{renderProp(prop)}</React.Fragment>
    ))

  const renderProperties = initialState.current.settings
    .filter(prop => !prop.base && !prop.section)
    .map(prop => (
      <React.Fragment key={prop.key}>{renderProp(prop)}</React.Fragment>
    ))

  const renderSections = () => {
    let sections = initialState.current.settings
      .filter(column => column.section)
      .map(column => column.section) as IKeyValuePair[]

    sections = _.uniqBy(sections, 'key')

    if (!sections.length) {
      return null
    }

    return (
      <>
        {sections.map(section => (
          <React.Fragment key={section.key}>
            <h3>{section.value}</h3>
            <div className='propContainer'>
              {initialState.current.settings
                .filter(col => col.section?.key === section.key)
                .map(col => (
                  <React.Fragment key={col.key}>
                    {renderProp(col)}
                  </React.Fragment>
                ))}
            </div>
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <div>
      {open && (
        <Dialog
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
        >
          <ModalTitle
            dataTestIdPrefix='editableModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            {type === EEditableModalType.INSERT ? title.insert : title.edit}
          </ModalTitle>
          <ModalContent dividers>
            {nameRow &&
              (type !== EEditableModalType.INSERT ||
                path.indexOf('positions') === -1) &&
              (path.indexOf('/assignment/') === -1 ||
                type !== EEditableModalType.INSERT) && (
                <FormControlLabel
                  className='fullWidth'
                  control={
                    isMultiline ? (
                      <TextField
                        type='text'
                        required={nameRow.required}
                        error={errors[nameRow.key]?.length > 0}
                        helperText={errors[nameRow.key]}
                        fullWidth
                        multiline
                        maxRows={maxRows}
                        variant='filled'
                        value={
                          state.data.find(p => p.key === nameRow.key)?.value ||
                          ''
                        }
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => handleStringPropChanged(e, nameRow)}
                        InputProps={{
                          endAdornment: nameRow.measureUnitSymbol ? (
                            <InputAdornment position='end'>
                              {nameRow.measureUnitSymbol}
                            </InputAdornment>
                          ) : null,
                          inputMode: 'text',
                        }}
                        inputProps={{
                          'data-test-id': 'editableModalNameInput',
                        }}
                      />
                    ) : (
                      <TextField
                        type='text'
                        required={nameRow.required}
                        error={errors[nameRow.key]?.length > 0}
                        helperText={errors[nameRow.key]}
                        fullWidth
                        variant='filled'
                        value={
                          state.data.find(p => p.key === nameRow.key)?.value ||
                          ''
                        }
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => handleStringPropChanged(e, nameRow)}
                        InputProps={{
                          endAdornment: nameRow.measureUnitSymbol ? (
                            <InputAdornment position='end'>
                              {nameRow.measureUnitSymbol}
                            </InputAdornment>
                          ) : null,
                          inputMode: 'text',
                        }}
                        inputProps={{
                          'data-test-id': 'editableModalNameInput',
                        }}
                      />
                    )
                  }
                  label={nameRow.title}
                  labelPlacement='top'
                />
              )}
            {nameRow &&
              nameRow.title === 'Название' &&
              nameRow.base &&
              (path.indexOf('positions') !== -1 ||
                path.indexOf('/assignment/') !== -1) &&
              type !== EEditableModalType.EDIT && (
                <FormControlLabel
                  className='fullWidth'
                  control={
                    <Autocomplete
                      filterOptions={(
                        options: Array<IKeyValuePair>,
                      ): Array<IKeyValuePair> => options}
                      className={classes.autocomplete}
                      clearOnBlur={false}
                      clearOnEscape
                      fullWidth
                      disableClearable
                      open={optionsListOpen}
                      onOpen={(): void => {
                        setOptionsListOpen(true)
                      }}
                      onClose={(): void => {
                        setOptionsListOpen(false)
                      }}
                      inputValue={inputValue}
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        newValue: IKeyValuePair | null,
                      ): void => {
                        if (newValue) {
                          setValueAutocomplete(newValue.value)
                        }
                      }}
                      onInputChange={(
                        event: React.ChangeEvent<{}>,
                        value: string,
                      ): void => {
                        onSearch$.next({
                          fieldName: 'Название',
                          fieldValue: value,
                        })
                        setInputValue(value)
                        handleStringAutocompletePropChanged(value, nameRow)
                      }}
                      noOptionsText={'Ничего не найдено'}
                      loadingText={'Загрузка данных'}
                      getOptionSelected={(
                        option: IKeyValuePair,
                        val: IKeyValuePair,
                      ): boolean => option.key === val.key}
                      getOptionLabel={(option: IKeyValuePair): string =>
                        option.value
                      }
                      options={searchValue}
                      loading={optionsListLoading}
                      renderOption={(
                        option: IKeyValuePair,
                        val: AutocompleteRenderOptionState,
                      ): JSX.Element => (
                        <p
                          data-test-id={`editableModalNameInputListItem_${option.key}`}
                          style={{
                            margin: '0',
                            padding: '0',
                            fontSize: '14px',
                          }}
                          onClick={(): void => {
                            setValueAutocomplete(val.inputValue)
                            handleStringAutocompletePropChanged(
                              val.inputValue,
                              nameRow,
                            )
                          }}
                        >
                          {option.value}
                        </p>
                      )}
                      renderInput={(
                        params: AutocompleteRenderInputParams,
                      ): JSX.Element =>
                        isMultiline ? (
                          <TextField
                            {...params}
                            error={errors[nameRow.key]?.length > 0}
                            helperText={errors[nameRow.key]}
                            variant='outlined'
                            multiline
                            maxRows={maxRows}
                            InputProps={{ ...params.InputProps }}
                            data-test-id='editableModalNameInput'
                          />
                        ) : (
                          <TextField
                            {...params}
                            error={errors[nameRow.key]?.length > 0}
                            helperText={errors[nameRow.key]}
                            variant='outlined'
                            InputProps={{ ...params.InputProps }}
                            data-test-id='editableModalNameInput'
                          />
                        )
                      }
                    />
                  }
                  label={nameRow.title}
                  labelPlacement='top'
                />
              )}
            <div className='propContainer'>{renderBaseProperties}</div>
            <FormControl>
              {files !== null && files.current !== null && (
                <FileUpload
                  initialState={files as React.MutableRefObject<IFileResult>}
                  fileTypes={'jpg,jpeg,png'}
                  fileSize={5}
                  showAddBtn
                  showDeleteBtn
                  dataTestIdPrefix='editableModal'
                />
              )}
            </FormControl>
            {initialState.current.settings.filter(prop => !prop.base).length >
              0 && (
              <>
                <Divider />
                <h3>{getCustomSectionName()}</h3>
                <div className='propContainer'>{renderProperties}</div>
              </>
            )}
            {renderSections()}
          </ModalContent>
          <ModalActions>
            <div className={classes.buttonContainer}>
              <ButtonComponent
                data-test-id='editableModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='editableModalSubmitBtn'
                text={
                  type === EEditableModalType.INSERT ? title.insert : title.edit
                }
                type={EButtonType.PRIMARY}
                disabled={dataIsLoading}
                onClick={debouncedSaveBtnHandler}
              />
            </div>
          </ModalActions>
        </Dialog>
      )}
    </div>
  )
}
