import React, { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import _ from 'lodash'

import { IKeyValuePair } from '../../types'

import { EValidationMessage } from '../../constants'

import { AddToAssignmentModal } from './AddToAssignmentModalPL'
import { useAddToAssignmentModalHooks } from './hooks/useAddToAssignmentModalHooks'
import * as I from './IAddToAssignmentModal'
import { AddToAssignmentInternalModel } from './hooks/useModelState'

const withLogicLayer = (
  Component: typeof AddToAssignmentModal,
): React.FC<I.OwnProps> => ({
  open,
  dataIsLoading,
  onClose,
  onSave,
}): React.ReactElement => {
  const [
    useState,
    useErrors,
    useAssignmentList,
    useAssignmentLotList,
    useDropDownSearch,
    useBooleanState,
    useModalValidation,
  ] = useAddToAssignmentModalHooks()
  const [optionsListLoading, setOptionsListLoading] = useBooleanState()

  const [state, updateState, clearState] = useState({
    assignmentId: '',
    lotId: '',
  })
  const [errors, updateErrors, clearErrors] = useErrors({
    assignmentId: '',
    lotId: '',
  })

  const [assignmentValues, setAssignmentValues] = useAssignmentList(
    setOptionsListLoading,
  )
  const [lotValues, setLotValues, clearLotValues] = useAssignmentLotList(
    setOptionsListLoading,
  )

  const [onSearch$] = useDropDownSearch()

  const [
    assignmentOptionsListOpen,
    setAssignmentOptionsListOpen,
  ] = useBooleanState()
  const [lotOptionsListOpen, setLotOptionsListOpen] = useBooleanState()

  const modalValidation = useModalValidation()

  useEffect(() => {
    const subscriptions: Array<Subscription> = new Array<Subscription>()

    subscriptions.push(
      onSearch$.pipe(debounceTime(400)).subscribe(setAssignmentValues),
    )

    return (): void => {
      if (subscriptions.length) {
        subscriptions.forEach(s => s.unsubscribe())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch$])

  const onSelectedValueChanged = (
    stateKey: keyof AddToAssignmentInternalModel,
  ) => (value: IKeyValuePair | null): void => {
    if (value) {
      const validationResult = modalValidation.validateRequiredFiled(value.key)
      updateErrors({ [stateKey]: validationResult.message })
      updateState({ [stateKey]: value.key })

      if (stateKey === 'assignmentId') {
        setLotValues(value.key)
      }
    } else {
      updateErrors({ [stateKey]: EValidationMessage.REQUIRED_FIELD })
      if (stateKey === 'assignmentId') {
        clearLotValues()
        clearState()
      } else {
        updateState({ [stateKey]: '' })
      }
    }
  }

  const toggleAssignmentOptionsList = (isOpen: boolean): void => {
    if (isOpen) {
      setAssignmentOptionsListOpen(true)
      setLotOptionsListOpen(false)
    } else {
      setAssignmentOptionsListOpen(false)
    }
  }

  const toggleLotOptionsList = (isOpen: boolean): void => {
    if (isOpen) {
      setLotOptionsListOpen(true)
      setAssignmentOptionsListOpen(false)
    } else {
      setLotOptionsListOpen(false)
    }
  }

  const onAssignmentSearchInputChange = (value: string): void => {
    onSearch$.next(value)
  }

  const onSaveHandler = (): void => {
    const validationResults = modalValidation.validateAssignmentModalForm<
      AddToAssignmentInternalModel
    >(state)

    if (_.values(validationResults).some(vr => !vr.valid)) {
      updateErrors(
        _.keys(validationResults).reduce((prev, curr) => {
          prev[curr as keyof AddToAssignmentInternalModel] =
            validationResults[
              curr as keyof AddToAssignmentInternalModel
            ].message
          return prev
        }, {} as { [key in keyof AddToAssignmentInternalModel]: string }),
      )
      return
    }

    onSave(state.lotId)
  }

  const onCloseHandler = (): void => {
    clearErrors()
    onClose()
  }

  return (
    <Component
      open={open}
      dataIsLoading={optionsListLoading || dataIsLoading}
      assignmentOptionsListOpen={assignmentOptionsListOpen}
      lotOptionsListOpen={lotOptionsListOpen}
      assignmentValues={assignmentValues}
      lotValues={lotValues}
      errors={errors}
      onSelectedValueChanged={onSelectedValueChanged}
      toggleAssignmentOptionsList={toggleAssignmentOptionsList}
      toggleLotOptionsList={toggleLotOptionsList}
      onAssignmentSearchInputChange={onAssignmentSearchInputChange}
      onSave={onSaveHandler}
      onClose={onCloseHandler}
    />
  )
}

export default withLogicLayer(AddToAssignmentModal)
