import { connect, ConnectedProps } from 'react-redux'

import {
  addAssignmentLotProperty,
  editAssignmentLotProperty,
  removeAssignmentLotProperty,
  exportAssignmentLot,
  removeAssignmentLotPositions,
  removeAssignmentLotSupplier,
  removeAssignmentLot,
  combineAssignmentLot,
  changeLotAssignment,
  changeLotName,
  movePositions,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  createAgreement,
  getSearchAssignmentFilterItems,
  setTender,
  getSearchOtherPageLotCategory,
  exportAssignmentTemplate,
} from '../../actions'

import { RootState } from '../../store'

import * as I from './assignment-view-search-types'

const mapStateToProps = (state: RootState): I.StateProps => ({
  assignment: state.searchAssignments.responseParams.positionPage,
  searchParams: state.searchAssignments.requestParams,
  categoriesData: state.searchAssignments.responseParams.categoriesData,
  tenderId: state.searchAssignments.requestParams.tenderId,
})

const mapDispatch = {
  addProperty: addAssignmentLotProperty,
  editProperty: editAssignmentLotProperty,
  removeProperty: removeAssignmentLotProperty,
  exportLot: exportAssignmentLot,
  removeLotPositions: removeAssignmentLotPositions,
  removeLotSupplier: removeAssignmentLotSupplier,
  removeLot: removeAssignmentLot,
  combineLot: combineAssignmentLot,
  changeLotAssignment,
  changeLotName,
  movePositions,
  addAssignmentLotSupplier,
  updateAssignmentLotSupplier,
  addAgreement: createAgreement,
  getSearchAssignmentFilterItems,
  setTender,
  getSearchOtherPageLotCategory,
  exportTemplate: exportAssignmentTemplate,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
