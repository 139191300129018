// TODO: все соседние папки про фильтр это заготовки чтобы пихнуть его сюда
// import { EventsLogFilterPanel } from './components/EventsLogFilterPanel/EventsLogFilterPanel'
import React from 'react'

import { Pointer } from '@gmini/ui-kit'

import { useHistory, useLocation } from 'react-router'

import { EventsLogIcon } from '../../icons'

import {
  BackButton,
  EventsLogPageWrapper,
  PageContent,
  PageHeader,
} from './EventsLogPage.styled'
import { EventsList } from './components/EventsList/EventsList'

export const EventsLogPage = () => {
  const location = useLocation()
  const history = useHistory()
  return (
    <EventsLogPageWrapper>
      <PageHeader>
        {location.state ? (
          <BackButton
            onClick={() => {
              history.push(String(location.state))
            }}
          >
            <Pointer />
          </BackButton>
        ) : null}
        <EventsLogIcon />
        <span>Лог событий</span>
      </PageHeader>
      <PageContent>
        {/* TODO: все соседние папки про фильтр это заготовки чтобы пихнуть его сюда  */}
        {/* <EventsLogFilterPanel onChange={handleChange} /> */}
        <EventsList />
      </PageContent>
    </EventsLogPageWrapper>
  )
}
