import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const styles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiAvatar-root': {
      borderRadius: 0,
    },
    '& .MuiBadge-badge': {
      height: '14px',
      width: '14px',
      minWidth: '14px',
      backgroundColor: '#E2E4E9',
      '&:hover': {
        backgroundColor: '#f0f1f4',
      },
    },
    '& .MuiBadge-root': {
      border: '1px solid #E2E4E9;',
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      fontSize: '14px',
      top: 0,
      right: 0,
      transform: 'scale(1)',
      borderRadius: 0,
      padding: '2px',
    },
    '& .MuiGrid-container': {
      alignItems: 'flex-start',
    },
  },
  input: {
    display: 'none',
  },
  listItemContainer: {
    display: 'flex',
    margin: '0 -5px',
  },
  listItem: {
    margin: '0 5px',
    alignSelf: 'center',
  },
}))
