import React from 'react'

export const LogoutIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M17.7061 8.70611C17.3161 8.31614 16.6839 8.31614 16.2939 8.70611C15.9048 9.09522 15.9038 9.72577 16.2917 10.1161L17.17 11H10C9.44771 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H17.17L16.2946 13.8754C15.9051 14.2649 15.9043 14.8966 16.2925 15.2875C16.6826 15.6804 17.3181 15.6819 17.7096 15.2904L20.6464 12.3536C20.8417 12.1583 20.8417 11.8417 20.6464 11.6464L17.7061 8.70611ZM5 5H13C13.5523 5 14 4.55228 14 4C14 3.44772 13.5523 3 13 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H13C13.5523 21 14 20.5523 14 20C14 19.4477 13.5523 19 13 19H5V5Z'
      fill='#353B60'
    />
  </svg>
)
