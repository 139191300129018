import * as _ from 'lodash'
import { useState } from 'react'

export const useErrorState = (initState: {
  [key: string]: string
}): [typeof errors, typeof updateErrors, typeof clearErrors] => {
  const [errors, setErrors] = useState<{ [key: string]: string }>(initState)

  const has = <K extends string>(
    key: K,
    x: Record<string, string>,
  ): x is { [key in K]: string } => key in x

  const updateErrors = (value: Record<string, string>): void => {
    if (
      typeof value !== 'object' ||
      Object.keys(value).some(k => !has(k, errors))
    ) {
      throw new Error(`В объекте присутствуют неизвестные свойства: ${value}`)
    }

    setErrors({ ...errors, ...value })
  }

  const clearErrors = (): void => {
    setErrors(_.mapValues(errors, () => ''))
  }

  return [errors, updateErrors, clearErrors]
}
