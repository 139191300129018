import React from 'react'

import { Spinner } from '../Spinner/Spinner'

import { useStyles } from './Style'

type Props = {
  visible: boolean
}

export const DocumentsFieldLoader: React.FC<Props> = ({ visible }) => {
  const classes = useStyles()

  return visible ? (
    <div className={classes.loaderBackground}>
      <Spinner />
    </div>
  ) : null
}
