import React from 'react'
import { Skeleton } from '@material-ui/lab'

import {
  CatalogButton,
  HeaderAction,
  HeaderActions,
  HeaderInfo,
  PaginationInfo,
  SkeletonTable,
  SkeletonTableHeader,
  SkeletonTableList,
  SkeletonTablePagination,
  SkeletonTableTitle,
  SkeletonTableWrapper,
  TableBreadcrumbs,
  TableTitle,
  TitleWrapper,
} from './Skeleton.styled'
import { TableSkeletonHead, TableSkeletonRow } from './TableSkeletonRow'

export const TableSkeleton = () => (
  <SkeletonTableWrapper>
    <SkeletonTable>
      <SkeletonTableTitle>
        <TitleWrapper>
          <TableTitle width={213} variant='text' />
        </TitleWrapper>
        <TableBreadcrumbs>
          <Skeleton width={50} variant='text' />
          <Skeleton width={85} variant='text' />
        </TableBreadcrumbs>
      </SkeletonTableTitle>
      <SkeletonTableHeader>
        <HeaderInfo>
          <CatalogButton variant='text' />
          <TableTitle width={234} variant='text' />
        </HeaderInfo>
        <HeaderActions>
          <HeaderAction width={169} variant='text' />
          <HeaderAction width={137} variant='text' />
          <HeaderAction width={250} variant='text' />
        </HeaderActions>
      </SkeletonTableHeader>
      <SkeletonTableList>
        <TableSkeletonHead />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
        <TableSkeletonRow />
      </SkeletonTableList>
      <SkeletonTablePagination>
        <PaginationInfo width={129} />
        <CatalogButton
          variant='text'
          style={{
            marginLeft: '7px',
            marginRight: '30px',
            width: '47px',
          }}
        />
        <CatalogButton variant='text' />
        <CatalogButton variant='text' />
        <PaginationInfo width={48} />
        <CatalogButton variant='text' />
        <CatalogButton variant='text' />
      </SkeletonTablePagination>
    </SkeletonTable>
  </SkeletonTableWrapper>
)
