import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  text: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    fontFamily: 'Roboto',
    color: '#4C5ECF',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '15.23px',
    letterSpacing: '0.15px',
  },
  bigGreyText: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    fontWeigth: 400,
    fontSize: '30px',
    lineHeight: '30px',
    color: '#fff',
  },
}))

export default useStyles
