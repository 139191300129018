import styled from 'styled-components'

type ExpandButtonProps = {
  expanded?: boolean
  hover?: boolean
}

export const ExpandButtonWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 12px;
  height: 7px;
  transform: ${(props: ExpandButtonProps) =>
    props.expanded ? 'rotate(90deg)' : 'initial'};
  & path {
    ${(props: ExpandButtonProps) =>
      props.hover ? 'fill: #353B60;' : 'fill: #00003D; opacity: 0.5'};
  }
`
