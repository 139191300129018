import React from 'react'
import { Drawer, IconButton, List, Divider, SvgIcon } from '@material-ui/core'

import { AgreementService } from '../../../../../services/agreements-service'
import { DocumentsField } from '../../../../DocumentsField/DocumentsField'

import { AgreementDetailsSection } from './AgreementDetailsSection/AgreementDetailsSection'
import useStyles from './Style'
import * as I from './IAgreementsDetails'

export const AgreementsDetails: React.FC<I.OwnProps> = ({
  open,
  data,
  toggleOpen,
  onOpenEditAgreementModalClick,
}): React.ReactElement | null => {
  const classes = useStyles()

  return open ? (
    <Drawer
      className={classes.drawerList}
      anchor={'right'}
      open={open}
      onClose={(): void => toggleOpen(false)}
    >
      <div role='presentation'>
        <List className={classes.drawerListHeader}>
          <h5 data-test-id='agreementDetailsDrawerHeading'>{`Детали соглашения №${data.agreementId}`}</h5>
          <IconButton
            data-test-id='agreementDetailsDrawerCloseBtn'
            onClick={(): void => toggleOpen(false)}
            className={classes.drawerListHeaderCloseBtn}
          >
            <SvgIcon viewBox={'0 0 14 13'} style={{ fontSize: '16px' }}>
              <path
                d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
                fill='#535F77'
              />
            </SvgIcon>
          </IconButton>
        </List>
        <List
          className={classes.drawerContent}
          style={{ flexDirection: 'column' }}
        >
          <Divider />
          <div className={classes.cardRowContainer}>
            {data.sectionsAgreementResponses.map((d, idx) => (
              <AgreementDetailsSection
                key={d.sectionId}
                data={d}
                onOpenEditAgreementModalClick={onOpenEditAgreementModalClick}
                dataTestIdPrefix={`${idx}`}
              />
            ))}
            <DocumentsField
              documents={data.attachments}
              services={{
                add: AgreementService.uploadDocument,
                delete: AgreementService.deleteDocument,
              }}
              afterFetch={(): void => toggleOpen(true)}
              id={data.agreementId.toString()}
            />
          </div>
        </List>
      </div>
    </Drawer>
  ) : null
}
