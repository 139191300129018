/* eslint-disable import/no-anonymous-default-export */
import { EActionTypes } from '../../constants'
import { LogEvent, TEventsLog } from '../../types'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type GetLogEventsResponse = {
  data: LogEvent[]
  pagination: { page: number; rowsPerPage: number; total: number }
}

export type IEventsLogResolveGetLogEventsAction = IBaseActionCreator<
  EActionTypes.RESOLVE_GET_LOG_EVENTS,
  GetLogEventsResponse
>
export type IEventsLogRejectGetLogEventsAction = IBaseActionCreator<
  EActionTypes.REJECT_GET_LOG_EVENTS,
  void
>
export type IEventsLogInitGetLogEventsAction = IBaseActionCreator<
  EActionTypes.INIT_GET_LOG_EVENTS,
  void
>

export type EventsLogAction =
  | IEventsLogResolveGetLogEventsAction
  | IEventsLogRejectGetLogEventsAction
  | IEventsLogInitGetLogEventsAction

const initialState: TEventsLog = {
  logEvents: [],
  pending: false,
  total: 0,
}

export default (state = initialState, action: EventsLogAction): TEventsLog => {
  switch (action.type) {
    case EActionTypes.INIT_GET_LOG_EVENTS: {
      return { ...state, pending: true }
    }
    case EActionTypes.REJECT_GET_LOG_EVENTS: {
      return { ...state, pending: false }
    }
    case EActionTypes.RESOLVE_GET_LOG_EVENTS: {
      const {
        data: logEvents,
        pagination: { total },
      } = action.payload
      return { ...state, logEvents, total, pending: false }
    }
    default: {
      return state
    }
  }
}
