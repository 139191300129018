import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  link: {
    color: 'rgba(255, 255, 255, 0.6)',
    fill: 'rgba(255, 255, 255, 0.6)',
    padding: '0 30px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.active': {
      backgroundColor: '#4353B4',
      fill: '#fff',
      color: '#fff',
      pointerEvents: 'none',
    },
    '&:hover': {
      color: '#fff',
      fill: '#fff',
    },
  },

  linkText: {
    marginLeft: '4px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    '-webkit-font-smoothing': 'auto',
  },
}))

export default useStyles
