/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-named-capture-group */
/* eslint-disable require-unicode-regexp */
import { EFilterOperator } from '../../constants'

const numberRegex = RegExp(/^\d+(?:\.\d+)?$/)
const dateRegex = RegExp(/\d{2}(.)\d{2}(.)\d{4}/)

const modalSchemaHandleSubmit = (
  values: any,
  type: string,
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {}

  if (type === EFilterOperator.INTERVAL) {
    if (!values.textField) {
      errors.textField = 'Поле обязательно'
    } else if (
      !numberRegex.test(values.textField) &&
      !dateRegex.test(values.textField)
    ) {
      errors.textField = 'Допустимы только цифры или дата [дд.мм.гггг]'
    } else {
      errors.textField = ''
    }

    if (!values.textFieldSecond) {
      errors.textFieldSecond = 'Поле обязательно'
    } else if (
      !numberRegex.test(values.textFieldSecond) &&
      !dateRegex.test(values.textFieldSecond)
    ) {
      errors.textFieldSecond = 'Допустимы только цифры или дата [дд.мм.гггг]'
    } else {
      errors.textFieldSecond = ''
    }

    if (!values.textAutocomplete.value) {
      errors.textAutocomplete = 'Значение не выбрано'
    } else {
      errors.textAutocomplete = ''
    }

    return errors
  }

  if (!values.textField) {
    errors.textField = 'Поле обязательно'
  } else if (
    !numberRegex.test(values.textField) &&
    !dateRegex.test(values.textField) &&
    (type === EFilterOperator.GT || type === EFilterOperator.LT)
  ) {
    errors.textField = 'Допустимы только цифры или дата [дд.мм.гггг]'
  } else {
    errors.textField = ''
  }
  if (!values.textAutocomplete.value) {
    errors.textAutocomplete = 'Значение не выбрано'
  } else {
    errors.textAutocomplete = ''
  }
  return errors
}

export default modalSchemaHandleSubmit
