/* eslint-disable require-unicode-regexp */
import { EFilterOperator } from '../../constants'

const numberRegex = RegExp(/^\d+(?:\.\d+)?$/)
const dateRegex = RegExp(/\d{2}.\d{2}.\d{4}/)

const modalSchemaHandleChange = (
  name: string,
  value: string,
  errors: { [key: string]: string },
  type: string,
): void => {
  if (type === EFilterOperator.INTERVAL) {
    switch (name) {
      case 'textField':
        if (!value) {
          errors.textField = 'Поле обязательно'
        } else if (!numberRegex.test(value) && !dateRegex.test(value)) {
          errors.textField = 'Допустимы только цифры или дата [дд.мм.гггг]'
        } else {
          errors.textField = ''
        }
        break
      case 'textFieldSecond':
        if (!value) {
          errors.textFieldSecond = 'Поле обязательно'
        } else if (!numberRegex.test(value) && !dateRegex.test(value)) {
          errors.textFieldSecond =
            'Допустимы только цифры или дата [дд.мм.гггг]'
        } else {
          errors.textFieldSecond = ''
        }
        break
      case 'textAutocomplete':
        if (!value) {
          errors.textAutocomplete = 'Значение не выбрано'
        } else {
          errors.textAutocomplete = ''
        }
        break

      default:
        break
    }
  } else {
    switch (name) {
      case 'textField':
        if (!value) {
          errors.textField = 'Поле обязательно'
        } else if (
          !numberRegex.test(value) &&
          !dateRegex.test(value) &&
          (type === EFilterOperator.GT || type === EFilterOperator.LT)
        ) {
          errors.textField = 'Допустимы только цифры или дата [дд.мм.гггг]'
        } else {
          errors.textField = ''
        }
        break
      case 'textAutocomplete':
        if (!value) {
          errors.textAutocomplete = 'Значение не выбрано'
        } else {
          errors.textAutocomplete = ''
        }
        break

      default:
        break
    }
  }
}

export default modalSchemaHandleChange
