import React from 'react'
import _ from 'lodash'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  TextField,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { editIcon } from '../../../../../../constants'
import { formatValue } from '../../../../../../utils'
//Уточнить по составу интерфейса
import { TRowData } from '../../../../../../types'

import * as I from './IAgreementDetailsSection'
import useStyles from './Style'

export const AgreementDetailsSection: React.FC<I.OwnProps> = ({
  data,
  onOpenEditAgreementModalClick,
  dataTestIdPrefix,
}): React.ReactElement => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const classes = useStyles()

  const valuesData: TRowData = []

  data.listView.data.forEach(d => _.extend(valuesData, d.data))

  return (
    <Accordion
      square
      expanded={expanded}
      TransitionProps={{ unmountOnExit: false }}
      classes={{ root: classes.accordionRoot }}
    >
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
        }}
        data-test-id={`agreementDetailsSection_${dataTestIdPrefix}_Accordion`}
      >
        <div className={classes.accordionSummaryHeader}>
          <div className='accordionSummaryHeaderContentLeft'>
            <ExpandMore
              style={{
                transform: `rotate(${expanded ? '0' : '-90'}deg)`,
                color: '#4C5ECF',
              }}
              onClick={(): void => {
                setExpanded(!expanded)
              }}
            ></ExpandMore>
            <Typography
              onClick={(): void => {
                setExpanded(!expanded)
              }}
            >
              {data.sectionName}
            </Typography>
          </div>
          {data.listView.columns && data.listView.columns.length ? (
            <div className={'accordionSummaryHeaderContentRight'}>
              <IconButton
                data-test-id={`agreementDetailsSection_${dataTestIdPrefix}_EditBtn`}
                onClick={(): void => {
                  onOpenEditAgreementModalClick(
                    data.listView.columns,
                    data.listView.data[0],
                    data.sectionName,
                  )
                }}
                className={classes.drawerEditBtn}
              >
                {editIcon}
              </IconButton>
            </div>
          ) : null}
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
        <div className={classes.lot}>
          <div className={classes.cardRowBody}>
            {data.listView.columns.map(c => (
              <TextField
                key={c.key}
                label={c.title}
                multiline={true}
                maxRows={50}
                value={
                  data.listView.data
                    ? formatValue(
                        c.type,
                        valuesData.find(d => d.key === c.key)?.value,
                        '-',
                        c.unit,
                      )
                    : '-'
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
