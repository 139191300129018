import { parse, format } from 'date-fns'

import { TEditModalInitialState, TRowData } from '../../types'
import { EColumnType } from '../../constants'

export const getModalValue = (
  state: TEditModalInitialState,
  fieldName: string,
): unknown => {
  const fieldId = state.settings.find(item => item.title === fieldName)?.key

  if (fieldId) {
    return state.row.data.find(item => item.key === fieldId)?.value
  }
}

export const editSupplierModalValue = (
  valueType: EColumnType,
  data: TRowData,
  key: any,
) => {
  switch (valueType) {
    case EColumnType.BOOLEAN:
      return data?.find(rd => rd.key === key)?.value || 'false'

    case EColumnType.DATE:
      return data.find(p => p.key === key)?.value
        ? format(
            parse(
              data.find(p => p.key === key)?.value || '',
              'dd.MM.yyyy',
              new Date(),
            ),
            'yyyy-MM-dd',
          )
        : ''

    default:
      return data?.find(rd => rd.key === key)?.value || ''
  }
}
