import { Link } from '@material-ui/core'
import React, { useMemo } from 'react'

import { TenderTaskInfo } from '../../types'
import { Accordion } from '../Accordion/Accordion'
import { DumbTable } from '../DumbTable/DumbTable'

import { SUPPLIER_HISTORY_COLUMNS } from './constants'

type Props = {
  data: Array<TenderTaskInfo>
}

export const SupplierHistoryField: React.FC<Props> = ({ data }) => {
  const mappedData = useMemo(
    () =>
      data.map(({ tenderTaskNumber, agreementNumber, ...rest }) => ({
        ...rest,
        id: tenderTaskNumber,
        agreementNumber: agreementNumber ? (
          <Link href={`/agreements/0/${agreementNumber}`}>
            {agreementNumber}
          </Link>
        ) : (
          '-'
        ),
        tenderTaskNumber: (
          <Link href={`/assignments/0/${tenderTaskNumber}`}>
            {tenderTaskNumber}
          </Link>
        ),
      })),
    [data],
  )

  return (
    <Accordion
      dataTestIdPrefix='supplierHistory'
      title='История участия в закупках'
    >
      <DumbTable
        dataTestIdPrefix='supplierHistory'
        columns={SUPPLIER_HISTORY_COLUMNS}
        rows={mappedData}
      />
    </Accordion>
  )
}
