import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiTableCell-head': {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#616284',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      border: '1px solid #E2E4E9',
      backgroundColor: '#F9F9FB',
    },
  },
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    padding: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    background: '#fff',
    '&:nth-child(even)': {
      background: '#fafafa',
    },
    '& a': {
      color: '#000',
      textDecoration: 'none',
    },
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    zIndex: 3,
    boxShadow: '2px 0 3px 0 rgb(0,0,0,.05)',
  },
  actionsCell: {
    padding: '0 8px',
    background: '#fff',
    width: '100px',
  },
  checkBoxCell: {
    width: '100px',
    padding: '0 8px',
  },
  emptyData: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '12px 0',
  },
  resizeHandle: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '12px',
    height: '100%',
    cursor: 'col-resize',
  },
  actionHeadCell: {
    width: '100px',
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    maxHeight: 700,
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))
