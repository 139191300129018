import { connect, ConnectedProps } from 'react-redux'

import {
  createAssignment,
  removeAssignments,
  getAssignments,
  exportAssignmentsByIds,
  addAssignmentProperty,
  editAssignmentProperty,
  removeAssignmentProperty,
  saveCategoriesAssignmetsChanges,
  getCategoriesAssignments,
  addSection,
  editSection,
  removeSection,
} from '../../../actions'

import { RootState } from '../../../store'

import * as I from './assignment-list-types'

const mapStateToProps = (state: RootState): I.StateProps => ({
  assignments: {
    ...state.assignments.data,
    columns: state.assignments.data.columns.map((column: any) => ({
      ...column,
      title:
        column.title.lastIndexOf('[') === -1
          ? column.title
          : column.title.substring(0, column.title.lastIndexOf('[')),
    })),
  },
  categories: state.categories,
  dataIsLoading: state.viewState.dataIsLoading,
})

const mapDispatch = {
  addAssignment: createAssignment,
  deleteAssignments: removeAssignments,
  getAssignments,
  exportAssignmentsByIds,
  addProperty: addAssignmentProperty,
  editProperty: editAssignmentProperty,
  removeProperty: removeAssignmentProperty,
  saveCategoriesChanges: saveCategoriesAssignmetsChanges,
  getCategories: getCategoriesAssignments,
  addSection,
  editSection,
  removeSection,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
