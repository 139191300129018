import styled, { css } from 'styled-components'
import { TextField } from '@material-ui/core'

export const SelectContainer = styled.div`
  position: relative;
`
type OptionProps = {
  hover?: boolean
  disabled?: boolean
  active?: boolean
}
export const Option = styled.div`
  background: transparent;
  ${(props: OptionProps) =>
    props.hover &&
    css`
      background: rgba(53, 59, 96, 0.05);
    `}
  ${(props: OptionProps) =>
    props.active &&
    css`
      background: rgba(92, 130, 229, 0.15);
    `}
  ${(props: OptionProps) =>
    props.active &&
    props.hover &&
    css`
      background: linear-gradient(
          rgba(53, 59, 96, 0.05),
          rgba(53, 59, 96, 0.05)
        ),
        linear-gradient(rgba(92, 130, 229, 0.15), rgba(92, 130, 229, 0.15));
    `}
  padding: 3.5px 0px;
  color: #353b60;
  cursor: ${(props: OptionProps) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  word-break: break-all;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`

type WinnerPositionTextProps = {
  selected?: boolean
}

export const WinnerPositionText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${({ selected }: WinnerPositionTextProps) =>
    selected ? 'rgba(0, 3, 53, 1)' : 'rgba(0, 3, 53, 0.4)'};
  margin-bottom: 6px;
  display: flex;
`
export const PositionInfoText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #a2a3b7;
  padding-right: 8px;
`

type ListItemProps = {
  hover?: boolean
  active?: boolean
}

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props: ListItemProps) =>
    props.hover && 'background: rgba(53, 59, 96, 0.05);'}
  ${(props: ListItemProps) =>
    props.active && 'background: rgba(92, 130, 229, 0.15);'}
  ${(props: ListItemProps) =>
    props.active &&
    props.hover &&
    'background: linear-gradient(rgba(53, 59, 96, 0.05), rgba(53, 59, 96, 0.05)), linear-gradient(rgba(92, 130, 229, 0.15), rgba(92, 130, 229, 0.15));'}
  cursor: default;
`

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
`

export const TooltipText = styled.div`
  width: 100%;
  display: flex;
`

type TextFieldStyledProps = {
  disabled?: boolean
}

export const TextFieldStyled = styled(TextField)`
  background: rgb(244, 244, 248);
  border: 1px solid transparent;
  border-radius: 4px;
  height: 40px;
  justify-content: center;
  transition: background-color 0.2s ease-out 0s, box-shadow 0.2s ease-out 0s,
    border-color 0.2s ease-out 0s;
  && > div {
    padding: 0;
    padding-left: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.1px;
    color: rgb(53, 59, 96);
    font-family: 'Roboto', sans-serif;
    & > fieldset {
      border: none;
    }
  }
  &:focus-within {
    background: #fff;
    box-shadow: rgba(53, 60, 96, 0.1) 0px 5px 10px;
    border: 1px solid rgb(76, 94, 207);
  }
  ${({ disabled }: TextFieldStyledProps) =>
    disabled
      ? css`
          background: rgba(244, 244, 248, 0.5);
          && > div {
            color: rgba(53, 59, 96, 0.2);
          }
        `
      : ''}
`
