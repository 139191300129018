import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  loaderBackground: {
    position: 'absolute',
    background: '#F0F8FF',
    opacity: '0.3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}))
