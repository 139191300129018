import React, { useEffect } from 'react'
import { Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import _ from 'lodash'

import { IKeyValuePair } from '../../types'

import { EValidationMessage } from '../../constants'

import ChangeAssignmentModalPL from './ChangeAssignmentModalPL'
import { useAddToAssignmentModalHooks } from './hooks/useChangeAssignmentModalHooks'
import * as I from './IChangeAssignmentModal'
import { ChangeAssignmentInternalModel } from './hooks/useModelState'

const withLogicLayer = (
  Component: typeof ChangeAssignmentModalPL,
): React.FC<I.OwnProps> => ({
  open,
  dataIsLoading,
  onClose,
  onSave,
}): React.ReactElement => {
  const [
    useState,
    useErrors,
    useAssignmentList,
    useDropDownSearch,
    useBooleanState,
    useModalValidation,
  ] = useAddToAssignmentModalHooks()
  const [optionsListLoading, setOptionsListLoading] = useBooleanState()

  const [state, updateState, clearState] = useState({
    assignmentId: '',
  })
  const [errors, updateErrors, clearErrors] = useErrors({
    assignmentId: '',
  })

  const [assignmentValues, setAssignmentValues] = useAssignmentList(
    setOptionsListLoading,
  )

  const [onSearch$] = useDropDownSearch()

  const [
    assignmentOptionsListOpen,
    setAssignmentOptionsListOpen,
  ] = useBooleanState()

  const modalValidation = useModalValidation()

  useEffect(() => {
    const subscriptions: Array<Subscription> = new Array<Subscription>()

    subscriptions.push(
      onSearch$.pipe(debounceTime(400)).subscribe(setAssignmentValues),
    )

    return (): void => {
      if (subscriptions.length) {
        subscriptions.forEach(s => s.unsubscribe())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearch$])

  const onSelectedValueChanged = React.useCallback(
    (value: IKeyValuePair | null): void => {
      if (value) {
        const validationResult = modalValidation.validateRequiredFiled(
          value.key,
        )
        updateErrors({ assignmentId: validationResult.message })
        updateState({ assignmentId: value.key })
      } else {
        updateErrors({ assignmentId: EValidationMessage.REQUIRED_FIELD })
        clearState()
      }
    },
    [clearState, modalValidation, updateErrors, updateState],
  )

  const toggleAssignmentOptionsList = React.useCallback(
    (isOpen: boolean): void => {
      setAssignmentOptionsListOpen(isOpen)
    },
    [setAssignmentOptionsListOpen],
  )

  const onAssignmentSearchInputChange = React.useCallback(
    (value: string): void => {
      onSearch$.next(value)
    },
    [onSearch$],
  )

  const onSaveHandler = React.useCallback((): void => {
    const validationResults = modalValidation.validateAssignmentModalForm<
      ChangeAssignmentInternalModel
    >(state)

    if (_.values(validationResults).some(vr => !vr.valid)) {
      updateErrors(
        _.keys(validationResults).reduce((prev, curr) => {
          prev[curr as keyof ChangeAssignmentInternalModel] =
            validationResults[
              curr as keyof ChangeAssignmentInternalModel
            ].message
          return prev
        }, {} as { [key in keyof ChangeAssignmentInternalModel]: string }),
      )
      return
    }

    onSave(state.assignmentId)
  }, [modalValidation, onSave, state, updateErrors])

  const onCloseHandler = React.useCallback((): void => {
    clearErrors()
    onClose()
  }, [clearErrors, onClose])

  return (
    <Component
      open={open}
      dataIsLoading={optionsListLoading || dataIsLoading}
      assignmentOptionsListOpen={assignmentOptionsListOpen}
      assignmentValues={assignmentValues}
      errors={errors}
      onSelectedValueChanged={onSelectedValueChanged}
      toggleAssignmentOptionsList={toggleAssignmentOptionsList}
      onAssignmentSearchInputChange={onAssignmentSearchInputChange}
      onSave={onSaveHandler}
      onClose={onCloseHandler}
    />
  )
}

export default withLogicLayer(ChangeAssignmentModalPL)
