import { createAsyncThunk } from '@reduxjs/toolkit'

import { apiClient } from '../../../../services/base-api-service/base-api-service'

import { setDataLoading } from '../../../../actions/RequestWrapper'
// import { storage } from '../../../../actions/BaseAction'

export const copyNodeAction = createAsyncThunk(
  'copyNodeAction',
  // eslint-disable-next-line require-await
  async (id: string | number, { dispatch, rejectWithValue }) => {
    dispatch(setDataLoading(true))

    const [err, res] = await apiClient.post({
      path: `/category/copy/${id}`,

      headers: {
        'Content-Type': 'application/json',
      },
    })

    dispatch(setDataLoading(false))

    if (err) {
      throw rejectWithValue(err)
    }

    return res.data
  },
)
