import React, { useEffect, ReactNode } from 'react'
import * as _ from 'lodash'

import { Grid } from '@material-ui/core'

import { toastr } from 'react-redux-toastr'

import { uuid } from '../../../utils'

import { ButtonComponent } from '../../Button/Button'

import { EButtonType, EButtonIcon } from '../../../constants'

import * as I from './ITableUpload'

import { styles } from './Style'

interface IWrongFile {
  file: File
  message: string
}

const notAvaibleFileTypes: Array<string> = [
  'sh',
  'apk',
  'bat',
  'bin',
  'cgi',
  'cmd',
  'exe',
  'jar',
  'exe',
  'msi',
  'msu',
  'ps1',
  'scr',
  'thm',
  'vb',
  'vbe',
  'vbs',
  'wsf',
]
let avaibleFileTypes: Array<string> = []
let maxFileSize: number
let wrongFiles: Array<IWrongFile> = []

export const TableUpload: React.FC<I.OwnProps> = ({
  hidden = false,
  fileTypes,
  fileSize = 5,
  uploadHandler,
  dataTestIdPrefix,
}): React.ReactElement => {
  let fileInputRef: HTMLInputElement | null = null
  let formRef: HTMLFormElement | null = null

  const classes = styles()

  useEffect(() => {
    if (fileTypes) {
      avaibleFileTypes = fileTypes.split(',')
    }

    if (fileSize) {
      maxFileSize = fileSize * 1049000
    }
  })

  const handleUploadClick = (
    event: React.FormEvent<HTMLInputElement>,
  ): void => {
    try {
      wrongFiles = []

      const { files } = event.target as HTMLInputElement
      if (!files || !files.length) {
        return
      }

      ;[].forEach.call(files, (file: File) => {
        restrictionCheck(file)
      })

      if (wrongFiles.length > 0) {
        const msg: Array<JSX.Element> = []

        const dict = _.chain(wrongFiles)
          .groupBy((wf: IWrongFile) => wf.message)
          .map((value, key) => ({
            message: key,
            files: value.map((wf: IWrongFile) => wf.file.name),
          }))
          .value()

        dict.forEach(d => {
          const renderFiles = (name: string, i: number): JSX.Element => (
            <li key={i}>
              <b>{name}</b>
            </li>
          )
          msg.push(
            <div key={uuid()}>
              {d.message}:
              <br />
              <ul>{d.files.map(renderFiles)}</ul>
            </div>,
          )
        })

        const MessageWrapper: React.FC<{ children?: ReactNode }> = ({
          children,
        }): React.ReactElement => <div>{children}</div>

        toastr.error('', '', {
          component: <MessageWrapper key={uuid()}>{msg}</MessageWrapper>,
          progressBar: false,
          showCloseButton: false,
        })
        toastr.error('', '', {
          component: (
            <MessageWrapper key={uuid()}>
              <b>Не все файлы были загружены!</b>
            </MessageWrapper>
          ),
          progressBar: false,
          showCloseButton: false,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkFileSize = (file: File): boolean =>
    !maxFileSize || file.size < maxFileSize

  const checkIfFileTypeAvaible = (file: File): boolean =>
    (!avaibleFileTypes ||
      avaibleFileTypes.length === 0 ||
      avaibleFileTypes.some((aft: string) =>
        file.name.toLowerCase().endsWith(`.${aft.toLowerCase().trim()}`),
      )) &&
    notAvaibleFileTypes.every(
      (naft: string) =>
        !file.name.toLowerCase().endsWith(`.${naft.toLowerCase().trim()}`),
    )

  const restrictionCheck = (file: File): void => {
    if (!checkFileSize(file)) {
      wrongFiles.push({
        file,
        message: `Размер файла превышает установленный лимит: ${fileSize} МБ`,
      })
    } else if (checkIfFileTypeAvaible(file)) {
      uploadHandler(new File([file], file.name))
      if (formRef) {
        formRef.reset()
      }
    } else {
      const message =
        !avaibleFileTypes || avaibleFileTypes.length === 0
          ? `Нельзя загружать исполняемые типы файлов: ${notAvaibleFileTypes.join(
              ', ',
            )}`
          : `Доступные типы файлов для загрузки: ${avaibleFileTypes.join(', ')}`

      wrongFiles.push({ file, message })
    }
  }

  return (
    <div>
      {!hidden && (
        <Grid container justify='center' alignItems='center'>
          <form
            ref={(form: HTMLFormElement | null): void => {
              formRef = form
            }}
          >
            <input
              ref={(input: HTMLInputElement | null): void => {
                fileInputRef = input
              }}
              className={classes.input}
              type='file'
              onChange={handleUploadClick}
            />
          </form>
          <ButtonComponent
            data-test-id={`${dataTestIdPrefix}ImportBtn`}
            text='Импорт'
            type={EButtonType.DEFAULT}
            typeIcon={EButtonIcon.UPLOAD}
            onClick={(): void => {
              if (fileInputRef) {
                fileInputRef.click()
              }
            }}
          />
        </Grid>
      )}
    </div>
  )
}
