import styled from 'styled-components'
import { IconButton } from '@gmini/ui-kit'

export const EventsLogPageWrapper = styled.div`
  margin: 32px -8px 18px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }
`
export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 1);
  margin-bottom: 29px;
`
export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
export const BackButton = styled(IconButton)`
  && {
    width: 24px;
    height: 24px;
  }
`
