import styled from 'styled-components'

export const LoaderBG = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: #f0f8ff;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`
