import styled from 'styled-components'
import { Skeleton } from '@material-ui/lab'

export const SkeletonWrapper = styled.div`
  margin-top: 36px;
  display: flex;
  margin-right: -34px;
  overflow: hidden;
`

export const CatalogSkeletonWrapper = styled.div`
  width: 256px;
  margin: 25px 26px 0 15px;
  span {
    background: #e6e6ec;
  }
`

export const CatalogButton = styled(Skeleton)`
  width: 24px;
  font-size: 24px;
  line-height: 40px;
`

export const CatalogHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  height: 24px;

  ${CatalogButton} {
    margin-left: 12px;
  }
`

export const CatalogItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 30px;
  ${CatalogButton} {
    margin-right: 8px;
  }
`

export const CatalogTitle = styled(Skeleton)`
  width: 24px;
  font-size: 24px;
  line-height: 40px;
  margin-right: 48px;
`

export const SkeletonTableTitle = styled.div`
  margin-bottom: 16px;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`

export const TableTitle = styled(Skeleton)`
  font-size: 24px;
  line-height: 40px;
`
export const TableBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 12px;
  margin-top: 6px;
`

export const SkeletonTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  height: 24px;
  margin-left: 8px;
`
export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 36px;
`
export const HeaderAction = styled(Skeleton)`
  font-size: 41.97px;
`

export const SkeletonTableList = styled.div`
  margin-bottom: 23px;
`

export const SkeletonHead = styled.div`
  position: relative;
  padding: 0 12px 0 11px;
  display: flex;
  align-items: center;
  gap: 9px;
  background: #f4f4f8;
  opacity: 0.5;
  border-radius: 4px;
  height: 36px;
`

export const HeadSettings = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  padding-right: 12px;
  height: 36px;
  background: linear-gradient(270deg, #f9f9fb 71%, transparent 29%);
`
export const Gradient = styled.div`
  background: linear-gradient(270deg, #f9f9fb 15%, transparent 95%);
  width: 20px;
  height: 36px;
`

export const Divider = styled.div`
  background: #e5e7f1;
  width: 1px;
  height: 24px;
  margin-right: 12px;
`

export const SkeletonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  padding-left: 40px;
  border-bottom: 1px solid #e5e7f1;
  height: 44px;
`

export const PaginationInfo = styled(Skeleton)`
  font-size: 17px;
  line-height: 28.34px;
  margin-left: 4px;
`

export const SkeletonTablePagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;

  ${CatalogButton} {
    width: 32px;
    font-size: 32px;
    line-height: 53.34px;
    margin-left: 4px;
  }
`
export const SkeletonTable = styled.div`
  margin: 25px 0 0 15px;
  width: max-content;
  span {
    background: #e6e6ec;
  }
`

export const SkeletonTableWrapper = styled.div`
  overflow: hidden;
  margin-right: -19px;
`
