import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 24px 24px 0;
  width: 600px;
`

export const Title = styled.div`
  color: #000335;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  && path {
    fill: #353b60;
  }
`

export const UnderlinedText = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

export const PositionsWarning = styled.div`
  padding: 4px 8px;
  background: rgba(217, 87, 94, 0.22);
  border-radius: 4px;
  color: #bf3737;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  width: max-content;
  margin-bottom: 20px;
`

export const InfoText = styled.div`
  max-width: 552px;
  color: #000335;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.1px;
  margin-bottom: 28px;
`

export const SupplierAgreementList = styled.div``

export const ListHead = styled.div`
  padding: 0 12px 6px;
  font-size: 13px;
  line-height: 20px;
  color: rgba(0, 3, 53, 0.6);
  border-bottom: 1px solid #e5e7f1;
  display: flex;
  justify-content: space-between;
`

export const List = styled.div`
  overflow-y: scroll;
  max-height: 200px;
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 10, 87, 0.07);
  }
`

export const ListItem = styled.div`
  padding: 17px 12px 18px;
  color: #353b60;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 1px solid #f4f4f8;
  display: flex;
  justify-content: space-between;
`

export const Agreement = styled.div`
  display: flex;
  gap: 6px;
`

export const NoAgreement = styled.div`
  color: rgba(53, 59, 96, 0.5);
`

export const ButtonContainer = styled.div`
  & button {
    font-size: 14px;
    padding: 8px;
  }
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 0;
`
