import { makeStyles, Theme } from '@material-ui/core/styles'

export const useImprovedHeadStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  checkedRow: {
    padding: 0,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
  },
  checkboxChecked: {},
  header: {
    height: '46px',
    backgroundColor: '#F9F9FB',
    '& .MuiSvgIcon-root': {
      height: '24px',
      cssFloat: 'right',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
    '& .MuiTableSortLabel-root': {
      width: '100%',
    },
  },
  stickyCell: {
    zIndex: 3,
  },
  stickyCheckbox: {
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: 3,
    '& + th': {
      left: '64px',
    },
    '&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox': {
      width: '64px',
    },
  },
  checkboxMenuButton: {
    justifyContent: 'flex-start',
    minWidth: '42px',
  },
  resizeHandle: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(0, -50%)',
    width: '12px',
    height: '20px',
    cursor: 'col-resize',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '2px',
      height: '100%',
      background: 'rgba(224, 224, 224, 1)',
      right: '0',
    },
  },
  wrappedTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

export default useImprovedHeadStyles
