import { connect, ConnectedProps } from 'react-redux'

import {
  createPosition,
  removePosition,
  getPositions,
  modifyPosition,
  exportPositionsByIds,
  exportPassedPositionsByIds,
  exportNotPassedPositionsByIds,
  exportArchivedPositionsByIds,
  exportPositionsFolderLevel,
  exportPositionsFolderLevelPassed,
  exportPositionsFolderLevelNotPassed,
  exportPositionsFolderLevelArchived,
  getCategories,
  saveCategoriesChanges,
  importPositions,
  exportPositionsTemplate,
  addToAssignment,
  addPositionProperty,
  removePositionProperty,
  editPositionProperty,
  resetPosition,
  copyPositions as copyPositionsAction,
} from '../../actions'

import { RootState } from '../../store'

import * as I from './position-types'

const mapStateToProps = (state: RootState): I.StateProps => ({
  positions: {
    ...state.positions.data,
    columns: state.positions.data.columns.map(column => ({
      ...column,
      title:
        column.title.lastIndexOf('[') === -1
          ? column.title
          : column.title.substring(0, column.title.lastIndexOf('[')),
    })),
  },
  dataIsLoading: state.viewState.dataIsLoading,
  user: state.user || null,
})

const mapDispatch = {
  addPosition: createPosition,
  deletePositions: removePosition,
  getPositions,
  editPosition: modifyPosition,
  exportPositions: exportPositionsByIds,
  exportPassedPositions: exportPassedPositionsByIds,
  exportNotPassedPositions: exportNotPassedPositionsByIds,
  exportArchivedPositions: exportArchivedPositionsByIds,
  exportPositionsFolderLevel,
  exportPositionsFolderLevelPassed,
  exportPositionsFolderLevelNotPassed,
  getCategories,
  saveCategoriesChanges,
  importPositions,
  exportPositionsTemplate,
  addToAssignment,
  addProperty: addPositionProperty,
  removeProperty: removePositionProperty,
  editProperty: editPositionProperty,
  resetPosition,
  copyPositions: copyPositionsAction,
  exportPositionsFolderLevelArchived,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
