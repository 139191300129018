import { AxiosResponse } from 'axios'

import { cloneDeep, get } from 'lodash'

import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  AssignmentTab,
  IAPIResult,
  PositionTab,
  TTreeList,
  TTreeModifyDict,
  TContractorsDoc,
} from '../../types'
import { EActionTypes, SPECIALISATION_ITEM_ID } from '../../constants'
import { treeify } from '../../utils'
import { CategoryService } from '../../services/category-service'
import { ApiResponse } from '../../services/base-api-service/base-api-service'

import { actionCreator, IActionCreator } from '../BaseAction'
import { setSpecialisations } from '../Specialisations'

export type GetCategoriesProps = {
  setLoading?: (data: boolean) => void
  tabIndex: number
  filterOptions?: { startDate: string; endDate: string }
  params?: Omit<TContractorsDoc, 'contractName'>
}

export const getCategories = createAsyncThunk<TTreeList, GetCategoriesProps>(
  'estimation/addNewCalculation',
  async (
    { tabIndex = 0, filterOptions, params, setLoading },
    { rejectWithValue, dispatch },
  ) => {
    setLoading?.(true)
    let request: [Error, AxiosResponse<IAPIResult<TTreeList>, any>]
    const prepareContract = { userContract: params }
    const isStartDate = get(params, 'startDate', '')

    if (isStartDate.length) {
      switch (tabIndex) {
        case PositionTab.Archived:
          request = await CategoryService.getCategoriesNotArchive()
          break
        case PositionTab.Passed:
          request = await CategoryService.getCategoriesPassedPost(
            prepareContract,
          )
          break
        case PositionTab.NotPassed:
          request = await CategoryService.getCategoriesNotPassedPost(
            prepareContract,
          )
          break
        default: {
          request = await CategoryService.getCategoriesPost(prepareContract)
          break
        }
      }
    } else {
      switch (tabIndex) {
        case PositionTab.Archived:
          request = await CategoryService.getCategoriesNotArchive()
          break
        case PositionTab.Passed:
          request = await CategoryService.getCategoriesPassed(filterOptions)
          break
        case PositionTab.NotPassed:
          request = await CategoryService.getCategoriesNotPassed()
          break
        default: {
          request = await CategoryService.getCategories()
          break
        }
      }
    }

    const [err, res] = request

    if (err) {
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    const tree = treeify(res.data.data || [])
    setLoading?.(false)
    dispatch(setCategories(tree))
    return tree
  },
)

export const saveCategoriesChanges = createAsyncThunk<
  unknown,
  {
    modifyDict: TTreeModifyDict
    callback: () => void
  }
>(
  'catalog/saveCategoriesChanges',
  async ({ modifyDict, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await CategoryService.modifyCategories(modifyDict)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setCategories(treeify(result.data.data)))
      callback()
    }
  },
)

export function setCategories(
  categories: TTreeList,
): IActionCreator<TTreeList> {
  return actionCreator<TTreeList>(EActionTypes.SET_CATEGORIES, categories)
}

export const getCategoriesSupplier = createAsyncThunk<
  unknown,
  {
    callback: (data: TTreeList) => void
    setLoading?: (data: boolean) => void
  }
>(
  'catalog/getCategoriesSupplier',
  async ({ callback, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading?.(true)
    const [err, result] = await CategoryService.getCategoriesSupplier()

    if (err) {
      callback([])
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setSpecialisations(result.data.data || []))
      const tree = treeify(result.data.data)
      callback(tree)
      setLoading?.(false)
      dispatch(setCategoriesSupplier(tree))
    }
  },
)

export const getCategoriesSupplierActive = createAsyncThunk<
  unknown,
  {
    setLoading?: (data: boolean) => void
    callback: (data: TTreeList) => void
  }
>(
  'catalog/getCategoriesSupplierActive',
  async ({ callback, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading?.(true)
    const [err, result] = await CategoryService.getCategoriesSupplierActive()

    if (err) {
      callback([])
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const tree = treeify(result.data.data)
      callback(tree)
      setLoading?.(false)
      dispatch(setCategoriesSupplier(tree))
    }
  },
)

export const saveCategoriesSupplierChanges = createAsyncThunk<
  unknown,
  {
    dict: TTreeModifyDict
    callback?: () => void
  }
>(
  'catalog/saveCategoriesSupplierChanges',
  async ({ dict, callback }, { rejectWithValue, dispatch }) => {
    const newDict = cloneDeep(dict)

    newDict.add.forEach(item => {
      if (item.parentId === SPECIALISATION_ITEM_ID) {
        item.parentId = null
      }
    })

    newDict.edit.forEach(item => {
      if (item.parentId === SPECIALISATION_ITEM_ID) {
        item.parentId = null
      }
    })

    const [err, result] = await CategoryService.modifyCategoriesSupplier(
      newDict,
    )

    if (err) {
      throw rejectWithValue(err)
    }
    if (result.data.data) {
      dispatch(setCategories(treeify(result.data.data)))
      dispatch(setSpecialisations(result.data.data))
      callback?.()
    }
  },
)

export function setCategoriesSupplier(
  categories: TTreeList,
): IActionCreator<TTreeList> {
  return actionCreator<TTreeList>(
    EActionTypes.SET_CATEGORIES_SUPPLIER,
    categories,
  )
}

export const getCategoriesAgreement = createAsyncThunk<
  unknown,
  {
    setLoading?: (data: boolean) => void
    params?: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }
>(
  'catalog/getCategoriesAgreement',
  async ({ params, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading?.(true)
    const prepareContract = { userContract: params }
    const isStartDate = get(params, 'startDate', '')
    let method: Promise<ApiResponse<IAPIResult<TTreeList>>>

    if (isStartDate.length) {
      method = CategoryService.getCategoriesAgreementPost(prepareContract)
    } else {
      method = CategoryService.getCategoriesAgreement()
    }
    const [err, result] = await method

    if (err) {
      dispatch(setCategories([]))
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const tree = treeify(result.data.data)
      dispatch(setCategories(tree))
      setLoading?.(false)
      dispatch(setCategoriesSupplier(tree))
    }
  },
)

export const getCategoriesAgreementFinished = createAsyncThunk<
  unknown,
  {
    setLoading?: (data: boolean) => void
    params?: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }
>(
  'catalog/getCategoriesAgreementFinished',
  async ({ params, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading?.(true)
    const prepareContract = { userContract: params }
    const isStartDate = get(params, 'startDate', '')
    let method: Promise<ApiResponse<IAPIResult<TTreeList>>>

    if (isStartDate.length) {
      method = CategoryService.getCategoriesAgreementFinishedPost(
        prepareContract,
      )
    } else {
      method = CategoryService.getCategoriesAgreementFinished()
    }
    const [err, result] = await method
    if (err) {
      dispatch(setCategories([]))
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const tree = treeify(result.data.data)
      dispatch(setCategories(tree))
      setLoading?.(false)
      dispatch(setCategories(tree))
    }
  },
)

export const saveCategoriesAgreementChanges = createAsyncThunk<
  unknown,
  {
    modifyDict: TTreeModifyDict
    callback: () => void
  }
>(
  'catalog/saveCategoriesAgreementChanges',
  async ({ modifyDict, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await CategoryService.modifyCategoriesAgreement(
      modifyDict,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setCategories(treeify(result.data.data)))
      callback()
    }
  },
)

export const getCategoriesAssignments = createAsyncThunk<
  unknown,
  {
    setLoading?: (data: boolean) => void
    tabIndex: number
    params?: Omit<TContractorsDoc, 'contractName'> | null | undefined
  }
>(
  'catalog/getCategoriesAssignments',
  async (
    { tabIndex = 0, params, setLoading },
    { rejectWithValue, dispatch },
  ) => {
    setLoading?.(true)
    const prepareContract = { userContract: params }
    const isStartDate = get(params, 'startDate', '')
    let method: Promise<ApiResponse<IAPIResult<TTreeList>>>

    if (isStartDate.length) {
      switch (tabIndex) {
        case AssignmentTab.Closed: {
          method = CategoryService.getCategoriesAssignmentsFinishedPost(
            prepareContract,
          )
          break
        }

        case AssignmentTab.Archived: {
          method = CategoryService.getCategoriesAssignmentsArchived()
          break
        }

        default: {
          method = CategoryService.getCategoriesAssignmentsPost(prepareContract)
          break
        }
      }
    } else {
      switch (tabIndex) {
        case AssignmentTab.Closed: {
          method = CategoryService.getCategoriesAssignmentsFinished()
          break
        }

        case AssignmentTab.Archived: {
          method = CategoryService.getCategoriesAssignmentsArchived()
          break
        }

        default: {
          method = CategoryService.getCategoriesAssignments()
          break
        }
      }
    }

    const [err, result] = await method

    if (err) {
      dispatch(setCategories([]))
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const tree = treeify(result.data.data)
      dispatch(setCategories(tree))
      setLoading?.(false)
      dispatch(setCategoriesSupplier(tree))
    }
  },
)

export const saveCategoriesAssignmetsChanges = createAsyncThunk<
  unknown,
  {
    modifyDict: TTreeModifyDict
    callback: () => void
  }
>(
  'catalog/saveCategoriesAssignmetsChanges',
  async ({ modifyDict, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await CategoryService.modifyCategoriesAssignments(
      modifyDict,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setCategories(treeify(result.data.data)))
      callback()
    }
  },
)
