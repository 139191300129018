import { connect, ConnectedProps } from 'react-redux'

import {
  getAgreementDetail,
  exportPositionsByIds,
  getAgreementCategoryPositions,
} from '../../../actions'

import { RootState } from '../../../store'

import * as I from './agreement-view-types'

const mapStateToProps = (state: RootState): I.StateProps => ({
  agreement: state.viewDetails.agreement,
  positions: state.positions.data,
})

const mapDispatch = {
  getAgreementDetail,
  getAgreementCategoryPositions,
  exportPositions: exportPositionsByIds,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
