import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '645px',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -5px',
    '& button': {
      margin: '0 5px',
    },
  },
  autocomplete: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
    '& .MuiInputBase-input': {
      fontSize: '14px',
      textIndent: '15px',
      display: 'flex',
      alignContent: 'center',
      background: '#F9F9FB',
      borderRadius: '5px',
      height: '53px',
      cursor: 'pointer',
      '&:active, &:focus, &:hover': {
        background: '#f4f6f9',
      },
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd': {
      padding: '0',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd': {
      padding: '0',
    },
    '& .MuiFormHelperText-root': {
      margin: '5px 10px 0',
      background: 'rgba(0,0,0,0)',
    },
    '& .MuiAutocomplete-root': {
      transition: theme.transitions.create('width'),
      color: 'inherit',
    },
    '&.MuiAutocomplete-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '&.Mui-focused': {
      cursor: 'text',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
    '& .MuiFormControl-fullWidth': {
      width: '590px',
      height: '53px',
    },
  },
  hidden: {
    opacity: '0',
    transition: '.5s',
  },
}))
