import React, { ReactNode, MouseEventHandler, Key } from 'react'
import { ToastrState } from 'react-redux-toastr'

import { GridColDef } from '@material-ui/x-grid'

import { TSpecialisationsState } from './reducers/specialisations'
import {
  EAPIResponseStatus,
  EColumnType,
  EButtonType,
  EButtonIcon,
  EFileUploadMainState,
  ETreeListItemType,
} from './constants'

import {
  Authorities,
  Resources,
  Scopes,
} from './services/auth-service/auth-constants'

export interface IAPIResult<T> {
  status: EAPIResponseStatus
  data?: T
  message?: string
  code?: number
}

export type ColumnSettings = {
  key: string | number
  title: string
  hide: boolean
  width: number
  sortOrder: boolean | null
}

export type SuppliersPageColumnSettings = {
  columns: ColumnSettings[]
  agreedSuppliers: boolean
  notAgreedSuppliers: boolean
  expiredSuppliers: boolean
}
export interface IColumn {
  /**
   * Системный идентификатор столбца
   */
  key: any
  /**
   * Альтернативный идентификатор столбца
   */
  id?: any
  /**
   * Заголовок столбца
   */
  title: string
  /**
   * Тип столбца
   */
  type: EColumnType
  /**
   * Обязательный
   */
  required: boolean
  /**
   * Признак того, является ли столбец базовым
   */
  base: boolean
  /**
   * Скрывать столбец из табличного представления
   */
  hidden: boolean
  /**
   * Возможность редактировать столбец в записи
   */
  editable: boolean
  /**
   * Единица измерения id
   */
  measureUnitId: number | null
  /**
   * Единица измерения имя
   */
  measureUnitSymbol: string | null
  /**
   * Единица измерения имя
   */
  unit: string
  /**
   * Раздел
   */
  section?: IKeyValuePair | null
}

export interface IRow {
  /**
   * ID строки
   */
  rowId: string
  /**
   * URL основной фотографии
   */
  photo?: string
  price?: string
  /**
   * Значения по столбцам
   */
  data: TRowData
}

export type ProjectObjects = { project: Project; objects: TObject[] }[]

export interface IAgreementRow extends IRow {
  lot: string
  tenderTask: string
  provider: string
  providerId: string
  number: number
  expire: string
  start: string
  positions: string
  versionDate: string
  projectsAndObjects: ProjectObjects
}

export interface IAssignmentColumn extends IColumn {
  supplierId?: string
  supplierName?: string
  agreement?: IAgreementRow
  hasAgreement?: boolean
  isSupplierPrice?: boolean
  position?: number | null
}

export interface IXGridColumn extends GridColDef {}

export interface IXGridColumnWithId extends IXGridColumn {
  id?: string
}
export interface IAssignmentXGridColumn extends IXGridColumn {
  supplierId?: string
  supplierName?: string
  hasAgreement?: boolean
  agreement?: IAgreementRow
  isSupplierPrice?: boolean
  position?: number | null
}

export interface IKeyValuePair {
  /**
   * Ключ столбца
   */
  key: any
  /**
   * Значение столбца
   */
  value: string
}
export interface SupplierInfo {
  providerId: string
  name: string
  inn: string
  kpp: string
}

export interface IButton {
  text: string
  type: EButtonType
  typeIcon?: EButtonIcon
  hidden?: boolean
  children?: ReactNode
  noMargin?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLElement>
  flagCatalog?: boolean
  className?: string
  tooltip?: string
  htmlType?: 'reset' | 'submit' | 'button'
  formId?: string
}

// VITA REMOVE!!!!
export type TTenderField = {
  inTenderValue: boolean
  notInTenderValue: boolean
  onToggleInTenderCheckbox: VoidFunction
  onToggleNotInTenderCheckbox: VoidFunction
}

export interface IImprovedTableToolbar {
  TitleComponent?: JSX.Element | React.Component<{}, {}, any>
  selectedRowsCount?: number
  showAddBtn: boolean
  showMoveToLotsBtn?: boolean
  showAgreementDetailsInDrawer?: boolean
  showTenderCheckboxes?: boolean
  inTenderValue?: boolean
  notInTenderValue?: boolean
  addBtnText?: string
  dataIsLoading?: boolean
  onAddBtnClick?: any
  onMoveToLotsBtnClick?: any
  isDeepTreeLevel?: boolean
  onGetCheckedCategories?: (ids: string[]) => void
  onSelectNode?: (nodeId: string) => void
  addPosition?: any
  showOnlyOnActive?: boolean
  authRoleChecker: (role: Authorities) => boolean
  dataTestIdPrefix?: string
}

export interface IAssignmentLotTableToolbar {
  lotIndex: number
  search?: boolean
  lot: IAssignmentLot
  supplierColumns: TColumn
  selectedRowsCount?: number
  assignmentId: string
  dataIsLoading?: boolean
  onAddBtnClick: () => void
  onAddBtnPositionsClick: () => void
  onRemovePositionsBtnClick: () => void
  onRemoveLotBtnClick: (callback: () => void) => void
  onExportBtnClick: () => void
  onSendBtnClick?: () => void
  onExportTemplateBtnClick?: () => void
  onCloseLot: () => void
  onChangeLotAssignmentClick: (assignmentId: string) => void
  onEditLotClick: () => void
  onMovePositionsBtnClick: () => void
  toggleCreateDialogModal: () => void
  disableActionButtons?: boolean
  openLotWinnerModal?: () => void
  openAddAgreementModal?: () => void
  onResetSelectedRows: () => void
  assignmentClosed: boolean
}

export interface IImprovedTableBody {
  requiredColumns: TColumn
  isRowChecked: boolean
  nameCatalog?: Record<number, Record<string, string>>
  data: TRow
  onRowSelectingChanged: (
    event: React.MouseEvent<HTMLElement>,
    name: string,
  ) => void
  selectedRows: Array<string>
  visibleColumns: TColumn
  showSections: boolean
  dataTestIdPrefix?: string
}

export interface IFileUploadState {
  mainState: EFileUploadMainState
  files: IFileResult
}

export interface IFile<T> {
  id: string
  file: T
}

export interface IFileResult {
  current: Array<IFile<string>>
  add: Array<IFile<File>>
  remove: Array<string>
}

export interface TabPanelProps {
  children?: React.ReactNode
  index: number
  className?: string
}

export type TStoreInitialState = {
  toastr: ToastrState
  viewState: TViewState
  positions: TPosition
  suppliers: TSupplier
  agreements: TAgreementState
  assignments: TAssignmentState
  viewDetails: TViewDetail
  filter: TFilterFieldsModel
  searchAssignments: TAssignmentSearchModel
  searchOtherPage: TSearchModel
  sentryReduxEnhancer: any
  user: User
  specialisations: TSpecialisationsState
}

export type TViewState = {
  dataIsLoading: boolean
}

export enum LogEventType {
  CREATE_TZ = 'Cоздание ТЗ',
  UPDATE_TZ_STATUS = 'Изменение статуса ТЗ',
  UPDATE_LOT = 'Изменение состава лота',
  ADD_TZ_PRICE = 'Добавление цены в ТЗ от поставщика',
  CREATE_AGREEMENT = 'Заключение соглашения',
  UPDATE_AGREEMENT = 'Изменение соглашения',
  ADD_POSITION = 'Добавление позиции',
  DELETE_POSITION = 'Удаление позиции',
  UPDATE_POSITION = 'Изменение характеристик позиции',
}

export type LogEvent = {
  date: string
  type: LogEventType
  author: string
  text: string
  id: number
}

export type TEventsLog = {
  logEvents: LogEvent[]
  pending: boolean
  total: number
}

export type TPosition = {
  categoryId: string
  data: TTableData
}

export type TRowData = Array<IKeyValuePair>

export type TPositionModel = {
  id: string
  name: string
  comment: string
  photos: Array<IKeyValuePair>
  suppliers: Array<IKeyValuePair>
  assignments: Array<IKeyValuePair>
  agreements: Array<TPositionDrawerKeyValueAgreements>
  columns: TColumn
  data: TRowData
  hash: string
  revitLink: string
}

export type TPositionDrawerKeyValueAgreements = {
  /**
   * Ключ столбца
   */
  key: any
  /**
   * Значение столбца
   */
  value: string
  /**
   * Значение номера столбца
   */
  number: string
}

export type TSuppliersFilters = {
  agreedSuppliers: boolean
  notAgreedSuppliers: boolean
  expiredSuppliers: boolean
}

export type TSupplier = {
  categoryId: string
  data: TTableData
  selectedIds: string[]
  suppliersFilters: TSuppliersFilters
}

export type Attachments = {
  id: Key
  author: string
  docType: string
  fileName: string
  url: string
  sendFile: boolean
}

export type TenderTaskInfo = {
  agreementNumber: number | null
  lotName: string
  tenderTaskName: string
  tenderTaskNumber: number
}

export type TSupplierModel = {
  id: string
  name: string
  comment: string
  address: string
  actualAddress: string
  representative: string
  email: string
  phone: string
  site: string
  data: TRowData
  assignments: Array<IKeyValuePair>
  agreements: Array<TPositionDrawerKeyValueAgreements>
  attachments: Attachments[]
  tenderTasks: Array<TenderTaskInfo>
  inn: string
  kpp: string
  conclusionSB: string
  sbdate: string
}

export interface ILotSupplier {
  /**
   * ID поставщика
   */
  rowId: string
  /**
   * Наиенование поставщика
   */
  name: string
  /**
   * Цена
   */
  price: string
  /**
   * ID позиции, к которой привязан поставщик в рамках лота
   */
  positionId: string
  /**
   * Условия доставки
   */
  deliveryConditions: string
  /**
   * Имеет ли поставщик соглашения
   */
  hasAgreement: boolean
  /**
   * Соглашение
   */
  agreement?: IAgreementRow
  /**
   * Цена позиции и остальные произвольные поля лота
   */
  data: TRowData
  /**
   * Флаг - является ли минимальной ценой
   */
  minPrice: boolean
  position?: number
}

export type TLotSupplier = {
  /**
   * Информация о произволных полях лота (добавляются через шестеренку)
   */
  columns: TColumn
  /**
   * Информация о поставщике + значение произвольных полей лота
   */
  data: TLotSuppliersRowData
}

export interface IAssignmentLot {
  /**
   * ID лота
   */
  id: string
  /**
   * Наименование лота
   */
  name: string
  /**
   * Имеются ли соглашения по лоту
   */
  hasAgreement: boolean
  /*
   * Порядковый номер лота в тендерном задании
   */
  position: number
  /**
   * Позиции лота
   */
  positions: TAssignmentTableData
  /**
   * Поставщики, связанные с лотом
   */
  suppliers: TLotSupplier
  /**
   * Тендерные условия
   */
  tenderConditions: TTenderCondition[]
  /**
   *  Описание
   */
  description: string
  /**
   *  Специализация
   */
  specialization: TTreeList
  /**
   *  Примечание
   */
  note: string
  /**
   *  Статус
   */
  status: ELotStatusDesc
  /**
   *  Соглашения по лоту
   */
  agreements: IAgreementRow[]
}

export type TTenderConditionsValue = {
  providerId: number
  providerName: string
  value: string
}

export type TTenderConditionField = {
  name: string
  fieldType?: string
  id?: number
  isRequired?: boolean
  measureUnit?: string
}

export type TTenderCondition = {
  name: string
  tenderConditionId: number
  serialNumber: number | null
  editable: boolean
  values: TTenderConditionsValue[]
}

export type TTenderConditionSuggestion = Record<string, Array<string>>

export type Project = {
  version: number
  name: string
  createdAt: string
  createdBy: string
  urn: string
  hubName: string
  projectSource: 'GStation' | 'Bim360' | null
}

export type TObject = {
  id: number
  name: string
  archived: boolean
  project: string
  hubName: string
}

export type TProjectsResponse = {
  content: Array<Project>
}

export type TAssignmentModel = {
  /**
   * ID Тендерного задания
   */
  id: string
  /**
   * Наименование тендерного задания
   */
  name: string
  /**
   * Остальные поля тендерного задания
   */
  data: TTableData
  /**
   * Лоты тендерного задания
   */
  lots: TAssignmentLots
  agreements: { agreement: IAgreementRow; projectObjects: ProjectObjects }[]
  status: string
  categoryPath: string
  /**
   * Список прикреплённых файлов
   */
  attachments: Attachments[]
}

export enum EAssignmentStatus {
  NEW = 'Новое',
  SEND_TO_PROVIDERS = 'Отправлено поставщикам',
  CHOSE_PROVIDER = 'Выбор поставщика',
  CLOSED = 'Закрыто',
}

export type TAgreementModel = {
  id: string
  supplier: TTableData
  category: TTreeList
  agreement: TAgreement
}

export type TAgreementModelDTO = {
  id: string
  supplier: TTableData
  category: TTreeList
  agreement: {
    columns: TColumn
    data: Array<IAgreementRow>
  }
}

export type TAgreementState = {
  categoryId: string
  data: TTableData
}

export type TEditModalInitialState = {
  settings: TColumn
  files?: IFileResult
  row: IRow
}

export type TAgreementNext = {
  number: number
  supplier: IKeyValuePair
  lotId: number
  columns: TColumn
}

export type ProviderPosition = {
  itemId: string[]
  position: number
  providerId: string
}

export type TLotPositionWinners = {
  lotId: string
  items: string[]
  providerPosition: ProviderPosition[]
}

export type TLotPositionItemWinners = {
  lotId: string
  itemId: string[]
}

export interface TAgreementCreateRequest {
  providerId: number
  number: number
  dateEnd: string
  dateStart: string
  lotId: number
  data: TRowData
  categoryId: string
  projectObjects: { [projectUrn: string]: { id: number; hubName: string }[] }
}

export interface TAgreementUpdateRequest {
  agreementId: string
  expire: string
  start: string
  data: TRowData
}

export type TAgreementCategoryPositions = TTableData & {
  id: number
}

export type TLotSuppliersRowData = Array<ILotSupplier>
export type TAssignmentLots = Array<IAssignmentLot>
export type TColumn = Array<IColumn>
export type TRow = Array<IRow>
export type TAgreement = {
  columns: TColumn
  data: IAgreementRow
}
export type TPagination = {
  page: number
  rowsPerPage: number
  total: number
}

export type TShortAgreement = {
  tenderTaskNumber: number
  number: string
  startDate: string
  expirationDate: string
}

export type ItemWithAgreements = {
  rowId: number
  data: TRowData[]
}

/**
 * Нужен для получения информации по конкректной позиции
 */
export type TTableData = {
  /**
   * Массив конфигурации столбцов
   */
  columns: TColumn
  /**
   * Массив записей таблицы
   */
  data: TRow
  /**
   * Параметры пагинации
   */
  pagination: TPagination
  /**
   * Путь в каталоге
   */
  categoryPath?: string
  categoryName?: string
  agreements?: Array<TShortAgreement>
  itemWithAgreements?: Array<ItemWithAgreements>
}

export type TCategory = {
  category: IKeyValuePair
}

export type TAssignmentLotPageSuppliers = {
  suppliers: TLotSupplier
}

export type TAssignmentLotPage = TTableData &
  TCategory &
  TAssignmentLotPageSuppliers

export type TAssignmentTableData = Array<
  TTableData & { category: IKeyValuePair }
>
export type TAssignmentState = {
  categoryId: string
  data: TTableData
}
/**
 * Нужен для получения информации по найденным поиском позиций
 */
export type TPositionsSearchData = {
  /**
   * Путь к найденной позиции
   */
  categoryPath: string
  /**
   * Параметры для получения информации по позиции
   */
  itemsFound: TTableData
}

export type TSort = 'asc' | 'desc' | undefined

export enum EDisabledTreeItemActions {
  ALL = 'all',
  RENAME = 'rename',
  DELETE = 'delete',
  MOVE = 'move',
  CONCAT = 'concat',
  ADD_FOLDER = 'addFolder',
  ADD_LIST = 'addList',
  CHECK = 'check',
}

export interface ITreeListItem {
  id: string
  parentId?: string | null
  type: ETreeListItemType
  name: string
  content: Array<ITreeListItem>
  count?: number
  serialNumber?: string
  disabledActions?: Partial<Record<EDisabledTreeItemActions, boolean>>
}

export type TTenderCountDataItem = {
  count: string
  id: string
  name: string
  lots: Array<TTenderCountDataItem>
}

export interface IMoveItem {
  sourceId: string
  destinationId?: string
  beforeId?: string
}

export type TTreeList = Array<ITreeListItem>
export type TMoveItem = Array<IMoveItem>

export type TTreeModifyDict = {
  add: TTreeList
  remove: Array<string>
  edit: TTreeList
  move: TMoveItem
  concat: TMoveItem
}

export type TViewDetail = {
  assignment: TAssignmentModel
  agreement: TAgreementModel
}

export type TFilterFieldType = {
  name: string
  types: Array<string>
}

export type TFilterFieldsModel = {
  agreement: Array<TFilterFieldType>
  items: Array<TFilterFieldType>
  provider: Array<TFilterFieldType>
  tender: Array<TFilterFieldType>
  availableColumns: Array<TAvailableColumn>
}

export type TSearchResultListAssignments = {
  searchResults: Array<TSearchDataAssignments>
}

export type TAvailableColumn = {
  name: string
  type: EColumnType
}

/**
 * Нужен для получения информации по найденным поиском тендерным заданиям
 */
export type TSearchDataAssignments = {
  /**
   * Путь к найденной позиции
   */
  path: string
  /**
   * Параметры для получения информации по позиции
   */
  names: Array<string>
}

/**
 * Нужен для получения информации по найденным поиском поставщикам и соглашениям
 */
export type TSearchDataOtherPage = {
  /**
   * Путь к найденной позиции
   */
  categoryPath: string
  /**
   * Параметры для получения информации по позиции
   */
  itemNames: Array<string>
}

export type TSearchResultListOtherPage = {
  searchResultList: Array<TSearchDataOtherPage>
}

export type TFilterItemsModel = {
  id: string
  keyValue: string
  content: TFilterItems
}

export type TFilterItems = {
  key: string
  singleOperators: Array<TFilterSingleOperators>
}

export type TFilterSingleOperators = {
  filterCompareType?: string
  value: string | number
}

export type TPositionSearchRequestModel = {
  fetchCategories: boolean
  keyword: string | null
  pageData: {
    page: number
    perPage: number
    categoryId: string
  }
  agreementConditions: Array<TFilterItems>
  itemConditions: Array<TFilterItems>
  tenderTaskConditions: Array<TFilterItems>
  providerConditions: Array<TFilterItems>
}

export type TFilterSearchModel = {
  agreementConditions: Array<TFilterItems>
  itemConditions: Array<TFilterItems>
  tenderTaskConditions: Array<TFilterItems>
  providerConditions: Array<TFilterItems>
}

export type TAssignmentSearchRequestModel = {
  fetchTenders: boolean
  keyword: string | null
  tenderId: string | null
  pageData: {
    page: number
    perPage: number
    categoryId: string
  }
  agreementConditions: Array<TFilterItems>
  itemConditions: Array<TFilterItems>
  tenderTaskConditions: Array<TFilterItems>
  providerConditions: Array<TFilterItems>
}

export type TAssignmentSearchResponseDTO = {
  categoriesData: Array<ICategoriesDataItem>
  categoryId: string
  positionPage: TAssignmentModel
}

export type TAssignmentSearchResponseModel = {
  categoriesData: TTreeList
  categoryId: string
  positionPage: TAssignmentModel
}

export interface ICategoriesDataItem extends ITreeListItem {
  tenderCountData: Array<ITenderDataCountItem>
}

export interface ITenderDataCountItem extends ITreeListItem {
  lots: TTreeList
}

export type TAssignmentSearchModel = {
  requestParams: TAssignmentSearchRequestModel
  responseParams: TAssignmentSearchResponseModel
}

export type PageData = {
  page: number
  perPage: number
  categoryId: string
  fieldKey?: number | string
  ascending?: boolean
}

export type TSearchRequest = {
  keyword: string | null
  table: string
  pageData: PageData
  fetchCategories: boolean
  agreementConditions: Array<TFilterItems>
  itemConditions: Array<TFilterItems>
  tenderTaskConditions: Array<TFilterItems>
  providerConditions: Array<TFilterItems>
  passed?: boolean
  active?: boolean
}

export type TSearchRequestPartialPageData = Omit<TSearchRequest, 'pageData'> & {
  pageData: Pick<PageData, 'categoryId'>
}

type SuppliersFiltersExportAll = {
  isAgreed: boolean
  isNotAgreed: boolean
  isExpired: boolean
}

export type PositionAttribute = {
  id: number
  name: string
  required: boolean
  type: EColumnType
  measureUnitId: number | null
  measureUnitSymbol: string
}

export type PositionAttributeMeasureUnit = {
  id: number
  groupId: number
  title: string
  symbol: string
}

export type ExportAllSuppliers = Partial<TSearchRequestPartialPageData> &
  SuppliersFiltersExportAll

export type TPositionsRequest = {
  table: 'items'
  pageData: PageData & {
    inTender?: boolean
    notInTender?: boolean
    sortColumnFieldTitle?: string
  }
  fetchCategories: boolean
  deleted: boolean
  itemConditions?: Array<TFilterItems>
  isActive?: boolean
  userContract: Omit<TContractorsDoc, 'contractName'>
  startDate?: string
  endDate?: string
}

export type TAgreementRequest = {
  table: 'agreements'
  pageData: PageData
  fetchCategories: boolean
  agreementConditions?: Array<TFilterItems>
  isActive?: boolean
  userContract: Omit<TContractorsDoc, 'contractName'>
}

export type TAgreementCategoryRequest = {
  table: 'items'
  pageData: Omit<PageData, 'categoryId'>
  fetchCategories: boolean
  itemConditions?: Array<TFilterItems>
}

export type TAssignmentRequest = {
  table: 'assignments'
  pageData: PageData
  fetchCategories: boolean
  tenderTaskConditions?: Array<TFilterItems>
  userContract: Omit<TContractorsDoc, 'contractName'>
}

export type TAssignmentLotRequest = {
  table: 'items'
  pageData: PageData & { tenderId: number; lotId: number }
  fetchCategories: boolean
  itemConditions?: Array<TFilterItems>
}

export type TExportPriceHistoryRequest = {
  lotId: string
  agreementId: string
  providersIds: string[]
  categories: {
    categoryId: number
    propositionCustomFieldIds: number[]
    columnIds: number[]
  }[]
}

export type TSearchModel = {
  requestParams: TSearchRequest
  responseParams: TSearchResponse
  isSearchRequest?: boolean
}

export type TSearchResponse = {
  categoriesData: TTreeList
  categoryId: string
  positionPage: TTableData
}

export type TTenderSearchPaginationRequestModel = {
  keyword: string | null
  tenderId: number
  fetchTenders: boolean
  pageData: {
    page: number
    perPage: number
    categoryId: number
    lotId: number
    tenderId: number
  }
  agreementConditions: Array<TFilterItems>
  itemConditions: Array<TFilterItems>
  tenderTaskConditions: Array<TFilterItems>
  providerConditions: Array<TFilterItems>
}

export type TPromptPositions = {
  fieldName: string
  fieldValue: string
}

export interface TAuthResponse {
  access: string
  refresh: string
}

export type TRolesAllModel = {
  groups: Array<TRolesGroups>
  roles: Array<TRolesRole>
}

export type TRolesGroups = {
  id: string
  name: string
  authorityViewList: Array<TRolesGroupAuthorityViewList>
}

export type TRolesGroupAuthorityViewList = {
  id: string
  name: string
}

export type TRolesRole = {
  id: string
  description: string
  authorities: Array<string>
}

export type TCreateRoleRequest = {
  name: string
  description: string
  authorityIds: Array<string>
}

export type TEditRoleRequest = {
  id: string
  name: string
  authorityIds: Array<string>
}

export type TAddAuthorityRoleResponse = {
  id: string
  name: string
  description: string
}

export type TAuthoritiesResponse = Array<string>

export interface TRefreshTokenRequest {
  refresh: string
  fingerprint: string
}

export type TContractorsDoc = {
  contractName: string
  endDate: string
  object: string
  project: string
  startDate: string
}

export type TUserPermissions = { [x: string]: Record<Resources, Scopes[]> }

export type User = {
  companyId: string
  email: string
  id: string
  name: string
  phone: string
  roles: { [x: string]: string }
  permissions: TUserPermissions
  hubPermissions: { [x: string]: Record<string, string[]> }
}

export type TGetRolesResponse = {
  list: Array<IKeyValuePair>
}

export interface IAddSectionRequest {
  name: string
  categoryId: string
}

export interface TAssignmentSectionData {
  sectionsTenderRequests: Array<IAssignmentSection>
  tenderId: number
}

export interface IAssignmentSection {
  sectionId: number
  sectionName: string
  listView: TTableData
  export: boolean
}

export interface IAgreementSection {
  sectionId: number
  sectionName: string
  listView: TTableData
}

export interface TAgreementSectionData {
  sectionsAgreementResponses: Array<IAgreementSection>
  agreementId: number
  attachments: Attachments[]
}

export interface TSectionModalInitialState {
  data: TEditModalInitialState
  title: string
}

export interface TChangePasswordRequest {
  passwordCurrent: string
  passwordNew: string
}

export enum EPageType {
  ITEMS = 'items',
  PROVIDER = 'provider',
  AGREEMENT = 'agreement',
  TENDER = 'tender',
}

export enum ELotStatusDesc {
  NEW = 'Новый',
  SENT = 'Отправлен поставщикам',
  ON_CONTEST = 'Определение победителя',
  CLOSED = 'Закрыт',
}

export const ELotStatus: Record<ELotStatusDesc, keyof typeof ELotStatusDesc> = {
  Новый: 'NEW',
  'Отправлен поставщикам': 'SENT',
  'Определение победителя': 'ON_CONTEST',
  Закрыт: 'CLOSED',
}

export type TNameCatalog = Record<number, Record<string, string>>

export type EmptyPricePositions = {
  positions: {
    columns: TColumn
    data: TRow
    pagination: { rowsPerPage: number; page: number; total: number }
  }[]
  itemsInfoWithOutPrices: { agreementNumber: number; supplierId: number }[]
}

export type UploadDocumentParams = {
  id: string
  file: File
  type: string
}

export interface ILotAssigmentAction {
  idAddTo: number
  name: string
  discription: string
  specializationIds: Array<number>
  callback: () => void
}

export type TSetSendFileRequest = {
  id: string | number
  value: boolean
}

export type TImportSuppliersResponse = {
  failed: Array<{
    inn: string
    kpp: string
    problems: Array<string>
  }>
  failedCount: number
  importedCount: number
  totalCount: number
}

export enum PositionTab {
  All = 0,
  Passed = 1,
  NotPassed = 2,
  Archived = 3,
}

export enum SupplierTab {
  All = 0,
  Active = 1,
}

export enum AgreementTab {
  Active = 0,
  Finished = 1,
}

export enum AssignmentTab {
  All = 0,
  Closed = 1,
  Archived = 2,
}
