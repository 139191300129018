import React from 'react'
import { Link } from 'react-router-dom'
import { uniqueId, isNil } from 'lodash'
import moment from 'moment'

import {
  DEFAULT_DISPLAY_DATE_FORMAT,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../../constants'

const getValue = (value: unknown) => {
  if (isNil(value)) {
    return ''
  }

  if (typeof value !== 'string') {
    return ''
  }

  if (
    moment(
      value,
      [
        'YYYY-MM-DDTHH:mm:ss.msZ',
        'YYYY-MM-DDTHH:mm:ssZ',
        'YYYY-MM-DDTHH:mm',
        'YYYY-MM-DDTHH:mm:ss',
        REVERSE_DISPLAY_DATE_FORMAT,
      ],
      true,
    ).isValid()
  ) {
    return moment(value).format(DEFAULT_DISPLAY_DATE_FORMAT)
  }

  if (value.indexOf('.') !== -1) {
    return Intl.NumberFormat('ru-RU', {
      style: 'currency',
      minimumFractionDigits: 2,
      currency: 'RUB',
      currencyDisplay: 'symbol',
    }).format(Number(value))
  }

  return value
}

export const createDumbTableRows = <T extends Record<string, unknown>[]>(
  agreements: T,
) =>
  agreements.map(el => ({
    ...el,
    number: (
      <Link to={`/agreements/0/${el.number}`}>
        {typeof el.number === 'string' && el.number}
      </Link>
    ),
    id: uniqueId(),
    price: getValue(el.price),
    startDate: getValue(el.startDate),
    expirationDate: getValue(el.expirationDate),
  }))

export const getDateValue = (value: string) => {
  if (!moment(value).isValid()) {
    return value
  }

  return moment(new Date(value)).format(DEFAULT_DISPLAY_DATE_FORMAT)
}
