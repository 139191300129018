import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  button: {
    fill: 'rgba(255, 255, 255, 0.6)',
    color: 'rgba(255, 255, 255, 0.6)',
    display: 'flex',
    padding: '0 19px',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '&.active': {
      backgroundColor: '#4353B4',
      fill: '#fff',
      color: '#fff',
    },
    '&:hover': {
      fill: '#fff',
      color: '#fff',
      cursor: 'pointer',
    },
  },
}))

export default useStyles
