import React, { useEffect, useRef, useState } from 'react'

import {
  ArrowDown,
  HiddenCalculationDiv,
  TextItem,
  TextItemWrapper,
  ShowAllButton,
} from './EventLogTextCell.styled'

type EventLogTextCellProps = {
  text: string
  idx: number
}

const parseTextWithLinksRegex = /[^(]+|\(.+?\)\[.+?\]/gmu

export const EventLogTextCell = ({ text, idx }: EventLogTextCellProps) => {
  const [isProjectsExpanded, setIsProjectsExpanded] = useState(false)
  const [expandVisible, setExpandVisible] = useState(false)
  const hiddenCalculationDivRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const cur = hiddenCalculationDivRef.current
    if (!cur || !cur.nextElementSibling) {
      return
    }
    if (cur?.clientHeight > cur?.nextElementSibling?.clientHeight) {
      setExpandVisible(true)
    } else {
      setExpandVisible(false)
    }
  }, [hiddenCalculationDivRef.current?.clientHeight])

  const parsedText = text.match(parseTextWithLinksRegex)?.map(m => {
    if (m[0] === '(') {
      const wordAndLink = m.slice(1, -1).split(')[')
      return (
        <a target='_blank' href={wordAndLink[1]} rel='noreferrer'>
          {wordAndLink[0]}
        </a>
      )
    }
    return <span key={m}>{m}</span>
  })

  return (
    <TextItemWrapper>
      <HiddenCalculationDiv ref={hiddenCalculationDivRef}>
        {parsedText}
      </HiddenCalculationDiv>
      <TextItem expanded={isProjectsExpanded}>{parsedText}</TextItem>
      {expandVisible ? (
        <ShowAllButton
          data-test-id={`showAllLogTextRowBtn_${idx}`}
          onClick={e => {
            e.stopPropagation()
            setIsProjectsExpanded(s => !s)
          }}
        >
          <span>{isProjectsExpanded ? 'Скрыть' : 'Показать все'}</span>
          <ArrowDown expanded={isProjectsExpanded} color='#4c5ecf' />
        </ShowAllButton>
      ) : null}
    </TextItemWrapper>
  )
}
