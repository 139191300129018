import {
  IAPIResult,
  TTableData,
  IRow,
  IColumn,
  TAgreementModelDTO,
  TAgreementUpdateRequest,
  TAgreementCategoryPositions,
  TAgreementNext,
  TAgreementCreateRequest,
  IKeyValuePair,
  TRowData,
  TAgreementSectionData,
  UploadDocumentParams,
  AgreementTab,
  TAgreementRequest,
  TAgreementCategoryRequest,
} from '../types'

import { apiClient, ApiResponse } from './base-api-service/base-api-service'

const headers: Record<string, string> = { 'Content-Type': 'application/json' }

const getAgreementsPaths = {
  [AgreementTab.Active]: '/agreement/page/filter',
  [AgreementTab.Finished]: '/agreement/page/finished/filter',
}

export class AgreementService {
  static getAgreementsNew(
    params: Partial<TAgreementRequest>,
    tab: AgreementTab,
  ): Promise<ApiResponse<IAPIResult<TTableData>>> {
    return apiClient.post({
      path: getAgreementsPaths[tab],
      headers,
      body: params,
    })
  }

  static getAgreementsSelecetAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/agreement/select/actual/${categoryId}`,
      headers,
    })
  }

  static getAgreementsFinishedSelectAll(
    categoryId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/agreement/select/past/${categoryId}`,
      headers,
    })
  }

  static addAgreement(
    agreement: TAgreementCreateRequest,
  ): Promise<ApiResponse<IAPIResult<IRow>>> {
    return apiClient.put({ path: `/agreement`, headers, body: agreement })
  }

  static getAgreementColumns(
    keyCatalogAgreement: string,
  ): Promise<ApiResponse<IAPIResult<TAgreementNext>>> {
    return apiClient.get({
      path: `/agreement/next/${keyCatalogAgreement}`,
      headers,
    })
  }

  static removeAgreements(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.post({ path: `/agreement/delete`, headers, body: ids })
  }

  static addProperty(
    model: IColumn,
    categoryId: string,
    sectionId: number,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    return apiClient.put({
      path: `/category/agreement/field`,
      headers,
      body: {
        categoryId,
        property: model,
        sectionId,
      },
    })
  }

  static editProperty(
    model: IColumn,
  ): Promise<ApiResponse<IAPIResult<IColumn>>> {
    const data = { ...model, sectionId: model.section?.key }
    return apiClient.post({
      path: `/category/agreement/field`,
      headers,
      body: data,
    })
  }

  static removeProperty(key: string): Promise<ApiResponse<IAPIResult<{}>>> {
    return apiClient.delete({
      path: `/category/agreement/field/${key}`,
      headers,
    })
  }

  static exportAgreementsByIds(
    ids: Array<string>,
  ): Promise<ApiResponse<IAPIResult<Blob>>> {
    return apiClient.post({
      path: `/export/agreement/`,
      body: ids,
      responseType: 'blob',
    })
  }

  static getAgreementDetail(
    agreementId: string,
  ): Promise<ApiResponse<IAPIResult<TAgreementModelDTO>>> {
    return apiClient.get({
      path: `/agreement/detail/${agreementId}`,
      headers,
    })
  }

  static getAgreementsDetailSelectAll(
    categoryId: string,
    agreementId: string,
  ): Promise<ApiResponse<IAPIResult<Array<string>>>> {
    return apiClient.post({
      path: `/agreement/detail/select`,
      headers,
      body: {
        categoryId,
        agreementId,
      },
    })
  }

  static updateAgreement(
    agreement: TAgreementUpdateRequest,
  ): Promise<ApiResponse<IAPIResult<TAgreementUpdateRequest>>> {
    return apiClient.post({ path: `/agreement`, headers, body: agreement })
  }

  static getAgreementCategoryPositions(
    agreementId: string,
    categoryId: string,
    params: TAgreementCategoryRequest,
  ): Promise<ApiResponse<IAPIResult<TAgreementCategoryPositions>>> {
    return apiClient.post({
      path: `/agreement/filter/detail/${agreementId}/category/${categoryId}`,
      headers,
      body: params,
    })
  }

  static addSection(
    categoryId: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.put({
      path: `/section/agreement`,
      headers,
      body: {
        name,
        categoryId,
      },
    })
  }

  static editSection(
    id: string,
    name: string,
  ): Promise<ApiResponse<IAPIResult<IKeyValuePair>>> {
    return apiClient.post({
      path: `/section/agreement`,
      headers,
      body: {
        id,
        name,
      },
    })
  }

  static removeSection(id: string): Promise<ApiResponse<IAPIResult<unknown>>> {
    return apiClient.delete({
      path: `/section/agreement/${id}`,
      headers,
    })
  }

  static searchSections(
    categoryId: string,
    query: string,
  ): Promise<ApiResponse<IAPIResult<TRowData>>> {
    return apiClient.post({
      path: `/section/agreement/search/${categoryId}`,
      headers,
      body: {
        query,
      },
    })
  }

  static getAgreementSectionData(
    agreementId: string,
  ): Promise<ApiResponse<IAPIResult<TAgreementSectionData>>> {
    return apiClient.get({
      path: `/section/agreement/${agreementId}`,
      headers,
    })
  }

  static editAgreement(data: IRow): Promise<ApiResponse<IAPIResult<IRow>>> {
    const dataDTN = { ...data, agreementId: data.rowId }
    return apiClient.post({
      path: `/agreement`,
      headers,
      body: {
        ...dataDTN,
      },
    })
  }

  static uploadDocument(
    params: UploadDocumentParams,
  ): Promise<ApiResponse<unknown>> {
    const formData = new FormData()

    Object.keys(params).forEach(key => {
      formData.append(key, params[key as keyof UploadDocumentParams])
    })

    return apiClient.post({
      path: `/attachment/savetoagreement`,
      body: formData,
    })
  }

  static deleteDocument(id: string | number): Promise<ApiResponse<unknown>> {
    return apiClient.delete({ path: `/attachment/delete/${id}` })
  }
}
