import { BehaviorSubject, Subscription } from 'rxjs'

import { EActionTypes } from '../constants'

import { actionCreator, IActionCreator } from './BaseAction'

export const requestSubscriptions: Array<Subscription> = []
export const httpBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  false,
)
export const dataIsLoading: BehaviorSubject<boolean> = new BehaviorSubject<
  boolean
>(false)

export function setDataLoading(isLoading: boolean): IActionCreator<boolean> {
  dataIsLoading.next(isLoading)
  return actionCreator<boolean>(EActionTypes.SET_DATA_LOADING, isLoading)
}
