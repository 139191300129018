import { Button } from '@gmini/ui-kit'
import styled from 'styled-components'

export const ConditionFilterButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  width: 165px;
  height: 36px !important;
  color: #353b60;
  margin-left: 10px;
`
export const ConditionCancelButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  gap: 7px;
  height: 36px !important;
  color: #353b60;
`
export const ConditionButtonText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const FilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 24px;
  color: #000335;
  gap: 24px;
  overflow-y: scroll;
  height: 100%;
`
export const FilterText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
`
