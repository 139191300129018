import { get } from 'lodash'
import { toastr } from 'react-redux-toastr'

import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit'

import { PositionService } from '../../services/position-service'
import { SuppliersService } from '../../services/supplier-service'
import {
  ExportAllSuppliers,
  IAPIResult,
  IColumn,
  IFileResult,
  IKeyValuePair,
  IRow,
  PositionAttribute,
  PositionAttributeMeasureUnit,
  PositionTab,
  TPositionsRequest,
  TRowData,
  TSort,
  TTableData,
} from '../../types'
import { EActionTypes, EAPIResponseStatus } from '../../constants'

import { ApiResponse } from '../../services/base-api-service/base-api-service'

import { downloadFile } from '../../services/base-api-service/utils'

import { actionCreator, IActionCreator } from '../BaseAction'

import { dataIsLoading, setDataLoading } from '../RequestWrapper'
import { setAvailableColumns } from '../Filter'

const START_PAGE_NUMBER = 1

export const getPositionsAllData = createAsyncThunk<
  unknown,
  {
    categoryId: string
    page: number
    allIds: any
    selectedRows: any
    sortColumnKey: number | undefined
    sortColumnDirection: TSort | undefined
    newPage: number
    pagination: any
    callback: () => void
    isCopy?: boolean
    author?: string
    date?: string
    authorKeyToCopy?: number
    dateKeyToCopy?: number
  }
>(
  'position/getPositionsAllData',
  async (
    {
      categoryId,
      page,
      allIds,
      sortColumnKey,
      sortColumnDirection,
      selectedRows,
      isCopy,
      authorKeyToCopy,
      author,
      callback,
      newPage,
      pagination,
      date,
      dateKeyToCopy,
    },
    { rejectWithValue, dispatch },
  ) => {
    const [err, result] = await PositionService.getPositionsOld(
      categoryId,
      page,
      allIds.length,
      sortColumnKey,
      sortColumnDirection,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data) {
      dispatch(setDataLoading(true))

      const columns = get(result.data, 'data.columns')
      const rows = get(result.data, 'data.data')
      const { key } = columns.find(
        (el: { title: string }) => el.title === 'Название[строковый]',
      )
      const newItems = rows
        // eslint-disable-next-line
        .filter((el: any) => selectedRows.includes(el.rowId))
        // eslint-disable-next-line
        .map((el: any) => ({
          ...el,
          // eslint-disable-next-line
          data: el.data.map((item: any) => ({
            ...item,
            value: key === item.key ? `(Копия) ${item.value}` : item.value,
          })),
        }))

      Promise.all(
        newItems.map(async (el: { data: TRowData }) => {
          let intermediateData = el.data
          if (isCopy) {
            intermediateData = el.data.map(element => {
              if (element.key === authorKeyToCopy) {
                return { ...element, value: author || '' }
              }

              if (element.key === dateKeyToCopy) {
                return { ...element, value: date || '' }
              }
              if (element.value === null) {
                return { ...element, value: '' }
              }
              return element
            })
          }
          const prepareData = intermediateData
          const [error, data] = await PositionService.addPosition(
            prepareData,
            categoryId,
          )
          if (error) {
            throw rejectWithValue(error)
          }

          return data
        }),
      ).then(() => {
        dispatch(setDataLoading(false))
        dispatch(
          getPositionsOld({
            categoryId,
            page: newPage === 0 ? 1 : newPage,
            perPage: pagination.rowsPerPage,
            sortColumnKey: undefined,
            sortColumnDirection: undefined,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callback: () => {},
          }),
        )
      })
    }
  },
)

export const getPositionAttributesMeasureUnits = createAsyncThunk<
  Promise<PositionAttributeMeasureUnit[]>,
  {
    groupId?: number
  }
>(
  'position/getPositionAttributesMeasureUnits',
  async ({ groupId }, { rejectWithValue }) => {
    const [
      err,
      result,
    ] = await PositionService.getPositionAttributesMeasureUnits(groupId)

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data.data || []
  },
)

export const getCommonPositionAttributes = createAsyncThunk<
  Promise<PositionAttribute[]>,
  {
    name: string
    passed?: boolean
  }
>(
  'position/getCommonPositionAttributes',
  async ({ name, passed }, { rejectWithValue }) => {
    const [err, result] = await PositionService.getCommonPositionAttributes(
      name,
      passed,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    return result.data.data?.fields || []
  },
)

export const getPositions = createAsyncThunk<
  unknown,
  {
    params: TPositionsRequest
    tab: PositionTab
    setLoading?: (value: boolean) => void
    isNotList?: boolean
  }
>(
  'position/getPositions',
  async (
    { params, tab, setLoading, isNotList },
    { rejectWithValue, dispatch },
  ) => {
    setLoading?.(true)
    const [err, result] = await PositionService.getPositions(params, tab)

    if (err) {
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      localStorage.setItem(
        `positions_${tab}_lastVisitedCategory`,
        params.pageData.categoryId,
      )
      setLoading?.(false)
      const namePos = result.data.data.columns.findIndex(
        item => item.title === 'Название[строковый]',
      )
      if (namePos) {
        const nameHeadCol = result.data.data.columns.splice(namePos, 1)
        result.data.data.columns = [...nameHeadCol, ...result.data.data.columns]
        result.data.data.data.forEach(d => {
          const nameBodyCol = d.data.splice(namePos, 1)
          d.data = [...nameBodyCol, ...d.data]
        })
      }

      if (isNotList) {
        dispatch(setPositions({ ...result.data.data, columns: [] }))
      } else {
        dispatch(setPositions(result.data.data))
      }
    }
  },
)

export const getPositionsOld = createAsyncThunk<
  unknown,
  {
    categoryId: string
    page: number
    perPage: number
    sortColumnKey: number | undefined
    sortColumnDirection: TSort | undefined
    callback: () => void
  }
>(
  'position/getPositionsOld',
  async (
    { categoryId, page, perPage, sortColumnKey, sortColumnDirection, callback },
    { rejectWithValue, dispatch },
  ) => {
    const [err, result] = await PositionService.getPositionsOld(
      categoryId,
      page,
      perPage,
      sortColumnKey,
      sortColumnDirection,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(setPositions(result.data.data))
      dispatch(
        dispatch(
          setAvailableColumns(
            result.data.data.columns.map(column => ({
              name: column.title,
              type: column.type,
            })),
          ),
        ),
      )
      callback()
    }
  },
)

export const createPosition = createAsyncThunk<
  unknown,
  {
    position: TRowData
    categoryId: string
    files: Array<File>
    callback: () => void
  }
>(
  'position/createPosition',
  async (
    { position, files, callback, categoryId },
    { rejectWithValue, dispatch },
  ) => {
    if (!dataIsLoading.getValue()) {
      dispatch(setDataLoading(true))
    }
    const [err, addPositionResult] = await PositionService.addPosition(
      position,
      categoryId,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (
      addPositionResult.data.status === EAPIResponseStatus.SUCCESS &&
      addPositionResult.data.data
    ) {
      const addedPosition = addPositionResult.data.data
      const query: Array<Promise<ApiResponse<IAPIResult<IKeyValuePair>>>> = []

      files.forEach(f =>
        query.push(PositionService.uploadFiles(addedPosition.rowId, f)),
      )
      const result = await Promise.all(
        query.map(async req => {
          const [error, res] = await req

          if (error) {
            throw rejectWithValue(error)
          }
          return res.data.data
        }),
      )

      if (result.length) {
        let photos = ''
        if (result && result.length > 0) {
          photos = result.map(r => r?.value).join('|')
        }

        addedPosition.photo = photos
        dispatch(addPosition(addedPosition))
        callback()
        dispatch(setDataLoading(false))
      } else {
        dispatch(addPosition(addedPosition))
        callback()
        dispatch(setDataLoading(false))
      }
    } else {
      toastr.error(
        '',
        `При отправке запроса произошла ошибка: ${addPositionResult.data}.`,
        {
          progressBar: false,
          showCloseButton: false,
        },
      )
      dispatch(setDataLoading(false))
    }
  },
)

export const modifyPosition = createAsyncThunk<
  unknown,
  {
    position: IRow
    files: IFileResult
    callback: () => void
  }
>(
  'position/modifyPosition',
  ({ files, position, callback }, { rejectWithValue, dispatch }) => {
    const removeFiles = async (
      innerDispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    ): Promise<void> => {
      if (!dataIsLoading.getValue()) {
        innerDispatch(setDataLoading(true))
      }

      const [err] = await PositionService.removeFiles(files.remove)

      if (err) {
        throw rejectWithValue(err)
      }

      if (files.add.length) {
        addFiles(innerDispatch)
        innerDispatch(setDataLoading(false))
      } else {
        updatePosition(innerDispatch)
        innerDispatch(setDataLoading(false))
      }
      callback()
    }

    const addFiles = async (
      innerDispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    ): Promise<void> => {
      if (!dataIsLoading.getValue()) {
        innerDispatch(setDataLoading(true))
      }
      const addQuery: Array<Promise<
        ApiResponse<IAPIResult<IKeyValuePair>>
      >> = []
      files.add.forEach(f =>
        addQuery.push(PositionService.uploadFiles(position.rowId, f.file)),
      )

      const result = await Promise.all(
        addQuery.map(async req => {
          const [err, res] = await req

          if (err) {
            throw rejectWithValue(err)
          }
          return res.data.data
        }),
      )

      if (result.length) {
        result.forEach((f: IKeyValuePair | undefined) => {
          if (!f) {
            return
          }
          if (!files.current.some(c => c.file === f.value)) {
            files.current.push({
              id: f.key || '',
              file: f.value || '',
            })
          }

          files.add.pop()
        })
      }
      updatePosition(innerDispatch)
      innerDispatch(setDataLoading(false))
      callback()
    }

    const updatePosition = async (
      innerDispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    ): Promise<void> => {
      if (!dataIsLoading.getValue()) {
        innerDispatch(setDataLoading(true))
      }
      const [err, editPositionResult] = await PositionService.editPosition(
        position,
      )

      if (err) {
        throw rejectWithValue(err)
      }

      if (
        editPositionResult.data.status === EAPIResponseStatus.SUCCESS &&
        editPositionResult.data.data
      ) {
        const editedPosition = editPositionResult.data.data
        if (files.current.length) {
          editedPosition.photo = files.current.map(r => r.file).join('|')
        }

        innerDispatch(editPosition(editedPosition))
        callback()
        innerDispatch(setDataLoading(false))
      }
    }

    if (files.remove.length) {
      removeFiles(dispatch)
    } else if (files.add.length) {
      addFiles(dispatch)
    } else {
      updatePosition(dispatch)
    }
  },
)

export const removePosition = createAsyncThunk<
  unknown,
  {
    ids: Array<string>
    callback: () => void
  }
>(
  'position/removePosition',
  async ({ ids, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.deletePositions(ids)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const exportPositionsByIds = createAsyncThunk<
  unknown,
  {
    ids: Array<string>
    categoryId?: string
    isdownloadBycategory?: boolean
  }
>(
  'position/exportPositionsByIds',
  async (
    { ids, categoryId, isdownloadBycategory },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportPositionsByIds(
      ids,
      categoryId,
      isdownloadBycategory,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export const exportPassedPositionsByIds = createAsyncThunk<
  unknown,
  {
    ids: Array<string>
    categoryId?: string
    isdownloadBycategory?: boolean
  }
>(
  'position/exportAgreementsByIds',
  async (
    { ids, categoryId, isdownloadBycategory },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportPassedPositionsByIds(
      ids,
      categoryId,
      isdownloadBycategory,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export const exportNotPassedPositionsByIds = createAsyncThunk<
  unknown,
  {
    ids: Array<string>
    categoryId?: string
    isdownloadBycategory?: boolean
  }
>(
  'position/exportNotPassedPositionsByIds',
  async (
    { ids, categoryId, isdownloadBycategory },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportNotPassedPositionsByIds(
      ids,
      categoryId,
      isdownloadBycategory,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export const exportArchivedPositionsByIds = createAsyncThunk<
  unknown,
  {
    ids: Array<string>
    categoryId?: string
    isdownloadBycategory?: boolean
  }
>(
  'position/exportArchivedPositionsByIds',
  async (
    { ids, categoryId, isdownloadBycategory },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportArchivedPositionsByIds(
      ids,
      categoryId,
      isdownloadBycategory,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export const exportPositionsFolderLevel = createAsyncThunk<
  unknown,
  {
    categoryId: string
    isExportOneFile: boolean
    cb: () => void
  }
>(
  'position/exportPositionsFolderLevel',
  async (
    { categoryId, isExportOneFile, cb },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportPositionsFolderLevel(
      categoryId,
      isExportOneFile,
    )

    if (err) {
      throw rejectWithValue(err)
    }
    downloadFile(blob)
    cb()
  },
)

export const exportPositionsFolderLevelPassed = createAsyncThunk<
  unknown,
  {
    categoryId: string
    isExportOneFile: boolean
    cb: () => void
  }
>(
  'position/exportPositionsFolderLevelPassed',
  async (
    { categoryId, isExportOneFile, cb },
    { rejectWithValue, dispatch },
  ) => {
    const [err, blob] = await PositionService.exportPositionsFolderLevelPassed(
      categoryId,
      isExportOneFile,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
    cb()
  },
)

export const exportPositionsFolderLevelNotPassed = createAsyncThunk<
  unknown,
  {
    categoryId: string
    isExportOneFile: boolean
    inTender: boolean
    notInTender: boolean
    cb: () => void
  }
>(
  'position/exportPositionsFolderLevelNotPassed',
  async (
    { categoryId, isExportOneFile, inTender, notInTender, cb },
    { rejectWithValue, dispatch },
  ) => {
    const [
      err,
      blob,
    ] = await PositionService.exportPositionsFolderLevelNotPassed(
      categoryId,
      isExportOneFile,
      inTender,
      notInTender,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
    cb()
  },
)

export const exportPositionsFolderLevelArchived = createAsyncThunk<
  unknown,
  {
    categoryId: string
    isExportOneFile: boolean
    inTender: boolean
    notInTender: boolean
    cb: () => void
  }
>(
  'position/exportPositionsFolderLevelArchived',
  async (
    { categoryId, isExportOneFile, inTender, notInTender, cb },
    { rejectWithValue, dispatch },
  ) => {
    const [
      err,
      blob,
    ] = await PositionService.exportPositionsFolderLevelArchived(
      categoryId,
      isExportOneFile,
      inTender,
      notInTender,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)

    cb()
  },
)

export const exportPositionsTemplate = createAsyncThunk<unknown, string>(
  'position/exportPositionsTemplate',
  async (categoryId, { rejectWithValue, dispatch }) => {
    const [err, blob] = await PositionService.exportPositionsTemplate(
      categoryId,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
    return 0
  },
)

export const importPositions = createAsyncThunk<
  unknown,
  {
    categoryId: string
    file: File
    callback: () => void
  }
>(
  'position/importPositions',
  async ({ categoryId, file, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.importPositions(categoryId, file)

    if (err) {
      toastr.error('', 'При импорте файла произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })
      throw rejectWithValue(err)
    }

    toastr.success('', 'Импорт успешно завершен!')
    callback()
  },
)

export const exportAllSuppliers = createAsyncThunk<unknown, ExportAllSuppliers>(
  'position/exportAllSuppliers',
  async (params, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, blob] = await SuppliersService.exportAllSuppliers(params)

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
    dispatch(setDataLoading(false))
  },
)

export const exportSuppliersByIds = createAsyncThunk<unknown, Array<string>>(
  'position/exportSuppliersByIds',
  async (ids, { rejectWithValue, dispatch }) => {
    const [err, blob] = await SuppliersService.exportSuppliersByIds(ids)

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export function setCategory(categoryId: string): IActionCreator<string> {
  return actionCreator<string>(EActionTypes.SET_CATEGORY, categoryId)
}

export const addExistingPropertyToCategory = createAsyncThunk<
  unknown,
  {
    categoryId: string
    fieldId: string
  }
>(
  'position/addExistingPropertyToCategory',
  async ({ categoryId, fieldId }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.addExistingPropertyToCategory(
      categoryId,
      fieldId,
    )

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const addPositionProperty = createAsyncThunk<
  unknown,
  {
    categoryId: number
    model: IColumn
    callback: () => void
  }
>(
  'position/addPositionProperty',
  async ({ categoryId, model, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.addProperty(categoryId, model)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const editPositionProperty = createAsyncThunk<
  unknown,
  {
    model: IColumn
    callback: () => void
  }
>(
  'position/editPositionProperty',
  async ({ model, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.editProperty(model)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const removePositionProperty = createAsyncThunk<
  unknown,
  {
    key: string
    categoryId: string
    callback: () => void
  }
>(
  'position/removePositionProperty',
  async ({ key, categoryId, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await PositionService.removeProperty(key, categoryId)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export function setPositions(
  positions: TTableData,
): IActionCreator<TTableData> {
  return actionCreator<TTableData>(EActionTypes.SET_POSITIONS, positions)
}

export function addPosition(position: IRow): IActionCreator<IRow> {
  return actionCreator<IRow>(EActionTypes.ADD_POSITION, position)
}

export function editPosition(position: IRow): IActionCreator<IRow> {
  return actionCreator<IRow>(EActionTypes.EDIT_POSITION, position)
}

export const resetPosition = (): IActionCreator<string> =>
  actionCreator<string>(EActionTypes.RESET_POSITIONS, '')

export const copyPositions = createAsyncThunk<
  unknown,
  {
    selectedRows: string[] | number[]
    author: string
    date: string
    authorKeyToCopy: number
    dateKeyToCopy: number
    categoryId: string
  }
>(
  'position/copyPositions',
  async (
    { selectedRows, author, authorKeyToCopy, dateKeyToCopy, date, categoryId },
    { rejectWithValue, dispatch, getState },
  ) => {
    const store = getState()
    const positions = get(store, 'positions')
    const pagination = get(positions, 'data.pagination')

    const [err, res] = await PositionService.getPositionsSelectAll(categoryId)

    if (err) {
      throw rejectWithValue(err)
    }

    if (res.data.data) {
      dispatch(
        getPositionsAllData({
          categoryId,
          page: 1,
          allIds: res.data.data,
          selectedRows,
          sortColumnKey: undefined,
          sortColumnDirection: undefined,
          newPage: START_PAGE_NUMBER,
          pagination: pagination.rowsPerPage,
          callback: () => ({}),
          isCopy: true,
          author,
          date,
          authorKeyToCopy,
          dateKeyToCopy,
        }),
      )
    }
  },
)
