/* eslint-disable require-unicode-regexp */
import _ from 'lodash'
import moment from 'moment'

import { IColumn, IKeyValuePair, TColumn } from '../../types'
import {
  EValidationMessage,
  TValidateResult,
  EColumnType,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../constants'

export type FormValidationResult<T> = {
  [key in keyof T]: TValidateResult
}

export const useModalValidation = (): {
  validateRequiredFiled: typeof validateRequiredFiled
  validateIntegerField: typeof validateIntegerField
  validatePhoneField: typeof validatePhoneField
  validateBooleanField: typeof validateBooleanField
  validateDateField: typeof validateDateField
  validateFloatField: typeof validateFloatField
  validateRequiredSelectedOption: typeof validateRequiredSelectedOption
  validateSelectedOptionInList: typeof validateSelectedOptionInList
  validateAssignmentModalForm: typeof validateAssignmentModalForm
  validateSectionEditableModal: typeof validateSectionEditableModal
} => {
  const validateRequiredFiled = (value: string): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (!`${value}` || !`${value}`.trim()) {
      result.valid = false
      result.message = EValidationMessage.REQUIRED_FIELD
    }

    return result
  }

  const validateRequiredSelectedOption = (
    value: string,
    required = true,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }
    if (required && (!`${value}` || !`${value}`.trim())) {
      result.valid = false
      result.message = EValidationMessage.OPTION_NOT_SELECTED
    }

    return result
  }

  const validateSelectedOptionInList = (
    value: string,
    options: Array<IKeyValuePair>,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }
    if (!`${value}` || !`${value}`.trim() || value === null) {
      result.valid = false
      result.message = EValidationMessage.OPTION_NOT_SELECTED
    } else if (!options || !options.length) {
      result.valid = false
      result.message = EValidationMessage.OPTIONS_EMPTY
    } else if (!options.some(i => i.key === value || i.value === value)) {
      result.valid = false
      result.message = EValidationMessage.UNKNOWN_OPTION
    }

    return result
  }

  const validateIntegerField = (
    value: string,
    required = true,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (required) {
      if (!value || !value.trim()) {
        result.valid = false
        result.message = EValidationMessage.REQUIRED_FIELD
      } else if (isNaN(Number(value)) || Number(value) < 0) {
        result.valid = false
        result.message = EValidationMessage.ONLY_POSITIVE
      }
    } else if (value && (isNaN(Number(value)) || Number(value) < 0)) {
      result.valid = false
      result.message = EValidationMessage.ONLY_POSITIVE
    }

    return result
  }

  const validatePhoneField = (
    value: string,
    required = true,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (required) {
      const starsCnt = (value.match(/[*]/g) || []).length

      if (!value || starsCnt === 10) {
        result.valid = false
        result.message = EValidationMessage.REQUIRED_FIELD
      } else if (starsCnt > 0 && starsCnt < 10) {
        result.valid = false
        result.message = EValidationMessage.INCORRECT_PHONE
      }
    } else if (value) {
      const starsCnt = (value.match(/[*]/g) || []).length

      if (starsCnt > 0 && starsCnt < 10) {
        result.valid = false
        result.message = EValidationMessage.INCORRECT_PHONE
      }
    }

    return result
  }

  const validateBooleanField = (value: string): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (!value || ['true', 'false'].indexOf(value) === -1) {
      result.valid = false
      result.message = EValidationMessage.ONLY_BOOLEAN
    }

    return result
  }

  const validateDateField = (
    value: string,
    required = true,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (required) {
      if (!value) {
        result.valid = false
        result.message = EValidationMessage.REQUIRED_FIELD
      } else if (
        !moment(
          value,
          [
            'YYYY-MM-DDTHH:mm:ss.msZ',
            'YYYY-MM-DDTHH:mm:ssZ',
            'YYYY-MM-DDTHH:mm',
            REVERSE_DISPLAY_DATE_FORMAT,
          ],
          true,
        ).isValid()
      ) {
        result.valid = false
        result.message = EValidationMessage.INCORRECT_DATE
      }
    } else if (
      value &&
      !moment(
        value,
        [
          'YYYY-MM-DDTHH:mm:ss.msZ',
          'YYYY-MM-DDTHH:mm:ssZ',
          'YYYY-MM-DDTHH:mm',
          REVERSE_DISPLAY_DATE_FORMAT,
        ],
        true,
      ).isValid()
    ) {
      result.valid = false
      result.message = EValidationMessage.INCORRECT_DATE
    }

    return result
  }

  const validateFloatField = (
    value: string,
    required = true,
  ): TValidateResult => {
    const result: TValidateResult = {
      valid: true,
      message: EValidationMessage.NONE,
    }

    if (required) {
      if (!value || !value.trim()) {
        result.valid = false
        result.message = EValidationMessage.REQUIRED_FIELD
      } else if (isNaN(parseFloat(value)) || parseFloat(value) < 0) {
        result.valid = false
        result.message = EValidationMessage.ONLY_DECIMAL_OR_INTEGER
      }
    } else if (value && (isNaN(parseFloat(value)) || parseFloat(value) < 0)) {
      result.valid = false
      result.message = EValidationMessage.ONLY_DECIMAL_OR_INTEGER
    }

    return result
  }

  const validateAssignmentModalForm = <T>(state: {
    [key: string]: string
  }): FormValidationResult<T> =>
    _.map(state, (value, key) => ({
      key,
      value,
    })).reduce((prev, curr) => {
      prev[curr.key as keyof T] = validateRequiredSelectedOption(curr.value)
      return prev
    }, {} as FormValidationResult<T>)

  const validateSectionEditableModal = (
    columns: TColumn,
    data: Array<IKeyValuePair>,
  ): FormValidationResult<IKeyValuePair> =>
    data.reduce((prev, curr) => {
      const prop = columns.find(c => c.key === curr.key)

      if (prop) {
        prev[curr.key as keyof IKeyValuePair] = validate(prop, curr.value)
      } else {
        prev[curr.key as keyof IKeyValuePair] = {
          valid: false,
          message: EValidationMessage.UNKNOWN_COLUMN,
        }
      }

      return prev
    }, {} as FormValidationResult<IKeyValuePair>)

  const validate = (prop: IColumn, value: string): TValidateResult => {
    let validationResult: TValidateResult

    switch (prop.type) {
      case EColumnType.STRING: {
        validationResult = prop.required
          ? validateRequiredFiled(value)
          : { valid: true, message: EValidationMessage.NONE }
        break
      }
      case EColumnType.INTEGER: {
        validationResult = validateIntegerField(value, prop.required)
        break
      }
      case EColumnType.PHONE: {
        validationResult = validatePhoneField(value, prop.required)
        break
      }
      case EColumnType.BOOLEAN: {
        validationResult = validateBooleanField(value)
        break
      }
      case EColumnType.DATE: {
        validationResult = validateDateField(value, prop.required)
        break
      }
      case EColumnType.DOUBLE:
      case EColumnType.CURRENCY: {
        validationResult = validateFloatField(value, prop.required)
        break
      }
      default: {
        throw new Error(`Неизвестный тип колонки: ${JSON.stringify(prop)}`)
      }
    }

    return validationResult
  }

  return {
    validateRequiredFiled,
    validateIntegerField,
    validatePhoneField,
    validateBooleanField,
    validateDateField,
    validateFloatField,
    validateRequiredSelectedOption,
    validateSelectedOptionInList,
    validateAssignmentModalForm,
    validateSectionEditableModal,
  }
}
