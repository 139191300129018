import React from 'react'
import { useFormik } from 'formik'
import { Dialog, TextField, FormControlLabel } from '@material-ui/core'

import { isNil } from 'lodash'

import { ModalTitle } from '../../../../components/BaseModalAddContextPositions/ModalTitle/ModalTitle'
import { ButtonComponent } from '../../../../components/Button/Button'
import { EButtonType } from '../../../../constants'

import './styles.scss'

type TEditNoteDialog = {
  note: string
  open: boolean
  handleClose: () => void
  handleOk: (note: string) => void
}

export const EditDialog: React.FC<TEditNoteDialog> = ({
  note,
  handleClose,
  open,
  handleOk,
}) => {
  const formik = useFormik({
    initialValues: {
      note,
    },
    onSubmit: values => handleOk(values.note),
  })

  return (
    <Dialog open={open} onClose={handleClose} className='note-dialog-modal'>
      <ModalTitle
        dataTestIdPrefix='editTenderNoteDialog'
        id='edit-dialog-title'
        onClose={handleClose}
      >
        {isNil(note) ? 'Добавить примечание' : 'Редактировать примечание'}
      </ModalTitle>
      <form className='form' onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='form-field'>
            <FormControlLabel
              label='Примечание'
              control={
                <TextField
                  value={formik.values.note}
                  autoFocus
                  name='note'
                  type='text'
                  variant='filled'
                  fullWidth
                  multiline
                  maxRows={7}
                  onChange={formik.handleChange}
                  inputProps={{
                    'data-test-id': 'editTenderNoteDialogInput',
                  }}
                />
              }
            />
          </div>
        </div>
        <div className='footer'>
          <ButtonComponent
            data-test-id='editTenderNoteDialogCancelBtn'
            htmlType='reset'
            text='Отменить'
            type={EButtonType.DEFAULT}
            onClick={handleClose}
          />
          <ButtonComponent
            data-test-id='editTenderNoteDialogSubmitBtn'
            htmlType='submit'
            text='Сохранить'
            type={EButtonType.PRIMARY}
            disabled={isNil(note) ? !formik.values.note : false}
          />
        </div>
      </form>
    </Dialog>
  )
}
