import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  contentContainer: {
    position: 'relative',
  },
  actionButton: {
    width: '40px',
    height: '40px',
  },
}))
