import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  title: {
    '& .MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}))
