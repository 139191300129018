import { toastr } from 'react-redux-toastr'

import { createAsyncThunk } from '@reduxjs/toolkit'

import _ from 'lodash'

import { SearchService } from '../../../services/search-service/search-service'
import { findTreeNode } from '../../../services/search-service/search-utils'
import {
  TAssignmentSearchRequestModel,
  TAssignmentSearchResponseModel,
  TAssignmentSearchModel,
  TTreeList,
} from '../../../types'
import { EActionTypes, ETreeListItemType } from '../../../constants'
import { treeify } from '../../../utils'

import { actionCreator, IActionCreator } from '../../BaseAction'

export const getSearchAssignmentFilterItems = createAsyncThunk<
  unknown,
  {
    params: TAssignmentSearchRequestModel
    callback: () => void
  }
>(
  'agreement/getSearchAssignmentFilterItems',
  async ({ params, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await SearchService.searchAssignmentsFilterItems(
      params,
    )

    if (err) {
      toastr.error('', 'При получении параметров фильтра произошла ошибка!', {
        progressBar: false,
        showCloseButton: false,
      })

      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const tendersData: { [key: string]: TTreeList } = {}
      const data: TAssignmentSearchResponseModel = {
        categoriesData: [],
        categoryId: result.data.data.categoryId,
        positionPage: result.data.data.positionPage,
      }

      const preparedData = treeify(
        result.data.data?.categoriesData.map(rd => {
          if (rd.type === ETreeListItemType.FOLDER) {
            return {
              id: `r${rd.id}`,
              parentId: rd.parentId ? `r${rd.parentId}` : rd.parentId,
              type: rd.type,
              name: rd.name,
              count: rd.count,
              content: [],
              serialNumber: rd.serialNumber,
            }
          }
          tendersData[`r${rd.id}`] = rd.tenderCountData.map(tcd => ({
            id: `t${tcd.id}`,
            parentId: `r${rd.id}`,
            name: tcd.name,
            type: ETreeListItemType.FOLDER,
            count: tcd.count,
            content: tcd.lots.map(lot => ({
              id: `l${lot.id}`,
              parentId: `t${tcd.id}`,
              name: lot.name || '[Без названия]',
              type: ETreeListItemType.LIST,
              count: lot.count,
              content: [],
            })),
          }))
          return {
            id: `r${rd.id}`,
            parentId: rd.parentId ? `r${rd.parentId}` : rd.parentId,
            type: ETreeListItemType.FOLDER,
            name: rd.name,
            count: rd.count,
            content: [],
            serialNumber: rd.serialNumber,
          }
        }),
      )

      _.keys(tendersData).forEach(key => {
        const elem = findTreeNode(preparedData, key)
        if (elem) {
          elem.content = tendersData[key]
        }
      })
      data.categoriesData = preparedData

      dispatch(
        searchAssignments({
          responseParams: data,
          requestParams: params,
        }),
      )
      callback()
    }
  },
)

export function searchAssignments(
  assignmentsToSearch: TAssignmentSearchModel,
): IActionCreator<TAssignmentSearchModel> {
  return actionCreator<TAssignmentSearchModel>(
    EActionTypes.SEARCH_ASSIGNMENTS,
    assignmentsToSearch,
  )
}

export function setTender(tenderId: string): IActionCreator<string> {
  return actionCreator<string>(EActionTypes.SET_TENDER, tenderId)
}
