import * as _ from 'lodash'

import { findTreeNode } from '../../../../utils'
import { CatalogState } from '../catalog.types'

export const dragAndDropAction = (
  state: CatalogState,
  {
    payload: { sourceId, destinationId, beforeId },
  }: {
    payload: { sourceId: string; destinationId?: string; beforeId?: string }
  },
): CatalogState => {
  const modifyStateDict = _.cloneDeep(state.modifyDict)
  let newSettingsData = _.cloneDeep(state.settingsTreeData)
  const sourceNode = findTreeNode(state.settingsTreeData, sourceId)

  if (!sourceNode) {
    return state
  }

  _.remove(
    modifyStateDict.move,
    i => i.sourceId.toString() === sourceId.toString(),
  )
  modifyStateDict.move.push({
    sourceId,
    destinationId,
    beforeId,
  })
  if (sourceNode.parentId) {
    const parentNode = findTreeNode(newSettingsData, sourceNode.parentId)
    if (parentNode) {
      _.remove(
        parentNode.content,
        i => i.id.toString() === sourceNode?.id.toString(),
      )
    }
  } else {
    _.remove(
      newSettingsData,
      i => i.id.toString() === sourceNode?.id.toString(),
    )
  }

  if (destinationId) {
    const destinationNode = findTreeNode(newSettingsData || [], destinationId)
    if (destinationNode) {
      if (beforeId) {
        const beforeChildIndex = _.findIndex(
          destinationNode.content,
          i => i.id.toString() === beforeId.toString(),
        )
        if (beforeChildIndex !== -1) {
          destinationNode.content = [
            ...destinationNode.content.slice(0, beforeChildIndex),
            { ...sourceNode, parentId: destinationNode.id },
            ...destinationNode.content.slice(beforeChildIndex),
          ]
        }
      } else {
        destinationNode.content.push(sourceNode)
      }
    }
  } else {
    if (beforeId) {
      const beforeChildIndex = _.findIndex(
        newSettingsData,
        i => i.id.toString() === beforeId.toString(),
      )
      if (beforeChildIndex !== -1) {
        newSettingsData = [
          ...newSettingsData.slice(0, beforeChildIndex),
          // eslint-disable-next-line no-undefined
          { ...sourceNode, parentId: undefined },
          ...newSettingsData.slice(beforeChildIndex),
        ]
      } else {
        newSettingsData.push(sourceNode)
      }
    }
  }

  return {
    ...state,
    modifyDict: modifyStateDict,
    settingsTreeData: newSettingsData,
    hasUnsavedChanges: true,
  }
}
