import styled from 'styled-components'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import IconButton from '@material-ui/core/IconButton'

export const DisplayColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 5px 16px;
  color: #535f77;
  font-size: 12px;
  font-weight: bold;
  & .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0);
    font-size: 18px;
  }
`

export const ListItemTextStyled = styled(ListItemText)`
  span {
    font-size: 14px;
  }
`

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 35px;
`
export const IconButtonStyled = styled(IconButton)`
  svg {
    max-height: 20px;
    max-width: 20px;
  }
`
