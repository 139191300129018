import { reducer as toastr } from 'react-redux-toastr'
import { Integrations } from '@sentry/tracing'
import {
  configureStore,
  ThunkAction,
  isRejectedWithValue,
  Action,
} from '@reduxjs/toolkit'

import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import { AnyAction, combineReducers } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import categories from './reducers/categories'
import positions from './reducers/positions'
import user from './reducers/user'
import suppliers from './reducers/suppliers'
import agreements from './reducers/agreements'
import assignments from './reducers/assignments'
import viewState from './reducers/viewState'
import eventsLog from './reducers/eventsLog'
import viewDetails from './reducers/viewDetails'
import filter from './reducers/filter'
import searchAssignments from './reducers/searchAssignments'
import searchOtherPage from './reducers/searchOtherPage'
import specialisations from './reducers/specialisations'
import catalog from './components/PositionCatalog/store/catalog-slice'
import { resolveExceptionError } from './middleware/error/resolveExceptionError'
import { maintenance } from './reducers/maintenance/maintenance.slice'

Sentry.init({
  dsn: window.SPA_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: window.SPA_ENV,
})

// TODO move to application start
const SHOW_VALIDATION_ERRORS = true

const errorInterceptor: ThunkMiddleware = ({ dispatch }) => next => action => {
  if (isRejectedWithValue(action)) {
    const payload = action.payload as Error

    dispatch(
      resolveExceptionError({
        error: payload,
        showValidationErr: SHOW_VALIDATION_ERRORS,
      }),
    )
  }
  return next(action)
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const rootReducer = combineReducers({
  toastr,
  viewState,
  eventsLog,
  categories,
  positions,
  suppliers,
  agreements,
  assignments,
  viewDetails,
  filter,
  searchAssignments,
  searchOtherPage,
  specialisations,
  catalog,
  user,
  maintenance,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: () => {
    const middleware = [thunk, errorInterceptor]

    return middleware
  },
  enhancers: [sentryReduxEnhancer],
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<ThunkAppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>

export type Thunk<ReturnType = Promise<Record<string, unknown>>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export default store
