/* eslint-disable no-undefined */
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthState } from '@gmini/auth'
import { Typography, Tooltip, ClickAwayListener } from '@material-ui/core'

import { Authorities } from '../../../services/auth-service/auth-constants'

import {
  EFormKeys,
  EButtonType,
  //KnowledgeIcon,
} from '../../../constants'
import { DialogModal } from '../../DialogModal/DialogModal'
import { LogoutIcon, SupportIcon, EventsLogIcon } from '../../../icons'

import { useNavbar } from '../../../common/useNavbar'
import { getCurrentEnvModulesData } from '../../../common/getCurrentEnvModulesData'
import { usePermissions } from '../../../hooks/usePermissions'

import { useAppSelector } from '../../../store'

import Link from './Link/LinkPL'
import useStyles, { EventsLogButtonWrapper, StyledIconButton } from './Style'
import { SidebarButton } from './SidebarButton/SidebarButton'
import { UserAvatar } from './UserAvatar/UserAvatar'
import { UserAccountPopup } from './UserAccountPopup/UserAccountPopup'

const emptyNavModules = [
  [{ id: 0, name: '', url: '' }],
  [{ id: 0, name: '', url: '' }],
]

const SidebarPL: React.FC<{
  resetFilters: () => void
  activeTabIndex: {
    positions: number
    suppliers: number
    agreements: number
    assignments: number
  }
}> = ({ resetFilters, activeTabIndex }): React.ReactElement => {
  const { initialized, authClient } = useAuthState()
  const checkPermissions = usePermissions()
  const history = useHistory()
  const userData = useAppSelector(state => state.user)
  const links = window.SPA_LINKS_CONFIG || process.env.SPA_LINKS_CONFIG
  const navModules = links ? getCurrentEnvModulesData(links) : null
  const { Navbar } = useNavbar({
    navModules: navModules || emptyNavModules,
  })
  const classes = useStyles()

  const [openDialogModal, setOpenDialogModal] = useState<boolean>()
  const [openUserAccountPopup, setOpenUserAccountPopup] = useState<boolean>()

  const filterMenuItem = (formKey: EFormKeys): boolean => {
    switch (formKey) {
      case EFormKeys.POSITIONS: {
        return checkPermissions(Authorities.ITEMS_PAGE)
      }
      case EFormKeys.SUPPLIERS: {
        return checkPermissions(Authorities.SUPPLIERS_PAGE)
      }
      case EFormKeys.AGREEMENTS: {
        return (
          checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_ACTIVE) ||
          checkPermissions(Authorities.AGREEMENTS_SHOW_TAB_COMPLETED)
        )
      }
      case EFormKeys.ASSIGNMENTS: {
        return (
          checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_ACTIVE) ||
          checkPermissions(Authorities.ASSIGNMENTS_SHOW_TAB_COMPLETED)
        )
      }
      default: {
        throw new Error(`Неизвестный ключ формы: ${formKey}`)
      }
    }
  }

  const onKeycloakLogoutHandler = (): void => {
    if (initialized && authClient.authenticated) {
      authClient.logout()
    }
  }

  const openHelpSite = React.useCallback(() => {
    window.open('https://gmini.freshdesk.com/a/tickets/new', '_blank')
  }, [])

  const closeSession = useCallback(() => {
    setOpenDialogModal(true)
  }, [])

  const userAccountPopupItems = [
    { text: 'Тех. поддержка', icon: <SupportIcon />, handler: openHelpSite },
    // { text: 'База знаний', icon: <KnowledgeIcon />, handler: () => {} },
    { text: 'Выйти', icon: <LogoutIcon />, handler: closeSession },
  ]

  return (
    <header className={classes.header}>
      {openDialogModal && (
        <DialogModal
          open={openDialogModal}
          handleDiscardChanges={(): void => setOpenDialogModal(false)}
          handleChanges={onKeycloakLogoutHandler}
          modalTitle={'Выход'}
          modalContent={`Вы действительно хотите выйти из сессии?`}
          modalButtonRightText={'Выйти'}
          modalButtonRightType={EButtonType.WARNING}
          modalButtonLeftText={'Отмена'}
          modalButtonLeftType={EButtonType.DEFAULT}
        />
      )}
      <div className={classes.column}>
        <div className={classes.logo}>
          <svg
            width='36'
            height='37'
            viewBox='0 0 36 37'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M18.6189 36.4896C28.2734 36.1634 36 28.234 36 18.5C36 14.9931 34.9971 11.7204 33.2625 8.95308C25.0668 9.6039 18.6189 16.4609 18.6189 24.8239V36.4896ZM18.1208 36.4996H17.8792C17.9194 36.4999 17.9597 36.5 18 36.5C18.0403 36.5 18.0806 36.4999 18.1208 36.4996ZM15.4346 36.3186C6.7086 35.0734 0 27.5701 0 18.5C0 17.7607 0.0445745 17.0317 0.131183 16.3158C6.17391 15.6697 12.2884 18.5971 15.4445 24.2026C15.4431 24.2453 15.4419 24.288 15.4408 24.3308C15.4367 24.4947 15.4346 24.6591 15.4346 24.8239V36.3186ZM0.834544 13.0667C6.59686 12.7745 12.3039 15.1504 16.1411 19.6582C18.083 12.7275 23.8355 7.3894 30.9964 6.0463C27.7201 2.62817 23.1086 0.5 18 0.5C9.95251 0.5 3.13848 5.7811 0.834544 13.0667Z'
              fill='white'
            />
          </svg>
          <Typography data-test-id='appTitle' className={classes.logoName}>
            Гранд-тендеры
          </Typography>
          {Navbar}
        </div>
        {Object.values(EFormKeys)
          .filter(filterMenuItem)
          .map(formKey => (
            <Link
              data-test-id={`${formKey}TabBtn`}
              key={formKey}
              formKey={formKey}
              currentTab={activeTabIndex[formKey]}
              resetFilters={resetFilters}
            />
          ))}
      </div>
      <div className={classes.column}>
        <EventsLogButtonWrapper
          active={history.location.pathname.includes('/eventslog')}
        >
          <Tooltip data-test-id='eventsLogPageBtn' title='Лог событий'>
            <StyledIconButton
              onClick={() => {
                history.push('/eventslog', history.location.pathname)
              }}
            >
              <EventsLogIcon color={'rgba(255, 255, 255, 0.5)'} />
            </StyledIconButton>
          </Tooltip>
        </EventsLogButtonWrapper>
        <div className={classes.divider} />
        <ClickAwayListener onClickAway={() => setOpenUserAccountPopup(false)}>
          <div className={classes.userAccountWrapper}>
            <SidebarButton
              data-test-id='userAccountPopupBtn'
              onClick={() => setOpenUserAccountPopup(!openUserAccountPopup)}
              active={openUserAccountPopup}
            >
              <UserAvatar fullname={userData?.name || ''} />
            </SidebarButton>
            {openUserAccountPopup && userData ? (
              <UserAccountPopup
                items={userAccountPopupItems}
                userData={userData}
              />
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    </header>
  )
}

export default SidebarPL
