import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 auto',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '535px',
      height: '347px',
    },
  },
  title: {
    width: '635px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '25px,',
    lineHeight: '29px',
    color: '#1B2B4B',
  },
  paper: {
    margin: '32px',
    position: 'relative',
    overflow: 'hidden',
  },
  label: {
    paddingTop: '12px',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 'bold',
  },
}))
