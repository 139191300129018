import React, { useCallback, useState, useMemo } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'

import { TextField } from '../TextField/TextField'
import { DropZone } from '../DropZone/DropZone'

import { validate } from './utils'
import { AddDocumentData } from './DocumentsField'

import { ADD_DOCUMENT_FORM_ID } from './constants'

type DocumentsFieldProps = {
  onSubmit?: (data: AddDocumentData) => void
  showCheckBox?: boolean
}

export const DocumentAddForm: React.FC<DocumentsFieldProps> = ({
  onSubmit,
  showCheckBox,
}): React.ReactElement => {
  const [showErrors, setShowErrors] = useState(false)
  const [sendFile, setSendFile] = useState(false)
  const [type, setType] = useState('')
  const [file, setFile] = useState<File[]>([])
  const errors = useMemo(() => validate({ type, file: file[0] }), [type, file])

  const setTypeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setType(event.target.value.trimStart())
    },
    [],
  )
  const setSendFileHandler = useCallback(
    (_, checked: boolean) => setSendFile(checked),
    [],
  )
  const setFileHandler = useCallback((value: File[]) => {
    setFile(value)
  }, [])

  const onSubmitHandler = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      if (Object.values(errors).some(error => !!error)) {
        setShowErrors(true)
        return
      }
      if (onSubmit) {
        const params: AddDocumentData = {
          type,
          file: file[0],
        }
        if (showCheckBox) {
          params.sendFile = sendFile
        }

        onSubmit(params)
      }
    },
    [errors, onSubmit, type, file, showCheckBox, sendFile],
  )

  return (
    <form onSubmit={onSubmitHandler} id={ADD_DOCUMENT_FORM_ID}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={showErrors && errors.type}
            label='Вид документа'
            value={type}
            onChange={setTypeHandler}
            maxLength={100}
            inputProps={{
              'data-test-id': 'documentsAddFormDocTypeInput',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropZone
            error={showErrors && errors.file}
            value={file}
            label='Документ'
            accept={['.pdf', '.xlsx', '.xls', '.doc', '.docx']}
            onChange={setFileHandler}
          />
        </Grid>
        {showCheckBox && (
          <Grid item xs={12}>
            <FormControlLabel
              className={'authCheckbox'}
              control={
                <Checkbox
                  data-test-id='documentsAddFormAddToMailerCheckbox'
                  checked={sendFile}
                  onChange={setSendFileHandler}
                />
              }
              label='Добавить в рассылку'
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}
