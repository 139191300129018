import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const HashCodeIcon = ({
  color = 'rgba(53, 59, 96, 0.5)',
  ...props
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='24' height='24' fill='transparent' />
    <path
      d='M7.36544 20.075C6.71486 20.075 6.23751 19.4636 6.39529 18.8325L7.08466 16.075H4.68036C4.02753 16.075 3.54976 15.4596 3.71156 14.8272L3.74992 14.6772C3.86314 14.2346 4.26189 13.925 4.71872 13.925H7.63466L8.58466 10.075H5.68036C5.02753 10.075 4.54976 9.45962 4.71156 8.82716L4.74992 8.67721C4.86314 8.23463 5.26189 7.92505 5.71872 7.92505H9.13466L9.94529 4.68251C10.0566 4.23735 10.4566 3.92505 10.9154 3.92505H11.0192C11.6698 3.92505 12.1472 4.53644 11.9894 5.16758L11.3 7.92505H14.75L15.5606 4.68251C15.6719 4.23735 16.0719 3.92505 16.5308 3.92505H16.6346C17.2852 3.92505 17.7625 4.53644 17.6047 5.16758L16.9154 7.92505H19.3197C19.9725 7.92505 20.4503 8.54042 20.2885 9.17289L20.2501 9.32284C20.1369 9.76542 19.7381 10.075 19.2813 10.075H16.3654L15.4154 13.925H18.3197C18.9725 13.925 19.4503 14.5404 19.2885 15.1729L19.2501 15.3228C19.1369 15.7654 18.7381 16.075 18.2813 16.075H14.8654L14.0547 19.3175C13.9434 19.7627 13.5435 20.075 13.0846 20.075H12.9808C12.3302 20.075 11.8529 19.4636 12.0106 18.8325L12.7 16.075H9.25001L8.43937 19.3175C8.32808 19.7627 7.9281 20.075 7.46923 20.075H7.36544ZM9.80001 13.925H13.25L14.2 10.075H10.75L9.80001 13.925Z'
      fill={color}
    />
  </svg>
)
