import { createAsyncThunk } from '@reduxjs/toolkit'

import { AuthService } from '../../services/auth-service/auth-service'
import { User } from '../../types'

import { EActionTypes } from '../../constants'

import { actionCreator } from '../BaseAction'

export const getCurrentUser = createAsyncThunk<unknown, void>(
  'user/getCurrentUser',
  async (_, { rejectWithValue, dispatch }) => {
    const [err, res] = await AuthService.getCurrentUser()

    if (err) {
      throw rejectWithValue(err)
    }

    if (res.data.data) {
      dispatch(actionCreator<User>(EActionTypes.SET_USER_DATA, res.data.data))
    }
  },
)
