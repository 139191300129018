/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash'

import {
  TAssignmentLotPage,
  TAssignmentSearchModel,
  TSearchRequest,
} from '../../types'
import { EActionTypes } from '../../constants'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type IFilterFields = IBaseActionCreator<
  EActionTypes.SEARCH_ASSIGNMENTS,
  TAssignmentSearchModel
>

export type ISetAssignemntsTenderAction = IBaseActionCreator<
  EActionTypes.SET_TENDER,
  string
>

export type IChangeAssignmentLotCategoryPageAction = IBaseActionCreator<
  EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE,
  {
    categoryId: number
    lotId: number
    lotData: TAssignmentLotPage
  }
>

export type ISeеCategoryAssignmentsAction = IBaseActionCreator<
  EActionTypes.SET_CATEGORY_ASSIGNMENTS,
  string
>

export type ISetSearchParamsAssignmentsAction = IBaseActionCreator<
  EActionTypes.SET_SEARCH_PARAMS_ASSIGNMENTS,
  TSearchRequest['pageData']
>

export type TSearchAction =
  | IFilterFields
  | ISetAssignemntsTenderAction
  | IChangeAssignmentLotCategoryPageAction
  | ISeеCategoryAssignmentsAction
  | ISetSearchParamsAssignmentsAction

const initialState: TAssignmentSearchModel = {
  responseParams: {
    categoriesData: [],
    positionPage: {
      id: '',
      name: '',
      data: {
        columns: [],
        data: [],
        pagination: {
          page: 1,
          rowsPerPage: 10,
          total: 0,
        },
      },
      agreements: [],
      lots: [],
      status: '',
      categoryPath: '',
      attachments: [],
    },
    categoryId: '',
  },
  requestParams: {
    fetchTenders: true,
    keyword: null,
    tenderId: null,
    pageData: {
      page: 1,
      perPage: 10,
      categoryId: '',
    },
    agreementConditions: [],
    itemConditions: [],
    tenderTaskConditions: [],
    providerConditions: [],
  },
}

export default (
  state = initialState,
  action: TSearchAction,
): TAssignmentSearchModel => {
  switch (action.type) {
    case EActionTypes.SEARCH_ASSIGNMENTS: {
      return action.payload
    }
    case EActionTypes.CHANGE_ASSIGNMENT_LOT_CATEGORY_PAGE: {
      const lots = _.cloneDeep(state.responseParams.positionPage.lots)

      const lot = lots.find(l => Number(l.id) === action.payload.lotId)

      if (lot) {
        lot.suppliers = action.payload.lotData.suppliers

        const position = lot.positions.find(
          p => Number(p.category.key) === action.payload.categoryId,
        )

        if (position) {
          position.data = action.payload.lotData.data
          position.pagination = action.payload.lotData.pagination
        }
      }

      return {
        ...state,
        responseParams: {
          ...state.responseParams,
          positionPage: {
            ...state.responseParams.positionPage,
            lots,
          },
        },
      }
    }
    case EActionTypes.SET_TENDER: {
      return {
        ...state,
        requestParams: { ...state.requestParams, tenderId: action.payload },
      }
    }
    case EActionTypes.SET_CATEGORY_ASSIGNMENTS: {
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          pageData: {
            page: state.requestParams.pageData.page,
            perPage: state.requestParams.pageData.perPage,
            categoryId: action.payload,
          },
        },
      }
    }
    case EActionTypes.SET_SEARCH_PARAMS_ASSIGNMENTS: {
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          pageData: action.payload,
        },
      }
    }
    default:
      return state
  }
}
