import React from 'react'
import Dialog from '@material-ui/core/Dialog'

import { ButtonComponent } from '../../components/Button/Button'
import { ModalTitle } from '../../components/BaseModalAddContextPositions/ModalTitle/ModalTitle'
import { EButtonType } from '../../constants'

type TAlertDialog = {
  open: boolean
  handleClose: () => void
  handleOk: () => void
}

export const AlertDialog: React.FC<TAlertDialog> = ({
  open,
  handleClose,
  handleOk,
}) => (
  <Dialog open={open} onClose={handleClose} className='condition-dialog-modal'>
    <ModalTitle
      dataTestIdPrefix='alertDialogModal'
      id='alert-dialog-title'
      onClose={handleClose}
    >
      Вы действительно хотите удалить условие ?
    </ModalTitle>
    <div className='footer'>
      <ButtonComponent
        data-test-id='alertDialogModalCancelBtn'
        text='Отменить'
        type={EButtonType.DEFAULT}
        onClick={handleClose}
      />
      <ButtonComponent
        data-test-id='alertDialogModalSubmitBtn'
        text='Удалить'
        type={EButtonType.PRIMARY}
        onClick={handleOk}
      />
    </div>
  </Dialog>
)
