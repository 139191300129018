import makeStyles from '@material-ui/core/styles/makeStyles'

const drawerWidth = 120
const useStyles = makeStyles(theme => ({
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: 'white',
    boxShadow: 'none',
    color: '#616284',
  },
  tabs: {
    '& .MuiTab-root': {
      maxWidth: 'none',
      minWidth: 'none',
      padding: '25px 0',
      margin: '0 10px',
    },
    '& .MuiTab-textColorPrimary': {
      color: '#535F77',
      '&.Mui-selected': {
        color: '#4C5ECF',
      },
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Proxima Nova Light',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      textTransform: 'none',
    },
    '& .PrivateTabIndicator-colorPrimary-13': {
      backgroundColor: '#4C5ECF',
      height: '3px',
    },
  },
  toolbar: theme.mixins.toolbar,
  searchFilterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerList: {
    background: 'rgba(44, 52, 98, 0.22)',
    '& .MuiList-root:first-child': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiPaper-root': {
      width: '634px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerContentDivider: {
    margin: '0 0 0 24px',
    paddingTop: 0,
    backgroundColor: '#E2E4E9',
  },
  drawerListHeader: {
    padding: '0',
    '& h5': {
      fontSize: '24px',
      color: '#000',
      fontWeight: 'normal',
      margin: '0',
      cursor: 'default',
    },
    '& .MuiList-padding': {
      minWidth: '334px',
    },
    '& .MuiList-root': {
      position: 'fixed',
    },
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
    boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
    margin: '0 15px 0 0',
    height: '68px',
    position: 'sticky',
    right: '0',
    bottom: '0',
    '& button': {
      margin: '0 0 0 10px',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 5px',
    '& .MuiButtonBase-root:first-child': {
      margin: '0',
      borderRadius: '5px 0 0 5px',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        margin: '0 5px 0 0',
      },
    },
    '& .MuiButtonBase-root:last-child': {
      margin: '0',
      borderRadius: '0 5px 5px 0',
      border: '1px solid #4C5ECF',
      '& .MuiSvgIcon-root': {
        margin: '0',
      },
    },
  },
  searchBackBlock: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    '& h5': {
      fontSize: '24px',
      fontWeight: '500',
      color: '#1B2B4B',
      margin: '26px',
    },
  },
}))

export default useStyles
