import { Button, IconButton, Negative, PlusCircle } from '@gmini/ui-kit'
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'
import { TextField, Checkbox } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import { Project, ProjectObjects, TObject } from '../../types'

import {
  ButtonContainer,
  FieldContainer,
  Field,
  FieldWrapper,
} from './SelectProjectsAndObjects.styled'

type SelectProjectsAndObjectsProps = {
  projectsAndObjects: ({
    project: string
    objects: { id: number; hubName: string }[]
  } | null)[]
  setProjectsAndObjects: Dispatch<
    SetStateAction<
      ({ project: string; objects: { id: number; hubName: string }[] } | null)[]
    >
  >
  allProjectsAndObjects: ProjectObjects
  initialProjectsAndObjects?: string[]
  unselectableProjectIds?: string[]
  handleProjectChange: (_: any, option: Project | null, idx: number) => void
  handleObjectChange: (_: any, values: TObject[], idx: number) => void
  refreshProjectList: () => void
}
const allProjectsOption: Project = {
  createdAt: '',
  createdBy: '',
  version: 0,
  hubName: '',
  projectSource: null,
  name: 'Все проекты',
  urn: '0',
}
const allObjectsOption = {
  name: 'Все объекты',
  id: 0,
  archived: false,
  project: '',
  hubName: '',
}
const emptyArray: string[] = []
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const getObjectOptions = (
  item: {
    project?: Project
    objects: { id: number; hubName: string }[]
  } | null,
  objects: { [x: string]: TObject[] },
) => {
  if (
    item &&
    item.project &&
    objects[item.project.urn] &&
    !item.objects.find(o => o.id === 0)
  ) {
    const formattedObjects = objects[item.project.urn].map(o => ({
      ...o,
      hubName: item.project?.hubName || '',
    }))
    return [allObjectsOption, ...formattedObjects]
  } else if (item) {
    return [allObjectsOption]
  }
  return []
}

export const SelectProjectsAndObjects = ({
  projectsAndObjects,
  setProjectsAndObjects,
  initialProjectsAndObjects = emptyArray,
  unselectableProjectIds = [],
  allProjectsAndObjects,
  handleObjectChange,
  handleProjectChange,
  refreshProjectList,
}: SelectProjectsAndObjectsProps) => {
  const { projects, objects } = allProjectsAndObjects.reduce(
    (
      acc: { projects: Project[]; objects: { [x: string]: TObject[] } },
      item,
    ) => ({
      projects: [...acc.projects, item.project],
      objects: { ...acc.objects, [item.project.urn]: item.objects },
    }),
    { projects: [], objects: {} },
  )
  const unselectableProjectIdsSet = [
    ...projectsAndObjects.map(item => item?.project),
    ...unselectableProjectIds,
  ].reduce(
    (acc: { [x: string]: boolean }, urn) =>
      urn ? { ...acc, [urn]: true } : acc,
    {},
  )
  const projectsSet = projects.reduce(
    (
      acc: {
        [x: string]: Project
      },
      p,
    ) => ({ ...acc, [p.urn]: p }),
    { '0': allProjectsOption },
  )
  const filteredProjects = projects.filter(
    p => !unselectableProjectIdsSet[p.urn],
  )

  const defaultValues = useMemo(
    () =>
      initialProjectsAndObjects.reduce(
        (
          acc: {
            [x: string]: { project: string; objects: string[] } | null
          },
          urn,
          idx,
        ) => ({
          ...acc,
          [`project${idx}`]: { project: projectsSet[urn].urn, objects: [] },
        }),
        {},
      ),
    [initialProjectsAndObjects, projectsSet],
  )

  const { control } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  const handleRemoveItem = useCallback(
    (idx: number) => {
      const newValue = projectsAndObjects.filter((_, i) => i !== idx)
      setProjectsAndObjects(newValue)
    },
    [projectsAndObjects, setProjectsAndObjects],
  )
  const hasProjects = projectsAndObjects.some(p => p)
  return (
    <div>
      <div>{'Проект-Объект(ы)'}</div>
      <FieldWrapper>
        {projectsAndObjects.map((item, idx) => (
          <FieldContainer key={item?.project ? `project${item.project}` : idx}>
            <Controller
              name={`project${idx}`}
              control={control}
              render={({ field }) => (
                <Field>
                  <Autocomplete
                    value={item?.project ? projectsSet[item?.project] : null}
                    options={[allProjectsOption, ...filteredProjects]}
                    onChange={(_, option) =>
                      handleProjectChange(_, option, idx)
                    }
                    getOptionLabel={(option: Project): string =>
                      `${option.name}${
                        option.projectSource ? ` (${option.projectSource})` : ''
                      }` ?? ''
                    }
                    renderOption={(option: Project) => (
                      <div
                        data-test-id={`selectProjectsAndObjectsProjectListItem_${option.urn}`}
                      >
                        {`${option.name}${
                          option.projectSource
                            ? ` (${option.projectSource})`
                            : ''
                        }` ?? ''}
                      </div>
                    )}
                    getOptionSelected={(opt, val) => opt.urn === val.urn}
                    onOpen={refreshProjectList}
                    noOptionsText='Нет доступных совпадений'
                    renderInput={(
                      params: AutocompleteRenderInputParams,
                    ): JSX.Element => (
                      <TextField
                        {...params}
                        variant='filled'
                        placeholder='Укажите проект'
                        error={!hasProjects}
                        helperText={!hasProjects ? 'Обязательное поле' : ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: params.InputProps.endAdornment,
                        }}
                        data-test-id={`selectProjectsAndObjectsProjectInput_${idx}`}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    options={getObjectOptions(
                      {
                        project: projectsSet[item?.project || ''],
                        objects: item?.objects || [],
                      },
                      objects,
                    )}
                    disableCloseOnSelect
                    noOptionsText='Нет доступных совпадений'
                    value={
                      item
                        ? getObjectOptions(
                            {
                              project: projectsSet[item?.project || ''],
                              objects: item?.objects || [],
                            },
                            objects,
                          ).filter(({ id }) =>
                            item?.objects.find(o => o.id === id),
                          )
                        : []
                    }
                    getOptionLabel={(option): string => option.name ?? ''}
                    getOptionSelected={(opt, val) => opt.id === val.id}
                    onChange={(_, option) => handleObjectChange(_, option, idx)}
                    renderOption={(option, { selected }) => (
                      <li
                        data-test-id={`selectProjectsAndObjectsObjectsListItem_${idx}_${option.id}`}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant='filled'
                        placeholder='Укажите объект(ы)'
                        error={Boolean(item?.project) && !item?.objects.length}
                        helperText={
                          item?.project && !item?.objects.length
                            ? 'Обязательное поле'
                            : ''
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: params.InputProps.endAdornment,
                        }}
                        data-test-id={`selectProjectsAndObjectsObjectsInput_${idx}`}
                      />
                    )}
                  />
                  {projectsAndObjects.length > 1 ? (
                    <IconButton
                      data-test-id={`selectProjectsAndObjects_${idx}_DeleteBtn`}
                      onClick={() => {
                        field.onChange(null)
                        handleRemoveItem(idx)
                      }}
                    >
                      <Negative color={'#DADADA'} opacity='0.3' />
                    </IconButton>
                  ) : null}
                </Field>
              )}
            />
          </FieldContainer>
        ))}
        {projectsAndObjects[0]?.project !== '0' ? (
          <ButtonContainer>
            <Button
              data-test-id='selectProjectsAndObjectsAddBtn'
              onClick={() =>
                setProjectsAndObjects([...projectsAndObjects, null])
              }
              leftIcon={<PlusCircle />}
              color='tertiary'
            >
              Добавить
            </Button>
          </ButtonContainer>
        ) : null}
      </FieldWrapper>
    </div>
  )
}
