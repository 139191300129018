import React, { HTMLAttributes } from 'react'

import { Spinner } from '../Spinner/Spinner'

import * as I from './ILoader'
import { LoaderBG } from './LoaderPL.styled'

export const LoaderPL: React.FC<I.StateProps> = ({
  dataIsLoading,
  ...other
}: HTMLAttributes<HTMLDivElement> & {
  dataIsLoading: boolean
}): React.ReactElement | null =>
  dataIsLoading ? (
    <LoaderBG {...other}>
      <Spinner />
    </LoaderBG>
  ) : null
