import React, { useMemo, useState } from 'react'
import {
  Divider,
  IconButton,
  List,
  SvgIcon,
  Tooltip as MuiTooltip,
  Drawer,
  TextField,
} from '@material-ui/core'

import CopyToClipboard from 'react-copy-to-clipboard'
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded'
import ReactImageGallery from 'react-image-gallery'
import * as _ from 'lodash'

import { IKeyValuePair, TPositionModel } from '../../types'
import { Accordion } from '../Accordion/Accordion'
import { DumbTable } from '../DumbTable/DumbTable'
import { AGREEMENT_TABLE_COLUMNS } from '../ImprovedTable/constants'
import { ButtonComponent } from '../Button/Button'
import { EButtonIcon, EButtonType } from '../../constants'
import { formatValue } from '../../utils'

import { createDumbTableRows } from '../ImprovedTable/utils'

import { modifyPosition } from '../../actions'

import { useAppDispatch } from '../../store'

import useStyles, { StyledLoaderPL } from './style'
import { RevitLinkBlock } from './RevitLinkBlock/RevitLinkBlock'
import { Label, LinkText } from './RevitLinkBlock/RevitLinkBlock.styled'

type TProps = {
  onClose: VoidFunction
  onDelete?: (id: string) => void
  onEdit?: VoidFunction
  onAddToAssignment?: VoidFunction
  data?: TPositionModel
  disableRemoveBtn: boolean
  disableEditBtn: boolean
  showMoveToAssignmentsBtn: boolean
  fetchPositionInfo?: (id: string) => void
}

export function PositionInfo({
  onClose,
  onDelete,
  onEdit,
  onAddToAssignment,
  data,
  disableRemoveBtn,
  disableEditBtn,
  showMoveToAssignmentsBtn,
  fetchPositionInfo,
}: TProps) {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const galleryItems = useMemo(() => {
    const arr: Array<{ original: string; thumbnail: string }> = []
    data?.photos.map((img: IKeyValuePair) =>
      arr.push({ original: img.value, thumbnail: img.value }),
    )
    return arr
  }, [data?.photos])

  const [copiedHashCode, setCopiedHashCode] = useState(false)
  const rvtLinkColKey = data?.columns.find(c => c.base && c.title === 'Ссылка')
    ?.key

  const onRvtLinkChange = (value: string) => {
    if (!rvtLinkColKey || !data) {
      return
    }
    dispatch(
      modifyPosition({
        position: {
          rowId: data.id,
          data: [{ key: rvtLinkColKey, value }],
        },
        files: { current: [], add: [], remove: [] },
        callback: () => fetchPositionInfo?.(data.id),
      }),
    )
  }

  const dumbTableRows = useMemo(
    () => createDumbTableRows(data?.agreements || []),
    [data?.agreements],
  )

  return (
    <Drawer
      className={classes.drawerList}
      anchor='right'
      open={true}
      onClose={onClose}
    >
      {!data ? (
        <StyledLoaderPL dataIsLoading />
      ) : (
        <div role='presentation'>
          <List className={classes.drawerListHeader}>
            <div className={classes.drawerListHeaderTitle}>
              <b data-test-id='positionInfoHeading'>{data.name}</b>
              <IconButton
                data-test-id='positionInfoCloseBtn'
                onClick={onClose}
                className={classes.drawerListHeaderCloseBtn}
              >
                <SvgIcon viewBox={'0 0 14 13'} style={{ fontSize: '16px' }}>
                  <path
                    d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
                    fill='#535F77'
                  />
                </SvgIcon>
              </IconButton>
            </div>
            <div className={classes.drawerListHeaderHash}>
              <Label>Хэш-код:</Label>
              <LinkText>{data.hash}</LinkText>
              <CopyToClipboard
                text={data.hash}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onCopy={() => setCopiedHashCode(true)}
              >
                <MuiTooltip
                  placement='right'
                  title={copiedHashCode ? 'Хэш-код скопирован' : ''}
                >
                  <IconButton data-test-id='positionInfoCopyHashcodeBtn'>
                    <SvgIcon
                      component={FileCopyRoundedIcon}
                      style={{ fontSize: '16px' }}
                    />
                  </IconButton>
                </MuiTooltip>
              </CopyToClipboard>
            </div>
            <RevitLinkBlock
              onSubmit={onRvtLinkChange}
              revitLink={data.revitLink}
              dataTestIdPrefix='positionInfo'
            />
          </List>
          <List className={classes.drawerContent}>
            {data.photos?.length > 0 && (
              <>
                <Divider />
                <div className={'app'}>
                  <ReactImageGallery
                    items={galleryItems}
                    additionalClass='app-image-gallery'
                    lazyLoad={false}
                    showIndex={true}
                    showBullets={false}
                    infinite={true}
                    showThumbnails={true}
                    showFullscreenButton={true}
                    showPlayButton={false}
                    showNav={true}
                    isRTL={false}
                    slideDuration={450}
                    slideInterval={2000}
                    slideOnThumbnailOver={false}
                    thumbnailPosition={'right'}
                  />
                </div>
              </>
            )}

            <Divider />
            <div className={classes.cardRowContainer}>
              <div>
                <h4 className={classes.cardRowTitle}>Характеристики</h4>
              </div>
              <div className={classes.cardRowBody}>
                {data?.columns
                  .filter(c => !c.base)
                  .map(c => (
                    <TextField
                      key={c.key}
                      label={c.title}
                      multiline={true}
                      maxRows={50}
                      value={
                        data
                          ? formatValue(
                              c.type,
                              data.data.find(d => d.key === c.key)?.value,
                              '-',
                              c.measureUnitSymbol || '',
                            )
                          : '-'
                      }
                      InputProps={{
                        disableUnderline: true,
                        readOnly: true,
                      }}
                    />
                  ))}
              </div>
            </div>

            {_.isArray(dumbTableRows) && (
              <div className={classes.cardRowContainer}>
                <Accordion dataTestIdPrefix='positionInfo' title='Соглашения'>
                  <DumbTable
                    columns={AGREEMENT_TABLE_COLUMNS}
                    rows={dumbTableRows}
                    dataTestIdPrefix='positionInfo'
                  />
                </Accordion>
              </div>
            )}
          </List>
          <List className={classes.drawerFooter}>
            {!disableRemoveBtn && (
              <div>
                <ButtonComponent
                  data-test-id='positionInfoDeleteBtn'
                  disabled={disableRemoveBtn}
                  text=''
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.DELETE}
                  noMargin={true}
                  onClick={(): void => onDelete && onDelete(data.id)}
                />
              </div>
            )}
            {!disableEditBtn && (
              <div className={classes.secondPartFooter}>
                <ButtonComponent
                  data-test-id='positionInfoEditBtn'
                  disabled={disableEditBtn}
                  text='Редактировать'
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.EDIT}
                  onClick={onEdit}
                />
                {showMoveToAssignmentsBtn && (
                  <ButtonComponent
                    data-test-id='positionInfoAddToAssignmentBtn'
                    text='Добавить в тендерное задание'
                    type={EButtonType.PRIMARY}
                    typeIcon={EButtonIcon.ADD}
                    onClick={(): void =>
                      onAddToAssignment && onAddToAssignment()
                    }
                  />
                )}
              </div>
            )}
          </List>
        </div>
      )}
    </Drawer>
  )
}
