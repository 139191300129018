import React from 'react'
import {
  withStyles,
  Typography,
  IconButton,
  DialogTitle,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { styles } from './Style'
import { DialogTitleProps } from './IModalTitle'

export const ModalTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, dataTestIdPrefix, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography
        data-test-id={dataTestIdPrefix ? `${dataTestIdPrefix}Heading` : ''}
        variant='h6'
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          data-test-id={dataTestIdPrefix ? `${dataTestIdPrefix}CloseBtn` : ''}
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})
