import React, { useState, useRef } from 'react'
import { toastr } from 'react-redux-toastr'

import * as Excel from 'exceljs/dist/es5/exceljs.browser'
import * as ExcelJs from 'exceljs'

import { Dialog, FormControl } from '@material-ui/core'

import { IFileResult, IFileUploadState, SupplierInfo } from '../../types'
import { SuppliersService } from '../../services/supplier-service'

import {
  EButtonType,
  EAPIResponseStatus,
  EButtonIcon,
  EFileUploadListType,
  EEditableModalType,
} from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'

import { FileUpload } from '../FileUpload/FileUpload'

import { useStyles } from './Style'
import * as I from './IAddAssignmentSupplierModal'

import { SupplierCard } from './SupplierCard/SupplierCard'

const INN_FIELD = 'ИНН'
const KPP_FIELD = 'КПП'

type TargetCellType = number | string

export const fetchSuppliers = async (value: string) => {
  try {
    const [, result] = await SuppliersService.searchSuppliers(value, {
      agreedSuppliers: true,
      notAgreedSuppliers: true,
      expiredSuppliers: true,
    })
    if (result.data.status === EAPIResponseStatus.SUCCESS && result.data.data) {
      const suppliers: Array<SupplierInfo> | undefined = result.data.data

      return suppliers
    }
  } catch (e) {
    throw new Error('Error fetching suppliers list')
  }
}

export const AddSupplierAssignment: React.FC<I.OwnProps> = ({
  open,
  type,
  supplier,
  onClose,
  onSave,
}): React.ReactElement => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [file, setFile] = React.useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [currentSupplier, setCurrentSupplier] = useState<SupplierInfo | null>(
    null,
  )
  const files = useRef<IFileResult>({
    current: [],
    add: [],
    remove: [],
  })

  React.useEffect(() => {
    if (supplier) {
      setCurrentSupplier(supplier)
    }
  }, [supplier])

  const saveBtnHandler = (): void => {
    if (!file || !currentSupplier) {
      toastr.error(
        '',
        !currentSupplier ? 'Поставщик не найден!' : 'Прайс не прикреплён!',
      )
      return
    }
    onSave(currentSupplier.providerId, files.current.add[0].file)
  }

  const onFileLoaded = (filesState: IFileUploadState) => {
    if (filesState.files.add.length) {
      setFile(filesState.files.add[0].file)
    } else {
      setFile(null)
    }
  }

  const getSupplierInfo = React.useCallback((file: File) => {
    setLoading(true)

    const reader = new FileReader()
    const workbook: ExcelJs.Workbook = new Excel.Workbook()
    let inn: TargetCellType | undefined = ''
    let kpp: TargetCellType | undefined = ''

    const isTargetCellType = (val: ExcelJs.CellValue): val is TargetCellType =>
      typeof val === 'string' || typeof val === 'number'

    const getNextCellValue = (
      cellArr: ExcelJs.CellValue[],
      nextCellIndex: number,
    ) => cellArr.slice(nextCellIndex, cellArr.length).find(isTargetCellType)

    reader.readAsArrayBuffer(file)
    reader.onload = async () => {
      const buffer = reader.result as any
      const book = await workbook.xlsx.load(buffer)
      await book.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.eachCell({ includeEmpty: true }, (cell, i) => {
            if (!Array.isArray(row.values)) {
              return
            }

            if (cell.value === INN_FIELD) {
              inn = getNextCellValue(row.values, i + 1)
            } else if (cell.value === KPP_FIELD) {
              kpp = getNextCellValue(row.values, i + 1)
            }
          })
        })
      })

      if (!inn && !kpp) {
        setError(
          `В таблице введены некорректные инн и кпп, убедитесь в правильности заполнении формы`,
        )
        return
      }

      const suppliersData = await fetchSuppliers(`${inn}`)
      const supplier = suppliersData?.find(
        data => data.inn === inn && data.kpp === kpp,
      )

      if (!supplier) {
        setError(
          ` ИНН:${inn} и КПП:${kpp} в загружаемом файле отсутствуют в базе`,
        )
      } else {
        setCurrentSupplier(supplier)
      }
    }
    setLoading(false)
  }, [])

  const reset = () => {
    setLoading(false)
    setCurrentSupplier(null)
    setError(null)
  }

  React.useEffect(() => {
    if (type !== EEditableModalType.INSERT) {
      return
    }

    if (file) {
      getSupplierInfo(file)
    } else if (!file) {
      reset()
    }
  }, [file, getSupplierInfo, type])

  const onDialogClose = (): void => {
    reset()
    onClose()
  }
  const operationType =
    type === EEditableModalType.INSERT ? 'Добавить прайс' : 'Обновить прайс'

  const submitDisabled = !currentSupplier || !file || loading

  return (
    <div>
      {open && (
        <Dialog
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
        >
          <ModalTitle
            dataTestIdPrefix='addSupplierAssignmentModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            {operationType}
          </ModalTitle>
          <ModalContent dividers className={classes.ModalContent}>
            <div>
              {currentSupplier && (
                <SupplierCard
                  inn={currentSupplier.inn}
                  kpp={currentSupplier.kpp}
                  name={currentSupplier.name}
                />
              )}

              {error && <div>{error}</div>}
            </div>
            <div className='fullWidth'></div>
            <FormControl>
              <FileUpload
                initialState={files as React.MutableRefObject<IFileResult>}
                fileTypes={'xls,xlsx'}
                maxNumberOfFiles={1}
                fileSize={5}
                addBtnText={`${operationType} (xls)`}
                addBtnIcon={EButtonIcon.EXCEL}
                allowMultiplySelect={false}
                listType={EFileUploadListType.FILE}
                showAddBtn
                showDeleteBtn={!loading}
                onFileLoaded={onFileLoaded}
                loading={loading}
                dataTestIdPrefix='addSupplierAssignmentModal'
              />
            </FormControl>
          </ModalContent>
          <ModalActions>
            <div className={classes.buttonContainer}>
              <ButtonComponent
                data-test-id='addSupplierAssignmentModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='addSupplierAssignmentModalSubmitBtn'
                text={operationType}
                disabled={submitDisabled}
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </div>
          </ModalActions>
        </Dialog>
      )}
    </div>
  )
}
