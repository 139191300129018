export const EDIT_MODAL_TITLE = {
  insert: 'Добавить поставщика',
  edit: 'Редактировать поставщика',
}

export const SUPPLIERS_TABS = [
  {
    label: 'Все',
    id: 0,
  },
  {
    label: 'Активные',
    id: 1,
  },
]
