import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  assignmentsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
    width: '100%',
    '& .left-side': {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginLeft: '10px',
      },
    },
    '& .right-side': {
      display: 'flex',
      alignItems: 'center',
      '& .status-info': {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'flex-end',
        '& .name': {
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#4C5ECF',
          margin: '0',
        },
        '& .date': {
          fontSize: '11px',
          lineHeight: '13px',
          textAlign: 'right',
          color: '#616284',
        },
      },
    },
  },
  detailsBtn: {
    marginLeft: '25px',
    '& p': {
      overflow: 'hidden',
      maxWidth: '194px',
      wordWrap: 'break-word',
      alignSelf: 'center',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  menuButton: {
    padding: 0,
    borderRadius: '5px',
    width: '44px',
    height: '44px',
    marginLeft: '15px',
    backgroundColor: '#F9F9FB',
    '&.active': {
      background: '#4C5ECF',
      color: '#FFFFFF',
    },
  },

  assignmentMenu: {
    '& li': {
      fontFamily: 'Proxima Nova Regular',
      fontSize: '14px',
    },
    '& .MuiListItem-button:hover': {
      backgroundColor: '#F2F0F8',
      borderRadius: '5px',
    },
    '& ul': {
      margin: '7px',
      padding: '0',
      minWidth: '270px',
    },
    '& .MuiSvgIcon-root': {
      backgroundColor: 'rgba(0,0,0,0)',
      color: 'white',
      fontSize: '10px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  menuTitle: {
    opacity: '1 !important',
  },
}))

export default useStyles
