import React, { useEffect, useMemo } from 'react'
import CloseIcon from '@material-ui/icons/Close'

import { useStyles } from './Style'

type Props = {
  file: File
  onDelete: (file: File) => void
  dataTestIdPostfix?: string
}

export const FileItem: React.FC<Props> = ({
  file,
  onDelete,
  dataTestIdPostfix,
}) => {
  const classes = useStyles()
  const url = useMemo(() => URL.createObjectURL(file), [file])

  useEffect(
    () => (): void => {
      URL.revokeObjectURL(url)
    },
    [url],
  )

  return (
    <div className={classes.fileItem}>
      <a
        data-test-id={`fileItemDownloadBtn_${dataTestIdPostfix}`}
        onClick={(event): void => event.stopPropagation()}
        href={url}
        className={classes.fileName}
        download
      >
        {file?.name}
      </a>
      <CloseIcon
        className={classes.deleteIcon}
        data-test-id={`fileItemDeleteBtn_${dataTestIdPostfix}`}
        onClick={(event): void => {
          event.stopPropagation()
          onDelete(file)
        }}
      />
    </div>
  )
}
