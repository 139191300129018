import React, { useCallback, useState } from 'react'

import { Accordion } from '@ifellow/ui-library'

import { TTenderCondition } from '../../types'

import { DumbRow } from '../../components/DumbTable/DumbTable'

import { useAppDispatch } from '../../store'
import {
  editTenderCondition,
  removeTenderCondition,
  dragTenderCondition,
} from '../../actions'

import { ExpandedTable } from './components/expanded-table'
import { AlertDialog } from './alert-dialog'
import { EditDialog } from './components/edit-dialog'

export type TTenderConditions = {
  assignmentId: string
  lotId: number
  getAssignmentDetail: () => void
  tenderConditions: TTenderCondition[]
  disabledDragDrop?: boolean
  lotIndex: number
}

export const TenderConditions: React.FC<TTenderConditions> = ({
  assignmentId,
  lotId,
  getAssignmentDetail,
  tenderConditions,
  disabledDragDrop,
  lotIndex,
}) => {
  const dispatch = useAppDispatch()
  const [currentTenderCondition, setCurrentTenderCondition] = useState<
    { id: number; name: string } | undefined
  >()
  const [
    isRemoveTenderConditionDialogOpen,
    setIsRemoveTenderConditionDialogOpen,
  ] = useState(false)

  const [selectedTenderConditionId, setSelectedTenderConditionId] = useState<
    number
  >(-1)

  const [
    isOpenEditTenderConditionModal,
    setIsOpenEditTenderConditionModal,
  ] = useState(false)

  const handleDragEnd = useCallback(
    async (rows: DumbRow[]): Promise<void> => {
      const newTenderConditions: TTenderCondition[] = rows
        .map(
          row =>
            tenderConditions.find(
              condition => row.id === condition.tenderConditionId,
            )!,
        )
        .map((el, index) => ({ ...el, serialNumber: index }))

      await dispatch(dragTenderCondition({ lotId, newTenderConditions }))
      await getAssignmentDetail()
    },
    [dispatch, getAssignmentDetail, lotId, tenderConditions],
  )

  const handleRemoveIcon = (tenderConditionId: number) => (): void => {
    setIsRemoveTenderConditionDialogOpen(true)
    setSelectedTenderConditionId(tenderConditionId)
  }

  const handleRemoveTenderCondition = () => {
    dispatch(
      removeTenderCondition({
        lotId,
        tenderConditionId: selectedTenderConditionId,
      }),
    ).then(() => setIsRemoveTenderConditionDialogOpen(false))

    getAssignmentDetail()
  }
  const handleEditDialog = (
    tenderConditionId: number,
    name: string,
  ) => (): void => {
    setIsOpenEditTenderConditionModal(true)
    setCurrentTenderCondition({ id: tenderConditionId, name })
  }

  const handleCloseEditDialog = (): void => {
    setIsOpenEditTenderConditionModal(false)
    setCurrentTenderCondition(undefined)
  }

  const handleSubmitEditDialog = (values: Record<string, unknown>): void => {
    dispatch(editTenderCondition(values)).then(() => {
      getAssignmentDetail()
      handleCloseEditDialog()
    })
  }

  return (
    <>
      <Accordion
        dataTestIdPrefix={`tenderConditions_Lot${lotIndex}`}
        title='ТУ'
      >
        <ExpandedTable
          disabledDragDrop={disabledDragDrop}
          onDragEnd={handleDragEnd}
          assignmentId={assignmentId}
          lotId={lotId}
          handleRemoveIcon={handleRemoveIcon}
          handleEditDialog={handleEditDialog}
          rows={tenderConditions}
          dataTestIdPrefix={`tenderConditionsTable_Lot${lotIndex}`}
        />
      </Accordion>
      {isRemoveTenderConditionDialogOpen && (
        <AlertDialog
          handleOk={handleRemoveTenderCondition}
          open={isRemoveTenderConditionDialogOpen}
          handleClose={(): void => setIsRemoveTenderConditionDialogOpen(false)}
        />
      )}
      {isOpenEditTenderConditionModal && (
        <EditDialog
          lotId={lotId}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
          tenderConditionId={currentTenderCondition?.id!}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
          tenderConditionName={currentTenderCondition?.name!}
          open={isOpenEditTenderConditionModal}
          handleClose={handleCloseEditDialog}
          handleOk={handleSubmitEditDialog}
        />
      )}
    </>
  )
}
