import { createAsyncThunk } from '@reduxjs/toolkit'

import { EActionTypes } from '../../constants'
import { treeify } from '../../utils'
import { downloadFile } from '../../services/base-api-service/utils'

import {
  TAgreementModel,
  TAgreementUpdateRequest,
  TTableData,
  TAgreementCreateRequest,
  IRow,
  IColumn,
  IKeyValuePair,
  TAgreementSectionData,
  AgreementTab,
  TAgreementRequest,
  TAgreementCategoryRequest,
} from '../../types'
import { AgreementService } from '../../services/agreements-service'
import { setPositions } from '../Positions'
import { actionCreator, IActionCreator } from '../BaseAction'
import { setAvailableColumns } from '../Filter'
import { agreementColumnsSorter } from '../../pages/agreement/list/utils'
import { setDataLoading } from '../RequestWrapper'

export const getAgreementsNew = createAsyncThunk<
  TTableData,
  {
    params: Partial<TAgreementRequest>
    tab: AgreementTab
    setLoading?: (value: boolean) => void
  }
>(
  'agreement/getAgreementsNew',
  async ({ params, tab, setLoading }, { rejectWithValue, dispatch }) => {
    setLoading?.(true)
    const [err, result] = await AgreementService.getAgreementsNew(params, tab)

    if (err) {
      setLoading?.(false)
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      if (params.pageData) {
        localStorage.setItem(
          `agreements_${tab}_lastVisitedCategory`,
          params.pageData.categoryId,
        )
      }
      result.data.data.columns = result.data.data.columns.sort(
        agreementColumnsSorter,
      )
      dispatch(setAgreements(result.data.data))
      dispatch(
        setAvailableColumns(
          result.data.data.columns.map(column => ({
            name: column.title,
            type: column.type,
          })),
        ),
      )
      setLoading?.(false)
    }
    const data: TTableData = result.data.data || {
      columns: [],
      data: [],
      pagination: { page: 0, rowsPerPage: 10, total: 0 },
    }
    return data
  },
)

export const getAgreementDetail = createAsyncThunk<
  unknown,
  { agreementId: string; callback: () => void }
>(
  'agreement/getAgreementDetail',
  async ({ agreementId, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setDataLoading(true))
    const [err, result] = await AgreementService.getAgreementDetail(agreementId)
    if (err) {
      throw rejectWithValue(err)
    }

    const { data } = result.data

    if (!data) {
      return
    }

    const obj = {
      id: data.id,
      supplier: data.supplier,
      category: treeify(data.category),
      agreement: {
        columns: data.agreement.columns,
        data: data.agreement.data.length
          ? data.agreement.data[0]
          : {
              rowId: '',
              lot: '',
              tenderTask: '',
              provider: '',
              providerId: '',
              number: 0,
              versionDate: '',
              expire: '',
              start: '',
              positions: '',
              data: [],
              projectsAndObjects: [],
            },
      },
    }

    dispatch(setAgreementDetail(obj))
    dispatch(setDataLoading(false))
    callback()
  },
)

export const createAgreement = createAsyncThunk<
  unknown,
  { agreement: TAgreementCreateRequest; callback: () => void }
>(
  'agreement/createAgreement',
  async ({ agreement, callback }, { rejectWithValue }) => {
    const [err, result] = await AgreementService.addAgreement(agreement)

    if (err) {
      throw rejectWithValue(err)
    }

    const item = result.data.data
    if (item) {
      callback()
    }
  },
)

export const modifyAgreement = createAsyncThunk<
  unknown,
  { agreement: TAgreementUpdateRequest; callback: () => void }
>(
  'agreement/modifyAgreement',
  async ({ agreement, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AgreementService.updateAgreement(agreement)

    if (err) {
      throw rejectWithValue(err)
    }

    dispatch(editAgreement(agreement))
    callback()
  },
)

export const removeAgreements = createAsyncThunk<
  unknown,
  { ids: Array<string>; callback: () => void }
>(
  'agreement/removeAgreements',
  async ({ ids, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AgreementService.removeAgreements(ids)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const addAgreementProperty = createAsyncThunk<
  unknown,
  { model: IColumn; categoryId: string }
>(
  'agreement/addAgreementProperty',
  async ({ model, categoryId }, { rejectWithValue }) => {
    const [err] = await AgreementService.addProperty(
      model,
      categoryId,
      parseInt(model.section?.key, 10),
    )

    if (err) {
      throw rejectWithValue(err)
    }
  },
)

export const editAgreementProperty = createAsyncThunk<
  unknown,
  { model: IColumn }
>('agreement/editAgreementProperty', async ({ model }, { rejectWithValue }) => {
  const [err] = await AgreementService.editProperty(model)

  if (err) {
    throw rejectWithValue(err)
  }
})

export const removeAgreementProperty = createAsyncThunk<
  unknown,
  { key: string }
>('agreement/removeAgreementProperty', async ({ key }, { rejectWithValue }) => {
  const [err] = await AgreementService.removeProperty(key)

  if (err) {
    throw rejectWithValue(err)
  }
})

export const exportAgreementsByIds = createAsyncThunk<unknown, Array<string>>(
  'agreement/exportAgreementsByIds',
  async (ids, { rejectWithValue, dispatch }) => {
    const [err, blob] = await AgreementService.exportAgreementsByIds(ids)

    if (err) {
      throw rejectWithValue(err)
    }

    downloadFile(blob)
  },
)

export const getAgreementCategoryPositions = createAsyncThunk<
  unknown,
  { agreementId: string; categoryId: string; params: TAgreementCategoryRequest }
>(
  'agreement/getAgreementCategoryPositions',
  async (
    { agreementId, categoryId, params },
    { rejectWithValue, dispatch },
  ) => {
    const [err, result] = await AgreementService.getAgreementCategoryPositions(
      agreementId,
      categoryId,
      params,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (!result.data.data) {
      return
    }

    const data = {
      ...result.data.data,
      columns: [
        ...result.data.data.columns.map(c => {
          c.base = c.base === null ? false : c.base
          return c
        }),
      ],
    }

    dispatch(setPositions(data))

    return data
  },
)

export function setAgreements(
  agreements: TTableData,
): IActionCreator<TTableData> {
  return actionCreator<TTableData>(EActionTypes.SET_AGREEMENTS, agreements)
}

export function setAgreementDetail(
  agreement: TAgreementModel,
): IActionCreator<TAgreementModel> {
  return actionCreator<TAgreementModel>(
    EActionTypes.SET_AGREEMENT_DETAIL,
    agreement,
  )
}

export function editAgreement(
  agreement: TAgreementUpdateRequest,
): IActionCreator<TAgreementUpdateRequest> {
  return actionCreator<TAgreementUpdateRequest>(
    EActionTypes.EDIT_AGREEMENT,
    agreement,
  )
}

export function setCategoryAgreement(
  categoryId: string,
): IActionCreator<string> {
  return actionCreator<string>(EActionTypes.SET_CATEGORY_AGREEMENT, categoryId)
}

export const addSectionAgreement = createAsyncThunk<
  unknown,
  { categoryId: string; name: string; callback: (model: IKeyValuePair) => void }
>(
  'agreement/addSectionAgreement',
  async ({ categoryId, name, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AgreementService.addSection(categoryId, name)

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const has = <T extends Record<string, unknown>, K extends string>(
        key: K,
        x: T,
      ): x is T => key in x
      if (
        typeof result.data.data !== 'object' ||
        Object.keys(result.data.data).some(k => !has(k, { key: '', value: '' }))
      ) {
        throw new Error(`Получен неверный ответ: ${result.data.data}`)
      }

      callback(result.data.data)
    }
  },
)

export const editSectionAgreement = createAsyncThunk<
  unknown,
  { value: IKeyValuePair; callback: (model: IKeyValuePair) => void }
>(
  'agreement/editSectionAgreement',
  async ({ value, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AgreementService.editSection(
      value.key,
      value.value,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      const has = <T extends Record<string, unknown>, K extends string>(
        key: K,
        x: T,
      ): x is T => key in x
      if (
        typeof result.data.data !== 'object' ||
        Object.keys(result.data.data).some(k => !has(k, { key: '', value: '' }))
      ) {
        throw new Error(`Получен неверный ответ: ${result.data.data}`)
      }

      callback(result.data.data)
    }
  },
)

export const removeSectionAgreement = createAsyncThunk<
  unknown,
  { key: string; callback: () => void }
>(
  'agreement/removeSectionAgreement',
  async ({ key, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AgreementService.removeSection(key)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)

export const getAgreementSectionData = createAsyncThunk<
  unknown,
  { agreementId: string; callback: (data: TAgreementSectionData) => void }
>(
  'agreement/getAgreementSectionData',
  async ({ agreementId, callback }, { rejectWithValue, dispatch }) => {
    const [err, result] = await AgreementService.getAgreementSectionData(
      agreementId,
    )

    if (err) {
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      callback(result.data.data)
    }
  },
)

export const editLotAgreement = createAsyncThunk<
  unknown,
  { data: IRow; callback: () => void }
>(
  'agreement/editLotAgreement',
  async ({ data, callback }, { rejectWithValue, dispatch }) => {
    const [err] = await AgreementService.editAgreement(data)

    if (err) {
      throw rejectWithValue(err)
    }

    callback()
  },
)
