import React, { useEffect, useMemo, useState } from 'react'
import { unionBy, sortBy } from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton, Button, Box } from '@material-ui/core'

import { TTenderCondition } from '../../../../types'
import {
  DumbTable,
  DumbColumn,
  DumbRow,
} from '../../../../components/DumbTable/DumbTable'

import './styles.scss'

type TExpandedTable = {
  assignmentId: string
  lotId: number
  handleRemoveIcon: (lotId: number) => () => void
  handleEditDialog: (lotId: number, name: string) => () => void
  rows: TTenderCondition[]
  onDragEnd: (row: DumbRow[]) => void
  disabledDragDrop?: boolean
  dataTestIdPrefix?: string
}

type savedColumnsType = {
  key: number
  title: string
}

export const ExpandedTable: React.FC<TExpandedTable> = ({
  assignmentId,
  lotId,
  handleRemoveIcon,
  handleEditDialog,
  onDragEnd,
  rows: rowsProp,
  disabledDragDrop,
  dataTestIdPrefix,
}) => {
  const [columns, rows] = useMemo(() => {
    const resultColumns: DumbColumn[] = [
      { key: 'name', title: 'Название условия', sticky: true },
    ]
    const resultRows: DumbRow[] = []

    rowsProp.forEach(({ values, name, editable, tenderConditionId }, idx) => {
      const rowValuesObject = values.reduce(
        (acc, { providerId, providerName, value }) => {
          resultColumns.push({ key: providerId, title: providerName })
          return {
            ...acc,
            [providerId]: value,
          }
        },
        {},
      )

      resultRows.push({
        ...rowValuesObject,
        editable,
        id: tenderConditionId,
        name: (
          <React.Fragment>
            {editable && (
              <div className='expanded-table-name-cell'>
                <IconButton
                  data-test-id={`${dataTestIdPrefix}ListItemDeleteBtn_${idx}`}
                  disabled={values.length > 0}
                  onClick={handleRemoveIcon(tenderConditionId)}
                  className='expanded-table-remove-button'
                  type='button'
                >
                  <DeleteIcon color='primary' />
                </IconButton>
                <Button
                  data-test-id={`${dataTestIdPrefix}ListItemEditBtn_${idx}`}
                  disableRipple
                  size='small'
                  disabled={values.length > 0}
                  onClick={handleEditDialog(tenderConditionId, name)}
                  className='expanded-table-edit-button'
                >
                  <Box overflow='hidden' textOverflow='ellipsis'>
                    {name}
                  </Box>
                </Button>
              </div>
            )}
            {!editable && (
              <Box overflow='hidden' textOverflow='ellipsis'>
                {name}
              </Box>
            )}
          </React.Fragment>
        ),
      })
    })

    return [unionBy(resultColumns, 'key'), resultRows]
  }, [handleEditDialog, handleRemoveIcon, rowsProp, dataTestIdPrefix])
  const [visibleColumns, setVisibleColumns] = useState<DumbColumn[]>([])
  useEffect(() => {
    function checkUserData(value?: string): void {
      if (!value) {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        setVisibleColumns(sortBy(columns, [i => i.key]))
        return
      }

      const savedColumns: savedColumnsType[] = JSON.parse(value)

      const newColumns = columns.filter(currentColumn => {
        if (currentColumn.key === 'name') {
          return true
        }

        const findColumn = savedColumns.find(
          item => currentColumn.key === item.key,
        )
        if (findColumn) {
          return currentColumn
        }

        return false
      })

      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      setVisibleColumns(sortBy(newColumns, [i => i.key]))
    }

    const originalSetItem = localStorage.setItem
    localStorage.setItem = function(key, value): void {
      const page = `assignments_${assignmentId}_lots_${lotId}_suppliers`
      if (key === page) {
        checkUserData(value)
      }

      originalSetItem.apply(this, [key, value])
    }

    checkUserData()
    // eslint-disable-next-line
  }, [columns])

  return rows.length ? (
    <div className='expanded-table'>
      <DumbTable
        columns={visibleColumns}
        rows={rows}
        onDragEnd={onDragEnd}
        disabledDragDrop={disabledDragDrop}
        dataTestIdPrefix={dataTestIdPrefix}
      />
    </div>
  ) : (
    <div>Нет созданных условий</div>
  )
}
