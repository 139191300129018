import React, { Dispatch, SetStateAction } from 'react'
import { MenuItem, IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { GridSortModelParams } from '@material-ui/x-grid'

import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import { TPagination } from '../../../types'

import {
  ButtonContainer,
  Description,
  PaginationWrapper,
  StyledSelect,
} from './CustomPagination.styled'

type CustomPaginationProps = {
  options: number[]
  pagination: TPagination
  setParamsState: Dispatch<
    SetStateAction<
      Omit<TableParams, 'sort'> & {
        sort: GridSortModelParams['sortModel']
      }
    >
  >
  dataTestIdPrefix?: string
}

const CustomPagination = ({
  options,
  pagination,
  setParamsState,
  dataTestIdPrefix,
}: CustomPaginationProps) => {
  const { page, rowsPerPage, total } = pagination
  const startPaginationNumber = (page - 1) * rowsPerPage
  const from = startPaginationNumber + 1
  const lastRowNumber = startPaginationNumber + rowsPerPage
  const to = lastRowNumber > total ? total : lastRowNumber

  return (
    <PaginationWrapper>
      <Description>Строк на странице</Description>
      <StyledSelect
        disableUnderline
        value={rowsPerPage}
        onChange={e => {
          const maxPage = Math.ceil(total / Number(e.target.value))
          if (page <= maxPage) {
            setParamsState(prev => ({
              ...prev,
              size: Number(e.target.value),
            }))
          } else {
            setParamsState(prev => ({
              ...prev,
              size: Number(e.target.value),
              page: maxPage - 1,
            }))
          }
        }}
        inputProps={{
          'data-test-id': `${dataTestIdPrefix}PageSizeSelect`,
        }}
      >
        {options.map((val, idx) => (
          <MenuItem
            data-test-id={`${dataTestIdPrefix}PageSizeListItem_${idx}`}
            key={val}
            value={val}
          >
            {val}
          </MenuItem>
        ))}
      </StyledSelect>
      <Description>
        {from}-{to} из {total !== -1 ? total : `больше чем ${to}`}
      </Description>
      <ButtonContainer>
        <IconButton
          data-test-id={`${dataTestIdPrefix}PrevPageBtn`}
          disabled={from === 1}
          onClick={() =>
            setParamsState(prev => ({
              ...prev,
              page: page - 2,
            }))
          }
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          data-test-id={`${dataTestIdPrefix}NextPageBtn`}
          disabled={to === total}
          onClick={() =>
            setParamsState(prev => ({
              ...prev,
              page,
            }))
          }
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonContainer>
    </PaginationWrapper>
  )
}

export default CustomPagination
