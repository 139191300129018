import React, { useState } from 'react'
import { Checkbox, TableCell, TableRow, IconButton } from '@material-ui/core'

import { DialogModal } from '../../../DialogModal/DialogModal'
import {
  pencilCreateIcon,
  circleXIcon,
  EButtonType,
} from '../../../../constants'

import * as I from './IAssignmentTableDisplayColumnsRow'

import useImprovedTableHeadDisplayColumnsStyles from './Style'

const AssignmentTableDisplayColumnsRow: React.FC<I.OwnProps> = ({
  index,
  column,
  isVisible,
  showActionButtons,
  onColumnVisibilityChanged,
  onEditPropertyBtnClick,
  removePropertyHandler,
  dataTestIdPrefix,
}): React.ReactElement => {
  const classes = useImprovedTableHeadDisplayColumnsStyles()
  const [openRemoveDialogModal, setOpenRemoveDialogModal] = useState<boolean>(
    false,
  )

  const isDisabled =
    column.title === 'Автор' || column.title === 'Дата создания'
      ? false
      : column.base

  return (
    <TableRow data-test-id={`${dataTestIdPrefix}Row_${index}`} key={index}>
      <TableCell align='left'>
        <div className={classes.flexContainer}>
          <Checkbox
            data-test-id={`${dataTestIdPrefix}Row_${index}_Checkbox`}
            className={classes.checkedRow}
            classes={{ checked: classes.checkboxChecked }}
            color='primary'
            onClick={(event: React.MouseEvent<HTMLButtonElement>): void => {
              event.stopPropagation()
              event.preventDefault()
              onColumnVisibilityChanged(column)
            }}
            checked={isVisible}
            disabled={isDisabled}
          />
          {column.title}
        </div>
      </TableCell>
      <TableCell align='right'>
        {!column.base && showActionButtons && (
          <div className={classes.actionsContainer}>
            <IconButton
              data-test-id={`${dataTestIdPrefix}Row_${index}_EditBtn`}
              className={classes.actionButton}
              onClick={onEditPropertyBtnClick({
                key: column.key,
                value: column.title,
              })}
            >
              {pencilCreateIcon}
            </IconButton>
            <IconButton
              data-test-id={`${dataTestIdPrefix}Row_${index}_DeleteBtn`}
              className={classes.actionButton}
              onClick={(): void => {
                setOpenRemoveDialogModal(true)
              }}
            >
              {circleXIcon}
            </IconButton>
          </div>
        )}
        {showActionButtons && openRemoveDialogModal && (
          <DialogModal
            open={openRemoveDialogModal}
            modalTitle={'Удалить'}
            modalContent={'Вы действительно хотите удалить свойство?'}
            modalButtonLeftText={'Отменить'}
            modalButtonRightText={'Удалить'}
            modalButtonLeftType={EButtonType.DEFAULT}
            modalButtonRightType={EButtonType.WARNING}
            handleDiscardChanges={(): void => setOpenRemoveDialogModal(false)}
            handleChanges={(): void => {
              setOpenRemoveDialogModal(false)
              removePropertyHandler(column.key, (): number => 0)
            }}
            dataTestIdPrefix={`${dataTestIdPrefix}Row_${index}_DeleteModal`}
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default AssignmentTableDisplayColumnsRow
