import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  lot: {
    display: 'flex',
    width: '100%',
  },
  drawerEditBtn: {
    width: '40px',
    height: '40px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
  },
  cardRowBody: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '20px',
    margin: '0 -10px',
    '& .MuiFormControl-root': {
      margin: '5px 10px',
      maxWidth: '280px',
    },
    '& div.MuiInput-formControl': {
      marginTop: '0 !important',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& label': {
      marginLeft: '0',
      position: 'relative',
    },
  },
}))

export default useStyles
