/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Drawer, List, IconButton, SvgIcon, Divider } from '@material-ui/core'

import { CompleteIcon, PlusCircle, Tooltip } from '@gmini/ui-kit'
import { useQuery } from '@gmini/utils'

import _ from 'lodash'

import { useDispatch } from 'react-redux'

import { CancelIcon } from '../../../../icons/CancelIcon'
import {
  TFilterItemsModel,
  TFilterSearchModel,
  TFilterSingleOperators,
} from '../../../../types'

import { AppHeaderSearchBarAssignment } from '../../../AppHeaderSearchBarAssignment/AppHeaderSearchBarAssignment'
import { AppHeaderSearchBarOtherPage } from '../../../AppHeaderSearchBarOtherPage/AppHeaderSearchBarOtherPage'
import { AppHeaderFilterDrawerContent } from '../../../AppHeaderFilterDrawerContent/AppHeaderFilterDrawerContent'

import { setFilterSearchDate } from '../../../../components/PositionCatalog/store/catalog-slice'

import { useAppSelector } from '../../../../store'

import { PositionFilter } from './PositionFilter/PositionFilter'
import {
  ConditionCancelButton,
  FilterText,
  FilterWrap,
  ConditionButtonText,
  ConditionFilterButton,
} from './Filter.styled'

import useStyles from './Style'
import * as I from './IAppHeaderSearchAndFilter'
import { FilterButton } from './FilterButton'

export const AppHeaderSearchAndFilter: React.FC<I.OwnProps> = ({
  isHidden,
  openDrawer,
  shouldResetFilter,
  filterFields,
  flagBackPage,
  getFilterFields,
  showDrawerHandler,
  flagBackPageHandler,
  getSearchAssignmentFilterItems,
  getSearchOtherPageFilterItems,
  refreshCategoryId,
  categoryId,
  availableColumns,
  currentPage,
  resetSearchOtherPage,
  isArchived,
}): React.ReactElement => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const query = useQuery()

  const [filterItems, setFilterItems] = useState<Array<TFilterItemsModel>>([])
  const [searchFilterParams, setSearchFilterParams] = useState<
    TFilterSearchModel
  >({
    itemConditions: [],
    agreementConditions: [],
    providerConditions: [],
    tenderTaskConditions: [],
  })

  const [flagSentRequest, setFlagSendedRequest] = useState<boolean>(false)
  const [errorCheckFlag, setErrorCheckFlag] = useState<
    Array<{ key: any; value: boolean }>
  >([])
  const [errorsField, setErrorsField] = useState<
    Array<{ key: string; value: { [key: string]: string } }>
  >([])

  const [
    errorCheckFlagInsideFilterItem,
    setErrorCheckFlagInsideFilterItem,
  ] = useState<Array<{ key: any; value: boolean }>>([])

  const [shouldGetParam, setShouldGetParam] = useState<boolean>(true)
  const [cleanFilter, setCleanFilter] = useState<boolean>(false)
  const [flagApplyBtn, setFlagApplyBtn] = useState<boolean>(false)
  const [searchPositionDate, setSearchPositionDate] = useState<string | null>(
    null,
  )
  /** tooltip для кнопки "применить" при незаполненном фильтре */
  const [promptToFillInTheFilter, setPromptToFillInTheFilter] = useState(false)
  const dispatch = useDispatch()
  const catalog = useAppSelector(state => state.catalog)
  const [searchInputValue, setSearchInputValue] = useState('')

  let tableValue = ''

  if (location.pathname.includes('/positions')) {
    tableValue = 'items'
  } else if (location.pathname.includes('/suppliers')) {
    tableValue = 'providers'
  } else if (location.pathname.includes('/assignments')) {
    tableValue = 'tenders'
  } else {
    tableValue = 'agreements'
  }

  const onSearch = React.useCallback(
    (value?: string) => {
      const paramsValue = {
        keyword: value || '',
        table: tableValue,
        fetchCategories: true,
        pageData: {
          page: 1,
          perPage: 10,
          categoryId,
        },
        deleted: Boolean(isArchived),
      }

      const generalParamsFull = _.assign(searchFilterParams, paramsValue)

      if (getSearchOtherPageFilterItems) {
        getSearchOtherPageFilterItems(generalParamsFull, () => {
          if (!location.pathname.includes('/search')) {
            history.push(`${location.pathname}/search`)
          }
        })
      }
    },
    [
      categoryId,
      getSearchOtherPageFilterItems,
      history,
      isArchived,
      location,
      searchFilterParams,
      tableValue,
    ],
  )

  /** Обработчик кнопки "применить" */
  const handleApplyButton = () => {
    setCleanFilter(true)
    if (!filterItems.length && searchPositionDate) {
      dispatch(setFilterSearchDate({ searchDate: searchPositionDate }))

      setPromptToFillInTheFilter(false)
      setFlagApplyBtn(true)
      showDrawerHandler(false)
      return
    }
    if (filterItems.length === 0 && location.pathname.includes('search')) {
      showDrawerHandler(false)
      setErrorCheckFlag([])
      history.push({
        pathname: location.pathname.replace('/search', ''),
        search: query.toString(),
      })
    }
    if (filterItems.length === 0 && !location.pathname.includes('search')) {
      return setPromptToFillInTheFilter(true)
    }
    setFlagApplyBtn(true)
    setPromptToFillInTheFilter(false)
    applyFilterItems()
  }

  /** Обработчик иконки закрытия фильтра */
  const handleCloseIcon = (): void => {
    showDrawerHandler(false)
    setErrorCheckFlag([])
    if (!searchPositionDate && catalog.filter.searchDate) {
      dispatch(setFilterSearchDate({ searchDate: null }))
    }

    if (filterItems.length > 0 && !flagApplyBtn) {
      dropFilterItems()
    }
  }

  const addFilterItem = (): void => {
    setPromptToFillInTheFilter(false)
    const id =
      filterItems.length === 0
        ? String(0)
        : String(Number(filterItems[filterItems.length - 1].id) + 1)
    filterItems.push({
      id,
      keyValue: '',
      content: {
        key: '',
        singleOperators: [
          {
            filterCompareType: 'contain',
            value: '',
          },
        ],
      },
    })
    setFilterItems([...filterItems])
  }

  const editFilterItem = (
    itemId: string,
    nameValue: string,
    keyValue: string,
    singleOperators: Array<TFilterSingleOperators>,
  ): void => {
    setFilterItems(
      filterItems.map((data: TFilterItemsModel) =>
        data.id === itemId && singleOperators[0].filterCompareType !== 'gte'
          ? {
              ...data,
              keyValue,
              content: {
                key: nameValue,
                singleOperators: [singleOperators[0]],
              },
            }
          : data.id === itemId && singleOperators[0].filterCompareType === 'gte'
          ? {
              ...data,
              keyValue,
              content: { key: nameValue, singleOperators },
            }
          : data,
      ),
    )
  }

  const removeFilterItem = (itemId: string): void => {
    setFilterItems([...filterItems.filter(item => !itemId.includes(item.id))])
    setErrorCheckFlag([
      ...errorCheckFlag.filter(data => !itemId.includes(data.key)),
    ])
    setErrorsField([...errorsField.filter(data => !itemId.includes(data.key))])
  }

  const dropFilterItems = (): void => {
    setPromptToFillInTheFilter(false)
    dispatch(setFilterSearchDate({ searchDate: null }))
    setSearchPositionDate(null)
    setFilterItems([])
    setSearchFilterParams({
      itemConditions: [],
      agreementConditions: [],
      providerConditions: [],
      tenderTaskConditions: [],
    })
    setErrorCheckFlag([])
    setErrorsField([])
    setFlagApplyBtn(false)

    if (location.pathname.includes('search')) {
      showDrawerHandler(false)
      setErrorCheckFlag([])
      if (resetSearchOtherPage) {
        resetSearchOtherPage()
      }
    }
  }

  const handleChangeFlagSent = (flag: boolean): void => {
    setFlagSendedRequest(flag)
  }

  const handleErrorCheckFlag = (flag: boolean, itemId: string): void => {
    setErrorCheckFlag(
      errorCheckFlag.map(data =>
        data.key === itemId ? { key: itemId, value: flag } : data,
      ),
    )
  }

  const handleShoulGetdData = (flag: boolean): void => {
    setShouldGetParam(flag)
  }

  const handleErrorsFields = (
    key: string,
    value: { [key: string]: string },
  ): void => {
    setErrorsField(
      errorsField.find(data => data.key === key)
        ? errorsField.map(data => (data.key === key ? { key, value } : data))
        : [...errorsField, { key, value }],
    )
  }

  useEffect(() => {
    dropFilterItems()
    refreshCategoryId()
    // eslint-disable-next-line
  }, [shouldResetFilter])

  useEffect(() => {
    if (flagBackPage) {
      dropFilterItems()
      refreshCategoryId()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagBackPage])

  useEffect(() => {
    if (openDrawer) {
      getFilterFields()
    }
    // eslint-disable-next-line
  }, [openDrawer])

  useEffect(() => {
    if (!shouldGetParam) {
      handleShoulGetdData(true)
      showDrawerHandler(false)
      setFlagSendedRequest(true)
    }
    // eslint-disable-next-line
  }, [shouldGetParam])

  // TODO legacy code refactoring
  const refreshFilterData = React.useCallback(() => {
    filterItems.map(data => {
      const err = errorCheckFlagInsideFilterItem
      if (
        err.length === 0 ||
        !err.filter(er => er.key === data.id).find(d => d.key === data.id)
      ) {
        err.push({ key: data.id, value: true })
      }
      if (err.length > 1 && err.find(d => d.key === data.id)) {
        err.map(row =>
          row.key === data.id ? { key: row.key, value: true } : row,
        )
      }
      setErrorCheckFlagInsideFilterItem(err)
      switch (data.keyValue) {
        case 'Позиции':
          searchFilterParams.itemConditions.push(data.content)
          return setSearchFilterParams({
            ...searchFilterParams,
            itemConditions: [...searchFilterParams.itemConditions],
          })
        case 'Соглашения':
          searchFilterParams.agreementConditions.push(data.content)
          return setSearchFilterParams({
            ...searchFilterParams,
            agreementConditions: [...searchFilterParams.agreementConditions],
          })
        case 'Поставщики':
          searchFilterParams.providerConditions.push(data.content)
          return setSearchFilterParams({
            ...searchFilterParams,
            providerConditions: [...searchFilterParams.providerConditions],
          })
        case 'Тендерные задания':
          searchFilterParams.tenderTaskConditions.push(data.content)
          return setSearchFilterParams({
            ...searchFilterParams,
            tenderTaskConditions: [...searchFilterParams.tenderTaskConditions],
          })
        default:
          return data
      }
    })
    setCleanFilter(false)
    setErrorCheckFlag(errorCheckFlagInsideFilterItem)
    if (flagApplyBtn) {
      onSearch()
    }
  }, [
    errorCheckFlagInsideFilterItem,
    filterItems,
    flagApplyBtn,
    onSearch,
    searchFilterParams,
  ])

  useEffect(() => {
    if (cleanFilter) {
      refreshFilterData()
    }
    // eslint-disable-next-line
  }, [cleanFilter])

  //#region обработка параметров фильтра для применения в основном запросе поиска
  const applyFilterItems = (): void => {
    setSearchFilterParams({
      itemConditions: [],
      agreementConditions: [],
      providerConditions: [],
      tenderTaskConditions: [],
    })
    setCleanFilter(true)
    setFlagApplyBtn(true)
  }
  //#endregion
  const renderDrawerList = (): JSX.Element => (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <List
        className={classes.drawerListHeader}
        style={{ margin: '20px 10px 10px 24px' }}
      >
        <h5 data-test-id='filterDrawerHeading'>Фильтр</h5>
        <IconButton
          data-test-id='closeFilterDrawerBtn'
          onClick={handleCloseIcon}
        >
          <SvgIcon viewBox={'0 0 14 13'} style={{ fontSize: '12px' }}>
            <path
              d='M12.7692 1.70712C13.1597 1.3166 13.1597 0.683431 12.7692 0.292907C12.3787 -0.0976177 11.7455 -0.0976177 11.355 0.292907L12.7692 1.70712ZM1.23064 10.4173C0.840113 10.8078 0.840113 11.4409 1.23064 11.8315C1.62116 12.222 2.25433 12.222 2.64485 11.8315L1.23064 10.4173ZM11.355 11.8315C11.7455 12.222 12.3787 12.222 12.7692 11.8315C13.1597 11.4409 13.1597 10.8078 12.7692 10.4172L11.355 11.8315ZM2.64485 0.292893C2.25433 -0.0976311 1.62116 -0.0976311 1.23064 0.292893C0.840113 0.683417 0.840113 1.31658 1.23064 1.70711L2.64485 0.292893ZM11.355 0.292907L1.23064 10.4173L2.64485 11.8315L12.7692 1.70712L11.355 0.292907ZM12.7692 10.4172L2.64485 0.292893L1.23064 1.70711L11.355 11.8315L12.7692 10.4172Z'
              fill='#535F77'
            />
          </SvgIcon>
        </IconButton>
      </List>
      <Divider className={classes.drawerContentDivider} />

      <FilterWrap>
        <PositionFilter
          setDate={searchDate => {
            setSearchPositionDate(searchDate)
          }}
          date={searchPositionDate}
          onReset={() => {
            setSearchPositionDate(null)
          }}
        />
        <FilterText> По условию:</FilterText>
        <List>
          {filterItems.map((item: TFilterItemsModel) => (
            <AppHeaderFilterDrawerContent
              key={item.id}
              filterFields={filterFields}
              availableColumns={availableColumns}
              currentPage={currentPage}
              filterItem={item}
              errorCheckFlag={errorCheckFlag}
              errorsField={errorsField}
              editFilterItem={editFilterItem}
              removeFilterItem={removeFilterItem}
              handleErrorCheckFlag={handleErrorCheckFlag}
              handleShouldData={handleShoulGetdData}
              handleErrorsFields={handleErrorsFields}
            />
          ))}
          <ConditionFilterButton
            color='secondary'
            leftIcon={<PlusCircle width='20px' height='20px' />}
            onClick={addFilterItem}
            data-test-id='filterDrawerAddConditionBtn'
          >
            <ConditionButtonText>Добавить условие</ConditionButtonText>
          </ConditionFilterButton>
        </List>
      </FilterWrap>
      <div className={classes.drawerFooter}>
        <ConditionCancelButton
          data-test-id='filterDrawerCancelBtn'
          color='secondary'
          leftIcon={<CancelIcon />}
          onClick={() => {
            dropFilterItems()
            if (location.pathname.includes('search')) {
              history.push({
                pathname: location.pathname.replace('/search', ''),
                search: query.toString(),
              })
            }
          }}
        >
          <ConditionButtonText>Сбросить</ConditionButtonText>
        </ConditionCancelButton>
        <Tooltip
          title={promptToFillInTheFilter ? 'Добавьте условия для фильтра' : ''}
        >
          <ConditionCancelButton
            data-test-id='filterDrawerApplyAndCloseBtn'
            onClick={handleApplyButton}
            leftIcon={<CompleteIcon />}
          >
            <ConditionButtonText>Применить и закрыть</ConditionButtonText>
          </ConditionCancelButton>
        </Tooltip>
      </div>
    </div>
  )

  const notAssignments =
    location.pathname.includes('positions') ||
    location.pathname.includes('suppliers') ||
    location.pathname.includes('agreements')

  return (
    <div className={classes.searchFilterContainer}>
      {!isHidden ? (
        <>
          {notAssignments ? (
            <AppHeaderSearchBarOtherPage
              searchFilterParams={searchFilterParams}
              categoryId={categoryId}
              isArchived={isArchived}
              onSearchInputChange={setSearchInputValue}
              searchInputValue={searchInputValue}
              onSearch={onSearch}
              tableValue={tableValue}
            />
          ) : (
            <AppHeaderSearchBarAssignment
              searchFilterParams={searchFilterParams}
              flagSentRequest={flagSentRequest}
              handleChangeFlagSent={handleChangeFlagSent}
              getSearchAssignmentFilterItems={getSearchAssignmentFilterItems}
              flagBackPage={flagBackPage}
              flagBackPageHandler={flagBackPageHandler}
              dropFilterItems={dropFilterItems}
              isArchived={isArchived}
            />
          )}
          {(filterItems.length > 0 || catalog.filter.searchDate) &&
          flagApplyBtn ? (
            <div className={classes.filterButtonContainer}>
              <FilterButton
                text={`Фильтр ${
                  catalog.filter.searchDate
                    ? filterItems.length + 1
                    : filterItems.length
                }`}
                active={true}
                onFilterClick={(): void => showDrawerHandler(true)}
                onCancelClick={() => {
                  dropFilterItems()
                  if (location.pathname.includes('search')) {
                    history.push({
                      pathname: location.pathname.replace('/search', ''),
                      search: query.toString(),
                    })
                  }
                }}
              />
            </div>
          ) : (
            <>
              <FilterButton
                text={'Фильтр'}
                active={false}
                onFilterClick={(): void => showDrawerHandler(true)}
              />
            </>
          )}
          {openDrawer && (
            <Drawer
              className={classes.drawerList}
              anchor={'right'}
              open={openDrawer}
              onClose={(): void => showDrawerHandler(false)}
            >
              {renderDrawerList()}
            </Drawer>
          )}
        </>
      ) : null}
    </div>
  )
}
