import React from 'react'
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import { OwnProps } from './IListEditorItem'
import { useStyles } from './Style'

export const ListEditorItem: React.FC<OwnProps> = ({
  value,
  onDelete,
  dataTestIdPrefix,
  ...other
}) => {
  const classes = useStyles()

  return (
    <ListItem
      className={classes.root}
      data-test-id={dataTestIdPrefix}
      {...other}
    >
      <ListItemText primary={value} />
      <ListItemSecondaryAction>
        <IconButton
          data-test-id={`${dataTestIdPrefix}DeleteBtn`}
          edge='end'
          aria-label='delete'
          onClick={onDelete}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
