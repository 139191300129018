export enum EAPIResponseStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  ERROR = 'error',
}

export enum Authorities {
  ITEMS_PAGE = 'ITEMS_PAGE', //Позиции. Доступ к странице
  ITEMS_SHOW_TAB_ALL = 'ITEMS_SHOW_TAB_ALL', //Позиции. Показать вкладку «Все»
  ITEMS_SHOW_TAB_PASSED_GT = 'ITEMS_SHOW_TAB_PASSED_GT', //Позиции. Показать вкладку «Прошедшие гранд-тендер»
  ITEMS_SHOW_TAB_NOT_PASSED_GT = 'ITEMS_SHOW_TAB_NOT_PASSED_GT', //Позиции. Показать вкладку «Не прошедшие гранд-тендер»
  ITEMS_SHOW_TAB_ARCHIVE = 'ITEMS_SHOW_TAB_ARCHIVE', //Позиции. Показать вкладку «Архив»
  ITEMS_EDIT_CATALOG = 'ITEMS_EDIT_CATALOG', //Позиции. Разрешить редактирование каталога
  ITEMS_CREATE_ITEM = 'ITEMS_CREATE_ITEM', //Позиции. Разрешить создание позиций
  ITEMS_EDIT_ITEM = 'ITEMS_EDIT_ITEM', //Позиции. Разрешить редактирование позиций
  ITEMS_REMOVE_ITEM = 'ITEMS_REMOVE_ITEM', //Позиции. Разрешить удаление позиций
  ITEMS_ADD_ITEM_TO_TENDER = 'ITEMS_ADD_ITEM_TO_TENDER', //Позиции. Разрешить добавление позиций в тендерное задание
  ITEMS_EXPORT = 'ITEMS_EXPORT', //Позиции. Разрешить экспорт
  ITEMS_IMPORT = 'ITEMS_IMPORT', //Позиции. Разрешить импорт
  ITEMS_SEARCH_AND_FILTER_ITEMS = 'ITEMS_SEARCH_AND_FILTER_ITEMS', //Позиции. Разрешить поиск и фильтрацию позиций
  ASSIGNMENTS_SHOW_TAB_ACTIVE = 'ASSIGNMENTS_SHOW_TAB_ACTIVE', //Тендерные задания. Показать вкладку «Активные»
  ASSIGNMENTS_SHOW_TAB_COMPLETED = 'ASSIGNMENTS_SHOW_TAB_COMPLETED', //Тендерные задания. Показать вкладку «Завершенные»
  ASSIGNMENTS_EDIT_CATALOG = 'ASSIGNMENTS_EDIT_CATALOG', //Тендерные задания. Разрешить редактирование каталога
  ASSIGNMENTS_CREATE_ITEM = 'ASSIGNMENTS_CREATE_ITEM', //Тендерные задания. Разрешить создание тендерных заданий
  ASSIGNMENTS_EDIT_ITEM = 'ASSIGNMENTS_EDIT_ITEM', //Тендерные задания. Разрешить редактирование тендерных заданий
  ASSIGNMENTS_EDIT_COMPLETED = 'ASSIGNMENTS_EDIT_COMPLETED', //Тендерные задания. Разрешить редактирование завершенных тендерных заданий
  ASSIGNMENTS_REMOVE_ITEM = 'ASSIGNMENTS_REMOVE_ITEM', //Тендерные задания. Разрешить удаление тендерных заданий
  ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS = 'ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS', //Тендерные задания. Разрешить поиск и фильтрацию тендерных заданий
  ASSIGNMENT_DETAILS_CREATE_LOT = 'ASSIGNMENT_DETAILS_CREATE_LOT', //Детали тендерного задания. Разрешить создание лотов
  ASSIGNMENT_DETAILS_RENAME_LOT = 'ASSIGNMENT_DETAILS_RENAME_LOT', //Детали тендерного задания. Разрешить переименование лотов
  ASSIGNMENT_DETAILS_REMOVE_LOT = 'ASSIGNMENT_DETAILS_REMOVE_LOT', //Детали тендерного задания. Разрешить удаление лотов
  ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT = 'ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT', //Детали тендерного задания. Разрешить смену тендерного задания для лота
  ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT = 'ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT', //Детали тендерного задания. Разрешить поставщиков в лоты
  ASSIGNMENT_DETAILS_REMOVE_SUPPLIER_FROM_LOT = 'ASSIGNMENT_DETAILS_REMOVE_SUPPLIER_FROM_LOT', //Детали тендерного задания. Разрешить удаление поставщиков из лотов
  ASSIGNMENT_DETAILS_EDIT_SUPPLIER_PROPERTIES = 'ASSIGNMENT_DETAILS_EDIT_SUPPLIER_PROPERTIES', //Детали тендерного задания. Разрешить создание/редактирование/удаление колонок поставщика
  ASSIGNMENT_DETAILS_CREATE_AGREEMENT = 'ASSIGNMENT_DETAILS_CREATE_AGREEMENT', //Детали тендреного задания. Разрешить создание соглашений
  ASSIGNMENT_DETAILS_REMOVE_ITEM_FROM_LOT = 'ASSIGNMENT_DETAILS_REMOVE_ITEM_FROM_LOT', //Детали тендерного задания. Разрешить удаление позиций из лота
  AGREEMENTS_SHOW_TAB_ACTIVE = 'AGREEMENTS_SHOW_TAB_ACTIVE', //Соглашения. Показать вкладку «Все»
  AGREEMENTS_SHOW_TAB_COMPLETED = 'AGREEMENTS_SHOW_TAB_COMPLETED', //Соглашения. Показать вкладку «Завершенные»
  AGREEMENTS_EDIT_CATALOG = 'AGREEMENTS_EDIT_CATALOG', //Соглашения. Разрешить редактирование каталога
  AGREEMENTS_EDIT_ITEM = 'AGREEMENTS_EDIT_ITEM', //Соглашения. Разрешить редактирование соглашений
  AGREEMENTS_REMOVE_ITEM = 'AGREEMENTS_REMOVE_ITEM', //Соглашения. Разрешить удаление соглашений
  AGREEMENTS_EXPORT = 'AGREEMENTS_EXPORT', //Соглашения. Разрешить экспорт
  AGREEMENTS_SEARCH_AND_FILTER_ITEMS = 'AGREEMENTS_SEARCH_AND_FILTER_ITEMS', //Соглашения. Разрешить поиск и фильтрацию соглашений
  SUPPLIERS_PAGE = 'SUPPLIERS_PAGE', //Поставщики. Доступ к странице
  SUPPLIERS_SHOW_TAB_ACTIVE = 'SUPPLIERS_SHOW_TAB_ACTIVE', //Поставщики. Показать вкладку «Прошедшие гранд-тендер»
  SUPPLIERS_EDIT_CATALOG = 'SUPPLIERS_EDIT_CATALOG', //Поставщики. Разрешить редактирование каталога
  SUPPLIERS_SHOW_ALL_ITEMS = 'SUPPLIERS_SHOW_ALL_ITEMS', //Поставщики. Разрешить просмотр всех соглашений
  SUPPLIERS_CREATE_ITEM = 'SUPPLIERS_CREATE_ITEM', //Поставщики. Разрешить создание позиций
  SUPPLIERS_REMOVE_ITEM = 'SUPPLIERS_REMOVE_ITEM', //Поставщики. Разрешить удаление позиций
  SUPPLIERS_EXPORT = 'SUPPLIERS_EXPORT', //Поставщики. Разрешить экспорт
  SUPPLIERS_IMPORT = 'SUPPLIERS_IMPORT', //Поставщики. Разрешить импорт
  SUPPLIERS_SEARCH_AND_FILTER_ITEMS = 'SUPPLIERS_SEARCH_AND_FILTER_ITEMS', //Поставщики. Разрешить поиск и фильтрацию тендерных заданий
  TENDER_CONDITIONS_CREATE_CONDITION = 'TENDER_CONDITIONS_CREATE_CONDITION', // Добавить поле тендерного условия
  ITEMS_SHOW_AGREEMENT_PROVIDER_ON_SIDEBAR_VIEW = 'ITEMS_SHOW_AGREEMENT_PROVIDER_ON_SIDEBAR_VIEW', //Позиции. Разрешить просмотр соглашений и поставщиков в деталях позиции
}

export enum Resources {
  GRAND_TENDER_TAB = 'GRAND_TENDER_TAB',
  GRAND_TENDER_POSITION = 'GRAND_TENDER_POSITION',
  GRAND_TENDER_CATEGORY = 'GRAND_TENDER_CATEGORY',
  GRAND_TENDER_TASK = 'GRAND_TENDER_TASK',
  GRAND_TENDER_TASK_LOT = 'GRAND_TENDER_TASK_LOT',
  GRAND_TENDER_TASK_AGREEMENT = 'GRAND_TENDER_TASK_AGREEMENT',
  GRAND_TENDER_PROVIDER = 'GRAND_TENDER_PROVIDER',
  GRAND_TENDER_PROVIDER_SPECIALIZATION = 'GRAND_TENDER_PROVIDER_SPECIALIZATION',
  GRAND_TENDER_CONDITION = 'GRAND_TENDER_CONDITION',
}

export enum Scopes {
  VIEW_GRAND_TENDER_POSITION_ALL = 'VIEW_GRAND_TENDER_POSITION_ALL',
  VIEW_GRAND_TENDER_POSITION_COMPLETED = 'VIEW_GRAND_TENDER_POSITION_COMPLETED',
  VIEW_GRAND_TENDER_POSITION_ACTIVE = 'VIEW_GRAND_TENDER_POSITION_ACTIVE',
  VIEW_GRAND_TENDER_POSITION_ARCHIVE = 'VIEW_GRAND_TENDER_POSITION_ARCHIVE',
  VIEW_GRAND_TENDER_AGREEMENT_ACTIVE = 'VIEW_GRAND_TENDER_AGREEMENT_ACTIVE',
  VIEW_GRAND_TENDER_AGREEMENT_COMPLETED = 'VIEW_GRAND_TENDER_AGREEMENT_COMPLETED',
  VIEW_GRAND_TENDER_PROVIDER_ALL = 'VIEW_GRAND_TENDER_PROVIDER_ALL',
  VIEW_GRAND_TENDER_PROVIDER_ACTIVE = 'VIEW_GRAND_TENDER_PROVIDER_ACTIVE',
  VIEW_GRAND_TENDER_TASK_ACTIVE = 'VIEW_GRAND_TENDER_TASK_ACTIVE',
  VIEW_GRAND_TENDER_TASK_COMPLETED = 'VIEW_GRAND_TENDER_TASK_COMPLETED',
  VIEW_OWN = 'VIEW_OWN',
  VIEW_FOREIGN = 'VIEW_FOREIGN',
  VIEW_DETAILS = 'VIEW_DETAILS',
  EDIT_OWN = 'EDIT_OWN',
  EDIT_FOREIGN = 'EDIT_FOREIGN',
  EDIT_COMPLETED = 'EDIT_COMPLETED',
  RENAME = 'RENAME',
  REMOVE = 'REMOVE',
  CREATE = 'CREATE',
}

type ResourceScopeCombination = {
  resource: Resources
  scopes: Scopes[]
}

//Для каждой роли в соответствие указан массив комбинаций, каждая из которых подтверждает ее наличие
export const mapRolesToResourceScope: Record<
  Authorities,
  ResourceScopeCombination[]
> = {
  ITEMS_PAGE: [
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  ITEMS_SHOW_TAB_ALL: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_POSITION_ALL],
    },
  ],
  ITEMS_SHOW_TAB_PASSED_GT: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_POSITION_COMPLETED],
    },
  ],
  ITEMS_SHOW_TAB_NOT_PASSED_GT: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_POSITION_ACTIVE],
    },
  ],
  ITEMS_SHOW_TAB_ARCHIVE: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_POSITION_ARCHIVE],
    },
  ],
  ITEMS_EDIT_CATALOG: [
    { resource: Resources.GRAND_TENDER_CATEGORY, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_CATEGORY,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ITEMS_CREATE_ITEM: [
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.EDIT_OWN] },
  ],
  ITEMS_EDIT_ITEM: [
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ITEMS_REMOVE_ITEM: [
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ITEMS_ADD_ITEM_TO_TENDER: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ITEMS_EXPORT: [
    { resource: Resources.GRAND_TENDER_CATEGORY, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_CATEGORY,
      scopes: [Scopes.VIEW_FOREIGN],
    },
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  ITEMS_IMPORT: [
    { resource: Resources.GRAND_TENDER_CATEGORY, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_CATEGORY,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ITEMS_SEARCH_AND_FILTER_ITEMS: [
    { resource: Resources.GRAND_TENDER_POSITION, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  ASSIGNMENTS_SHOW_TAB_ACTIVE: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_TASK_ACTIVE],
    },
  ],
  ASSIGNMENTS_SHOW_TAB_COMPLETED: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_TASK_COMPLETED],
    },
  ],
  ASSIGNMENTS_EDIT_CATALOG: [
    { resource: Resources.GRAND_TENDER_CATEGORY, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_CATEGORY,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ASSIGNMENTS_CREATE_ITEM: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.VIEW_OWN] },
  ],
  ASSIGNMENTS_EDIT_ITEM: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_OWN] },
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_FOREIGN] },
  ],
  ASSIGNMENTS_EDIT_COMPLETED: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_COMPLETED] },
  ],
  ASSIGNMENTS_REMOVE_ITEM: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_OWN] },
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_FOREIGN] },
  ],
  ASSIGNMENTS_SEARCH_AND_FILTER_ITEMS: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.VIEW_OWN] },
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.VIEW_FOREIGN] },
  ],
  ASSIGNMENT_DETAILS_CREATE_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.VIEW_OWN] },
  ],
  ASSIGNMENT_DETAILS_RENAME_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.RENAME] },
  ],
  ASSIGNMENT_DETAILS_REMOVE_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.REMOVE] },
  ],
  ASSIGNMENT_DETAILS_CHANGE_TENDER_FOR_LOT: [
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_OWN] },
    { resource: Resources.GRAND_TENDER_TASK, scopes: [Scopes.EDIT_FOREIGN] },
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ASSIGNMENT_DETAILS_ADD_SUPPLIER_TO_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ASSIGNMENT_DETAILS_REMOVE_SUPPLIER_FROM_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ASSIGNMENT_DETAILS_EDIT_SUPPLIER_PROPERTIES: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  ASSIGNMENT_DETAILS_CREATE_AGREEMENT: [
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.VIEW_OWN],
    },
  ],
  ASSIGNMENT_DETAILS_REMOVE_ITEM_FROM_LOT: [
    { resource: Resources.GRAND_TENDER_TASK_LOT, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_TASK_LOT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  AGREEMENTS_SHOW_TAB_ACTIVE: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_AGREEMENT_ACTIVE],
    },
  ],
  AGREEMENTS_SHOW_TAB_COMPLETED: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_AGREEMENT_COMPLETED],
    },
  ],
  AGREEMENTS_EDIT_CATALOG: [
    { resource: Resources.GRAND_TENDER_CATEGORY, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_CATEGORY,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  AGREEMENTS_EDIT_ITEM: [
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.EDIT_OWN],
    },
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  AGREEMENTS_REMOVE_ITEM: [
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.EDIT_OWN],
    },
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  AGREEMENTS_EXPORT: [
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.VIEW_OWN],
    },
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  AGREEMENTS_SEARCH_AND_FILTER_ITEMS: [
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.VIEW_OWN],
    },
    {
      resource: Resources.GRAND_TENDER_TASK_AGREEMENT,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  SUPPLIERS_PAGE: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_PROVIDER_ALL],
    },
  ],
  SUPPLIERS_SHOW_TAB_ACTIVE: [
    {
      resource: Resources.GRAND_TENDER_TAB,
      scopes: [Scopes.VIEW_GRAND_TENDER_PROVIDER_ACTIVE],
    },
  ],
  SUPPLIERS_EDIT_CATALOG: [
    {
      resource: Resources.GRAND_TENDER_PROVIDER_SPECIALIZATION,
      scopes: [Scopes.EDIT_OWN],
    },
    {
      resource: Resources.GRAND_TENDER_PROVIDER_SPECIALIZATION,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  SUPPLIERS_SHOW_ALL_ITEMS: [
    {
      resource: Resources.GRAND_TENDER_PROVIDER,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  SUPPLIERS_CREATE_ITEM: [
    { resource: Resources.GRAND_TENDER_PROVIDER, scopes: [Scopes.CREATE] },
  ],
  SUPPLIERS_REMOVE_ITEM: [
    { resource: Resources.GRAND_TENDER_PROVIDER, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_PROVIDER,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  SUPPLIERS_EXPORT: [
    { resource: Resources.GRAND_TENDER_PROVIDER, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_PROVIDER,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  SUPPLIERS_IMPORT: [
    { resource: Resources.GRAND_TENDER_PROVIDER, scopes: [Scopes.EDIT_OWN] },
    {
      resource: Resources.GRAND_TENDER_PROVIDER,
      scopes: [Scopes.EDIT_FOREIGN],
    },
  ],
  SUPPLIERS_SEARCH_AND_FILTER_ITEMS: [
    { resource: Resources.GRAND_TENDER_PROVIDER, scopes: [Scopes.VIEW_OWN] },
    {
      resource: Resources.GRAND_TENDER_PROVIDER,
      scopes: [Scopes.VIEW_FOREIGN],
    },
  ],
  TENDER_CONDITIONS_CREATE_CONDITION: [
    { resource: Resources.GRAND_TENDER_CONDITION, scopes: [Scopes.VIEW_OWN] },
  ],
  ITEMS_SHOW_AGREEMENT_PROVIDER_ON_SIDEBAR_VIEW: [
    {
      resource: Resources.GRAND_TENDER_POSITION,
      scopes: [Scopes.VIEW_DETAILS],
    },
  ],
}
