import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import * as I from './IAppHeader'

import { AppHeaderPositionsContainer } from './AppHeaderPositionsContainer'
import { AppHeaderSuppliersContainer } from './AppHeaderSuppliersContainer'
import { AppHeaderAgreementContainer } from './AppHeaderAgreement/AppHeaderAgreementContainer'
import { AppHeaderAssignmentContainer } from './AppHeaderAssignment/AppHeaderAssignmentContainer'

const AppHeaderPL: React.FC<I.OwnProps & I.StateProps & I.DispatchProps> = (
  { ...props },
  context,
) => {
  const { setCategorySupplier, setAvailableColumns } = props

  const location = useLocation()
  const { agreementId, assignmentId } = useParams<{
    agreementId?: string
    assignmentId?: string
  }>()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [flagBackPage, setFlagBackPage] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const a11yProps = (index: number): Record<string, unknown> => ({
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    value: index,
  })

  const showDrawerHandler = (isShow: boolean): void => {
    setOpenDrawer(isShow)
  }

  const flagBackPageHandler = (): void => {
    setFlagBackPage(false)
  }

  const refreshCategoryId = (): void => {
    setCategorySupplier('')
    setAvailableColumns([])
  }

  if (location.pathname.includes('suppliers')) {
    return null
  }

  const getHeader = (pathname: string) => {
    const search = pathname.includes('search')
    if (pathname.includes('positions')) {
      return (
        <AppHeaderPositionsContainer
          search={search}
          showDrawerHandler={showDrawerHandler}
          flagBackPageHandler={flagBackPageHandler}
          refreshCategoryId={refreshCategoryId}
          a11yProps={a11yProps}
          flagBackPage={flagBackPage}
          setFlagBackPage={setFlagBackPage}
          openDrawer={openDrawer}
          {...props}
        />
      )
    }
    if (pathname.includes('suppliers')) {
      return (
        <AppHeaderSuppliersContainer
          search={search}
          showDrawerHandler={showDrawerHandler}
          flagBackPageHandler={flagBackPageHandler}
          refreshCategoryId={refreshCategoryId}
          a11yProps={a11yProps}
          flagBackPage={flagBackPage}
          setFlagBackPage={setFlagBackPage}
          openDrawer={openDrawer}
          {...props}
        />
      )
    }
    if (pathname.includes('/agreements')) {
      return (
        <AppHeaderAgreementContainer
          search={search}
          agreementOpen={Boolean(agreementId)}
          showDrawerHandler={showDrawerHandler}
          flagBackPageHandler={flagBackPageHandler}
          refreshCategoryId={refreshCategoryId}
          a11yProps={a11yProps}
          flagBackPage={flagBackPage}
          setFlagBackPage={setFlagBackPage}
          openDrawer={openDrawer}
          {...props}
        />
      )
    }
    if (pathname.includes('/assignments')) {
      return (
        <AppHeaderAssignmentContainer
          search={search}
          assignmentOpen={Boolean(assignmentId)}
          showDrawerHandler={showDrawerHandler}
          flagBackPageHandler={flagBackPageHandler}
          refreshCategoryId={refreshCategoryId}
          a11yProps={a11yProps}
          flagBackPage={flagBackPage}
          setFlagBackPage={setFlagBackPage}
          openDrawer={openDrawer}
          {...props}
        />
      )
    }
    return <>{null}</>
  }

  return getHeader(location.pathname)
}

export default AppHeaderPL
