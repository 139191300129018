import React, { useState } from 'react'

import { ExpandMoreIcon } from '../../icons/ExpandMoreIcon'

import { ExpandButtonWrap } from './ExpandButton.styled'

type ExpandButtonProps = {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  expanded: boolean
}

export const ExpandButton = ({ onClick, expanded }: ExpandButtonProps) => {
  const [hover, setHover] = useState(false)

  return (
    <ExpandButtonWrap
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      onClick={onClick}
      hover={hover}
      expanded={expanded}
    >
      <ExpandMoreIcon />
    </ExpandButtonWrap>
  )
}
