import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '645px',
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -5px',
    '& button': {
      margin: '0 5px',
    },
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '& .MuiFormHelperText-root': {
      color: 'red',
    },
  },
  autocompletePaper: {
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
  },
  drawerAutocomplete: {
    '& .MuiAutocomplete-root': {
      transition: theme.transitions.create('width'),
      height: '40px',
      color: 'inherit',
    },
    '&.MuiAutocomplete-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-input': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      outline: 'none',
    },
    '& .MuiAutocomplete-listbox': {
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1 !important',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888 !important',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555 !important',
      },
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
  },
}))
