import { makeStyles, Theme } from '@material-ui/core/styles'

const useImprovedTableRowStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    margin: '0',
    padding: '0',
    '&$rowSelected, &$rowSelected:hover,': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    cursor: 'pointer',
    '& .MuiAvatar-root': {
      borderRadius: 0,
      border: '1px solid #E2E4E9;',
      display: 'inline-flex',
      marginRight: '5px',
    },
    '& .flex-container': {
      display: 'flex',
    },
    '& span': {
      alignSelf: 'center',
      wordWrap: 'break-word',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.error': {
      backgroundColor: '#FCE6E6',
      '&$rowSelected, &$rowSelected:hover,': {
        backgroundColor: '#FCE6E6',
      },
    },
    '& .MuiTableCell-root': {
      maxWidth: '350px',
    },
  },
  rowSelected: {},
  checkedRow: {
    '&:hover, &:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '&$checkboxChecked, &$checkboxChecked:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& .MuiTableCell-root': {
      border: '0',
    },
  },
  checkboxChecked: {},
  customTooltip: {
    background: 'rgba(244, 246, 249, 1)',
    margin: '10px 0 0 0',
    padding: '0',
    minWidth: '200px',
    width: '100%',
    maxWidth: 'none',
    boxShadow: '1px 1px 3px 0px rgba(0,0,0,.1);',
  },
  tooltip: {},
  customArrow: {
    color: 'rgba(244, 246, 249, .9)',
    textAlign: 'center',
  },
  arrow: {},
  stickyCheckbox: {
    position: 'sticky',
    top: '0',
    left: '0',
    background: '#fff',
    zIndex: 2,
    '& + td': {
      left: '64px',
    },
    '&.MuiTableCell-sizeSmall.MuiTableCell-paddingCheckbox': {
      width: '64px',
    },
  },
  stickyCell: {
    position: 'sticky',
    top: '0',
    left: '0',
    background: '#fff',
    zIndex: 2,
  },
  settingsEmptyBodyCell: {
    '&:nth-child(odd)': {
      background: '#fafafa',
    },
    '.error &:nth-child(odd)': {
      background: '#f3dddd',
    },
  },
}))

export default useImprovedTableRowStyles
