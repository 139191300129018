const getTextDeclension = (
  number: number,
  stringArr: string[],
  cases: number[],
) =>
  stringArr[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ]

export const getPositionText = (positionCount: number) =>
  getTextDeclension(
    positionCount,
    ['позиция', 'позиции', 'позиций'],
    [2, 0, 1, 1, 1, 2],
  )

export const getSupplierText = (count: number) =>
  getTextDeclension(
    count,
    ['поставщик', 'поставщика', 'поставщиков'],
    [2, 0, 1, 1, 1, 2],
  )
