/* eslint-disable no-undefined */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
  Dialog,
  FormControlLabel,
  TextField,
  Switch,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import InputMask from 'react-input-mask'
import * as _ from 'lodash'

import moment from 'moment'

import { TAgreementUpdateRequest, IColumn } from '../../types'
import {
  EButtonType,
  EColumnType,
  REVERSE_DISPLAY_DATE_FORMAT,
} from '../../constants'
import { ModalTitle } from '../BaseModal/ModalTitle/ModalTitle'
import { ModalContent } from '../BaseModal/ModalContent/ModalContent'
import { ModalActions } from '../BaseModal/ModalActions/ModalActions'

import { ButtonComponent } from '../Button/Button'

import { validateDateProp, validate } from '../../utils/validate'

import { useStyles } from './Style'
import * as I from './IEditAgreementModal'

export const EditAgreementModal: React.FC<I.OwnProps> = ({
  open,
  model,
  onClose,
  onSave,
}): React.ReactElement => {
  const classes = useStyles()
  const [state, setState] = useState<TAgreementUpdateRequest>({
    agreementId: model.agreementId,
    expire: model.expire
      ? moment(model.expire).format(REVERSE_DISPLAY_DATE_FORMAT)
      : '',
    start: model.start
      ? moment(model.start).format(REVERSE_DISPLAY_DATE_FORMAT)
      : '',
    data: model.data.filter(d =>
      model.columns.filter(c => c.editable).some(c => c.key === d.key),
    ),
  })

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    expire: '',
    start: '',
    ..._.reduce(state.data, (acc, { key }) => ({ ...acc, [key]: '' }), {}),
  })

  const handleDateChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    let err = ''
    const { value, name } = event.target
    const diffDuration = moment.duration(moment(value).diff(new Date()))

    if (!value || !value.trim()) {
      err = 'Обязательное поле'
    } else if (validateDateProp(value)) {
      err = 'Неверная дата'
    } else if (name === 'expire' && diffDuration.days() < 0) {
      err = 'Выбранная дата уже прошла'
    }

    if (
      name === 'start' &&
      state.expire.trim() !== '' &&
      moment(value).isAfter(state.expire)
    ) {
      setErrors({ ...errors, [name]: err })
      setState({ ...state, [name]: value, expire: value })
      return
    }

    setErrors({ ...errors, [name]: err })
    setState({ ...state, [name]: value })
  }

  const handleBooleanPropChanged = (key: string): void => {
    const newState = _.cloneDeep(state)
    const item = newState.data.find(prop => prop.key === key)

    if (item) {
      item.value = (item.value !== 'true').toString()
      setState(newState)
    }
  }

  const handleStringPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value
      setState(newState)
    }
  }

  const handleIntegerPropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      setState(newState)
    }
  }

  const handleDatePropChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: IColumn,
  ): void => {
    validate(prop, event.target.value, errors, setErrors)

    const newState = _.cloneDeep(state)
    const item = newState.data.find(p => p.key === prop.key)

    if (item) {
      item.value = event.target.value && event.target.value.trim()
      item.value = moment(event.target.value).format('YYYY-MM-DDTHH:mm')
      setState(newState)
    }
  }

  const validateDateForm = (name: 'expire' | 'start') => {
    const error: { [key: string]: string } = {}

    if (!state[name] || !state[name].trim().length) {
      error[name] = 'Обязательное поле'
    } else if (validateDateProp(state[name])) {
      error[name] = 'Неверная дата'
    }
    const diffDuration = moment.duration(moment(state[name]).diff(new Date()))
    if (name === 'expire' && diffDuration.days() < 0) {
      error[name] = 'Выбранная дата уже прошла'
    }

    return error
  }

  const validateForm = (): { [key: string]: string } => {
    const error: { [key: string]: string } = {}

    state.data.forEach(d => {
      const prop = model.columns.find((p: IColumn) => p.key === d.key)
      if (prop) {
        const msg = validate(prop, d.value, errors, setErrors)
        if (msg.length) {
          error[prop.key] = msg
        }
      }
    })

    if (!state.expire || !state.expire.trim().length) {
      error.expire = 'Обязательное поле'
    } else if (validateDateProp(state.expire)) {
      error.expire = 'Неверная дата'
    }

    if (!state.start || !state.start.trim().length) {
      error.start = 'Обязательное поле'
    } else if (validateDateProp(state.start)) {
      error.start = 'Неверная дата'
    }

    return {
      ...error,
      ...validateDateForm('start'),
      ...validateDateForm('expire'),
    }
  }

  const saveBtnHandler = (): void => {
    const error = validateForm()

    if (_.keys(error).length) {
      setErrors(error)
      return
    }

    onSave(state)
  }

  const onDialogClose = (): void => {
    setErrors({})
    onClose()
  }

  const renderProperties = model.columns.map(prop => {
    switch (prop.type) {
      case EColumnType.BOOLEAN:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <Switch
                checked={
                  state.data.find(r => r.key === prop.key)?.value === 'true' ||
                  false
                }
                onChange={(): void => handleBooleanPropChanged(prop.key)}
                name={prop.key}
                data-test-id={`editAgreementModalPropInput_${prop.key}`}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.STRING:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <TextField
                type='text'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                defaultValue={
                  state.data.find(p => p.key === prop.key)?.value || ''
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleStringPropChanged(e, prop)
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : null,
                  inputMode: 'text',
                }}
                inputProps={{
                  'data-test-id': `editAgreementModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.PHONE:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <InputMask
                mask='+7 (999) 999-99-99'
                maskChar='*'
                alwaysShowMask={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleStringPropChanged(e, prop)
                }
                value={
                  state.data.find(p => p.key === prop.key)
                    ? state.data.find(p => p.key === prop.key)?.value
                    : ''
                }
              >
                {(): JSX.Element => (
                  <TextField
                    type='phone'
                    required={prop.required}
                    error={errors[prop.key]?.length > 0}
                    helperText={errors[prop.key]}
                    variant='filled'
                    defaultValue={
                      state.data.find(p => p.key === prop.key)?.value || ''
                    }
                    inputProps={{
                      'data-test-id': `editAgreementModalPropInput_${prop.key}`,
                    }}
                  />
                )}
              </InputMask>
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DOUBLE:
      case EColumnType.CURRENCY:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            control={
              <TextField
                type='number'
                required={prop.required}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                variant='filled'
                onKeyDown={(event: React.KeyboardEvent): boolean => {
                  if (
                    ![
                      '0',
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      ',',
                      '.',
                      'Backspace',
                    ].some(r => event.key === r)
                  ) {
                    event.preventDefault()
                    return false
                  }
                  return true
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleIntegerPropChanged(e, prop)
                }
                defaultValue={
                  state.data.find(p => p.key === prop.key)?.value || ''
                }
                InputProps={{
                  endAdornment: prop.unit ? (
                    <InputAdornment position='end'>{prop.unit}</InputAdornment>
                  ) : null,
                  inputMode: 'numeric',
                }}
                inputProps={{
                  'data-test-id': `editAgreementModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      case EColumnType.DATE:
        return (
          <FormControlLabel
            className='prop'
            key={prop.key}
            style={{ display: 'flex', flexWrap: 'wrap' }}
            control={
              <TextField
                id='date'
                type='date'
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                  handleDatePropChanged(e, prop)
                }
                style={{
                  width: 279,
                  marginLeft: '10px',
                  marginRight: '10px',
                }}
                error={errors[prop.key]?.length > 0}
                helperText={errors[prop.key]}
                required={prop.required}
                value={
                  state.data.find(p => p.key === prop.key)?.value
                    ? moment(
                        state.data.find(p => p.key === prop.key)?.value,
                      ).format('YYYY-MM-DDTHH:mm')
                    : undefined
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  'data-test-id': `editAgreementModalPropInput_${prop.key}`,
                }}
              />
            }
            label={prop.title}
            labelPlacement='top'
          />
        )
      default:
        return <></>
    }
  })

  return (
    <div>
      {open && (
        <Dialog
          onClose={onDialogClose}
          aria-labelledby='customized-dialog-title'
          open={open}
          disableBackdropClick={true}
          className={classes.root}
        >
          <ModalTitle
            dataTestIdPrefix='editAgreementModal'
            id='customized-dialog-title'
            onClose={onDialogClose}
          >
            Редактировать соглашение
          </ModalTitle>
          <ModalContent dividers>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                className='fullWidth'
                control={
                  <TextField
                    type='text'
                    required={true}
                    variant='filled'
                    fullWidth
                    disabled
                    defaultValue={model.providerName}
                    inputProps={{
                      'data-test-id':
                        'editAgreementModalAgreementSupplierInput',
                    }}
                  />
                }
                label='Поставщик'
                labelPlacement='top'
              />
              <FormControlLabel
                className='fullWidth'
                style={{ maxWidth: '75px', marginLeft: '20px' }}
                control={
                  <TextField
                    type='text'
                    required={true}
                    variant='filled'
                    fullWidth
                    disabled
                    defaultValue={model.number}
                    inputProps={{
                      'data-test-id': 'editAgreementModalAgreementNumberInput',
                    }}
                  />
                }
                label='№'
                labelPlacement='top'
              />
            </div>
            <div className='propContainer'>
              <FormControlLabel
                className='prop'
                control={
                  <TextField
                    id='date'
                    type='date'
                    name='start'
                    error={
                      errors.start !== undefined && errors.start.length > 0
                    }
                    required={true}
                    helperText={errors.start}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleDateChanged(e)
                    }
                    value={
                      state.start.trim().length > 0
                        ? moment(state.start).format(
                            REVERSE_DISPLAY_DATE_FORMAT,
                          )
                        : undefined
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      'data-test-id':
                        'editAgreementModalAgreementDateStartInput',
                    }}
                  />
                }
                label={'Дата начала действия соглашения'}
                labelPlacement='top'
              />
              <FormControlLabel
                className='prop'
                control={
                  <TextField
                    id='date'
                    type='date'
                    name='expire'
                    error={
                      errors.expire !== undefined && errors.expire.length > 0
                    }
                    required={true}
                    helperText={errors.expire}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleDateChanged(e)
                    }
                    value={
                      state.expire.trim().length > 0
                        ? moment(state.expire).format(
                            REVERSE_DISPLAY_DATE_FORMAT,
                          )
                        : undefined
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      'data-test-id':
                        'editAgreementModalAgreementDateExpireInput',
                      min: state.start,
                    }}
                  />
                }
                label={'Дата окончания действия соглашения'}
                labelPlacement='top'
              />
            </div>
            <Divider />
            <FormControlLabel
              className='fullWidth'
              control={
                <TextField
                  type='text'
                  required={true}
                  variant='filled'
                  fullWidth
                  disabled
                  defaultValue={model.tenderTaskName}
                  inputProps={{
                    'data-test-id': 'editAgreementModalAssignmentInput',
                  }}
                />
              }
              label='Тендерное задание'
              labelPlacement='top'
            />
            <FormControlLabel
              className='fullWidth'
              control={
                <TextField
                  type='text'
                  required={true}
                  variant='filled'
                  fullWidth
                  disabled
                  defaultValue={model.lotName}
                  inputProps={{
                    'data-test-id': 'editAgreementModalLotInput',
                  }}
                />
              }
              label='Лот'
              labelPlacement='top'
            />
            <Divider />
            <div className='propContainer'>{renderProperties}</div>
          </ModalContent>
          <ModalActions>
            <div className={classes.buttonContainer}>
              <ButtonComponent
                data-test-id='editAgreementModalCancelBtn'
                text='Отменить'
                type={EButtonType.DEFAULT}
                onClick={onDialogClose}
              />
              <ButtonComponent
                data-test-id='editAgreementModalSubmitBtn'
                text='Сохранить'
                type={EButtonType.PRIMARY}
                onClick={saveBtnHandler}
              />
            </div>
          </ModalActions>
        </Dialog>
      )}
    </div>
  )
}
