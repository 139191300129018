import { makeStyles, Theme } from '@material-ui/core/styles'

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    minHeight: '44px !important',
    margin: '25px 0 20px 0',
    '&.MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0',
    },
    '&.MuiToolbar-regular': {
      minHeight: '0',
    },
  },
  highlight: {
    color: '#000000',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '& .title': {
      marginBottom: '-5px',
    },
    '& .header': {
      fontSize: '24px',
      lineHeight: '29px',
      fontFamily: 'Proxima Nova Regular',
      color: '#1B2B4B',
      wordBreak: 'break-all',
    },
    '& .content': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '15px',
      fontFamily: 'Proxima Nova Regular',
      color: '#535F77',
      marginTop: '5px',
      wordBreak: 'break-all',
    },
  },
  buttonContainer: {
    display: 'flex',
    margin: '0 -5px',
    '& button': {
      margin: '0 5px',
    },
  },
  filterButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 5px',
    '& .MuiButtonBase-root:first-child': {
      margin: '0',
      borderRadius: '5px 0 0 5px',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        width: '14px',
        height: '10px',
        margin: '0 0 0 5px',
      },
    },
    '& .MuiButtonBase-root:last-child': {
      margin: '0',
      borderRadius: '0 5px 5px 0',
      '& .MuiSvgIcon-root': {
        margin: '0',
      },
    },
  },
  filterButtonContainerList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0',
    borderRadius: '5px',
    '& .MuiSvgIcon-root': {
      width: '14px',
      height: '10px',
      margin: '0 0 0 5px',
    },
  },
  tenderCheckboxes: {
    marginLeft: '10px',
  },
  menuCatalog: {
    '& .MuiPaper-root': {
      maxHeight: '550px',
      minHeight: '250px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
}))

export default useToolbarStyles
