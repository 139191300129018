import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiList-root:last-child': {
      padding: '8px 24px 7px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '59px',
      boxShadow: '0px -5px 30px 0px rgba(50, 50, 50, 0.05)',
    },
    '& .MuiButton-label': {
      fontWeight: 'normal',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    '& > .MuiPaper-root': {
      width: '660px',
      '&::-webkit-scrollbar': {
        width: '8px !important',
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      /* Handle on hover */
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  drawerHeader: {
    padding: '20px 20px 5px 26px',
  },
  drawerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawerContent: {
    minHeight: 'calc(100vh - 160px)',
  },
  cardRowContainer: {
    margin: '0 16px 20px 16px;',
  },
  cardRowBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .MuiFormControl-root': {
      margin: '0 10px',
      maxWidth: '280px',
    },
    '& .MuiInputBase-input': {
      lineHeight: '25px',
    },
    '& .MuiInputBase-root': {
      marginTop: '0',
    },

    '& label': {
      marginLeft: '0',
      position: 'relative',
    },
  },
  accordionBody: {
    padding: '10px 0',
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  drawerFooter: {
    position: 'sticky',
    right: 0,
    bottom: 0,
    backgroundColor: '#ffffff',
  },
  secondPartFooter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      margin: '0 0 0 10px',
    },
  },
}))

export default useStyles
