/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetLogEventsResponse } from 'src/reducers/eventsLog'

import { IAPIResult } from '../../types'
import { EActionTypes } from '../../constants'

import { apiClient } from '../../services/base-api-service/base-api-service'

import { actionCreator } from '../BaseAction'

export type Pagination = {
  page: number
  perPage: number
}

export const getLogEvents = createAsyncThunk<void, Pagination>(
  'filter/getLogEvents',
  async ({ page, perPage }, { rejectWithValue, dispatch }) => {
    dispatch({ type: EActionTypes.INIT_GET_LOG_EVENTS })
    const [err, result] = await apiClient.post<
      Pagination,
      IAPIResult<GetLogEventsResponse>
    >({
      path: '/log/page',
      body: {
        page,
        perPage,
      },
    })

    if (err) {
      dispatch({ type: EActionTypes.REJECT_GET_LOG_EVENTS })
      throw rejectWithValue(err)
    }

    if (result.data.data) {
      dispatch(
        actionCreator<GetLogEventsResponse>(
          EActionTypes.RESOLVE_GET_LOG_EVENTS,
          result.data.data,
        ),
      )
    }
  },
)
