import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'

import { DFormKeysNames, DSideBarItemContent } from '../../../../constants'

import { SidebarIconWrapper } from '../SidebarIconWrapper/SidebarIconWrapperPL'

import * as I from './ILink'
import useStyles from './Style'

const LinkPL: React.FC<I.OwnProps> = ({
  formKey,
  resetFilters,
  currentTab,
  ...other
}): React.ReactElement => {
  const classes = useStyles()
  const location = useLocation()

  const checkIfRouteSelected = (): boolean =>
    location.pathname.indexOf(formKey) !== -1

  return (
    <Link
      className={cn(classes.link, { active: checkIfRouteSelected() })}
      replace
      to={`/${formKey}${
        typeof currentTab === 'number' ? `/${currentTab}` : ''
      }`}
      onClick={resetFilters}
      {...other}
    >
      <SidebarIconWrapper path={DSideBarItemContent[formKey]} />
      <div className={classes.linkText}>{DFormKeysNames[formKey]}</div>
    </Link>
  )
}

export default LinkPL
