import * as _ from 'lodash'

import { ITreeListItem, TTreeList } from '../../../../types'
import { ETreeListItemType } from '../../../../constants'

import { findTreeNode, treeify } from '../../../../utils'
import { CatalogState } from '../catalog.types'

export const removeNodeAction = (
  state: CatalogState,
  { payload }: { payload: ITreeListItem },
): CatalogState => {
  const { id, type } = payload
  const modifyDictState = _.cloneDeep(state.modifyDict)
  let newSettingsData = _.cloneDeep(state.settingsTreeData)

  _.remove(
    modifyDictState.concat,
    md => md.sourceId === id || md.destinationId === id,
  )
  _.remove(
    modifyDictState.move,
    md => md.sourceId === id || md.destinationId === id,
  )

  if (modifyDictState.add.some((md: ITreeListItem) => md.id === id)) {
    if (type === ETreeListItemType.FOLDER) {
      const tempTree = treeify([...modifyDictState.add]).filter(
        el => el.id === id,
      )

      const removeHandler = (content: TTreeList): void => {
        content.forEach(i => {
          _.remove(modifyDictState.add, (md: ITreeListItem) => md.id === i.id)
          removeHandler(i.content)
        })
      }

      if (tempTree.length) {
        removeHandler(tempTree[0].content)
      }
    }
    _.remove(modifyDictState.add, (md: ITreeListItem) => md.id === id)
  } else {
    if (modifyDictState.edit.some((md: ITreeListItem) => md.id === id)) {
      _.remove(modifyDictState.edit, (md: ITreeListItem) => md.id === id)
    }
    modifyDictState.remove.push(id)
  }

  const node = findTreeNode(newSettingsData, id)
  if (node) {
    if (!node.parentId) {
      newSettingsData = newSettingsData.filter(
        (item: ITreeListItem) => item.id !== id,
      )
    } else {
      const parentNode = findTreeNode(newSettingsData, node.parentId)
      if (parentNode) {
        parentNode.content = parentNode.content.filter(item => item.id !== id)
      }
    }
  }

  return {
    ...state,
    modifyDict: modifyDictState,
    settingsTreeData: newSettingsData,
    selectedCheckbox: [],
    hasUnsavedChanges: true,
  }
}
