/* eslint-disable require-unicode-regexp */
import { ITreeListItem, TTreeList } from '../../types'

const findTreeElement = (
  node: ITreeListItem,
  key: string,
): ITreeListItem | null => {
  if (node.id.toString() === key.toString()) {
    return node
  }
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < node.content.length; i++) {
    result = findTreeElement(node.content[i], key)
  }
  return result
}

export const findTreeNode = (
  treeData: TTreeList,
  key: string,
): ITreeListItem | null => {
  let result: ITreeListItem | null = null
  for (let i = 0; result === null && i < treeData.length; i++) {
    result = findTreeElement(treeData[i], key)
    if (result) {
      return result
    }
  }

  return result
}
