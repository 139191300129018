import styled from 'styled-components'

export const AgreementHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 35px 10px 5px 10px;
`
export const AgreementText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000335;
  opacity: 0.6;
`

export const Content = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex-basis: content;
`

export const AgreementInfoText = styled(AgreementText)`
  opacity: 1;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e7f1;
`

export const AgreementContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AgreementContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 18px 24px;
  height: 100%;
  overflow-y: auto;
`

export const ProviderContent = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #353b60;
`

export const ProviderAgreementStatus = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #353b60;
`

export const CompleteStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 85px;
  border-radius: 4px;
  background: #0d996640;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #00774c;
`
