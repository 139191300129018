import React from 'react'

import { SupplierWrapper } from './SupplierCard.styled'
import { SupplierRow } from './SupplierRow'

type SupplierCardProps = {
  name: string
  inn: string
  kpp: string
}

export const SupplierCard = (params: SupplierCardProps) => (
  <SupplierWrapper>
    <SupplierRow value={params.name} />
    <SupplierRow property='ИНН' value={params.inn} />
    <SupplierRow property='КПП' value={params.kpp} />
  </SupplierWrapper>
)
