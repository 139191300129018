import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

import styles from './styles.module.scss'

export type SpinnedBackdropProps = {
  showSpinner?: boolean
}

const SpinnedBackdrop: React.FC<SpinnedBackdropProps> = ({
  showSpinner = true,
}) => (
  <Backdrop open={true} className={styles.backdrop}>
    <CircularProgress color='inherit' />
  </Backdrop>
)

export { SpinnedBackdrop }
