import React from 'react'

import useStyles from './Style'

type UserAvatarProps = {
  fullname: string
  bigGrey?: boolean
}

export const UserAvatar = ({ fullname, bigGrey }: UserAvatarProps) => {
  const classes = useStyles()
  const initials = fullname
    .split(' ')
    .map(word => word.slice(0, 1))
    .join('')

  const diameter = bigGrey ? 72 : 28
  const fill = bigGrey ? '#A2A3B7' : '#fff'

  return (
    <div className={classes.wrapper} style={{ maxHeight: `${diameter}px` }}>
      <svg
        width={diameter}
        height={diameter}
        viewBox={`0 0 ${diameter} ${diameter}`}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          cx={diameter / 2}
          cy={diameter / 2}
          r={diameter / 2}
          fill={fill}
        />
      </svg>
      <span className={bigGrey ? classes.bigGreyText : classes.text}>
        {initials}
      </span>
    </div>
  )
}
