import styled from 'styled-components'

export const AssignmentsStickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 11;
`

export const View = styled.div`
  padding: 0 34px;
  height: 100%;
  min-width: 0;
  width: 100%;
`
export const Content = styled.main`
  flex: 1 1 auto;
  padding: 0;
  background-color: white;
`
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Proxima Nova Regular';
  height: 100%;
  & a {
    text-decoration: none;
  }
  & .MuiToolbar-gutters {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0 24px;
  }
  & .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
  }
`
