import { connect, ConnectedProps } from 'react-redux'

import {
  removeAgreements,
  addAgreementProperty,
  editAgreementProperty,
  removeAgreementProperty,
  exportAgreementsByIds,
  getSearchOtherPageFilterItems,
} from '../../../actions'

import { RootState } from '../../../store'

import * as I from './agreement-search-types'

const mapStateToProps = (state: RootState): I.StateProps => ({
  agreements: state.searchOtherPage.responseParams.positionPage,
  searchParams: state.searchOtherPage.requestParams,
  categoriesData: state.searchOtherPage.responseParams.categoriesData,
})

const mapDispatch = {
  deleteAgreements: removeAgreements,
  addProperty: addAgreementProperty,
  editProperty: editAgreementProperty,
  removeProperty: removeAgreementProperty,
  exportAgreementsByIds,
  getSearchResult: getSearchOtherPageFilterItems,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
