/* eslint-disable import/no-anonymous-default-export */
import { EActionTypes } from '../../constants'
import { treeify } from '../../utils'

import { ITreeListItem, TTreeList } from '../../types'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type TSetSpecialisationsAction = IBaseActionCreator<
  EActionTypes.SET_SPECIALISATIONS,
  TTreeList
>

export type TSpecialisationsAction = TSetSpecialisationsAction

export type TSpecialisationsState = {
  list: TTreeList
  nameList: Record<string, string>
  isLoading: boolean
}

export const initialState: TSpecialisationsState = {
  list: [],
  nameList: {},
  isLoading: false,
}

export default (
  state = initialState,
  action: TSpecialisationsAction,
): TSpecialisationsState => {
  switch (action.type) {
    case EActionTypes.SET_SPECIALISATIONS: {
      const names: Record<string, string> = {}
      action.payload.forEach((item: ITreeListItem): void => {
        names[item.id] = item.name
      })

      return {
        ...state,
        list: treeify(action.payload),
        nameList: names,
      }
    }
    default:
      return state
  }
}
