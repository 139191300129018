import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { FormControl, FormLabel, FormHelperText } from '@material-ui/core'

import { FileItem } from './FileItem'

import { useStyles } from './Style'

type Props = {
  disabled?: boolean
  multiple?: boolean
  label?: string
  infoText?: string
  error?: boolean | string
  value?: File[]
  accept?: string[]
  onChange?: (files: File[]) => void
}

export const DropZone: React.FC<Props> = ({
  disabled,
  label = 'Файлы',
  error,
  infoText = 'Кликните по полю или перетащите сюда файл',
  value = [],
  multiple = false,
  accept,
  onChange,
}) => {
  const classes = useStyles()
  const onChangeHandler = useCallback(
    acceptedFiles => {
      if (onChange) {
        onChange(acceptedFiles)
      }
    },
    [onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onChangeHandler,
    disabled,
    multiple,
    accept,
  })
  const onDelete = useCallback(
    (file: File) => {
      onChangeHandler(value.filter(one => one !== file))
    },
    [onChangeHandler, value],
  )

  return (
    <FormControl className={classes.root} error={!!error}>
      <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>
      <div className={classes.files}>
        {value?.map((item, idx) => (
          <FileItem
            key={`${item.name}${item.lastModified}${item.size}`}
            onDelete={onDelete}
            file={item}
            dataTestIdPostfix={`${idx}`}
          />
        ))}
      </div>
      {!value.length && !multiple && (
        <div className={classes.zone} {...getRootProps()}>
          <input
            {...getInputProps()}
            multiple={false}
            data-test-id='dropZoneInput'
          />

          <p className={classes.infoText}>{infoText}</p>
        </div>
      )}
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  )
}
