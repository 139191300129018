import { connect, ConnectedProps } from 'react-redux'

import { TTableData, TSuppliersFilters } from '../../types'

import {
  createSupplier,
  removeSupplier,
  getSuppliersNew,
  modifySupplier,
  exportSuppliersByIds,
  addSupplierProperty,
  editSupplierProperty,
  removeSupplierProperty,
  importSuppliers,
  setCategorySupplier,
  saveCategoriesSupplierChanges,
  getCategoriesSupplier,
  getCategoriesSupplierActive,
  addSectionSupplier,
  editSectionSupplier,
  removeSectionSupplier,
  checkSupplierExistence,
  resetSuppliers,
  setSelectedSuppliers,
  exportTemplate,
  exportAllSuppliers,
} from '../../actions'
import { RootState } from '../../store'
import { setDataLoading } from '../../actions/RequestWrapper'

export interface StateProps {
  suppliers: TTableData
  dataIsLoading: boolean
  categoryId: string
  selectedIds: string[]
  suppliersFilters: TSuppliersFilters
}

const mapStateToProps = (state: RootState): StateProps => ({
  suppliers: state.suppliers.data,
  dataIsLoading: state.viewState.dataIsLoading,
  categoryId: state.suppliers.categoryId,
  selectedIds: state.suppliers.selectedIds,
  suppliersFilters: state.suppliers.suppliersFilters,
})

const mapDispatch = {
  addSupplier: createSupplier,
  deleteSuppliers: removeSupplier,
  resetSuppliers,
  getSuppliersNew,
  editSupplier: modifySupplier,
  checkSupplierExistence,
  exportAllSuppliers,
  exportSuppliers: exportSuppliersByIds,
  addProperty: addSupplierProperty,
  editProperty: editSupplierProperty,
  removeProperty: removeSupplierProperty,
  importSuppliers,
  setCategory: setCategorySupplier,
  saveCategoriesChanges: saveCategoriesSupplierChanges,
  getCategories: getCategoriesSupplier,
  getCategoriesActive: getCategoriesSupplierActive,
  addSection: addSectionSupplier,
  editSection: editSectionSupplier,
  removeSection: removeSectionSupplier,
  setIsLoading: setDataLoading,
  setSelectedSuppliers,
  exportTemplate,
}

export const connector = connect(mapStateToProps, mapDispatch)

export type PropsFromRedux = ConnectedProps<typeof connector>
