import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
export type ModalContent = JSX.Element | null

class ModalService {
  private subscription: ((content: ModalContent) => void) | null = null

  private content: ModalContent = null

  openModal(content: JSX.Element): void {
    this.content = content
    if (this.subscription) {
      this.subscription(this.content)
    }
  }

  closeModal(): void {
    this.content = null
    if (this.subscription) {
      this.subscription(this.content)
    }
  }

  openTextModal(title: string, text: string | string[]): void {
    let body
    if (Array.isArray(text)) {
      body = (
        <ul>
          {text.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )
    } else {
      body = <p>{text}</p>
    }

    this.openModal(
      <Dialog
        open={true}
        onClose={modalService.closeModal.bind(this)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={modalService.closeModal.bind(this)}
            color='primary'
            autoFocus
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>,
    )
  }

  subscribe(
    func: (
      value:
        | ((prevState: JSX.Element | null) => JSX.Element | null)
        | JSX.Element
        | null,
    ) => void,
  ): void {
    this.subscription = func
  }

  unsubscribe(): void {
    this.subscription = null
  }
}

export const modalService = new ModalService()
