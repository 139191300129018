import { useErrorState } from './../../../hooks/useErrorState'
import { useModelState } from './useModelState'
import { useAssignmentList } from './../../AddToAssignmentModal/hooks/useAssignmentList'
import { useDropDownSearch } from './../../../hooks/useDropDownSearch'
import { useBooleanState } from './../../../hooks/useBooleanState'
import { useModalValidation } from './../../../hooks/useModalValidation'

export const useAddToAssignmentModalHooks = (): [
  typeof useModelState,
  typeof useErrorState,
  typeof useAssignmentList,
  typeof useDropDownSearch,
  typeof useBooleanState,
  typeof useModalValidation,
] => [
  useModelState,
  useErrorState,
  useAssignmentList,
  useDropDownSearch,
  useBooleanState,
  useModalValidation,
]
