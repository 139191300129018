import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  label: {
    marginBottom: '16px',
    color: '#1B2B4B',
    height: '17px',
    fontSize: '14px',
    alignSelf: 'flex-start',
    fontFamily: 'Proxima Nova Light',
    lineHeight: '17px',
    '&.Mui-focused': {
      color: '#1B2B4B',
    },
  },
  zone: {
    border: '2px dashed #dedede',
    cursor: 'pointer',
    outline: 'none',
    padding: '4px',
    '.Mui-error ~ &': {
      borderColor: '#f44336',
    },
  },
  infoText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#1B2B4B',
    fontFamily: 'Proxima Nova Light',
  },
  files: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  fileItem: {
    background: '#f5f5f5',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 8px',
    overflow: 'hidden',
  },
  fileName: {
    fontSize: '12px',
    color: '#1B2B4B',
    fontFamily: 'Proxima Nova Regular',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  deleteIcon: {
    color: '#1B55C5',
    fontSize: '14px',
    marginLeft: '5px',
    cursor: 'pointer',
  },
}))
