/* eslint-disable no-undefined */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _, { debounce } from 'lodash'

import { XGridTable, Accordion } from '@ifellow/ui-library'
import { TableParams } from '@ifellow/ui-library/dist/components/x-grid-table/x-grid-table'

import { Copy, Tooltip } from '@gmini/ui-kit'

import { Authorities } from '../../../../../services/auth-service/auth-constants'

import { FileIcon } from '../../../../../icons/FileIcon'

import { EColumnType, EFileUploadListType } from '../../../../../constants'
import {
  TColumn,
  IColumn,
  TRowData,
  TFilterItems,
  TAssignmentLotRequest,
  IAssignmentColumn,
  IAssignmentXGridColumn,
  IFileResult,
} from '../../../../../types'

import { AssignmentTableDisplayColumns } from '../../../../../components/AssignmentTableDisplayColumns/AssignmentTableDisplayColumns'

import { assignmentColumnsMapper, rowsMapper } from '../../../../../utils/xgrid'

import { LocalStorageHelper, validatePrice } from '../../../../../utils'

import { renderCell } from '../../../../../utils/renders'

import { FileUpload } from '../../../../../components/FileUpload/FileUpload'

import { getLotCategory } from '../../../../../actions'
import { usePermissions } from '../../../../../hooks/usePermissions'

import * as I from './assignment-lot-accordion-types'
import useStyles from './style'

import {
  AgreementDate,
  AgreementProject,
  AgreementTitle,
  AgreementTooltip,
  HeaderWrapper,
  PositionBackground,
  PositionNumber,
  PriceText,
  PriceUpdatedBadge,
  PriceUpdatedBadgeTooltip,
  UpdatePriceButton,
} from './assignment-lot-accordion.styled'

const AssignmentLotAccordion: React.FC<I.OwnProps> = ({
  lotAndPositionIndex,
  assignmentId,
  assignmentName,
  assignmentClosed,
  category,
  lotId,
  agreements,
  positionColumns,
  positionData,
  suppliers,
  showSupplierWithAgreementOnly,
  visibleSuppliers,
  pagination,
  selectedRows,
  updateSelectedRowsState,
  handleSelectedVisibleSuppliers,
  addPropertyHandler,
  editPropertyHandler,
  removePropertyHandler,
  exportPriceHistoryHandler,
  toggleDrawer,
  updatePrice,
}): React.ReactElement => {
  const checkPermissions = usePermissions()
  const classes = useStyles()
  const dispatch = useDispatch()
  const files = React.useRef<IFileResult>({
    current: [],
    add: [],
    remove: [],
  })
  const [visiblePositionColumns, setVisiblePositionColumns] = useState<TColumn>(
    [],
  )
  const [visibleSupplierColumns, setVisibleSupplierColumns] = useState<TColumn>(
    [],
  )

  const [checkedRows, setCheckedRows] = useState<Array<string | number>>([])

  const hasAgreement = React.useCallback(
    (supplierId: string) =>
      suppliers.data.find(s => s.rowId === supplierId)?.hasAgreement,
    [suppliers.data],
  )

  const [dataGridRows, dataGridColumns] = useMemo(() => {
    let id =
      positionColumns.reduce((prev, col) => Math.max(prev, col.key), 0) + 1
    const ids: Record<string, Record<string, number>> = {}

    const cols: Array<IAssignmentColumn> = [
      ...positionColumns.filter(positionColumn =>
        visiblePositionColumns.some(
          visibleColumn => visibleColumn.key === positionColumn.key,
        ),
      ),
      ...suppliers.data
        .filter(
          (_, index, original) =>
            original[index].rowId !== original[index - 1]?.rowId,
        )
        .filter(supplier =>
          visibleSuppliers.some(
            visibleColumn => visibleColumn.key === supplier.rowId,
          ),
        )
        .reduce<Array<IAssignmentColumn>>((acc, supplier) => {
          ids[supplier.rowId] = {}
          ids[supplier.rowId][0] = id++
          return [
            ...acc,
            {
              base: true,
              editable: false,
              hidden: false,
              key: ids[supplier.rowId][0],
              required: false,
              section: null,
              title: 'цена',
              type: 'currency',
              measureUnitId: null,
              measureUnitSymbol: null,
              agreement: agreements?.find(
                ag => ag.providerId === supplier.rowId,
              ),
              supplierId: supplier.rowId,
              supplierName: supplier.name,
              hasAgreement: supplier.hasAgreement,
              isSupplierPrice: true,
              position: supplier.position || null,
            } as IAssignmentColumn,
            ...suppliers.columns
              .filter(supplierColumn =>
                visibleSupplierColumns.some(
                  visibleColumn => visibleColumn.key === supplierColumn.key,
                ),
              )
              .map(column => {
                ids[supplier.rowId][column.key] = id++
                return {
                  ...column,
                  title: column.title,
                  key: ids[supplier.rowId][column.key],
                  supplierId: supplier.rowId,
                  supplierName: supplier.name,
                  hasAgreement: supplier.hasAgreement,
                  position: supplier.position || null,
                  isSupplierPrice: false,
                }
              }),
          ]
        }, []),
    ]
    const gridCols: Array<IAssignmentXGridColumn> = assignmentColumnsMapper(
      cols,
    ).map((col: IAssignmentXGridColumn, index, origCols) => ({
      ...col,
      sortable: !col.supplierId,
      filterable: !col.supplierId,
      disableColumnMenu: !!col.supplierId,
      renderHeader: (props: { colDef: IAssignmentXGridColumn }) => {
        let tooltip: JSX.Element | null = null
        const { colDef } = props
        if (col.agreement) {
          const {
            number,
            start,
            expire,
            projectsAndObjects,
            providerId,
          } = col.agreement
          tooltip = (
            <Tooltip
              interactive
              noMaxWidth
              title={
                <AgreementTooltip>
                  <AgreementTitle>
                    <FileIcon color='#fff' opacity={0.5} />
                    <div>
                      <span>Соглашение № {number}: </span>
                      {assignmentName}
                    </div>
                  </AgreementTitle>
                  <AgreementDate>
                    <span>Начало/окончание действия</span>
                    <div>
                      {new Date(start).toLocaleDateString()} -{' '}
                      {new Date(expire).toLocaleDateString()}
                    </div>
                  </AgreementDate>
                  {projectsAndObjects.length ? (
                    projectsAndObjects.map(({ project, objects }) => (
                      <AgreementProject key={project.urn}>
                        {project.name}:
                        <span>
                          {objects.reduce(
                            (acc, o) =>
                              !acc
                                ? ` ${o?.name || 'Все объекты'}`
                                : `${acc}, ${o.name}`,
                            '',
                          )}
                        </span>
                      </AgreementProject>
                    ))
                  ) : (
                    <AgreementProject>
                      Все проекты: <span>Все объекты</span>
                    </AgreementProject>
                  )}
                  {checkPermissions(Authorities.ASSIGNMENTS_EDIT_COMPLETED) &&
                  assignmentClosed ? (
                    <FileUpload
                      initialState={files}
                      allowMultiplySelect={false}
                      listType={EFileUploadListType.FILE}
                      showAddBtn
                      showDeleteBtn
                      fileTypes={'xls,xlsx'}
                      maxNumberOfFiles={1}
                      fileSize={5}
                      onFileLoaded={({ files }, setVal) => {
                        if (files.add[0]?.file) {
                          validatePrice({
                            file: files.add[0]?.file,
                            onSuccess: () => {
                              updatePrice(files.add[0].file, providerId)
                              setVal(val => ({
                                ...val,
                                files: { current: [], add: [], remove: [] },
                              }))
                            },
                            onFailure: () =>
                              setVal(val => ({
                                ...val,
                                files: { current: [], add: [], remove: [] },
                              })),
                          })
                        }
                      }}
                      dataTestIdPrefix={`assignmentLotAccordion_${lotAndPositionIndex}_${providerId}`}
                      customButton={({ onClick, ...other }) => (
                        <UpdatePriceButton {...other} onClick={onClick}>
                          Обновить прайс-лист
                        </UpdatePriceButton>
                      )}
                    />
                  ) : null}
                </AgreementTooltip>
              }
            >
              <div
                data-test-id={`assignmentLotAccordion${lotAndPositionIndex}_OpenAgreementBtn_${number}`}
                onClick={() => window.open(`/agreements/0/${number}`, '_blank')}
                style={{ display: 'flex', cursor: 'pointer' }}
              >
                <FileIcon opacity={0.25} />
              </div>
            </Tooltip>
          )
        }

        return (
          <HeaderWrapper>
            <div className={classes.supplierHeader}>
              {col.supplierId &&
                hasAgreement(col.supplierId) &&
                col.agreement &&
                tooltip}
              <div className={classes.supplierNameWrap}>
                <div
                  className={classes.supplierName}
                  data-test-id={`assignmentLotAccordion_${lotAndPositionIndex}_OpenSupplierDrawerBtn_${colDef.supplierId}`}
                  onClick={event => {
                    event.stopPropagation()
                    event.preventDefault()
                    toggleDrawer(true, {
                      key: colDef.supplierId ?? '',
                      value: colDef.supplierName ?? '',
                    })
                  }}
                >
                  {colDef.supplierName}
                </div>
              </div>
            </div>
            <div className={classes.supplierHeader}>
              {col?.agreement?.versionDate ? (
                <PriceUpdatedBadgeTooltip
                  interactive
                  noMaxWidth
                  title={
                    <AgreementTooltip width={255}>
                      <AgreementTitle>
                        <Copy color='#fff' opacity={0.5} />
                        <div>
                          <span>Была осуществлена замена прайса</span>
                        </div>
                      </AgreementTitle>
                      <AgreementDate>
                        <div>Дата добавления</div>
                        <div>
                          {new Date(
                            col.agreement.versionDate,
                          ).toLocaleDateString()}
                        </div>
                      </AgreementDate>
                      {exportPriceHistoryHandler ? (
                        <UpdatePriceButton
                          data-test-id={`assignmentLotAccordion_${lotAndPositionIndex}_ExportPriceHistoryBtn_${col.agreement.number}`}
                          onClick={() => {
                            exportPriceHistoryHandler(
                              col.agreement?.number || '',
                            )
                          }}
                        >
                          Выгрузить динамику цен в xls
                        </UpdatePriceButton>
                      ) : null}
                    </AgreementTooltip>
                  }
                >
                  <PriceUpdatedBadge>Замена прайса</PriceUpdatedBadge>
                </PriceUpdatedBadgeTooltip>
              ) : null}
              <PriceText alignRight={Boolean(colDef.supplierId)}>
                {colDef.headerName}
              </PriceText>
            </div>
          </HeaderWrapper>
        )
      },
      renderCell: colDef => {
        const column = colDef.colDef as IAssignmentXGridColumn
        const supplier = suppliers.data.find(
          s => s.positionId === colDef.id && s.rowId === column.supplierId,
        )

        if (supplier?.position) {
          const getBackground = (position: number) => {
            switch (position % 3) {
              case 0:
                return '#ffdec3'
              case 1:
                return '#fef4cc'
              case 2:
                return '#eaeaf1'

              default:
                return undefined
            }
          }
          const value = renderCell(
            colDef,
            column.isSupplierPrice ? EColumnType.CURRENCY : EColumnType.STRING,
          )

          const valueExists = value && value !== '-'

          return (
            <>
              <PositionBackground
                background={
                  valueExists ? getBackground(supplier?.position) : undefined
                }
              />
              <div style={{ zIndex: 100 }}>
                {valueExists && (
                  <PositionNumber>{supplier?.position}</PositionNumber>
                )}
                <div>{value}</div>
              </div>
            </>
          )
        }
        return column.isSupplierPrice ? (
          <div>{renderCell(colDef, EColumnType.CURRENCY)}</div>
        ) : (
          <div>{colDef.value}</div>
        )
      },
    }))

    const gridRows = rowsMapper(positionData) as Array<Record<string, string>>
    suppliers.data
      .filter(supplier => ids[supplier.rowId])
      .filter(supplier =>
        positionData.some(position => position.rowId === supplier.positionId),
      )
      .forEach(supplierRow => {
        const gridRow = gridRows.find(
          item => item.id === supplierRow.positionId,
        )!
        gridRow[ids[supplierRow.rowId][0]] = supplierRow.price
        supplierRow.data.forEach(({ key, value }) => {
          gridRow[ids[supplierRow.rowId][key]] = value
        })
      })

    return [gridRows, gridCols]
  }, [
    positionColumns,
    suppliers.data,
    suppliers.columns,
    exportPriceHistoryHandler,
    positionData,
    visiblePositionColumns,
    visibleSuppliers,
    agreements,
    assignmentName,
    visibleSupplierColumns,
    classes.supplierHeader,
    classes.supplierNameWrap,
    classes.supplierName,
    hasAgreement,
    toggleDrawer,
    checkPermissions,
    updatePrice,
    assignmentClosed,
    lotAndPositionIndex,
  ])

  const handleChangeVisiblePositionColumns = React.useCallback(
    (data: TColumn): void => {
      const columns = _.uniqBy(data, 'key')

      setVisiblePositionColumns(columns)

      const page = `assignments_${assignmentId}_category_${category.key}_positions_${lotId}`

      LocalStorageHelper.set<TColumn>(page, columns)
    },
    [assignmentId, category.key, lotId],
  )

  const handleChangeVisibleSupplierColumn = React.useCallback(
    (data: TColumn): void => {
      const columns = _.uniqBy(data, 'key')

      setVisibleSupplierColumns(columns)

      const page = `assignments_${assignmentId}_category_${category.key}_lots_${lotId}`

      LocalStorageHelper.set<TColumn>(page, columns)
    },
    [assignmentId, category.key, lotId],
  )

  useEffect(() => {
    const page = `assignments_${assignmentId}_category_${category.key}_lots_${lotId}`
    const values = LocalStorageHelper.get<TColumn>(`${page}`)
    if (values === null) {
      const columnsWithRules = positionColumns.filter(
        col => !(col.title === 'Автор' || col.title === 'Дата создания'),
      )
      handleChangeVisiblePositionColumns(_.uniq(columnsWithRules))
      handleChangeVisibleSupplierColumn(_.uniq(positionColumns))
    }
  }, [
    positionColumns,
    assignmentId,
    category,
    lotId,
    handleChangeVisiblePositionColumns,
    handleChangeVisibleSupplierColumn,
  ])

  const handleClickPositionColumnCheckbox = React.useCallback(
    (column: IColumn): void => {
      const isVisibleColumn = visiblePositionColumns.some(
        visibleCol => visibleCol.key === column.key,
      )
      const newVisibleColumns = isVisibleColumn
        ? visiblePositionColumns.filter(
            visibleCol => visibleCol.key !== column.key,
          )
        : [...visiblePositionColumns, column]

      handleChangeVisiblePositionColumns(newVisibleColumns)
    },
    [handleChangeVisiblePositionColumns, visiblePositionColumns],
  )

  const handleClickSupplierColumnCheckbox = React.useCallback(
    (column: IColumn): void => {
      const isVisibleColumn = visibleSupplierColumns.some(
        visibleCol => visibleCol.key === column.key,
      )
      const newVisibleColumns = isVisibleColumn
        ? visibleSupplierColumns.filter(
            visibleCol => visibleCol.key !== column.key,
          )
        : [...visibleSupplierColumns, column]

      handleChangeVisibleSupplierColumn(newVisibleColumns)
    },
    [handleChangeVisibleSupplierColumn, visibleSupplierColumns],
  )

  useEffect(() => {
    const columnsWithRules = positionColumns.filter(
      col => !(col.title === 'Автор' || col.title === 'Дата создания'),
    )
    columnsWithRules.forEach(data => {
      if (data.base && !data.hidden) {
        if (
          _.findIndex(visiblePositionColumns, c => c.key === data.key) === -1
        ) {
          visiblePositionColumns.push(data)
        } else {
          const column = visiblePositionColumns.find(vc => vc.key === data.key)
          if (column) {
            _.extend(column, data)
          }
        }
      }
    })

    const page = `assignments_${assignmentId}_category_${category.key}_positions_${lotId}`

    const values = LocalStorageHelper.get<TColumn>(`${page}`)

    if (values?.length && visiblePositionColumns !== null) {
      _.xorBy(
        visiblePositionColumns,
        positionColumns.filter(c =>
          values
            ?.filter(value => value !== null && value !== undefined)
            .some(pc => pc.key === c.key),
        ),
        'key',
      ).forEach(column => visiblePositionColumns.push(column))
    }

    handleChangeVisiblePositionColumns(visiblePositionColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positionColumns])

  useEffect(() => {
    suppliers.columns.forEach(data => {
      if (data.base && !data.hidden) {
        if (
          _.findIndex(visibleSupplierColumns, c => c.key === data.key) === -1
        ) {
          visibleSupplierColumns.push(data)
        } else {
          const column = visibleSupplierColumns.find(vc => vc.key === data.key)
          if (column) {
            _.extend(column, data)
          }
        }
      }
    })

    const page = `assignments_${assignmentId}_category_${category.key}_lots_${lotId}`

    const cookies = LocalStorageHelper.get<TColumn>(`${page}`)

    if (cookies && cookies.length && visibleSupplierColumns !== null) {
      _.xorBy(
        visibleSupplierColumns,
        suppliers.columns.filter(c =>
          cookies
            ?.filter(cookie => cookie !== null && cookie !== undefined)
            .some(sc => sc.key === c.key),
        ),
        'key',
      ).forEach(column => visibleSupplierColumns.push(column))
    }

    handleChangeVisibleSupplierColumn(visibleSupplierColumns)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers.columns])

  useEffect(() => {
    const temp: TRowData = []

    if (
      showSupplierWithAgreementOnly &&
      suppliers.data.some(sd => sd.hasAgreement)
    ) {
      const supplier = suppliers.data.find(sd => sd.hasAgreement)
      if (supplier) {
        temp.push({ key: supplier.rowId, value: supplier.name })
      }
    } else {
      const columns = _.uniqBy(
        suppliers.data.map(sd => ({
          key: sd.rowId,
          title: sd.name,
          type: EColumnType.STRING,
          required: false,
          base: false,
          hidden: false,
          editable: false,
        })),
        'key',
      )

      // const page = `assignments_${assignmentId}_lots_${lotId}_suppliers`
      //
      // const cookies = LocalStorageHelper.get<TRowData>(`${page}`)
      //
      // if (cookies !== null && cookies.length) {
      //   _.uniqBy(
      //     columns.filter(c =>
      //       cookies
      //         ?.filter(cookie => cookie !== null && cookie !== undefined)
      //         .some(sc => sc.key === c.key),
      //     ),
      //     'key',
      //   ).forEach(column => temp.push({ key: column.key, value: column.title }))
      //   // _.xorBy(visibleData, );
      // } else {
      columns.forEach(data => temp.push({ key: data.key, value: data.title }))
      // }
    }

    handleSelectedVisibleSuppliers(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSupplierWithAgreementOnly])

  useEffect(() => {
    const newCheckedRows = (checkedRows as string[]).filter(checkedRow =>
      selectedRows.includes(checkedRow),
    )
    if (newCheckedRows.sort().join(',') !== checkedRows.sort().join(',')) {
      setCheckedRows(newCheckedRows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])

  const [tableParams, setTableParams] = useState<TableParams | undefined>()

  const getCurrentRequestParams = useCallback(
    (tableParams: TableParams) => {
      const params: TAssignmentLotRequest = {
        pageData: {
          page: tableParams.page + 1,
          perPage: tableParams.size,
          categoryId: category.key,
          ascending: tableParams.sort?.sort === 'asc',
          fieldKey: tableParams.sort?.field,
          tenderId: +assignmentId,
          lotId: +lotId,
        },
        itemConditions: tableParams.filters as TFilterItems[],
        fetchCategories: true,
        table: 'items',
      }
      return params
    },
    [category, assignmentId, lotId],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleParametersChange = useCallback(
    debounce((params: TableParams) => {
      if (!tableParams) {
        setTableParams(params)
      } else {
        const curParams = getCurrentRequestParams(params)
        dispatch(
          getLotCategory({
            ...curParams,
            pageData: {
              ...curParams.pageData,
              page: params.page + 1,
              perPage: params.size,
              ascending: params.sort?.sort === 'asc',
              fieldKey: params.sort?.field,
            },
            itemConditions: params.filters as TFilterItems[],
          }),
        )
      }
    }, 500),
    [tableParams, getCurrentRequestParams],
  )

  const handleSelectionModelChange = useCallback(
    selectionModel => {
      const deletedIds = checkedRows.filter(
        item => !selectionModel.includes(item),
      )
      const addedIds = selectionModel.filter(
        (item: any) => !checkedRows.includes(item),
      )

      setCheckedRows(selectionModel)

      const newSelectedRow = selectedRows
        .filter(item => !deletedIds.includes(item))
        .concat(addedIds as string[])

      updateSelectedRowsState(newSelectedRow)
    },
    [checkedRows, selectedRows, updateSelectedRowsState],
  )

  return (
    <Accordion
      dataTestIdPrefix='assignmentLotAccordion'
      title={category.value}
      className={classes.accordion}
      actions={
        <div className={classes.accordionActions}>
          <AssignmentTableDisplayColumns
            positionColumns={positionColumns}
            lotColumns={suppliers.columns}
            visibleColumns={visiblePositionColumns}
            visibleData={visibleSupplierColumns}
            lotId={lotId}
            showSupplierButtons={checkPermissions(
              Authorities.ASSIGNMENT_DETAILS_EDIT_SUPPLIER_PROPERTIES,
            )}
            addPropertyHandler={addPropertyHandler}
            editPropertyHandler={editPropertyHandler}
            removePropertyHandler={(
              key: string,
              callback: () => void,
            ): void => {
              const cb = (): void => {
                callback()
                handleChangeVisibleSupplierColumn(
                  visibleSupplierColumns.filter(svd => svd.key !== key),
                )
              }
              removePropertyHandler(key, cb)
            }}
            onColumnVisibilityChanged={handleClickPositionColumnCheckbox}
            onDataVisibilityChanged={handleClickSupplierColumnCheckbox}
            dataTestIdPrefix={`assignmentLotAccordionTable${lotAndPositionIndex}`}
          />
        </div>
      }
    >
      <div className={classes.tableWrapper}>
        <XGridTable
          selectionModel={checkedRows}
          rowsPerPageOptions={[10, 20, 50]}
          pageSize={pagination.rowsPerPage}
          pageStart={pagination.page - 1}
          mode='server'
          autoHeight={true}
          rows={dataGridRows}
          columns={dataGridColumns}
          rowCount={pagination.total}
          disableFiltering={false}
          onParametersChange={handleParametersChange}
          onSelectionModelChange={handleSelectionModelChange}
          columnBuffer={10}
          dataTestIdPrefix={`assignmentLotAccordionTable_${lotAndPositionIndex}`}
        />
      </div>
    </Accordion>
  )
}

export default AssignmentLotAccordion
