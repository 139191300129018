import React, { memo } from 'react'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import { TTenderCondition } from '../../tender-conditions.types'

import styles from './styles.module.scss'

export type TCondition = {
  name: string
}

type TConditionList = {
  list: TTenderCondition
  handleSelectedList?: (conditions: Array<string>) => void
}

export const ConditionListPL = ({
  list,
  handleSelectedList,
}: TConditionList) => {
  const [checkedList, setCheckedList] = React.useState<Array<string>>([])

  const handleChange = (condition: string) => (
    event: React.ChangeEvent<HTMLElement>,
  ): void => {
    const hasItem = checkedList.find(el => el === condition)

    const newCheckedList = !hasItem
      ? [...checkedList, condition]
      : checkedList.filter(el => el !== condition)

    setCheckedList(newCheckedList)
    if (handleSelectedList) {
      handleSelectedList(newCheckedList)
    }
  }

  if (!list.length) {
    return null
  }

  return (
    <List className={styles.root} subheader={<li />}>
      {list.map(({ label, conditions }, idx) => (
        <li
          data-test-id={`conditionListItem_${idx}`}
          key={label}
          className={styles.listSection}
        >
          <ul className={styles.ul}>
            <ListSubheader className={styles.listSubheader}>
              {label}
            </ListSubheader>
            {conditions.map((condition: string, index) => (
              <ListItem
                data-test-id={`conditionListItemCondition_${index}`}
                key={condition}
              >
                <ListItemText primary={condition} />
                <Checkbox
                  data-test-id={`conditionListItemCondition_${index}Checkbox`}
                  color='primary'
                  checked={Boolean(checkedList.find(el => el === condition))}
                  onChange={handleChange(condition)}
                />
              </ListItem>
            ))}
          </ul>
        </li>
      ))}
    </List>
  )
}

export const ConditionList = memo(ConditionListPL)
