import React, { useState } from 'react'
import { Toolbar, Typography, Popover } from '@material-ui/core'
import clsx from 'clsx'

import { toastr } from 'react-redux-toastr'

import { Authorities } from '../../../services/auth-service/auth-constants'

import { ButtonComponent } from '../../Button/Button'

import { IImprovedTableToolbar } from '../../../types'
import { EButtonType, EButtonIcon } from '../../../constants'

import { PositionCatalogMini } from '../../PositionCatalogMini/PositionCatalogMini'

import useToolbarStyles from './Styles'

const emptyFunc = (): number => 0

export const ImprovedTableToolbar: React.FC<IImprovedTableToolbar> = ({
  selectedRowsCount = 0,
  showAddBtn = false,
  showMoveToLotsBtn = false,
  addBtnText = '',
  TitleComponent,
  onAddBtnClick = emptyFunc,
  onMoveToLotsBtnClick = emptyFunc,
  onSelectNode = emptyFunc,
  onGetCheckedCategories,
  authRoleChecker,
  isDeepTreeLevel,
  dataTestIdPrefix,
}: IImprovedTableToolbar): React.ReactElement => {
  const classes = useToolbarStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <Toolbar
      disableGutters={true}
      className={clsx(classes.root, {
        [classes.highlight]: selectedRowsCount > 0,
      })}
    >
      {selectedRowsCount > 0 ? (
        <Typography
          className={classes.title}
          variant='subtitle1'
          component='div'
        >
          {selectedRowsCount} Выбрано
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='subtitle2'
          id='tableTitle'
          component='div'
        >
          {TitleComponent}
          <div className={classes.buttonContainer}>
            <div className={classes.filterButtonContainerList}>
              <ButtonComponent
                text='Каталог'
                data-test-id={`${dataTestIdPrefix}OpenCatalogBtn`}
                type={EButtonType.PRIMARY}
                typeIcon={EButtonIcon.ARROW_UP}
                flagCatalog={true}
                onClick={
                  authRoleChecker && authRoleChecker(Authorities.ITEMS_PAGE)
                    ? handleClick
                    : (): void => toastr.error('', 'Доступ запрещен')
                }
              />
              <Popover
                anchorEl={anchorEl}
                id={'simple-popover'}
                className={classes.menuCatalog}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <PositionCatalogMini
                  anchorEl={anchorEl}
                  onSelectNode={onSelectNode}
                  onGetCheckedCategories={onGetCheckedCategories}
                  dataTestIdPrefix={dataTestIdPrefix}
                />
              </Popover>
            </div>
            {isDeepTreeLevel && (
              <>
                <ButtonComponent
                  data-test-id={`${dataTestIdPrefix}AddPositionBtn`}
                  text={addBtnText}
                  type={EButtonType.DEFAULT}
                  typeIcon={EButtonIcon.ADD}
                  hidden={!showAddBtn}
                  onClick={onAddBtnClick}
                />
              </>
            )}
          </div>
        </Typography>
      )}
      {selectedRowsCount > 0 && (
        <div className={classes.buttonContainer}>
          <ButtonComponent
            data-test-id={`${dataTestIdPrefix}AddToLotBtn`}
            text='Добавить в лот'
            type={EButtonType.PRIMARY}
            typeIcon={EButtonIcon.ADD}
            hidden={showMoveToLotsBtn}
            onClick={onMoveToLotsBtnClick}
          />
        </div>
      )}
    </Toolbar>
  )
}
