import moment from 'moment'

import { IColumn } from '../../types'
import { EColumnType, REVERSE_DISPLAY_DATE_FORMAT } from '../constants'

export const validateFloatProp = (value: string): boolean => {
  if (!value) {
    return false
  }
  return isNaN(parseFloat(value)) || parseFloat(value) < 0
}

export const validateIntegerProp = (value: string): boolean => {
  if (!value) {
    return false
  }
  return isNaN(Number(value)) || Number(value) < 0
}

export const validateDateProp = (value: string): boolean => {
  if (!value) {
    return false
  }

  return !moment(
    value,
    [
      'YYYY-MM-DDTHH:mm:ss.msZ',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DDTHH:mm',
      REVERSE_DISPLAY_DATE_FORMAT,
      'YYYY-MM-DDT00:00',
      'YYYY-MM-DDTHH:mm:ss.x',
    ],
    true,
  ).isValid()
}

export const validatePhoneProp = (value: string): boolean =>
  value.indexOf('*') !== -1 || !value.length

export const validateBooleanProp = (value: boolean): boolean => value

export const validateRequiredProp = (value: string, prop: IColumn): boolean => {
  switch (prop.type) {
    case EColumnType.PHONE:
      return validatePhoneProp(value)

    case EColumnType.BOOLEAN:
      return validateBooleanProp(value === 'true' ? false : true)

    default:
      return !value || !value.trim()
  }
}

const getValidateMessage = (prop: IColumn, value: string): string => {
  let message = ''

  if (
    prop.required &&
    validateRequiredProp(value, prop) &&
    prop.type !== EColumnType.BOOLEAN
  ) {
    message = 'Обязательное поле'
  }

  if (prop.type === EColumnType.INTEGER && validateIntegerProp(value)) {
    message = 'Только целые числа'
  }

  if (
    prop.type === EColumnType.PHONE &&
    value.length &&
    validatePhoneProp(value)
  ) {
    message = 'Номер введён не полностью'
  }

  if (prop.type === EColumnType.BOOLEAN) {
    const normalizeValue = String(Boolean(value))
    if (!(normalizeValue === 'false' || normalizeValue === 'true')) {
      message = 'Только логическое значение'
    }
  }

  if (prop.type === EColumnType.DATE && validateDateProp(value)) {
    message = 'Неверная дата'
  }

  if (prop.type === EColumnType.DOUBLE || prop.type === EColumnType.CURRENCY) {
    if (validateFloatProp(value)) {
      message = 'Только целые или дробные числа'
    }
  }

  if (prop.type === EColumnType.STRING && prop.title === 'ИНН') {
    if ((prop.required || value.length) && !validateInn(value)) {
      message = 'ИНН не корректный'
    }
  }

  if (prop.type === EColumnType.STRING && prop.title === 'КПП') {
    if ((prop.required || value.length) && !validateKpp(value)) {
      message = 'КПП не корректный'
    }
  }

  if (
    prop.type === EColumnType.STRING &&
    ['email', 'e-mail', 'емайл'].includes(prop.title.toLowerCase())
  ) {
    if ((prop.required || value.length) && !validateEmail(value)) {
      message = 'Email не корректный'
    }
  }

  return message
}

export const validate = (
  prop: IColumn,
  value: string,
  errors: { [key: string]: string },
  setErrors: (errors: { [key: string]: string }) => void,
): string => {
  const msg = getValidateMessage(prop, value)

  setErrors({ ...errors, [prop.key]: msg })
  return msg
}

export function validateInn(inn: string): boolean {
  let isValid = false
  const multipliers = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
  const ch: Array<number> = [0, 0, 0]

  const innArray: Array<number> = inn.split('').map(item => +item)

  for (let i = 0; i < 12; i++) {
    for (let j = 0; j < 3; j++) {
      if (multipliers[i + j]) {
        ch[j] += innArray[i] * multipliers[i + j]
      }
    }
  }

  if (innArray.length === 10) {
    isValid = innArray[9] === (ch[2] % 11) % 10
  }
  if (innArray.length === 12) {
    isValid =
      innArray[10] === (ch[1] % 11) % 10 && innArray[11] === (ch[0] % 11) % 10
  }

  return isValid
}

export function validateKpp(kpp: string): boolean {
  return /^\d{9}$/u.test(kpp)
}

export function validateEmail(email: string): boolean {
  return !!email.match(
    // eslint-disable-next-line require-unicode-regexp,prefer-named-capture-group
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}
