import { EActionTypes } from '../../constants'

import { User } from '../../types'

import { IBaseActionCreator } from '../../actions/BaseAction'

export type ISetUserDataAction = IBaseActionCreator<
  EActionTypes.SET_USER_DATA,
  User
>

export type TUserActions = ISetUserDataAction

const initialState: User = {
  companyId: '',
  email: '',
  id: '',
  phone: '',
  name: '',
  roles: {},
  hubPermissions: {},
  permissions: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: TUserActions): User => {
  switch (action.type) {
    case EActionTypes.SET_USER_DATA: {
      return action.payload
    }
    default:
      return state
  }
}
