import { SvgComponentType } from '@gmini/ui-kit'
import React from 'react'

export const ArrowIcon = ({
  color = '#4c5ecf',
  ...props
}: SvgComponentType) => (
  <svg
    width='8'
    height='12'
    viewBox='0 0 8 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.9107 0.920485C2.52018 0.529961 1.88701 0.529961 1.49649 0.920486L1.2647 1.15227C0.874569 1.5424 0.874123 2.1748 1.26371 2.56548L4.68859 6.00005L1.2657 9.42294C0.875177 9.81346 0.875176 10.4466 1.2657 10.8372L1.49649 11.0679C1.88701 11.4585 2.52018 11.4585 2.9107 11.0679L7.16193 6.81671C7.37193 6.60671 7.50026 6.31505 7.50026 6.00005C7.50026 5.68505 7.37193 5.39338 7.16193 5.17171L2.9107 0.920485Z'
      fill={color}
    />
  </svg>
)
